import type { IProductOptionValueDiscount } from '~/shared/api/v1/products/types';

export const getMaxDiscount = (discounts: Array<IProductOptionValueDiscount>) => {
  let maxDiscount: IProductOptionValueDiscount = discounts[0];

  discounts.forEach((elem) => {
    if (maxDiscount.to < elem.to) {
      maxDiscount = elem;
    }
  });

  return maxDiscount;
};
