import BaseApi from '../..';
import type { IRequestParamsHandler, IResponseWithPaging } from '../../types';
import type { ICategoryFromTree } from '../categories/types';
import type { IProductApiV1 } from '../products/types';
import type { IShopApiV1 } from '../shops/types';
import type { IBannersResponse, ICustomBanner, ISectionsResponse } from './types';

class HomeApiV1 extends BaseApi {
  private VERSION = 'v1';
  private RESOURCE = 'home';

  async getHomeCustomBanners(params?: IRequestParamsHandler<IResponseWithPaging<ICustomBanner>>) {
    const url = `${this.VERSION}/${this.RESOURCE}/customBanners`;

    const requestAsyncData = await useAsyncData<IResponseWithPaging<ICustomBanner>>(
      url,
      () =>
        this.get({
          url,
          query: params?.params,
          fetchOptions: params?.fetchOptions,
          errorOptions: params?.errorOptions,
        }),
      params?.asyncDataOptions,
    );

    return requestAsyncData;
  }

  async getHomeCategories(params?: IRequestParamsHandler<Array<ICategoryFromTree>>) {
    const url = `${this.VERSION}/${this.RESOURCE}/categories`;

    const requestAsyncData = await useAsyncData<Array<ICategoryFromTree>>(
      url,
      () =>
        this.get({
          url,
          query: params?.params,
          fetchOptions: params?.fetchOptions,
          errorOptions: params?.errorOptions,
        }),
      params?.asyncDataOptions,
    );

    return requestAsyncData;
  }

  async getHomeShops(params?: IRequestParamsHandler<Array<IShopApiV1>>) {
    const url = `${this.VERSION}/${this.RESOURCE}/shops`;

    const requestAsyncData = await useAsyncData<Array<IShopApiV1>>(
      url,
      () =>
        this.get({
          url,
          query: params?.params,
          fetchOptions: params?.fetchOptions,
          errorOptions: params?.errorOptions,
        }),
      params?.asyncDataOptions,
    );

    return requestAsyncData;
  }

  async getHomeBanners(params?: IRequestParamsHandler<IBannersResponse>) {
    const url = `${this.VERSION}/${this.RESOURCE}/images`;

    const requestAsyncData = await useAsyncData<IBannersResponse>(
      url,
      () =>
        this.get({
          url,
          query: params?.params,
          fetchOptions: params?.fetchOptions,
          errorOptions: params?.errorOptions,
        }),
      params?.asyncDataOptions,
    );

    return requestAsyncData;
  }

  async getHomeSections(params?: IRequestParamsHandler<ISectionsResponse>) {
    const url = `${this.VERSION}/${this.RESOURCE}/sections`;

    const requestAsyncData = await useAsyncData<ISectionsResponse>(
      url,
      () =>
        this.get({
          url,
          query: params?.params,
          fetchOptions: params?.fetchOptions,
          errorOptions: params?.errorOptions,
        }),
      params?.asyncDataOptions,
    );

    return requestAsyncData;
  }

  async getHomeProductsNew(params?: IRequestParamsHandler<Array<IProductApiV1>>) {
    const url = `${this.VERSION}/${this.RESOURCE}/products/new`;

    return this.get<Array<IProductApiV1>>({
      url,
      query: params?.params,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }
}

export default HomeApiV1;
