export default defineNuxtComponent({
  name: 'WiNuxtErrorBoundary',

  setup() {
    const errorLogger = (error: any) => {
      console.error(error);
    };

    return {
      errorLogger,
    };
  },
});
