import { useMobile } from '~/shared/hooks/useMobile';
import { useProfileLayoutModel } from '../model';

import MainLayout from '~/app/layouts/main/ui/index.vue';
import WiCommonHeader from '~/shared/ui/header/WiCommonHeader/ui/index.vue';
import WiCommonFooter from '~/shared/ui/WiCommonFooter/index.vue';
import WiCommonMobileControl from '~/shared/ui/WiCommonMobileControl/ui/index.vue';
import WiNuxtErrorBoundary from '~/shared/ui/WiNuxtErrorBoundary/index.vue';
import WiProfileMenu from '../components/WiProfileMenu/index.vue';

export default defineNuxtComponent({
  name: 'ProfileLayout',

  components: {
    WiNuxtErrorBoundary,
    MainLayout,
    WiCommonHeader,
    WiCommonFooter,
    WiCommonMobileControl,
    WiProfileMenu,
  },

  props: {
    isHideFooter: {
      type: Boolean,
      default: false,
    },
    isHideMobileControl: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const { isMobile } = useMobile();
    const { getShopOrdersStatistic } = useProfileLayoutModel();
    const { shopOrdersStatusesStatistic } = storeToRefs(useGlobalUserStore());
    const { t } = useI18n();

    useSeoMeta({
      title: t('pageTitles.profile'),
    });

    onMounted(() => {
      getShopOrdersStatistic();
    });

    return {
      isMobile,
      shopOrdersStatusesStatistic,
    };
  },
});
