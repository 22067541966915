import { globalFeaturesFlagsStore } from '~/app/store/featuresFlags';
import type { IRequestParams } from '~/shared/api/types';
import type { IApiFeatureFlag } from '~/shared/api/v1/featureFlags/types';

export const useDefaultLayoutApi = () => {
  const { $api: api } = useNuxtApp();

  const useGlobalFeaturesFlagsStore = globalFeaturesFlagsStore();

  const getFeaturesFlagsData = async () => {
    const payload: IRequestParams<IApiFeatureFlag> = {
      page: 1,
      limit: 100,
    };

    const { data } = await api.featuresFlags.getFeaturesFlags({
      params: payload,
      asyncDataOptions: { server: true },
    });

    if (data.value) {
      useGlobalFeaturesFlagsStore.setFeaturesFlags(data.value.items);
    }
  };

  const auth = async () => {
    const data = await api.auth.auth();

    return data;
  };

  return {
    getFeaturesFlagsData,
    auth,
  };
};
