import { StorageService } from '../browserAPI/storageService';
import { globalUiConfigStore } from '~/app/store/uiConfig';

export const setThemeMode = () => {
  const storage = new StorageService('local');
  const isDark = storage.getItem('Theme', false) === 'dark';
  const uiConfigStore = globalUiConfigStore();

  const documentElement = document.documentElement;

  if (documentElement) {
    if (isDark) {
      documentElement.classList.remove('light');
      documentElement.classList.add('dark');

      uiConfigStore.setIsDarkMode(true);
    } else {
      documentElement.classList.add('light');
      documentElement.classList.remove('dark');

      uiConfigStore.setIsDarkMode(false);
    }
  }
};

export const changeThemeMode = () => {
  const storage = new StorageService('local');
  const isDark = storage.getItem('Theme', false) === 'dark';
  const uiConfigStore = globalUiConfigStore();

  storage.setItem('Theme', isDark ? 'light' : 'dark');

  uiConfigStore.setIsDarkMode(!isDark);

  setThemeMode();
};
