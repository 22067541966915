import WiMobileMenu from '~/shared/ui/WiMobileMenu/ui/index.vue';
import WiMobileControlCategoryContent from '~/shared/ui/mobileControl/modules/legacy/WiMobileControlCategoryContent/ui/index.vue';
import WiMobileControlWrapper from '~/shared/ui/mobileControl/modules/WiMobileControlWrapper/ui/index.vue';
import { Icons } from '@wikkeo/ui-kit';

import type { IApiCategoriesTree } from '~/shared/api/v1/categories/types';
import useAppRouter from '~/shared/hooks/useAppRouter';

const { WiCategoryMenu } = Icons;

interface IShowCategory {
  id: number;
  level: string;
  slug: string;
  name: string;
}

export default defineNuxtComponent({
  name: 'WiMobileControlCategory',

  components: {
    WiMobileMenu,
    WiCategoryMenu,
    WiMobileControlCategoryContent,
    WiMobileControlWrapper,
  },

  props: {
    categories: {
      type: Array as PropType<Array<IApiCategoriesTree> | null>,
      default: () => [],
    },

    isLoadingCategories: {
      type: Boolean,
    },
  },

  setup() {
    const isOpenMenuCategory = ref(false);
    const router = useAppRouter();

    const isActive = toRef(() => router.route.value.meta.isCategoryPage);

    const showCategory = ref({
      1: null,
      2: null,
      3: null,
    }) as any;

    const levelShowCategory = computed(() => {
      if (showCategory.value[2]) {
        return 3;
      }

      if (showCategory.value[1]) {
        return 2;
      }

      return 1;
    });

    const showSubCategories = (data: IShowCategory) => {
      const { id, level, slug, name } = data;

      if (showCategory.value[level] === undefined) {
        return;
      }

      showCategory.value[level] = { id, name, slug };

      if (level === '2') {
        showCategory.value[3] = null;
      }
    };

    const handleToggleCategoryMenu = () => {
      isOpenMenuCategory.value = !isOpenMenuCategory.value;
    };

    const handleCloseCategoryMenu = () => {
      isOpenMenuCategory.value = false;

      clearShowCategory();
    };

    const clearShowCategory = () => {
      showCategory.value = {
        1: null,
        2: null,
        3: null,
      };
    };

    const onLevelDownCategory = () => {
      if (showCategory.value[2]?.id) {
        showCategory.value[2] = null;
        return;
      }

      if (showCategory.value[1]?.id) {
        showCategory.value[1] = null;
        return;
      }
    };

    return {
      isActive,
      isOpenMenuCategory,
      showCategory,
      levelShowCategory,

      handleCloseCategoryMenu,
      handleToggleCategoryMenu,
      showSubCategories,
      onLevelDownCategory,
    };
  },
});
