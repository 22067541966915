import WiNuxtErrorBoundary from '~/shared/ui/WiNuxtErrorBoundary/index.vue';

export default defineNuxtComponent({
  name: 'WiStaticWrapperControlBlock',

  props: {
    isWithBlur: {
      type: Boolean,
      default: true,
    },

    minHeight: {
      type: String,
      default: '66px',
    },
  },

  components: {
    WiNuxtErrorBoundary,
  },
});
