import { ShopEntity } from '~/entities/shop';
import type { IShopApiV1 } from '~/shared/api/v1/shops/types';

export class ShopAdapter {
  public fromApi(shop: IShopApiV1) {
    return new ShopEntity({
      id: shop.id,
      userId: shop.userId,
      slug: shop.slug,
      name: shop.name,
      description: shop.description,
      sumMin: shop.sumMin,
      isActive: shop.isActive,
      rating: shop.rating,
      socialSettings: shop.socialSettings,
      createdAt: shop.createdAt,
      ordersCount: shop.ordersCount,
      reviewsCount: shop.reviewsCount,
      answerInterval: shop.answerInterval,
      wholesale: shop.wholesale,
      isVerified: shop.isVerified,
      image: shop.image,
      banner: shop.banner,
      bannerV2: shop.bannerV2,
      metrics: shop.metrics,
      chatChannelInfo: shop.chatChannelInfo,
    });
  }
}
