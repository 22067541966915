export default {
  "default-api": () => import("/workspace/www/wikkeo-main-frontend/app/layouts/default/api/index.ts").then(m => m.default || m),
  default: () => import("/workspace/www/wikkeo-main-frontend/app/layouts/default/index.ts").then(m => m.default || m),
  "default-model": () => import("/workspace/www/wikkeo-main-frontend/app/layouts/default/model/index.ts").then(m => m.default || m),
  "default-ui": () => import("/workspace/www/wikkeo-main-frontend/app/layouts/default/ui/index.ts").then(m => m.default || m),
  main: () => import("/workspace/www/wikkeo-main-frontend/app/layouts/main/index.ts").then(m => m.default || m),
  "main-ui": () => import("/workspace/www/wikkeo-main-frontend/app/layouts/main/ui/index.ts").then(m => m.default || m),
  "profile-api": () => import("/workspace/www/wikkeo-main-frontend/app/layouts/profile/api/index.ts").then(m => m.default || m),
  "profile-components-wi-profile-menu": () => import("/workspace/www/wikkeo-main-frontend/app/layouts/profile/components/WiProfileMenu/index.ts").then(m => m.default || m),
  "profile-components-wi-profile-menu-item": () => import("/workspace/www/wikkeo-main-frontend/app/layouts/profile/components/WiProfileMenuItem/index.ts").then(m => m.default || m),
  profile: () => import("/workspace/www/wikkeo-main-frontend/app/layouts/profile/index.ts").then(m => m.default || m),
  "profile-model": () => import("/workspace/www/wikkeo-main-frontend/app/layouts/profile/model/index.ts").then(m => m.default || m),
  "profile-ui": () => import("/workspace/www/wikkeo-main-frontend/app/layouts/profile/ui/index.ts").then(m => m.default || m)
}