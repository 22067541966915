import BaseApi from '../..';
import type { IRequestParamsHandler } from '../../types';
import type {
  ICartShopPromotion,
  ICartShopPromotionsValidate,
  ICartsRequestPayload,
  ICartsResponse,
  IShippingCompaniesByShopsIdsRequest,
  IShippingCompaniesByShopsIdsResponse,
} from './types';

class CartsApiV1 extends BaseApi {
  private VERSION = 'v1';
  private RESOURCE = 'carts';

  async validateCartsProducts(params: IRequestParamsHandler<ICartsRequestPayload>): Promise<ICartsResponse> {
    const url = `${this.VERSION}/${this.RESOURCE}/validate`;

    return this.post({
      url,
      body: params?.data,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
      isRaw: params?.isRaw,
    });
  }

  async addCartsProducts(params: IRequestParamsHandler<ICartsRequestPayload>): Promise<ICartsResponse> {
    const url = `${this.VERSION}/${this.RESOURCE}/products`;

    return this.post({
      url,
      body: params?.data,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
      isRaw: params?.isRaw,
    });
  }

  async getCartsProducts(params?: IRequestParamsHandler<ICartsRequestPayload>): Promise<ICartsResponse> {
    const url = `${this.VERSION}/${this.RESOURCE}`;

    return this.get({
      url,
      body: params?.data,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
      isRaw: params?.isRaw,
    });
  }

  async removeCartsProducts(params: IRequestParamsHandler<ICartsRequestPayload>): Promise<ICartsResponse> {
    const url = `${this.VERSION}/${this.RESOURCE}/products`;

    return this.delete({
      url,
      body: params?.data,
      query: params?.params,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
      isRaw: params?.isRaw,
    });
  }

  async validateShopPromotions(
    params: IRequestParamsHandler<Array<ICartShopPromotion>, ICartShopPromotionsValidate>,
  ): Promise<Array<ICartShopPromotion>> {
    const url = `${this.VERSION}/${this.RESOURCE}/shopPromotions/validate`;

    return this.post({
      url,
      body: params?.data,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
      isRaw: params?.isRaw,
    });
  }

  async getShippingCompaniesByShopsIds(
    params: IRequestParamsHandler<Array<IShippingCompaniesByShopsIdsResponse>, IShippingCompaniesByShopsIdsRequest>,
  ): Promise<Array<IShippingCompaniesByShopsIdsResponse>> {
    const url = `${this.VERSION}/${this.RESOURCE}/shippingCompanies/validate`;

    return this.post({
      url,
      body: params?.data,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
      isRaw: params?.isRaw,
    });
  }
}

export default CartsApiV1;
