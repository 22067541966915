import { StringUtils } from '~/shared/utils/string';

export const useWiInputCountModel = (
  value: Ref<number>,
  min: Ref<number>,
  max: Ref<number>,
  step: Ref<number>,
  isNeedZero: Ref<boolean>,
  isDisabled?: Ref<boolean>,
) => {
  const minDecrement = computed(() => {
    return isNeedZero.value ? 0 : min.value;
  });

  const isDisableDecrement = computed(() => value.value - step.value < minDecrement.value || isDisabled?.value);

  const isDisableIncrement = computed(
    () => minDecrement.value > max.value || value.value + step.value > max.value || isDisabled?.value,
  );

  const isDisableInput = computed(() => isDisableDecrement.value && isDisableIncrement.value);

  const getChangedValueIncrement = () => {
    let newValue = value.value + step.value;

    if (newValue < min.value) {
      newValue = min.value;
    }

    const delta = newValue % step.value;

    if (delta > 0) {
      const integerDivisor = parseInt((newValue / step.value).toString());

      const increment = (integerDivisor + 1) * step.value < max.value ? 1 : 0;

      return (integerDivisor + increment) * step.value;
    }

    return newValue;
  };

  const getChangedValueDecrement = () => {
    const newValue = value.value - step.value;

    return newValue < min.value ? minDecrement.value : newValue;
  };

  const getChangedValueInput = (valueCandidate: string) => {
    const parseOnlyNumber = StringUtils.getOnlyNumbers(valueCandidate);

    if (parseOnlyNumber === '') {
      return minDecrement.value;
    }

    let numberValueCandidate = parseInt(parseOnlyNumber);

    if (numberValueCandidate < min.value) {
      numberValueCandidate = minDecrement.value;
    }

    if (numberValueCandidate > max.value) {
      numberValueCandidate = max.value;
    }

    const delta = numberValueCandidate % step.value;

    if (delta > 0) {
      const integerDivisor = parseInt((numberValueCandidate / step.value).toString());

      const increment = (integerDivisor + 1) * step.value < max.value ? 1 : 0;

      return (integerDivisor + increment) * step.value;
    }

    return numberValueCandidate;
  };

  return {
    isDisableDecrement,
    isDisableIncrement,
    isDisableInput,

    getChangedValueIncrement,
    getChangedValueDecrement,
    getChangedValueInput,
  };
};
