<template>
  <WiNuxtErrorBoundary>
    <button class="wi-header-search-options-button" @click="handlerDropdown">
      <div
        class="wi-header-search-options-button__body wi-desktop-version"
        :class="{
          'wi-header-search-options-button__body_open-dropdown': isOpenDropdown,
        }"
      >
        <div v-show="userDataForSearch.selectOption === null" class="wi-header-search-options-button__body-item">
          <NuxtLink to="/" class="wi-header-search-options-button__logo-wrapper" @click.stop>
            <WiLogo class="wi-header-search-options-button__logo" />
          </NuxtLink>

          <span class="wi-header-search-options-button__text">Везде</span>

          <div class="wi-header-search-options-button__icon-wrapper">
            <WiChevron
              class="wi-header-search-options-button__icon"
              :class="[isOpenDropdown ? 'wi-header-search-options-button__icon_dropdown-open' : '']"
            />
          </div>
        </div>

        <ClientOnly>
          <div
            v-if="shopData"
            v-show="userDataForSearch?.selectOption === 'shop'"
            class="wi-header-search-options-button__body-item wi-header-search-options-button__body-item_select"
          >
            <span class="wi-header-search-options-button__body-item-text">{{ shopData.name }}</span>

            <button
              class="wi-header-search-options-button__body-item-close-icon-wrapper wi-pointer"
              @click.stop.prevent="changeSelectOption(null)"
            >
              <WiClose class="wi-header-search-options-button__body-item-close-icon" />
            </button>
          </div>

          <div
            v-if="categoryData"
            v-show="userDataForSearch?.selectOption === 'category'"
            class="wi-header-search-options-button__body-item wi-header-search-options-button__body-item_select"
          >
            <span class="wi-header-search-options-button__body-item-text">{{ categoryData.name }}</span>
            <button
              class="wi-header-search-options-button__body-item-close-icon-wrapper wi-pointer"
              @click.stop.prevent="changeSelectOption(null)"
            >
              <WiClose class="wi-header-search-options-button__body-item-close-icon" />
            </button>
          </div>
        </ClientOnly>
      </div>

      <div
        v-show="isOpenDropdown"
        class="wi-header-search-options-button__dropdown wi-desktop-version"
        :class="{ 'wi-header-search-options-button__dropdown_show': isOpenDropdown }"
      >
        <div class="wi-header-search-options-button__dropdown-item" @click="changeSelectOption(null)">Везде</div>

        <div v-if="shopData" class="wi-header-search-options-button__dropdown-item" @click="changeSelectOption('shop')">
          {{ shopData.name }}
        </div>

        <div
          v-if="categoryData"
          class="wi-header-search-options-button__dropdown-item"
          @click="changeSelectOption('category')"
        >
          {{ categoryData.name }}
        </div>
      </div>

      <div class="wi-header-search-options-button__mobile-logo wi-mobile-version">
        <a
          v-if="userDataForSearch?.selectOption === 'shop' && userDataForSearch.shop"
          :href="userDataForSearch.shop.link"
          class="wi-header-search-options-button__logo-wrapper"
          @click.stop
        >
          <WiCustomImage :image="shopAvatar" defaultImage="shopAvatar" />
        </a>

        <a v-else href="/" class="wi-header-search-options-button__logo-wrapper" @click.stop>
          <WiLogo class="wi-header-search-options-button__logo" />
        </a>
      </div>
    </button>
  </WiNuxtErrorBoundary>
</template>

<script lang="ts" src="./index.ts" />
<style lang="scss" src="./index.scss" scoped />
