<template>
  <WiNuxtErrorBoundary>
    <div class="wi-mobile-control-user-menu-content">
      <ul v-show="userInfo" class="wi-mobile-control-user-menu-content__list">
        <li class="wi-mobile-control-user-menu-content__list-item no-padding">
          <a class="wi-mobile-control-user-menu-content__list-link" href="/user/profile">
            <div class="flex-container">
              <div v-if="userInfo && !userInfo.photo" class="wi-mobile-control-user-menu-content__list-initials">
                <span>{{
                  StringUtils.getUserInitials({ firstName: userInfo.firstName, lastName: userInfo.lastName })
                }}</span>
              </div>
              <!-- <img
              v-else
              :src="userInfo && imgSrc(userInfo?.photo?.sizes)"
              alt=""
              class="wi-mobile-control-user-menu-content__photo"
            /> -->

              <div class="wi-mobile-control-user-menu-content__list-user">
                <span class="wi-mobile-control-user-menu-content__list-user-firstName">
                  {{ userInfo?.firstName || '' }}
                </span>

                <span class="wi-mobile-control-user-menu-content__list-user-control">
                  {{ $t('wiMobileControlUserMenuContent.manageYourAccount') }}
                </span>
              </div>
            </div>

            <div class="wi-mobile-control-user-menu-content__content-categories-item-icon-wrapper">
              <WiChevron class="wi-mobile-control-user-menu-content__content-categories-item-icon wi-image-container" />
            </div>
          </a>
        </li>
      </ul>

      <ul v-show="userInfo" class="wi-mobile-control-user-menu-content__list">
        <li v-for="link in links" :key="link.id" class="wi-mobile-control-user-menu-content__list-item">
          <a class="wi-mobile-control-user-menu-content__list-link" :href="link.link">
            <div class="flex-container">
              <component :is="link.icon" style="width: 20px; height: 20px" />
              {{ link.label }}
            </div>

            <div class="wi-mobile-control-user-menu-content__content-categories-item-icon-wrapper">
              <WiChevron class="wi-mobile-control-user-menu-content__content-categories-item-icon wi-image-container" />
            </div>
          </a>
        </li>
      </ul>

      <ul class="wi-mobile-control-user-menu-content__list">
        <li
          v-for="menuItem in getMobileProfileMenu"
          :key="menuItem.link"
          class="wi-mobile-control-user-menu-content__list-item"
        >
          <a
            v-if="menuItem.link"
            class="wi-mobile-control-user-menu-content__list-link"
            :href="menuItem.link"
            :target="menuItem.target"
          >
            {{ menuItem.label }}

            <div class="wi-mobile-control-user-menu-content__content-categories-item-icon-wrapper">
              <WiChevron class="wi-mobile-control-user-menu-content__content-categories-item-icon wi-image-container" />
            </div>
          </a>

          <span
            v-else
            class="wi-mobile-control-user-menu-content__list-link"
            @click="onApplyLinkAction(menuItem.action)"
          >
            {{ menuItem.label }}

            <div class="wi-mobile-control-user-menu-content__content-categories-item-icon-wrapper">
              <WiChevron class="wi-mobile-control-user-menu-content__content-categories-item-icon wi-image-container" />
            </div>
          </span>
        </li>
      </ul>

      <WiThemeButton />

      <button v-show="userInfo" class="wi-mobile-control-user-menu-content__logout-button" @click="userLogout">
        <span>{{ $t('wiMobileControlUserMenuContent.logout') }}</span>
      </button>
    </div>
  </WiNuxtErrorBoundary>
</template>

<script lang="ts" src="./index.ts"></script>
<style lang="scss" scoped src="./index.scss"></style>
