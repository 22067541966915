import { type FetchContext } from 'ofetch';
import { EnumNotificationItemStatus } from '~/app/store/notifications/types';
import { EnumCustomErrorCode, type IErrorOptionsRequest } from '../api/types';

import { globalNotificationsStore } from '~/app/store/notifications';
import { useGlobalUserStore } from '~/app/store/user';
import { StorageService } from '../browserAPI/storageService';

import ErrorNetwork from '@/shared/errors/ErrorNetwork';
import ErrorNotFound from '@/shared/errors/ErrorNotFound';
import ErrorUnauthorized from '@/shared/errors/ErrorUnauthorized';
import ErrorForbidden from '../errors/ErrorForbidden';
import { logout } from './auth';

export const errorHandler = async (error: unknown, errorOptions: IErrorOptionsRequest, data?: FetchContext) => {
  const { isShow, isShowDetailsErrors, entity } = errorOptions;

  const notificationStore = globalNotificationsStore();
  const managementStore = globalComponentManagementStore();

  if (error instanceof ErrorUnauthorized) {
    if (data === undefined) {
      return;
    }

    const requestPaths = data.request.toString().split('/');
    const requestType = requestPaths[requestPaths.length - 1];
    if (requestType === 'login') {
      return;
    }

    const storageService = new StorageService('local');
    const oldRefreshToken = storageService.getItem<string | null>('RefreshToken', false);
    const oldAccessToken = storageService.getItem<string | null>('AccessToken', false);

    if (oldRefreshToken === null && oldAccessToken === null) {
      managementStore.setIsOpenLoginModal(true);
    }

    return;
  }

  if (error instanceof ErrorForbidden && data?.response?._data.code === EnumCustomErrorCode.PHONE_NOT_VERIFIED) {
    managementStore.setIsOpenConfirmPhoneNumberModal(true);
  }

  if (error instanceof ErrorNetwork) {
    if (isShowDetailsErrors) {
      showDetailsErrors(error, entity);
    } else if (isShow) {
      notificationStore.addNotificationsPending([{ message: error.message, type: EnumNotificationItemStatus.Error }]);
    }

    return;
  }

  if (error instanceof ErrorNotFound) {
    if (isShowDetailsErrors) {
      showDetailsErrors(error, entity);
    } else if (isShow) {
      notificationStore.addNotificationsPending([{ message: error.message, type: EnumNotificationItemStatus.Error }]);
    }

    return;
  }

  if (isShow) {
    notificationStore.addNotificationsPending([
      { message: (error as Error).message, type: EnumNotificationItemStatus.Error },
    ]);
  }
};

let pendingRequests: any[] = [];
let isRefreshRunning = false;

export const refreshRequest = async (options?: { onResponseSuccess?: () => void; url?: string }) => {
  if (options?.url?.includes('/auth/refresh')) {
    pendingRequests = [];
    isRefreshRunning = false;
    logout();
    return;
  }

  const storageService = new StorageService('local');

  const oldRefreshToken = storageService.getItem<string | null>('RefreshToken', false);
  const oldAccessToken = storageService.getItem<string | null>('AccessToken', false);

  if (oldRefreshToken === null) {
    return;
  }

  if (oldAccessToken === null) {
    return;
  }

  if (options?.onResponseSuccess) {
    pendingRequests.push(options.onResponseSuccess);
  }

  if (isRefreshRunning) {
    return;
  }

  isRefreshRunning = true;

  const userStore = useGlobalUserStore();
  const managementStore = globalComponentManagementStore();
  const { $api } = useNuxtApp();

  try {
    const { accessToken, refreshToken } = await $api.auth.refresh({
      accessToken: oldAccessToken,
      refreshToken: oldRefreshToken,
    });

    await userStore.setTokens({ accessToken: accessToken, refreshToken: refreshToken });

    for (const onResponseSuccess of pendingRequests) {
      onResponseSuccess();
    }

    return { accessToken, refreshToken };
  } catch (error) {
    managementStore.setIsOpenLoginModal(true);
    throw error;
  } finally {
    pendingRequests = [];
    isRefreshRunning = false;
  }
};

export const checkNetworkRecaptchaError = (message: string, withDefaultMessage = true) => {
  const { $i18n } = useNuxtApp();
  const notificationStore = globalNotificationsStore();

  if (message.includes('network failure')) {
    notificationStore.addNotificationsPending([
      {
        message: $i18n.t('error.networkRecaptchaError'),
        type: EnumNotificationItemStatus.Error,
      },
    ]);
  } else {
    if (withDefaultMessage) {
      notificationStore.addNotificationsPending([
        {
          message: message,
          type: EnumNotificationItemStatus.Error,
        },
      ]);
    }
  }
};

const showDetailsErrors = (error: ErrorNetwork | ErrorNotFound, entity?: string) => {
  const { $i18n } = useNuxtApp();
  const notificationStore = globalNotificationsStore();

  error.errors.forEach((item) => {
    const entityFieldTranslationKey = `entityFields.${entity}.${item.property}`;

    for (const key in item.constraints) {
      const message =
        entity && item.property
          ? item.constraints[key].replaceAll(`[${item.property}]`, $i18n.t(entityFieldTranslationKey))
          : item.constraints[key];

      notificationStore.addNotificationsPending([
        {
          message,
          type: EnumNotificationItemStatus.Error,
        },
      ]);
    }
  });
};
