// @ts-ignore
import WikkeoChatLibrary from '@wikkeo/wikkeo-chat-library';
import type { PublicRuntimeConfig } from 'nuxt/schema';

export default defineNuxtPlugin({
  name: 'WikkeoChatLibrary',
  dependsOn: ['pinia'],
  async setup(nuxtApp) {
    // @ts-ignore
    await import('@wikkeo/wikkeo-chat-library/styles');

    const config = useRuntimeConfig();

    const publicConfig = config.public as unknown as PublicRuntimeConfig;

    // @ts-ignore
    nuxtApp.vueApp.use(WikkeoChatLibrary, {
      config: {
        apiURL: publicConfig.chat.apiURL,
        socketURL: publicConfig.chat.socketURL,
        buyerJwtAccessTokenKey: publicConfig.chat.buyerJwtAccessTokenKey,
        wikkeoURL: publicConfig.chat.wikkeoURL,
        isLegacy: false,
      },
    });
  },
});
