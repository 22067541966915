import WiHeaderNavigation from '~/shared/ui/header/WiHeaderNavigation/index.vue';
import WiStaticWrapperHeader from '~/shared/ui/header/WiStaticWrapperHeader/index.vue';
import WiNuxtErrorBoundary from '~/shared/ui/WiNuxtErrorBoundary/index.vue';
import WiCategoryBlock from '~/widgets/WiCategoryBlock/ui/index.vue';
import WiHeaderControlBlock from '~/shared/ui/header/legacy/WiHeaderControlBlock/index.vue';
import WiHeaderSearchBlock from '~/shared/ui/header/legacy/WiHeaderSearchBlock/index.vue';
import { useMobile } from '~/shared/hooks/useMobile';
import { useDefaultLayoutModel } from '~/app/layouts/default/model';

export default defineNuxtComponent({
  name: 'WiCommonHeader',

  components: {
    WiHeaderNavigation,
    WiNuxtErrorBoundary,
    WiStaticWrapperHeader,
    WiCategoryBlock,
    WiHeaderControlBlock,
    WiHeaderSearchBlock,
  },

  props: {
    isWithSearch: {
      type: Boolean,
      default: true,
    },
  },

  setup() {
    const { isMobile } = useMobile();

    const defaultLayoutModel = useDefaultLayoutModel();
    const userStore = useGlobalUserStore();
    const { user } = storeToRefs(userStore);
    const isLoadingUserInfo = computed(() => userStore.isLoadingUserInfo);

    const onLogout = () => {
      defaultLayoutModel.logout();
    };

    return {
      isMobile,
      user,
      isLoadingUserInfo,
      onLogout,
    };
  },
});
