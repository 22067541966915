<template>
  <WiNuxtErrorBoundary>
    <section class="wi-static-wrapper-header" :class="{ 'wi-static-wrapper-header_transparent-top': isTransparentTop }">
      <div id="wiStaticWrapperHeader" class="wi-static-wrapper-header__content-wrapper">
        <div class="wi-static-wrapper-header__content wi-custom-container">
          <slot name="default"></slot>
        </div>
      </div>
    </section>
  </WiNuxtErrorBoundary>
</template>

<script lang="ts" src="./index.ts" />
<style lang="scss" src="./index.scss" scoped />
