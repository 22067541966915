import ProfileLayout from '~/app/layouts/profile/ui/index.vue';

export default defineNuxtComponent({
  name: 'Forbidden',

  components: {
    ProfileLayout,
  },

  setup() {
    const onClearError = () => {
      clearError({ redirect: '/' });
    };

    return {
      onClearError,
    };
  },
});
