import { globalUiConfigActions } from './actions';
import { globalUiConfigState } from './state';
import type { IGlobalUiConfigStore } from './types';
import { checkIsServer } from '~/shared/browserAPI/isServer';
import { StorageService } from '~/shared/browserAPI/storageService';

export const globalUiConfigStore = defineStore('globalUiConfigStore', {
  state: () => globalUiConfigState() as IGlobalUiConfigStore,
  actions: globalUiConfigActions,

  hydrate(state, _initialState) {
    let wikIsAcceptPrivate = null;
    const isServer = checkIsServer();

    // TODO check hydrate only client
    const storageService = new StorageService();

    if (!isServer) {
      storageService.setProvider('local');
    }

    const wikIsAcceptPrivateFromStorage = storageService.getItem<string | null>('IsAcceptPrivate');
    if (typeof wikIsAcceptPrivateFromStorage === 'string') {
      if (wikIsAcceptPrivateFromStorage.toLowerCase() === 'true') {
        wikIsAcceptPrivate = true;
      } else {
        wikIsAcceptPrivate = false;
      }
    } else {
      wikIsAcceptPrivate = null;
    }
    state.isAcceptPrivate = wikIsAcceptPrivate;
  },
});
