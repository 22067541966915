import WiForgetPasswordModal from '~/widgets/login/WiForgetPasswordModal/ui/index.vue';
import type { ILoginPayload } from '~/shared/api/v1/auth/types';
import { useVuelidate } from '@vuelidate/core';
import { required, email, minLength, helpers } from '@vuelidate/validators';
import { errorMessages } from '~/shared/config/errorMessages';
import useWiAuthorizationWidgetApi from '../api/index';

import { Components } from '@wikkeo/ui-kit';

// @ts-ignore
const { WiInput, WiButton } = Components;

export default defineNuxtComponent({
  name: 'WiAuthorization',

  components: { WiInput, WiButton, WiForgetPasswordModal },

  setup() {
    const { isLoadingLogin, onLogin } = useWiAuthorizationWidgetApi();

    const { t } = useI18n();
    const errorMessagesData = ref(errorMessages(t));

    const isOpenForgetPassword = ref<boolean>(false);

    const loginCredentials = ref<ILoginPayload>({
      email: '',
      password: '',
    });

    const loginRules = {
      email: {
        required: {
          ...required,
          $message: errorMessagesData.value.REQUIRED,
        },

        email: {
          ...email,
          $message: errorMessagesData.value.INVALID_EMAIL,
        },

        $autoDirty: true,
      },
      password: {
        required: {
          ...required,
          $message: errorMessagesData.value.REQUIRED,
        },

        minLength: helpers.withMessage(errorMessagesData.value.getMinLengthErrorMessage(6), minLength(6)),
      },
    };

    const v$ = useVuelidate(loginRules, loginCredentials);

    const handleLogin = async () => {
      v$.value.$touch();

      if (v$.value.$error) {
        return;
      }

      await onLogin(loginCredentials.value);
    };

    const setValue = (key: keyof typeof loginCredentials.value, value: string) => {
      loginCredentials.value[key] = value;
    };

    const showForgetPasswordModal = () => {
      isOpenForgetPassword.value = true;
    };

    const onCloseForgetPasswordModal = () => {
      isOpenForgetPassword.value = false;
    };

    return {
      v$,
      isOpenForgetPassword,
      loginCredentials,
      isLoadingLogin,
      handleLogin,
      setValue,
      showForgetPasswordModal,
      onCloseForgetPasswordModal,
    };
  },
});
