<template>
  <WiModalWrapper :isOpen="isOpen" @onClose="closeModal" width="330px">
    <template #title>
      <span class="wi-verified-phone__title">{{ $t('login.enterCode') }}</span>
    </template>

    <template #body>
      <div class="wi-verified-phone">
        <span class="wi-verified-phone__description"
          >{{ $t('login.confirmNumber') }}

          <br />

          <span class="wi-verified-phone__description-phone">+{{ getPhoneNumber }}</span>

          <br />

          {{ $t('login.enterCodeFromMessage') }}
        </span>

        <div
          ref="codeInputsWrapper"
          class="wi-verified-phone__code-inputs-wrapper"
          :class="{ 'wi-verified-phone__code-inputs-wrapper_error': IsErrorCode }"
        >
          <input
            v-for="(_item, index) in codeArray"
            :key="index"
            class="wi-verified-phone__code-input"
            :value="codeArray[index]"
            placeholder="-"
            type="tel"
            @focus="changeCodeInputFocus"
            @input="(event) => codeInputHandler(event, index)"
            @keydown.backspace="(event) => codeInputBackspace(event, index)"
          />
        </div>

        <span class="wi-verified-phone__error-message" v-show="IsErrorCode"> {{ $t('login.invalidCode') }}</span>

        <span v-show="timeLeft" class="wi-verified-phone__time-text"
          >{{ $t('login.newCodeTimer') }} {{ timeLeft }}</span
        >

        <span v-show="timeLeft === 0" class="wi-verified-phone__refresh" @click="getNewCode">
          {{ $t('login.getNewCode') }}
        </span>

        <div class="wi-verified-phone__change" @click="closeModal()">{{ $t('common.back') }}</div>
      </div>
    </template>
  </WiModalWrapper>
</template>

<style lang="scss" scoped src="./index.scss"></style>
<script lang="ts" src="./index.ts"></script>
