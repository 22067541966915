import BaseApi from '../..';
import type { IRequestParamsHandler } from '../../types';
import type {
  IShippingDepartment,
  IShippingDepartmentsRequest,
  IStatusHistoryItemResponse,
  TShippingServiceProvider,
} from './types';

class ShippingsApiV1 extends BaseApi {
  private VERSION = 'v1';
  private RESOURCE = 'shippings';

  async getStatusHistory(
    id: number,
    provider: TShippingServiceProvider,
    params: IRequestParamsHandler<{ code: string }>,
  ): Promise<Array<IStatusHistoryItemResponse>> {
    const url = `${this.VERSION}/${this.RESOURCE}/getStatusHistory?id=${id}&provider=${provider}`;

    return this.post({
      url,
      body: params?.data,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }

  async getDepartments(
    provider: string,
    params: IRequestParamsHandler<IShippingDepartmentsRequest>,
  ): Promise<Array<IShippingDepartment>> {
    const url = `${this.VERSION}/${this.RESOURCE}/getDepartments?provider=${provider}`;

    return this.post({
      url,
      body: params?.data,
      query: params?.params,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }
}

export default ShippingsApiV1;
