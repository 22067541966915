import WiNuxtErrorBoundary from '~/shared/ui/WiNuxtErrorBoundary/index.vue';
import { Icons } from '@wikkeo/ui-kit';
import type { IImage } from '~/shared/api/types';
import WiCustomImage from '~/shared/ui/WiCustomImage/index.vue';

// @ts-ignore
const { WiClose, WiChevron, WiLogo } = Icons;

// import { useStore } from '@/store'
// import { getDefaultImgSrc } from '@/helpers/main'
// import { DEFAULT_IMG_PATH } from '@/constants/main'

export default defineNuxtComponent({
  name: 'WiHeaderSearchOptionsButton',

  components: {
    WiLogo,
    WiChevron,
    WiClose,
    WiNuxtErrorBoundary,
    WiCustomImage,
  },

  props: {
    shopAvatar: {
      type: Object as PropType<IImage | null>,
      default: null,
    },
  },

  emits: ['openDropdown', 'changeSelectOption'],

  setup(_, { emit }) {
    const userStore = useGlobalUserStore();

    const { userDataForSearch } = storeToRefs(userStore);

    const isOpenDropdown = ref(false);

    // const defaultImageLink = DEFAULT_IMG_PATH

    const shopData = computed(() => {
      if (!userDataForSearch.value?.shop) {
        return null;
      }

      return userDataForSearch.value.shop;
    });

    const categoryData = computed(() => {
      if (!userDataForSearch.value?.category) {
        return null;
      }

      return userDataForSearch.value.category;
    });

    const handlerDropdown = () => {
      if (!isOpenDropdown.value && userDataForSearch.value?.selectOption !== null) {
        return;
      }

      isOpenDropdown.value ? handlerCloseDropdown() : handlerOpenDropdown();
    };

    const handlerOpenDropdown = () => {
      emit('openDropdown');

      isOpenDropdown.value = true;
    };

    const handlerCloseDropdown = () => {
      isOpenDropdown.value = false;
    };

    const changeSelectOption = (value: string | null) => {
      if (userDataForSearch.value?.selectOption === value) {
        return;
      }

      emit('changeSelectOption');
      userStore.setDataForSearch({ key: 'selectOption', value: value });
    };

    return {
      isOpenDropdown,
      shopData,
      categoryData,
      userDataForSearch,

      handlerCloseDropdown,
      handlerDropdown,
      changeSelectOption,
    };
  },
});
