import { StorageService } from '~/shared/browserAPI/storageService';

const storageService = new StorageService('local');

export default defineNuxtComponent({
  name: 'WiCookieModal',

  setup() {
    const isNeedShowCookieModal = ref(false);

    const cookiePoliticsLink = useRuntimeConfig().public.documents.cookie;

    onMounted(() => {
      cookieConsentCheck();
    });

    const cookieConsentCheck = () => {
      const cookieAccepted = storageService.getItem('CookieAccepted');
      isNeedShowCookieModal.value = !cookieAccepted;
    };

    const setCookieInLocalStorage = () => {
      storageService.setItem('CookieAccepted', true);
      isNeedShowCookieModal.value = false;
    };

    return {
      isNeedShowCookieModal,
      cookiePoliticsLink,

      setCookieInLocalStorage,
    };
  },
});
