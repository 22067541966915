import { FetchError } from 'ofetch';
import { useReCaptcha } from 'vue-recaptcha-v3';
import useAppRouter from '~/shared/hooks/useAppRouter';
import { formatPhone } from '~/shared/helpers/formatPhone';
import { setUser } from '~/shared/helpers/auth';
import type { IRegistrationData } from '../types';
import { useStoreWithContext } from '~/shared/hooks/useStoreWithContext';
import { sentryLog } from '~/shared/helpers/sentryLog';
import { EnumCustomErrorCode } from '~/shared/api/types';

//@ts-ignore
import { useChatStore } from '@wikkeo/wikkeo-chat-library';
import { checkNetworkRecaptchaError } from '~/shared/helpers/errorHandler';

export default function useWiRegistrationWidgetApi() {
  const { t } = useI18n();
  const { $api } = useNuxtApp();
  const router = useAppRouter();
  const recaptchaInstance = useReCaptcha();
  const notificationsStore = globalNotificationsStore();
  const chatStore = useStoreWithContext(useChatStore);
  const managementStore = globalComponentManagementStore();

  const isLoadingSignup = ref<boolean>(false);

  const onSignup = async (registrationData: IRegistrationData, onOpenVerifyModal?: () => void) => {
    isLoadingSignup.value = true;

    try {
      await recaptchaInstance?.recaptchaLoaded();

      const recaptchaToken = await recaptchaInstance?.executeRecaptcha('submit');

      if (!recaptchaToken) {
        notificationsStore.addNotificationsPending([
          {
            message: t('error.recaptchaError'),
            type: EnumNotificationItemStatus.Error,
          },
        ]);

        return;
      }

      const res = await $api.auth.register({
        data: {
          lastName: registrationData.lastName,
          firstName: registrationData.firstName,
          phone: '+' + formatPhone(registrationData.phone),
          email: registrationData.email,
          password: registrationData.password,
          passwordConfirmation: registrationData.confirmPassword,
        },
        fetchOptions: { headers: { 'g-recaptcha-response': recaptchaToken } },
      });

      if (res) {
        setUser(res);

        managementStore.onSuccessLoginCallback?.();

        managementStore.setIsOpenLoginModal(false);

        router.goToProfileAfterLogin();

        await chatStore.initChat();
      }
    } catch (error) {
      if (error instanceof FetchError && error.data?.code) {
        if (error.data.code === EnumCustomErrorCode.PHONE_NOT_VERIFIED && onOpenVerifyModal) {
          onOpenVerifyModal();
        } else if (error.data?.message) {
          checkNetworkRecaptchaError(error.data?.message);
        }

        sentryLog(error as Error);
      }
    } finally {
      isLoadingSignup.value = false;
    }
  };

  return { isLoadingSignup, onSignup };
}
