<template>
  <ClientOnly>
    <NuxtLoadingIndicator :color="primaryColor" :height="isMobile ? 2 : 4" :throttle="0" />
  </ClientOnly>

  <WiNuxtErrorBoundary>
    <slot name="default">
      <NuxtPage />
    </slot>
  </WiNuxtErrorBoundary>
</template>

<script lang="ts" src="./index.ts"></script>
<style lang="scss" scoped src="./index.scss"></style>
