import { toLocaleString } from '../utils/number/toLocaleString';

export const getPriceString = (price?: number) => {
  const { $i18n } = useNuxtApp();

  if (price === undefined) {
    return '';
  }

  return `${toLocaleString(price)} ${$i18n.t('currency.ruble')}`;
};
