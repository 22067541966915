import BaseApi from '../..';
import type { IRequestParamsHandler, IResponseWithPaging } from '../../types';
import type { IShippingCompany } from './types';

class ShippingCompaniesApiV1 extends BaseApi {
  private VERSION = 'v1';
  private RESOURCE = 'shippingCompanies';

  async getShippingCompanies(
    params: IRequestParamsHandler<IResponseWithPaging<IShippingCompany>>,
  ): Promise<IResponseWithPaging<IShippingCompany>> {
    const url = `${this.VERSION}/${this.RESOURCE}`;

    return this.get({
      url,
      query: params?.params,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }
}

export default ShippingCompaniesApiV1;
