import type { IAddressApi } from '~/shared/api/v1/addresses/types';

export const defaultAddress: IAddressApi = {
  address: 'г Москва',
  addressOriginal: 'Россия, г Москва',
  city: 'Москва',
  cityFiasId: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
  cityKladrId: '7700000000000',
  coordinates: { latitude: '55.75396', longitude: '37.620393' },
  country: 'Россия',
  countryCode: 'RU',
  flat: null,
  house: null,
  regionFiasId: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
  settlement: null,
  settlementFiasId: null,
  settlementKladrId: null,
  street: null,
  zip: '101000',
  cityDistrict: null,
};

export const geocodeMapsUrl = 'https://geocode-maps.yandex.ru/1.x'
