import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { errorMessages } from '~/shared/config/errorMessages';

import useWiForgetPasswordModalWidgetApi from '../api/index';
import WiModalWrapper from '~/shared/ui/WiModalWrapper/index.vue';

import { Components } from '@wikkeo/ui-kit';

// @ts-ignore
const { WiInput, WiButton } = Components;

export default defineNuxtComponent({
  name: 'WiForgetPasswordModal',

  components: {
    WiModalWrapper,
    WiInput,
    WiButton,
  },

  props: {
    isOpen: {
      type: Boolean,
    },

    email: {
      type: String,
      default: '',
    },
  },

  emits: ['onClose'],

  setup(props, { emit }) {
    const { isLoadingReset, onRequestResetPassword } = useWiForgetPasswordModalWidgetApi();

    const { t } = useI18n();
    const errorMessagesData = ref(errorMessages(t));

    const emailData = ref({ email: props.email });

    onUpdated(() => {
      emailData.value.email = props.email;
    });

    const emailRules = {
      email: {
        required: {
          ...required,
          $message: errorMessagesData.value.REQUIRED,
        },

        email: {
          ...email,
          $message: errorMessagesData.value.INVALID_EMAIL,
        },

        $autoDirty: true,
      },
    };

    const v$ = useVuelidate(emailRules, emailData);

    const handlerForgetPassword = async () => {
      v$.value.$touch();

      if (v$.value.$error) {
        return;
      }

      await onRequestResetPassword(emailData.value, closeModal);
    };

    const setValue = (value: string) => {
      emailData.value.email = value;
    };

    const closeModal = () => {
      emit('onClose');
    };

    return { v$, isLoadingReset, emailData, setValue, closeModal, handlerForgetPassword };
  },
});
