<template>
  <footer class="wi-common-footer">
    <div class="wi-common-footer-container">
      <div class="wi-common-footer-content">
        <div class="wi-common-footer-content-item wi-common-footer-content-item__description">
          <div class="wi-common-footer-content-item__logo">
            <WiLogoFull />
          </div>

          <div>
            <p class="wi-common-footer-content-item__description">
              {{ $t('footer.description') }}
            </p>
          </div>
        </div>

        <div class="wi-common-footer-content-item__menu">
          <h3>{{ $t('footer.site') }}</h3>

          <ul class="wi-common-footer-content-item__menu-list links-grid">
            <li v-for="item in site" :key="item.label">
              <a :href="item.link" target="_blank">{{ item.label }}</a>
            </li>
          </ul>
        </div>

        <div class="wi-common-footer-content-item__menu wi-common-footer-content-item__menu_mobile">
          <h3>{{ $t('common.info') }}</h3>

          <ul class="wi-common-footer-content-item__menu-list">
            <li v-for="item in info" :key="item.label">
              <a :href="item.link" download>{{ item.label }}</a>
            </li>
          </ul>
        </div>

        <div class="wi-common-footer-content-item wi-common-footer-content-item__telegram">
          <h3>{{ $t('footer.subscribe') }}</h3>

          <p>{{ $t('footer.subscribeNext') }}</p>

          <a href="https://t.me/wikkeocom" target="_blank">
            <div class="wi-common-footer-content-item__telegram-subscribe-btn">
              {{ $t('footer.tgLink') }}
              <WiArrow />
            </div>
          </a>
        </div>
      </div>

      <div class="wi-common-footer-additional-info">
        <div class="wi-common-footer-additional-info__copyright">
          {{ $t('footer.rightsReserved') }} © wikkeo.com {{ new Date().getFullYear() }}
          <div class="app-version">{{ $t('footer.appVersion') }}: {{ config.public.appVersion }}</div>
          <div class="app-version">{{ $t('footer.chatVersion') }}: {{ chatVersion }}</div>
        </div>

        <div class="wi-common-footer-social-network">
          <a v-for="(link, key) in socialNetworks" :key="key" target="_blank" :href="link">
            <div
              class="wi-common-footer-social-network-icon-wrapper"
              :class="'wi-common-footer-social-network-icon-wrapper__' + key"
            >
              <component :is="key" />
            </div>
          </a>
        </div>
      </div>

      <div class="up-scroll-button">
        <WiSliderChevron @click="scrollToTop()" />
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped src="./index.scss"></style>
<script lang="js" src="./index.ts" />
