<template>
  <ul class="wi-profile-menu-item" @click="onClickMenuItem">
    <div class="wi-profile-menu-item__row" :class="{ 'wi-active-menu-row': menuItem.checkIsActive?.() }">
      <component :is="menuItem.icon" class="wi-profile-menu-item__icon" width="24" height="24"></component>

      <span class="wi-profile-menu-item__title">
        {{ menuItem.label }}
      </span>

      <div
        v-if="!menuItem.subMenu"
        class="wi-profile-menu-item__counter"
        :class="[menuItem.counter ? '' : 'wi-profile-menu-item__counter_hidden']"
      >
        {{ menuItem.counter }}
      </div>
      <WiChevron
        v-else
        class="wi-profile-menu-item__chevron"
        :class="[isShowSubMenu ? 'wi-profile-menu-item__chevron_rotate' : '']"
      />
    </div>

    <li class="wi-profile-menu-item__submenu" :class="{ 'is-open-sub-menu': isShowSubMenu }">
      <div class="wi-profile-menu-item__submenu__items-wrapper">
        <ul
          v-for="subMenuItem in menuItem.subMenu"
          :key="subMenuItem.link"
          class="wi-profile-menu-item__submenu__item"
          :class="{ 'wi-active-menu-row': subMenuItem.checkIsActive?.() }"
          @click.stop="onClickMenuSubItem(subMenuItem.link)"
        >
          <span class="wi-profile-menu-item__title">
            {{ subMenuItem.label }}
          </span>

          <div
            class="wi-profile-menu-item__counter"
            :class="[
              getShopOrdersCounterByStatus(subMenuItem?.counterKey) ? '' : 'wi-profile-menu-item__counter_hidden',
            ]"
          >
            {{ getShopOrdersCounterByStatus(subMenuItem?.counterKey) }}
          </div>
        </ul>
      </div>
    </li>
  </ul>
</template>

<script lang="ts" src="./index.ts" />
<style lang="scss" src="./index.scss" scoped />
