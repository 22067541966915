import WiNuxtErrorBoundary from '~/shared/ui/WiNuxtErrorBoundary/index.vue';
import WiSubstrateArea from '~/widgets/WiSubstrateArea/index.vue';
import WiCustomImage from '~/shared/ui/WiCustomImage/index.vue';
import WiInputCount from '~/widgets/WiInputCount/ui/index.vue';
import WiCartPreviewDesktopItem from '../WiCartPreviewDesktopItem/index.vue';
import { Icons, Components } from '@wikkeo/ui-kit';

import { useSubstrateManagementHook } from '~/shared/hooks/useSubstrateManagement';
import type { CartProductEntity } from '~/entities/cartProduct';
import useAppRouter from '~/shared/hooks/useAppRouter';
import { NumberUtils } from '~/shared/utils/number';
import { useFeatureAddProductToCart } from '~/features/cart/addProductsToCart';
import { getPriceString } from '~/shared/formatters/getPriceString';
import { getCartStore } from '~/features/cart/getCartStore';
import { CartProductWithErrorEntity } from '~/entities/cartProductWithError';
import { getInputCountProps } from '~/shared/helpers/product/getInputCountProps';

const { WiButton } = Components;
const { WiTrash, WiNoPhoto } = Icons;

export default defineNuxtComponent({
  name: 'WiCartPreviewDesktop',

  components: {
    WiNuxtErrorBoundary,
    WiSubstrateArea,
    WiButton,
    WiCustomImage,
    WiTrash,
    WiInputCount,
    WiNoPhoto,
    WiCartPreviewDesktopItem,
  },

  props: {
    isOpen: {
      type: Boolean,
    },
  },

  emits: ['onClose'],

  setup(props, { emit }) {
    const cartStore = globalCartStore();

    const router = useAppRouter();

    const { dataCartStore } = storeToRefs(getCartStore(cartStore));

    const { cartEntity } = reactive(dataCartStore.value);

    const { t } = useI18n();

    const { isOpen } = toRefs(props);

    const { isShowSubstrate, handleOpenSubstrate } = useSubstrateManagementHook();

    const { removeProducts } = useFeatureAddProductToCart();

    const cartProducts = ref<Array<CartProductEntity | CartProductWithErrorEntity>>([]);

    const { toLocaleString } = NumberUtils;

    const resultSumText = computed(() => {
      if (!cartEntity) {
        return '';
      }

      const sum = cartEntity.getPriceProducts(cartProducts.value);

      return `${toLocaleString(sum.price)} ${t('currency.ruble')}`;
    });

    watch(isShowSubstrate, () => {
      if (!isShowSubstrate.value) {
        emit('onClose');
      }
    });

    watch(isOpen, () => {
      handleOpenSubstrate(isOpen.value);

      if (isOpen.value) {
        setCartProductsToValue();
      }
    });

    if (cartEntity) {
      watch(cartEntity, () => {
        setCartProductsToValue();

        if (cartEntity.productsCart.length === 0) {
          emit('onClose');
        }
      });
    }

    onMounted(() => {
      if (isOpen.value) {
        setCartProductsToValue();
      }
    });

    const setCartProductsToValue = () => {
      if (!cartEntity) {
        return;
      }

      cartProducts.value = cartEntity.productsCart;
    };

    const removeProduct = (product: CartProductEntity | CartProductWithErrorEntity) => {
      if (!cartEntity) {
        return;
      }

      removeProducts([product]);

      if (cartEntity.productsCart.length === 0) {
        emit('onClose');
      }
    };

    const removeAllProduct = () => {
      if (!cartEntity) {
        return;
      }

      const products = [...cartEntity.productsCart];

      removeProducts(products);

      emit('onClose');
    };

    const goToCart = () => {
      router.goToCart();
    };

    return {
      isShowSubstrate,
      resultSumText,
      cartProducts,

      getPriceString,
      removeProduct,
      removeAllProduct,
      goToCart,
      getInputCountProps,
    };
  },
});
