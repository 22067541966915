import WiVerifyPhoneModal from '~/widgets/login/legacy/WiVerifyPhoneModal/index.vue';
import WiConfirmPhoneNumberModal from '~/widgets/WiConfirmPhoneNumberModal/ui/index.vue';

export default defineNuxtComponent({
  name: 'WiConfirmAndVerifyPhoneModals',

  components: { WiVerifyPhoneModal, WiConfirmPhoneNumberModal },

  props: {},

  setup(props) {
    const managementStore = globalComponentManagementStore();

    const phoneNumber = ref('');

    const setCurrentPhone = (phone: string) => {
      phoneNumber.value = phone;
    };

    const onCloseConfirmPhoneNumberModal = () => {
      managementStore.setIsOpenConfirmPhoneNumberModal(false);
    };

    const onCloseVerifyPhoneModal = () => {
      managementStore.setIsOpenVerifyPhoneModal(false);
    };

    return {
      managementStore,
      phoneNumber,
      setCurrentPhone,
      onCloseConfirmPhoneNumberModal,
      onCloseVerifyPhoneModal,
    };
  },
});
