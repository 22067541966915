<template>
  <ClientOnly>
    <WiNuxtErrorBoundary>
      <div class="wi-login-tg-section" ref="elRef"></div>
    </WiNuxtErrorBoundary>
  </ClientOnly>
</template>

<style lang="scss" scoped src="./index.scss" />
<script lang="ts" src="./index.ts" />
