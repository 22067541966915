import { DEFAULT_LIMIT, DEFAULT_PAGE } from '~/shared/api/constants';
import type { IRequestParams, TSort } from '~/shared/api/types';
import { ObjectUtils } from '~/shared/utils/object';
import { FILTER_CONDITION_DELIMITER, FILTER_KEY_PREFIX } from './constants';

export const queryBuilder = <T>(
  payload: IRequestParams<T> | undefined,
  withPagination = true,
): Record<string, string | number | Array<string | number>> => {
  if (payload === undefined) {
    return {};
  }

  const { query, page, limit, search, sortBy, filter } = payload;

  let result: Record<string, string | number | Array<string | number>> = {};

  if (query !== undefined && search !== '') {
    result.query = query;
  }

  if (search !== undefined && search !== '') {
    result.search = search;
  }

  if (sortBy !== undefined) {
    result.sortBy = getSortBy(sortBy);
  }

  if (filter !== undefined) {
    for (const value of getFilter(filter)) {
      result[FILTER_KEY_PREFIX + value.key] = value.value;
    }
  }

  if (withPagination) {
    result = { ...result, ...getPagination(page, limit) };
  }

  return result;
};

const getPagination = (payloadPage: number | undefined, payloadLimit: number | undefined) => {
  const page = payloadPage !== undefined && payloadPage > 0 ? parseInt(payloadPage.toString()) : DEFAULT_PAGE;
  const limit = payloadLimit !== undefined && payloadLimit > 0 ? parseInt(payloadLimit.toString()) : DEFAULT_LIMIT;

  return { page, limit };
};

const getSortBy = <T>(payloadSortBy: TSort<T>) => {
  let result: Array<string | number> = [];

  for (const [key, value] of Object.entries(payloadSortBy)) {
    result.push(`${key}${FILTER_CONDITION_DELIMITER}${(value as string).toUpperCase()}`);
  }

  return result;
};

const getFilter = (payloadFilter: TAnyLiteral) => {
  let result: Array<{ key: string; value: Array<string> }> = [];

  for (const [filterKey, conditions] of Object.entries(payloadFilter)) {
    try {
      let array: any[] = getFilterValueToString(conditions);
      if (array.length) {
        result.push({ key: filterKey, value: array });
      }
    } catch (error) {}
  }

  return result;
};

const getFilterValueToString = (conditions?: TAnyLiteral | boolean): Array<string> => {
  let result: Array<string> = [];

  if (conditions === undefined) {
    throw new Error('incorrect condition');
  }

  if (typeof conditions === 'boolean') {
    result.push(conditions.toString());
  }

  for (const [key, condition] of Object.entries(conditions)) {
    if (!checkValidFilterValue(condition)) {
      throw new Error('incorrect condition');
    }

    if (ObjectUtils.isObject(condition)) {
      result.push(`${key}${FILTER_CONDITION_DELIMITER}${getFilterValueToString(condition)}`);
    } else {
      result.push(`${key}${FILTER_CONDITION_DELIMITER}${condition.toString()}`);
    }
  }

  return result;
};

const checkValidFilterValue = (value: any) => {
  const isArray = Array.isArray(value);

  if (isArray) {
    return value.length !== 0;
  } else {
    return value !== undefined && value !== null;
  }
};
