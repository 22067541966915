import type { IImage } from '~/shared/api/types';
import { defaultImages, type TImageDefault } from './config';
import { Components, Icons } from '@wikkeo/ui-kit';
import NoPhotoProductIconLight from '~/public/icons/no-photo-light.vue';
import NoPhotoProductIconDark from '~/public/icons/no-photo-dark.vue';

const { WiNoPhoto } = Icons;
const { WiSkeleton } = Components;

export default defineNuxtComponent({
  name: 'WiCustomImage',

  components: {
    WiSkeleton,
    WiNoPhoto,
    NoPhotoProductIconLight,
    NoPhotoProductIconDark,
  },

  props: {
    image: {
      type: Object as PropType<IImage | null>,
      default: null,
    },

    alt: {
      type: String,
      default: 'Нет фото',
    },

    selectedSize: {
      type: Object as PropType<{ mobileSize: number; desktopSize: number }>,
      default: () => ({}),
    },

    defaultImage: {
      type: String as PropType<TImageDefault>,
      default: 'default',
    },

    disableBackground: {
      type: Boolean,
      default: false,
    },

    isPill: {
      type: Boolean,
      default: true,
    },

    isForceShowImg: {
      type: Boolean,
      default: false,
    },

    isLazy: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['onLoad', 'onError'],

  setup(props, { emit }) {
    const config = useRuntimeConfig();

    const { image, disableBackground, selectedSize, isLazy } = toRefs(props);

    const errorImg = ref(false);

    const isLoadingImage = ref(props.isForceShowImg ? false : true);

    const imgElemRef = ref<null | HTMLImageElement>(null);

    onMounted(() => {
      if (!image.value) {
        isLoadingImage.value = false;
        errorImg.value = true;
      }

      /**
       * fix infinite loader for image with "lazy":
       * because if the "lazy" attribute is set,
       * the "load" event is not called if the image is in the viewport
       */
      if (isLazy.value) {
        isLoadingImage.value = false;
      }
    });

    const splitSize = (value: string) => {
      return Number(value?.split('x')[0]);
    };

    const sizeKeysSorted = computed(() => {
      if (image && image.value?.sizes) {
        return Object.keys(image.value?.sizes).sort((a, b) => {
          return splitSize(b) - splitSize(a);
        });
      } else {
        return [];
      }
    });

    const getCustomMaxSize = (size: number) => {
      if (size < splitSize(sizeKeysSorted.value[sizeKeysSorted.value.length - 1])) {
        return sizeKeysSorted.value[sizeKeysSorted.value.length - 1];
      }

      if (sizeKeysSorted.value.length > 1) {
        for (const item of sizeKeysSorted.value) {
          if (Number(splitSize(item)) <= size) {
            return item;
          }
        }
      }

      return sizeKeysSorted.value[0];
    };

    const currentDesktopsSize = computed(() => {
      return getCustomMaxSize(selectedSize.value?.desktopSize ?? splitSize(sizeKeysSorted.value?.[0]));
    });

    const currentMobileSize = computed(() => {
      return getCustomMaxSize(selectedSize.value?.mobileSize ?? config.public.mobileImgSize);
    });

    watch(
      () => imgElemRef.value?.complete,
      () => {
        if (imgElemRef.value?.complete && !disableBackground.value && imgElemRef.value instanceof HTMLImageElement) {
          isLoadingImage.value = false;
        }
      },
    );

    const loadHandler = () => {
      isLoadingImage.value = false;

      emit('onLoad');
    };

    const errorHandler = () => {
      isLoadingImage.value = false;

      errorImg.value = true;

      emit('onError');
    };

    return {
      imgElemRef,
      isLoadingImage,
      sizeKeysSorted,
      defaultImages,
      currentMobileSize,
      currentDesktopsSize,

      loadHandler,
      errorHandler,
      errorImg,
    };
  },
});
