import { FetchError } from 'ofetch';
import { useReCaptcha } from 'vue-recaptcha-v3';
import { checkNetworkRecaptchaError } from '~/shared/helpers/errorHandler';
import { sentryLog } from '~/shared/helpers/sentryLog';

export default function useWiForgetPasswordModalWidgetApi() {
  const { t } = useI18n();
  const { $api } = useNuxtApp();
  const recaptchaInstance = useReCaptcha();
  const notificationsStore = globalNotificationsStore();

  const isLoadingReset = ref<boolean>(false);

  const onRequestResetPassword = async (emailData: { email: string }, closeModal: () => void) => {
    isLoadingReset.value = true;

    await recaptchaInstance?.recaptchaLoaded();

    const recaptchaToken = await recaptchaInstance?.executeRecaptcha('submit');

    if (!recaptchaToken) {
      notificationsStore.addNotificationsPending([
        {
          message: t('error.recaptchaError'),
          type: EnumNotificationItemStatus.Error,
        },
      ]);

      return;
    }

    try {
      const res = await $api.auth.passwordResetRequest({
        data: { ...emailData },
        fetchOptions: { headers: { 'g-recaptcha-response': recaptchaToken } },
      });

      notificationsStore.addNotificationsPending([
        {
          message: t('success.sendToEmail'),
          type: EnumNotificationItemStatus.Success,
        },
      ]);

      closeModal();
    } catch (error) {
      if (error instanceof FetchError && error.data?.message) {
        checkNetworkRecaptchaError(error.data?.message, false);
      }

      sentryLog(error as Error);
    } finally {
      isLoadingReset.value = false;
    }
  };

  return { isLoadingReset, onRequestResetPassword };
}
