<template>
  <div class="wi-user-geo-location">
    <div class="wi-user-geo-location__icon-wrapper">
      <WiGeoLocation class="wi-image-container" />
    </div>

    <span class="wi-user-geo-location__text">{{ $t('wiUserGeoLocation.russia') }}</span>
  </div>
</template>

<script lang="ts" src="./index.ts" />
<style lang="scss" src="./index.scss" scoped />
