<template>
  <button class="wi-theme-button" @click="onChangeThemeMode">
    <div class="wi-theme-button__icon-wrapper">
      <WiDarkModeIcon class="wi-image-container wi-theme-button__icon_dark" />

      <WiLiteModeIcon class="wi-image-container wi-theme-button__icon_light" />
    </div>

    <span class="wi-theme-button__text wi-theme-button__text_dark"> {{ $t('theme.dark') }} </span>

    <span class="wi-theme-button__text wi-theme-button__text_light"> {{ $t('theme.light') }} </span>
  </button>
</template>

<script lang="ts" src="./index.ts"></script>
<style lang="scss" src="./index.scss" scoped />
