import { ShopEntity } from '../shop';

export class CartShopEntity extends ShopEntity {
  errors: Array<string> = [];

  constructor(dataShop: ShopEntity, errors: Array<string>) {
    super(dataShop);

    this.errors = errors;
  }
}
