<template>
  <div
    class="wi-mobile-control-wrapper"
    :class="[
      'wi-mobile-control-wrapper_variant-' + variant,
      { 'wi-mobile-control-wrapper_active': isActive, 'wi-mobile-control-wrapper_is-wish': currentIsWish },
    ]"
    @click="handleClick"
  >
    <div class="wi-mobile-control-wrapper__icon-wrapper">
      <span v-show="count" class="wi-mobile-control-wrapper__icon-wrapper-counter wi-flex-content-center">
        {{ count > 99 ? '99+' : count }}
      </span>
      <slot />
    </div>
  </div>
</template>

<script lang="ts" src="./index.ts"></script>
<style lang="scss" scoped src="./index.scss"></style>
