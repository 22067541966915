import type { IGlobalCartStore } from './types';

export const globalCartState = (): IGlobalCartStore => {
  return {
    dataCartStore: reactive({
      cartEntity: null,
      cartOrderEntity: null,
    }),
  };
};
