import type { IGlobalComponentManagementPreviousDeliveryInfoStore, IGlobalComponentManagementStore } from './types';

export const globalComponentManagementActions = {
  addSubstrateAreaId: function (this: IGlobalComponentManagementStore, id: string) {
    this.wiSubstrateArea.activeIds = [...this.wiSubstrateArea.activeIds, id];
  },

  removeSubstrateAreaId: function (this: IGlobalComponentManagementStore, id: string) {
    const newArray = this.wiSubstrateArea.activeIds.filter((activeId) => activeId !== id);
    this.wiSubstrateArea.activeIds = [...newArray];
  },

  removeLastSubstrateAreaId: function (this: IGlobalComponentManagementStore) {
    const newArray = [...this.wiSubstrateArea.activeIds];
    newArray.pop();

    this.wiSubstrateArea.activeIds = newArray;
  },

  removeAllSubstrateAreaIds: function (this: IGlobalComponentManagementStore) {
    this.wiSubstrateArea.activeIds = [];
  },

  setIsOpenLoginModal: function (
    this: IGlobalComponentManagementStore,
    isOpen: boolean,
    onSuccessCallback?: () => void,
  ) {
    this.isOpenLoginModal = isOpen;

    if (isOpen) {
      this.onSuccessLoginCallback = onSuccessCallback;
    } else {
      this.onSuccessLoginCallback = undefined;
    }
  },

  setIsOpenVerifyPhoneModal: function (this: IGlobalComponentManagementStore, isOpen: boolean) {
    this.isOpenVerifyPhoneModal = isOpen;
  },

  setIsOpenConfirmPhoneNumberModal: function (this: IGlobalComponentManagementStore, isOpen: boolean) {
    this.isOpenConfirmPhoneNumberModal = isOpen;
  },

  setIsOpenSupportModal: function (this: IGlobalComponentManagementStore, isOpen: boolean) {
    this.isOpenSupportModal = isOpen;
  },

  setIsCategoriesTree: function (this: IGlobalComponentManagementStore, isCategoriesTree: boolean) {
    this.isCategoriesTree = isCategoriesTree;
  },

  setIsLoadingPreviousDelivery: function (this: IGlobalComponentManagementStore, isLoadingPreviousDelivery: boolean) {
    this.isLoadingPreviousDelivery = isLoadingPreviousDelivery;
  },

  setPreviousDeliveryInfo: function (
    this: IGlobalComponentManagementStore,
    previousDeliveryInfo: IGlobalComponentManagementPreviousDeliveryInfoStore,
  ) {
    this.previousDeliveryInfo = previousDeliveryInfo;
  },
};
