export const getMobileControlMenuProfilesIsLoginUser = (localization: any) => {
  return [
    {
      label: localization('wiHeaderNavigation.createShop'),
      link: '/user/subscription/5',
    },
    {
      label: localization('wiHeaderNavigation.shopManagement'),
      link: 'https://vendor.wikkeo.com/login?reffer=home_page&hardcode=true',
      target: '_blank',
    },
    {
      label: localization('wiHeaderNavigation.newItems'),
      link: '/new_products',
    },
    {
      label: localization('wiHeaderNavigation.forSuppliers'),
      link: '/for-vendors',
    },
    {
      label: localization('wiHeaderNavigation.help'),
      link: '/help',
    },
    {
      label: localization('wiHeaderNavigation.support'),
      link: '',
      action: 'openSupportModal',
    },
  ];
};

export const getMobileControlMenuProfilesIsLoginVendor = (localization: any) => {
  return [
    {
      label: localization('wiHeaderNavigation.shopManagement'),
      link: 'https://vendor.wikkeo.com/login?reffer=home_page&hardcode=true',
      target: '_blank',
    },
    {
      label: localization('wiHeaderNavigation.newItems'),
      link: '/new_products',
    },
    {
      label: localization('wiHeaderNavigation.forSuppliers'),
      link: '/for-vendors',
    },
    {
      label: localization('wiHeaderNavigation.help'),
      link: '/help',
    },
    {
      label: localization('wiHeaderNavigation.support'),
      link: '',
      action: 'openSupportModal',
    },
  ];
};

export const getMobileControlMenuProfilesNotLogin = (localization: any) => {
  return [
    {
      label: localization('wiHeaderNavigation.sighIn'),
      link: '/user/login',
    },
    {
      label: localization('wiHeaderNavigation.shopManagement'),
      link: 'https://vendor.wikkeo.com/login?reffer=home_page&hardcode=true',
      target: '_blank',
    },
    {
      label: localization('wiHeaderNavigation.newItems'),
      link: '/new_products',
    },
    {
      label: localization('wiHeaderNavigation.forSuppliers'),
      link: '/for-vendors',
    },
    {
      label: localization('wiHeaderNavigation.help'),
      link: '/help',
    },
    {
      label: localization('wiHeaderNavigation.support'),
      link: '',
      action: 'openSupportModal',
    },
  ];
};

export const getMobileControlUserLinks = (localization: any) => {
  return [
    {
      id: 'orders',
      label: localization('wiControlUser.orders'),
      link: '/user/orders',
      icon: 'OrdersIcon',
    },
    {
      id: 'chat',
      label: localization('wiControlUser.messages'),
      link: '/user/chat',
      icon: 'MessagesIcon',
    },
    {
      id: 'wishlists',
      label: localization('wiControlUser.favorite'),
      link: '/user/wishlists',
      icon: 'WishIcon',
    },
    {
      id: 'reviews',
      label: localization('wiControlUser.myReviews'),
      link: '/user/reviews',
      icon: 'ReviewIcon',
    },
  ];
};
