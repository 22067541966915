export const getReviewAuthorName = (user: IUser, isWithColon?: boolean) => {
  const { firstName, lastName, email } = user;

  const { $i18n } = useNuxtApp();

  let result = '';

  if (!result && firstName) {
    result = `${firstName} ${lastName ? lastName[0] + '.' : ''}`;
  }

  if (!result) {
    result = email;
  }

  if (!result) {
    result = $i18n.t('common.noName');
  }

  return isWithColon ? result + ':' : result;
};
