<template>
  <WiNuxtErrorBoundary>
    <MainLayout>
      <template #header>
        <slot name="header">
          <WiCommonHeader v-if="isMobile === false" />
        </slot>
      </template>

      <template #default>
        <section class="profile-layout__content wi-custom-container">
          <WiProfileMenu v-if="isMobile === false" :shopOrdersStatusesStatistic="shopOrdersStatusesStatistic" />

          <slot name="default"> </slot>
        </section>
      </template>

      <template #mobileControl v-if="!isHideMobileControl && isMobile">
        <WiCommonMobileControl />
      </template>
    </MainLayout>
  </WiNuxtErrorBoundary>
</template>

<script lang="ts" src="./index.ts"></script>
<style lang="scss" scoped src="./index.scss"></style>
