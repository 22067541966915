export const infoFilesLinks = (t: any) => {
  const { documents } = useRuntimeConfig().public;

  return [
    {
      label: t('footer.terms'),
      link: documents.userAgreement,
    },
    {
      label: t('footer.dataPolicy'),
      link: documents.privacyPolicy,
    },
    {
      label: t('footer.content'),
      link: documents.contentRulesAndRequirements,
    },
  ];
};
