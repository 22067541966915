import { errorHandler, refreshRequest } from '@/shared/helpers/errorHandler';
import { type $Fetch, type FetchContext, type FetchOptions, type FetchResponse, type FetchError } from 'ofetch';
import { StorageService } from '../browserAPI/storageService';
import { QueryUtils } from '../utils/query';
import { convertError } from './convertError';
import type { IErrorOptionsRequest, IRequestParams } from './types';
import { EnumResponseCode } from './constants';

const storageService = new StorageService('local');

export interface IBaseApiCallPayload {
  method: keyof typeof MethodEnum;
  url: string;
  body?: any;
  query?: IRequestParams<any>;
  fetchOptions?: Partial<FetchOptions<'json'>>;
  errorOptions?: IErrorOptionsRequest;
  isRaw?: boolean;
  isSkipRefresh?: boolean;
}

export enum MethodEnum {
  GET,
  POST,
  PATCH,
  DELETE,
  PUT,
}

/*
 The FetchFactory acts as a wrapper around an HTTP client.
 It encapsulates the functionality for making API requests asynchronously
 through the call function, utilizing the provided HTTP client.
 @see https://nuxt.com/docs/api/composables/use-fetch
*/
class BaseApi {
  private $fetch: $Fetch;

  constructor(fetcher: $Fetch) {
    this.$fetch = fetcher;
  }

  private async _refresh<T>(options: IBaseApiCallPayload): Promise<T> {
    return new Promise(async (resolve, reject) => {
      try {
        await refreshRequest({
          url: options.url,
          onResponseSuccess: () => {
            const requestResponse = this.call<T>(options);
            resolve(requestResponse);
          },
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * The HTTP client is utilized to control the process of making API requests.
   * @param method the HTTP method (GET, POST, ...)
   * @param url the endpoint url
   * @param data the body data
   * @param fetchOptions fetch options
   * @returns
   */

  async call<T>(options: IBaseApiCallPayload): Promise<T> {
    const {
      method,
      url,
      body,
      query,
      errorOptions = { isShow: true, isShowServerError: false },
      fetchOptions = {},
      isRaw = false,
      isSkipRefresh = false,
    } = options;

    const accessToken = storageService.getItem('AccessToken', false);

    const headers = {
      ...(fetchOptions.headers || {}),
      Authorization: `Bearer ${accessToken}`,
    };

    const fetchOptionsFull = {
      ...fetchOptions,
      headers,
    };

    const getQuery = (query: IRequestParams<unknown> | undefined) => {
      return isRaw ? query : QueryUtils.builder(query);
    };

    const request = this.$fetch<T>(url, {
      method,
      body,
      query: getQuery(query),
      ...fetchOptionsFull,
      retryStatusCodes: [],
      retry: false,

      onResponse: (data) => {
        if (!data.response.ok) {
          this.handlerResponseError(data.response, errorOptions, data);
        }
      },

      onResponseError(data) {
        if (errorOptions.isShowServerError && data.response.status >= 500) {
          throw showError({
            data: data.response._data,
            statusCode: 500,
            fatal: true,
          });
        }
      },
    });

    return new Promise(async (resolve, reject) => {
      try {
        const [requestResponse] = await Promise.all([request]);

        resolve(requestResponse);
      } catch (error) {
        if ((error as FetchError)?.data?.statusCode === EnumResponseCode.Unauthorized && !isSkipRefresh) {
          const refreshResponse = await this._refresh<T>(options);
          resolve(refreshResponse);
        } else {
          reject(error);
        }
      }
    });
  }

  async get<T>(options: Omit<IBaseApiCallPayload, 'method'>): Promise<T> {
    const { url, body, query, errorOptions, fetchOptions, isRaw, isSkipRefresh } = options;

    return this.call<T>({
      method: 'GET',
      url,
      body,
      query,
      errorOptions,
      fetchOptions,
      isRaw,
      isSkipRefresh,
    });
  }

  async post<T>(options: Omit<IBaseApiCallPayload, 'method'>): Promise<T> {
    const { url, body, query, errorOptions, fetchOptions, isRaw, isSkipRefresh } = options;

    return this.call<T>({
      method: 'POST',
      url,
      body,
      query,
      errorOptions,
      fetchOptions,
      isRaw,
      isSkipRefresh,
    });
  }

  async patch<T>(options: Omit<IBaseApiCallPayload, 'method'>): Promise<T> {
    const { url, body, query, errorOptions, fetchOptions, isRaw, isSkipRefresh } = options;

    return this.call<T>({
      method: 'PATCH',
      url,
      body,
      query,
      errorOptions,
      fetchOptions,
      isRaw,
      isSkipRefresh,
    });
  }

  async delete<T>(options: Omit<IBaseApiCallPayload, 'method'>): Promise<T> {
    const { url, body, query, errorOptions, fetchOptions, isRaw, isSkipRefresh } = options;

    return this.call<T>({
      method: 'DELETE',
      url,
      body,
      query,
      errorOptions,
      fetchOptions,
      isRaw,
      isSkipRefresh,
    });
  }

  async put<T>(options: Omit<IBaseApiCallPayload, 'method'>): Promise<T> {
    const { url, body, query, errorOptions, fetchOptions, isRaw, isSkipRefresh } = options;

    return this.call<T>({
      method: 'PUT',
      url,
      body,
      query,
      errorOptions,
      fetchOptions,
      isRaw,
      isSkipRefresh,
    });
  }

  handlerResponseError(
    response: FetchResponse<any> & FetchResponse<'json'>,
    errorOptions: IErrorOptionsRequest,
    data: FetchContext,
  ) {
    const errors = convertError(response);

    errors.forEach((error) => {
      errorHandler(error, errorOptions, data);
    });
  }
}

export default BaseApi;
