export default defineNuxtComponent({
  name: 'WiMobileControlWrapper',

  props: {
    variant: {
      type: String as PropType<'default' | 'alternative'>,
      default: 'default',
    },

    isActive: {
      type: Boolean,
    },

    count: {
      type: Number,
      default: 0,
    },

    isWish: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['onClick'],

  setup(props, { emit }) {
    const currentIsWish = ref(false);

    onNuxtReady(() => {
      currentIsWish.value = props.isWish;
    });

    const handleClick = () => {
      emit('onClick');
    };

    return {
      currentIsWish,
      handleClick,
    };
  },
});
