import WiAuthorization from '~/widgets/login/WiAuthorization/ui/index.vue';
import WiRegistration from '~/widgets/login/WiRegistration/ui/index.vue';
import WiSighInTelegram from '~/widgets/login/WiSighInTelegram/ui/index.vue';

import { Components } from '@wikkeo/ui-kit';

const { WiTabs } = Components;

export default {
  name: 'WiLogin',

  components: { WiTabs, WiAuthorization, WiRegistration, WiSighInTelegram },

  setup() {
    const { documents } = useRuntimeConfig().public;
    const { t } = useI18n();

    const offerForSuppliersLink = documents.offerForSuppliers;
    const privacyPolicyLink = documents.privacyPolicy;

    const tabs = [
      { key: 'login', name: t('login.authorization') },
      { key: 'register', name: t('login.registration') },
    ];

    const activeTab = ref<string>('login');

    const setActiveTab = (key: string) => {
      activeTab.value = key;
    };

    return {
      tabs,
      activeTab,
      offerForSuppliersLink,
      privacyPolicyLink,

      setActiveTab,
    };
  },
};
