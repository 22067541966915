import type { IApiCategoriesTree } from '~/shared/api/v1/categories/types';
import { StorageService } from '~/shared/browserAPI/storageService';

const ttlCategories = 86400000;
interface ICategoriesTreeWithDate {
  installationDate: Date;
  categories: Array<IApiCategoriesTree>;
}

export const useHeaderCategoryBlockApi = () => {
  const storageService = new StorageService('local');
  const managementStore = globalComponentManagementStore();

  const { $api } = useNuxtApp();

  let isLoadingCategories = ref(false);

  let categoriesTree = ref<null | Array<IApiCategoriesTree>>(null);

  const sortCategories = (categories: Array<IApiCategoriesTree>) => categories.sort((a, b) => a.value.id - b.value.id);

  const downloadCategoriesTree = async () => {
    try {
      isLoadingCategories.value = true;
      const data = await $api.categories.getCategoriesTree();
      categoriesTree.value = sortCategories(data);
    } finally {
      isLoadingCategories.value = false;
    }
  };

  const setCategoriesToLocalStorage = (categories: Array<IApiCategoriesTree>) => {
    const payload = {
      categories,
      installationDate: new Date(),
    };

    storageService.setItem('CategoriesTreeWithDate', payload);
    managementStore.setIsCategoriesTree(true);
  };

  const setCategoriesFromLocalStorage = () => {
    const dataCategories = storageService.getItem('CategoriesTreeWithDate') as ICategoriesTreeWithDate | null;

    if (!dataCategories) {
      return;
    }

    const { categories } = dataCategories;

    categoriesTree.value = sortCategories(categories);

    managementStore.setIsCategoriesTree(true);
  };

  const downloadAndSetCategoriesToStorage = async () => {
    await downloadCategoriesTree();

    if (!categoriesTree.value) {
      return;
    }

    setCategoriesToLocalStorage(categoriesTree.value);
  };

  const checkCategoriesFromLocalStorage = () => {
    const categoriesWithDateFromStorage = storageService.getItem(
      'CategoriesTreeWithDate',
    ) as ICategoriesTreeWithDate | null;

    if (categoriesWithDateFromStorage) {
      const { installationDate } = categoriesWithDateFromStorage;

      const timeDiff = Math.abs(new Date().getTime() - new Date(installationDate).getTime());

      if (timeDiff < ttlCategories) {
        setCategoriesFromLocalStorage();

        return;
      }
    }

    downloadAndSetCategoriesToStorage();
  };

  return { checkCategoriesFromLocalStorage, isLoadingCategories, categoriesTree };
};
