import WiNuxtErrorBoundary from '@/shared/ui/WiNuxtErrorBoundary/index.vue';

import '@wikkeo/ui-kit/styles';
import { setThemeMode } from '~/shared/helpers/themeMode';
import { broadcastMessages } from '~/shared/services/BroadcastChannel';
import { BroadcastChannelEventEnum } from '~/shared/services/BroadcastChannel/types';
import { useDefaultLayoutApi } from '../api';
import { useDefaultLayoutModel } from '../model';
import { useFeatureAddProductToCart } from '~/features/cart/addProductsToCart';
import { migrateOldCartDataToNew } from '~/shared/helpers/cart/migrateOldCartDataToNew';
import { useMobile } from '~/shared/hooks/useMobile';
import useAppRouter from '~/shared/hooks/useAppRouter';
import { ymapsApiSrc } from '~/shared/config/scripts/ymaps';
import { checkIsServer } from '~/shared/browserAPI/isServer';
import { DOMUtils } from '~/shared/utils/dom';

export default defineNuxtComponent({
  head() {
    return {
      // @ts-ignore
      script: checkIsServer() ? [] : [{ src: ymapsApiSrc(useRuntimeConfig().public.apiYandexMapsKey), defer: true }],
    };
  },

  name: 'DefaultLayout',

  components: {
    WiNuxtErrorBoundary,
  },

  setup() {
    const { setCartEntityFromLocalStorageToStore, synchronizationCartWhenExitPage } = useFeatureAddProductToCart();

    const { getFeaturesFlagsData } = useDefaultLayoutApi();

    const notificationsStore = globalNotificationsStore();

    const defaultLayoutModel = useDefaultLayoutModel();

    const { checkWindowView } = useMobile();

    const router = useAppRouter();

    const { isMobile } = useMobile();

    getFeaturesFlagsData();

    const primaryColor = DOMUtils.getCSSVariable('--primary-base');

    const init = async () => {
      broadcastMessages.addEventListener(BroadcastChannelEventEnum.toggleTheme, setThemeMode);

      setThemeMode();

      defaultLayoutModel.setTokens();

      await defaultLayoutModel.getUserInfo();

      setCartEntityFromLocalStorageToStore();
    };

    watch(router.route, () => {
      handleChangePage();
    });

    const clear = () => {
      broadcastMessages.clear();
    };

    const handleChangePage = () => {
      synchronizationCartWhenExitPage();

      notificationsStore.clear();
    };

    onMounted(() => {
      migrateOldCartDataToNew();

      init();

      window.addEventListener('resize', checkWindowView);

      window.addEventListener('beforeunload', synchronizationCartWhenExitPage);
    });

    onUnmounted(() => {
      clear();

      window.removeEventListener('resize', checkWindowView);

      window.removeEventListener('beforeunload', synchronizationCartWhenExitPage);
    });

    checkWindowView();

    return {
      primaryColor,
      isMobile,
    };
  },
});
