import { getCurrentDiscount } from './getCurrentDiscount';
import { getCurrentWholesaleByObjectKey } from './getCurrentWholesaleByObjectKey';
import { getCustomIdDiscount } from './getCustomIdDiscount';
import { getMaxDiscount } from './getMaxDiscount';
import { getMinDiscount } from './getMinDiscount';

export const ProductHelpers = {
  getMaxDiscount,
  getCurrentDiscount,
  getCustomIdDiscount,
  getMinDiscount,
  getCurrentWholesaleByObjectKey,
};
