import { checkIsServer } from '~/shared/browserAPI/isServer';
import { StorageService } from '~/shared/browserAPI/storageService';
import { useMobile } from '~/shared/hooks/useMobile';

export default defineNuxtRouteMiddleware((to, from) => {
  if (checkIsServer()) {
    return;
  }

  const { isMobile, checkWindowView } = useMobile();

  const storageService = new StorageService('local');

  if (!storageService.getItem('AccessToken', false) && to.meta.isPrivate) {
    checkWindowView();

    if (isMobile.value) {
      return navigateTo('/user/unauthorized');
    } else {
      return navigateTo('/user/login');
    }
  }
});
