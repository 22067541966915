import WiSubstrateArea from '~/widgets/WiSubstrateArea/index.vue';
import WiNuxtErrorBoundary from '~/shared/ui/WiNuxtErrorBoundary/index.vue';
import { Widgets } from '@wikkeo/ui-kit';

import { useSubstrateManagementHook } from '~/shared/hooks/useSubstrateManagement';
import { useMobile } from '~/shared/hooks/useMobile';

const { WiInputPhone } = Widgets;

export default defineNuxtComponent({
  name: 'WiInputPhoneWrapper',

  components: {
    WiInputPhone,
    WiSubstrateArea,
    WiNuxtErrorBoundary,
  },

  props: {
    modelValue: {
      type: String,
    },

    label: {
      type: String,
    },

    isVerify: {
      type: Boolean as PropType<boolean | null>,
      default: null,
    },

    isDisabled: {
      type: Boolean,
      default: undefined,
    },

    isReadonly: {
      type: Boolean,
      default: undefined,
    },

    errorMessage: {
      type: String,
    },

    isSubstrate: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['update:modelValue', 'validate', 'onOpenDropdown'],

  setup(props, { emit }) {
    const { isMobile } = useMobile();

    const { isSubstrate } = toRefs(props);

    const { isShowSubstrate, handleOpenSubstrate } = useSubstrateManagementHook();

    const isOpenDropdown = ref(false);

    onMounted(() => {
      handleOpenSubstrateWrapper(isOpenDropdown.value);
    });

    watch(isShowSubstrate, () => {
      if (!isSubstrate.value) {
        return;
      }

      onOpenDropdown(isShowSubstrate.value);
    });

    watch(isOpenDropdown, () => {
      handleOpenSubstrateWrapper(isOpenDropdown.value);
    });

    const handleOpenSubstrateWrapper = (isOpen: boolean) => {
      if (!isSubstrate.value) {
        return;
      }

      handleOpenSubstrate(isOpen);
    };

    const onOpenDropdown = (isOpen: boolean) => {
      isOpenDropdown.value = isOpen;

      emit('onOpenDropdown');
    };

    const onValidate = (data: any) => {
      emit('validate', data);
    };

    const onUpdateModelValue = (data: any) => {
      emit('update:modelValue', data);
    };

    return {
      isOpenDropdown,
      isShowSubstrate,
      isMobile,

      onOpenDropdown,
      onValidate,
      onUpdateModelValue,
    };
  },
});
