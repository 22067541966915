import { checkIsServer } from '~/shared/browserAPI/isServer';
import { StorageService } from '~/shared/browserAPI/storageService';
import { globalUserActions } from './actions';
import { getGlobalUserState } from './state';
import { globalUserGetters } from './getters.js';
import type { IUser } from './types';

export const useGlobalUserStore = defineStore('globalUserStore', {
  state: () => getGlobalUserState(),
  actions: globalUserActions,
  getters: globalUserGetters,

  hydrate(state, _initialState) {
    const tokens: { accessToken?: string; refreshToken?: string } = {};
    const isServer = checkIsServer();

    // TODO check hydrate only client
    const storageService = new StorageService();

    if (!isServer) {
      storageService.setProvider('local');
    }

    const wikAccessToken = storageService.getItem<string>('AccessToken', false);
    const wikRefreshToken = storageService.getItem<string>('RefreshToken', false);

    if (wikAccessToken) {
      tokens.accessToken = wikAccessToken;
    }

    if (wikRefreshToken) {
      tokens.refreshToken = wikRefreshToken;
    }

    state.wishlistIds = storageService.getItem<Array<number>>('WishlistIds');

    state.tokens = tokens;
    state.user = storageService.getItem<IUser>('User');
  },
});
