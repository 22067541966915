import { Icons } from '@wikkeo/ui-kit';
import WiMobileControlUserMenuContent from '~/shared/ui/mobileControl/modules/legacy/WiMobileControlUserMenuContent/ui/index.vue';
import WiMobileControlWrapper from '~/shared/ui/mobileControl/modules/WiMobileControlWrapper/ui/index.vue';
import WiNuxtErrorBoundary from '~/shared/ui/WiNuxtErrorBoundary/index.vue';
import useAppRouter from '~/shared/hooks/useAppRouter';

const { WiMenuUser } = Icons;

export default defineNuxtComponent({
  name: 'WiMobileControlUserMenu',

  components: {
    WiMenuUser,
    WiMobileControlUserMenuContent,
    WiNuxtErrorBoundary,
    WiMobileControlWrapper,
  },

  setup() {
    const router = useAppRouter();

    const isActive = toRef(
      () => router.route.value.meta.isProfilePage || router.route.value.meta.isUnauthorizedProfilePage,
    );

    const handleGoProfile = () => {
      router.goToProfile();
    };

    return {
      isActive,
      handleGoProfile,
    };
  },
});
