<template>
  <WiNuxtErrorBoundary>
    <article class="main-layout">
      <section id="mainLayoutScroll" class="main-layout__content wi-custom-scroll">
        <section v-show="$slots.header" class="main-layout__header">
          <slot name="header"> </slot>
        </section>

        <div class="main-layout__content-container wi-custom-container">
          <slot name="default"> </slot>
        </div>

        <section v-show="$slots.footer" class="main-layout__footer">
          <slot name="footer"> </slot>
        </section>
      </section>

      <section v-show="$slots.mobileControl && isMobile" class="main-layout__mobile-control">
        <slot name="mobileControl"> </slot>
      </section>
    </article>

    <WiLoginModal
      v-if="managementStore?.isOpenLoginModal"
      :isOpen="managementStore?.isOpenLoginModal"
      @onClose="onCloseLoginModal"
    />

    <WiSupportModal />

    <WiCookieModal />

    <ClientOnly>
      <WiNotificationSection />

      <WiConfirmAndVerifyPhoneModals />
    </ClientOnly>
  </WiNuxtErrorBoundary>
</template>

<script lang="ts" src="./index.ts"></script>
<style lang="scss" scoped src="./index.scss"></style>
