import { LinkUtils } from '~/shared/utils/link';

export default defineNuxtComponent({
  name: 'ServerError',

  setup() {
    const config = useRuntimeConfig();

    const onClearError = () => {
      clearError({ redirect: '/' });
    };

    const onRefresh = () => {
      location.reload();
    };

    return {
      onClearError,
      onRefresh,

      supportTgUrl: LinkUtils.getTelegramLink(config.public.supportTgName),
    };
  },
});
