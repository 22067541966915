<template>
  <WiModalWrapper :isOpen="managementStore.isOpenSupportModal" @onClose="onClose">
    <template #title>
      <span class="wi-support-modal-title"> {{ $t('supportModal.title') }} </span>
    </template>

    <template #body>
      <div class="wi-support-modal-content">
        <a :href="supportOrdersTgUrl" target="_blank" class="content__link">
          <WiTgPlane />
          <span>{{ $t('supportModal.helpWithOrders') }}</span>
        </a>

        <a :href="supportVendorsTgUrl" target="_blank" class="content__link">
          <WiTgPlane />
          <span>{{ $t('supportModal.supplierRegistration') }}</span>
        </a>

        <a :href="supportTgUrl" target="_blank" class="content__link">
          <WiTgPlane />
          <span>{{ $t('supportModal.technicalSupport') }}</span>
        </a>
      </div>
    </template>
  </WiModalWrapper>
</template>

<script lang="ts" src="./index.ts" />
<style lang="scss" src="./index.scss" scoped />
