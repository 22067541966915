<template>
  <div class="wi-mobile-menu-wrapper">
    <WiNuxtErrorBoundary>
      <div v-show="isOpen" class="wi-mobile-menu">
        <Teleport to="#teleports" :disabled="!isOpen">
          <div class="wi-mobile-menu__content">
            <WiSubstrateArea v-if="isShowSubstrate" />

            <div class="wi-mobile-menu__header">
              <div class="wi-mobile-menu__header-back">
                <WiUserGeoLocation v-if="menuLevel < 2" class="location-left-padding" />

                <div v-else @click="comeBackPreviousLevel" class="wi-mobile-menu__header-back">
                  <div class="wi-mobile-menu__header-back-icon-wrapper">
                    <WiChevron class="wi-mobile-menu__header-back-icon wi-image-container" />
                  </div>

                  <button class="wi-mobile-menu__header-back-button">Назад</button>
                </div>
              </div>

              <span class="wi-mobile-menu__header-title wi-flex-content-center">{{ menuName }}</span>

              <div class="wi-mobile-menu__header-close-button-wrapper">
                <div class="wi-mobile-menu__header-close-button wi-flex-content-center" @click="handleCloseMenu">
                  <WiClose class="wi-mobile-menu__header-close-button-icon" />
                </div>
              </div>
            </div>

            <div class="wi-mobile-menu__body">
              <slot name="content"> </slot>
            </div>
          </div>
        </Teleport>
      </div>
    </WiNuxtErrorBoundary>
  </div>
</template>

<script lang="ts" src="./index.ts"></script>
<style lang="scss" scoped src="./index.scss"></style>
