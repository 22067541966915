import { CartShopEntity } from '~/entities/cartShop';
import { ShopEntity } from '~/entities/shop';
import type { IShopApiV1 } from '~/shared/api/v1/shops/types';
import { ShopAdapter } from './shop';

export class CartShopAdapter {
  public fromApi(data: { shop: IShopApiV1; errors: Array<string> }) {
    const { shop, errors } = data;

    return new CartShopEntity(new ShopAdapter().fromApi(shop), errors);
  }

  public fromEntity(data: { shop: ShopEntity; errors: Array<string> }) {
    const { shop, errors } = data;

    return new CartShopEntity(shop, errors);
  }
}
