// TODO: remove localization argument in all references
export const errorMessages = (localization?: any) => {
  const { $i18n } = useNuxtApp();

  return {
    REQUIRED: $i18n.t('error.required'),

    INVALID_EMAIL: $i18n.t('error.invalidEmail'),

    INVALID_PHONE: $i18n.t('error.invalidPhone'),

    SAME_PASSWORD_ERROR: $i18n.t('error.samePassword'),

    getMinLengthErrorMessage: (amount = 3) =>
      $i18n.t('error.minLength') + ' ' + amount + ' ' + $i18n.t('error.symbols', amount),

    getMaxLengthErrorMessage: (amount = 255) =>
      $i18n.t('error.maxLength') + ' ' + amount + ' ' + $i18n.t('error.symbols', amount),

    RECAPTCHA_ERROR: $i18n.t('error.recaptchaError'),

    TOKEN_AVAILABLE: $i18n.t('error.tokenAvailable'),

    MAP_ERROR: $i18n.t('error.mapError'),

    VALUE_NOT_VALID: $i18n.t('error.valueNotValid'),
  };
};
