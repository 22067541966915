import {
  buyerCreateRoomByOrder,
  buyerCreateRoomByProduct,
  buyerCreateRoomByShop,
  useChatStore,
} from '@wikkeo/wikkeo-chat-library';
import useAppRouter from './useAppRouter';
import { useStoreWithContext } from './useStoreWithContext';

interface CreateChat {
  byOrder: Parameters<typeof buyerCreateRoomByOrder>[0];
  byProduct: Parameters<typeof buyerCreateRoomByProduct>[0];
  byShop: Parameters<typeof buyerCreateRoomByShop>[0];
}

type CreateChatType = keyof CreateChat;

export const useChatHelper = () => {
  const userStore = useGlobalUserStore();
  const router = useAppRouter();
  const { t } = useI18n();

  const componentManagementStore = globalComponentManagementStore();
  const notificationsStore = globalNotificationsStore();

  const chatStore = useStoreWithContext(useChatStore);

  const typeAction = ref<CreateChatType | null>(null);
  const optionsAction = ref<TAnyLiteral | null>(null);

  // private actions
  const checkIsEnabledCreateChat = () => {
    if (userStore.user?.id) {
      return true;
    }

    componentManagementStore.setIsOpenLoginModal(true, () => {
      if (!typeAction.value) {
        return;
      }

      createChat(typeAction.value, optionsAction.value as CreateChat[typeof typeAction.value]);
    });

    return false;
  };

  const _createChat = async <Type extends CreateChatType>(type: Type, options: CreateChat[Type]) => {
    switch (type) {
      case 'byOrder': {
        const { data } = await buyerCreateRoomByOrder(options as CreateChat['byOrder']);
        return data.roomId;
      }
      case 'byProduct': {
        const { data } = await buyerCreateRoomByProduct(options as CreateChat['byProduct']);
        return data.roomId;
      }
      case 'byShop': {
        const { data } = await buyerCreateRoomByShop(options as CreateChat['byShop']);
        return data.roomId;
      }
    }
  };
  // end private actions

  // public actions
  const createChat = async <Type extends CreateChatType>(type: Type, options: CreateChat[Type]) => {
    if (!checkIsEnabledCreateChat()) {
      typeAction.value = type;
      optionsAction.value = options;
      return;
    }

    try {
      const roomID = await _createChat(type, options);

      if (!roomID) {
        throw Error(t('error.createChatError'));
      }

      chatStore.changeActiveRoomID(roomID);

      router.goToProfileChat({ query: { roomId: roomID } });

      notificationsStore.addNotificationsPending([
        {
          message: t('success.createChat'),
          type: EnumNotificationItemStatus.Success,
        },
      ]);

      typeAction.value = null;
      optionsAction.value = null;
    } catch (error) {
      notificationsStore.addNotificationsPending([
        {
          message: (error as Error).message,
          type: EnumNotificationItemStatus.Error,
        },
      ]);

      console.error(error);
    }
  };

  return {
    createChat,
  };
};
