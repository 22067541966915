import WiNuxtErrorBoundary from '~/shared/ui/WiNuxtErrorBoundary/index.vue';
import WiUserGeoLocation from '~/shared/ui/WiUserGeoLocation/ui/index.vue';
import WiThemeButton from '~/shared/ui/WiThemeButton/ui/index.vue';

import { useGlobalUserStore } from '~/app/store/user';
import { getMenuIsLoginVendor, getMenuIsNotLoginUser, getMenuLoginUser } from '~/shared/config/headerMenuUser';
import useAppRouter from '~/shared/hooks/useAppRouter';

export default defineNuxtComponent({
  name: 'WiHeaderNavigation',

  components: {
    WiNuxtErrorBoundary,
    WiUserGeoLocation,
    WiThemeButton,
  },

  setup() {
    const managementStore = globalComponentManagementStore();

    const { t } = useI18n();

    const globalUserStore = useGlobalUserStore();

    const { user, tokens } = storeToRefs(globalUserStore);

    const router = useAppRouter();

    const getLocationPathName = ref<null | string>(null);

    const defaultProfileMenu = getMenuIsNotLoginUser(t);

    const getProfileMenu = (): any => {
      if (!tokens.value.accessToken) {
        return getMenuIsNotLoginUser(t);
      }
      if (user.value?.isSeller === true) {
        return getMenuIsLoginVendor(t);
      } else {
        return getMenuLoginUser(t);
      }
    };

    onNuxtReady(() => {
      getProfileMenu();
      setLocationPathName();
    });

    const setLocationPathName = async () => {
      getLocationPathName.value = router.route.value.path;
    };

    const onApplyLinkAction = (action: string | undefined) => {
      if (!action) {
        return;
      }
      if (action === 'openSupportModal') {
        managementStore.setIsOpenSupportModal(true);
      }
    };

    return {
      defaultProfileMenu,
      getProfileMenu,
      getLocationPathName,

      onApplyLinkAction,
    };
  },
});
