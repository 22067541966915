import type { IImage } from '../../types';
import type { IShopApiV1 } from '../shops/types';

export interface IProductOptionValueDiscount {
  from: number;
  to: number;
  price: number;
}

export interface IProductOptionValueWholesale {
  priceLarge: number;
  priceNormal: number;
  priceSmall: number;
}

export interface IProductOptionValueApiV1 {
  id: number;
  title: string;
  price: number;
  quantity: number;
  step: number;
  sortOrder: number;
  discounts: Array<IProductOptionValueDiscount>;
  wholesale: any;
  image: any;
}

export interface IProductOptionApiV1 {
  id: number;
  title: string;
  sortOrder: number;
  values: Array<IProductOptionValueApiV1> | null;
}

export interface IProductStorages {
  id: number;
  city: string;
  isActive: boolean;
}

export interface IProductApiV1 {
  id: number;
  sku: string;
  shopId: number;
  name: string;
  productGroupId: number | null;

  description: string;
  policy: string;

  price: number;
  pricePrevious: number;

  isActive: boolean;
  isPrivate: boolean;
  isFreeShipping: boolean;

  salesCount: number;
  rating: number;
  reviewsCount: number;
  options: Array<IProductOptionApiV1> | null;
  storages: Array<IProductStorages> | null;

  image: IImage | null;
  galleryImages: Array<IImage>;

  youtubeLink: string | null;

  categoryId: number;
  brandId: number;
  isAvailable: boolean;
  isTop: boolean;
  priceType: ProductPriceType;
  slug: string;
  shop: Pick<IShopApiV1, 'id' | 'image' | 'name' | 'slug' | 'banner' | 'bannerV2' | 'wholesale'>;
  discountObject: IDiscountObject;
}

export enum ProductPriceType {
  Fixed = 'fix',
  ByQuantity = 'quantity',
  BySum = 'sum',
}

export interface IDiscountObject {
  discount: number;
  from: number;
  price: number;
  to: number;
}

export interface IDeliveryInfoApiV1 {
  address: string;
  errors: Array<string>;
  sumMin?: number;
  daysMin?: number;
  code?: number;
  incorrectAddress?: boolean;
}
