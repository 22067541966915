import type { IProductOptionValueDiscount } from '~/shared/api/v1/products/types';
import { ProductHelpers } from '.';

export const getCurrentDiscount = (discounts: Array<IProductOptionValueDiscount>, quantity: number) => {
  let discount = discounts.find((elem) => {
    if (elem.from <= quantity && elem.to >= quantity) {
      return true;
    }
  });

  if (discount === undefined) {
    const maxDiscount = ProductHelpers.getMaxDiscount(discounts);

    if (maxDiscount !== undefined && maxDiscount.to < quantity) {
      discount = maxDiscount;
    }
  }

  return discount;
};
