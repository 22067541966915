import useAppRouter from '~/shared/hooks/useAppRouter';

export const useWish = () => {
  const { t } = useI18n();
  const { $api } = useNuxtApp();
  const router = useAppRouter();

  const userStore = useGlobalUserStore();
  const { wishlistIds } = storeToRefs(userStore);
  const notificationStore = globalNotificationsStore();

  const getIsWish = (productId: number) => {
    return wishlistIds.value ? wishlistIds.value.includes(productId) : false;
  };

  const loadWishlistIds = async () => {
    if (userStore.isAuthorized && (!router.isHistory() || !userStore.wishlistIds)) {
      try {
        const wishlistIds = await $api.productsWishlist.getProductIdsWishlist();

        userStore.setWishlistIds(wishlistIds);
      } catch (error: any) {
        notificationStore.addNotificationsPending([
          {
            title: '',
            message: error.message,
            type: EnumNotificationItemStatus.Error,
          },
        ]);
      }
    }
  };

  const toggleProductInWishlistById = async (productId: number, value: boolean) => {
    if (value) {
      await $api.productsWishlist.removeProductWishlist(productId);

      notificationStore.addNotificationsPending([
        {
          title: '',
          message: t('success.removeFromWish'),
          type: EnumNotificationItemStatus.Success,
        },
      ]);
    } else {
      await $api.productsWishlist.setProductWishlist({ data: { productId } });

      notificationStore.addNotificationsPending([
        {
          title: '',
          message: t('success.addToWish'),
          type: EnumNotificationItemStatus.Success,
        },
      ]);
    }
    userStore.toggleProductInWishlist(productId, value);
  };

  const removeProductFromWishlistByIds = async (ids: Array<number>) => {
    await $api.productsWishlist.removeProductWishlistByIds({ data: { ids } });

    notificationStore.addNotificationsPending([
      {
        title: '',
        message: t('success.removeFromWish'),
        type: EnumNotificationItemStatus.Success,
      },
    ]);
  };

  return {
    getIsWish,
    loadWishlistIds,
    toggleProductInWishlistById,
    removeProductFromWishlistByIds,
  };
};
