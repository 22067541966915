import { StringUtils } from '~/shared/utils/string';
import type { INotificationItem, IGlobalNotificationsStore } from './types';

export const globalNotificationsActions = {
  addNotificationsPending: function (this: IGlobalNotificationsStore, newItems: Array<Partial<INotificationItem>>) {
    newItems.forEach((elem) => {
      const { id, type, position } = elem;

      if (type === undefined) {
        return;
      }

      let notificationWithId = { ...elem } as INotificationItem;

      if (id === undefined) {
        notificationWithId.id = StringUtils.generateUid();
      }

      if (position === undefined) {
        notificationWithId.position = 'top';
      }

      notificationWithId = getFullNotification(notificationWithId);

      if (notificationWithId.position === 'top') {
        this.topNotificationsQueue.push(notificationWithId);
      }

      if (notificationWithId.position === 'bottom') {
        this.bottomNotificationsQueue.push(notificationWithId);
      }
    });
  },

  remove: function (this: IGlobalNotificationsStore, notification: INotificationItem) {
    if (notification.position === 'top') {
      const notificationIndex = this.topNotificationsQueue.findIndex((item) => item.id === notification.id);

      this.topNotificationsQueue.splice(notificationIndex, 1);

      return;
    }

    if (notification.position === 'bottom') {
      const notificationIndex = this.bottomNotificationsQueue.findIndex((item) => item.id === notification.id);

      this.bottomNotificationsQueue.splice(notificationIndex, 1);
    }
  },

  clear: function (this: IGlobalNotificationsStore) {
    this.bottomNotificationsQueue = [];
    this.topNotificationsQueue = [];
  },
};

const getFullNotification = (notification: Partial<INotificationItem>) => {
  const { title, message, icon, type } = notification;
  const fullNotification = { ...notification } as INotificationItem;

  if (title === undefined) {
    fullNotification.title = getTitleByType(type!);
  }

  if (message === undefined) {
    fullNotification.message = getMessageByType(type!);
  }

  if (icon === undefined) {
    fullNotification.icon = getIconByType(type!);
  }

  return fullNotification;
};

const getTitleByType = (type: EnumNotificationItemStatus): string => {
  const { $i18n } = useNuxtApp();

  if (type === EnumNotificationItemStatus.Warn) {
    return $i18n.t('common.attention');
  }

  if (type === EnumNotificationItemStatus.Success) {
    return $i18n.t('common.successfully');
  }

  if (type === EnumNotificationItemStatus.Info) {
    return $i18n.t('common.info');
  }

  return $i18n.t('common.error');
};

const getMessageByType = (type: EnumNotificationItemStatus): string => {
  const { t } = useI18n();

  if (type === EnumNotificationItemStatus.Warn) {
    return '';
  }

  if (type === EnumNotificationItemStatus.Success) {
    return '';
  }

  return t('error.unknown');
};

const getIconByType = (type: EnumNotificationItemStatus): INotificationItemIcon => {
  if (type === EnumNotificationItemStatus.Error) {
    return {
      component: 'WiWarningNotification',
      color: 'red',
    };
  }

  if (type === EnumNotificationItemStatus.Warn) {
    return {
      component: 'WiWarningNotification',
      color: 'orange',
    };
  }

  if (type === EnumNotificationItemStatus.Success) {
    return {
      component: 'WiSuccessNotification',
      color: 'green',
    };
  }

  return {
    component: 'WiWarningNotification',
    color: 'blue',
  };
};
