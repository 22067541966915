import * as Sentry from '@sentry/vue';
import type { SeverityLevel } from '@sentry/vue';

export const sentryLog = (payload: string | object | Error, severity: SeverityLevel = 'info'): string | undefined => {
  let eventId: string | undefined;

  Sentry.withScope((scope) => {
    const _message = typeof payload === 'string' ? payload : JSON.stringify(payload);
    const _severity = payload instanceof Error ? 'error' : severity;

    scope.setLevel(_severity);

    eventId = Sentry.captureMessage(_message);
  });

  return eventId;
};
