import BaseApi from '../..';
import type { IRequestParamsHandler, IResponseWithPaging } from '../../types';
import type {
  IUserDeliveryProfileRequest,
  IUserDeliveryProfileResponse,
  IUserFullDeliveryProfileResponse,
} from './types';

class UserDeliveryProfilesApiV1 extends BaseApi {
  private VERSION = 'v1';
  private RESOURCE = 'userDeliveryProfiles';

  async addUserDeliveryProfile(
    params: IRequestParamsHandler<IUserDeliveryProfileRequest>,
  ): Promise<IUserFullDeliveryProfileResponse> {
    const url = `${this.VERSION}/${this.RESOURCE}`;

    return this.post({
      url,
      body: params?.data,
      fetchOptions: params?.fetchOptions,
      errorOptions: { ...params?.errorOptions, entity: 'deliveryProfile' },
    });
  }

  async editUserDeliveryProfile(
    id: string,
    params: IRequestParamsHandler<IUserDeliveryProfileRequest>,
  ): Promise<IUserFullDeliveryProfileResponse> {
    const url = `${this.VERSION}/${this.RESOURCE}/${id}`;

    return this.patch({
      url,
      body: params?.data,
      fetchOptions: params?.fetchOptions,
      errorOptions: { ...params?.errorOptions, entity: 'deliveryProfile' },
    });
  }

  async getUserDeliveryProfiles(
    params?: IRequestParamsHandler<IUserDeliveryProfileResponse>,
  ): Promise<IResponseWithPaging<IUserDeliveryProfileResponse>> {
    const url = `${this.VERSION}/${this.RESOURCE}`;

    return this.get({
      url,
      query: params?.params,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }

  async getUserDeliveryProfileById(id: string): Promise<IUserFullDeliveryProfileResponse> {
    const url = `${this.VERSION}/${this.RESOURCE}/${id}`;

    return this.get({ url });
  }

  async removeUserDeliveryProfile(id: string): Promise<any> {
    const url = `${this.VERSION}/${this.RESOURCE}/${id}`;

    return this.delete({ url });
  }
}

export default UserDeliveryProfilesApiV1;
