<template>
  <div class="wi-authorization">
    <form @submit.prevent="handleLogin">
      <WiInput
        :styleVariant="'second'"
        :placeholder="$t('login.placeholderEmail')"
        :value="loginCredentials.email"
        @onInput="setValue('email', $event)"
        :type="'text'"
        :autocomplete="'email'"
        :infoMessage="{
          text: v$.email.$errors[0]?.$message,
          type: 'error',
        }"
      />
      <WiInput
        :styleVariant="'second'"
        :placeholder="$t('login.placeholderPassword')"
        :value="loginCredentials.password"
        @onInput="setValue('password', $event)"
        :type="'password'"
        :autocomplete="'password'"
        isToggleVisible
        :infoMessage="{
          text: v$.password.$errors[0]?.$message,
          type: 'error',
        }"
      />

      <div class="wi-authorization__button-section">
        <button class="button-section_forget-password" type="button" @click="showForgetPasswordModal">
          {{ $t('login.forgetPassword') }}
        </button>

        <WiButton type="submit" :size="'largeMedium'" :isLoading="isLoadingLogin">{{ $t('login.login') }}</WiButton>
      </div>
    </form>

    <WiForgetPasswordModal
      :isOpen="isOpenForgetPassword"
      :email="loginCredentials.email"
      @onClose="onCloseForgetPasswordModal"
    />
  </div>
</template>

<style lang="scss" scoped src="./index.scss"></style>
<script lang="ts" src="./index.ts" />
