import WiNuxtErrorBoundary from '~/shared/ui/WiNuxtErrorBoundary/index.vue';

import { useHeaderFixedTopHook } from '~/shared/hooks/useHeaderFixedTop';

export default defineNuxtComponent({
  name: 'WiStaticWrapperHeader',

  components: {
    WiNuxtErrorBoundary,
  },

  props: {
    isFixed: {
      type: Boolean,
      default: true,
    },

    isTransparentTop: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { isFixed } = toRefs(props);

    if (isFixed.value) {
      useHeaderFixedTopHook({
        scrollElementId: 'mainLayoutScroll',
        overElementId: 'wiNavigationHeader',
        targetElementId: 'wiStaticWrapperHeader',
      });
    }
  },
});
