<template>
  <WiNuxtErrorBoundary>
    <div class="wi-category-block">
      <div class="wi-desktop-version wi-content">
        <WiHeaderCategoryBlockDesktop :categories="categoriesTree" :isLoadingCategories="isLoadingCategories" />
      </div>

      <div class="wi-mobile-version wi-content">
        <WiMobileControlCategory :categories="categoriesTree" :isLoadingCategories="isLoadingCategories"/>
      </div>
    </div>
  </WiNuxtErrorBoundary>
</template>

<script lang="ts" src="./index.ts"></script>
<style lang="scss" scoped src="./index.scss"></style>
