import validate from "/workspace/www/wikkeo-main-frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import check_45is_45only_45mobile_45global from "/workspace/www/wikkeo-main-frontend/middleware/checkIsOnlyMobile.global.ts";
import clear_45states_45global from "/workspace/www/wikkeo-main-frontend/middleware/clearStates.global.ts";
import user_45auth_45global from "/workspace/www/wikkeo-main-frontend/middleware/userAuth.global.ts";
export const globalMiddleware = [
  validate,
  check_45is_45only_45mobile_45global,
  clear_45states_45global,
  user_45auth_45global
]
export const namedMiddleware = {
  "order-by-wko-redirect": () => import("/workspace/www/wikkeo-main-frontend/middleware/orderByWkoRedirect.ts"),
  "set-cart": () => import("/workspace/www/wikkeo-main-frontend/middleware/setCart.ts")
}