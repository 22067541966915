import { Icons } from '@wikkeo/ui-kit';
import { useChatStore } from '@wikkeo/wikkeo-chat-library';
import useAppRouter from '~/shared/hooks/useAppRouter';
import { useChatHelper } from '~/shared/hooks/useChatHelper';
import { useStoreWithContext } from '~/shared/hooks/useStoreWithContext';
import WiMobileControlWrapper from '~/shared/ui/mobileControl/modules/WiMobileControlWrapper/ui/index.vue';

const { WiChatMessage } = Icons;

export default defineNuxtComponent({
  name: 'WiMobileControlChat',

  components: {
    WiChatMessage,
    WiMobileControlWrapper,
  },

  props: {
    variant: {
      type: String as PropType<'default' | 'alternative'>,
      default: 'default',
    },
    productId: {
      type: Number,
    },
  },

  setup(props) {
    const router = useAppRouter();
    const chatHelper = useChatHelper();
    const userStore = useGlobalUserStore();
    const managementStore = globalComponentManagementStore();
    const chatStore = useStoreWithContext(useChatStore);
    const { isAuthorized } = storeToRefs(userStore);

    const isActive = toRef(() => router.route.value.meta.isChatPage);

    const isProductPage = computed(() => router.currentRoute.value.meta.isProductPage);

    const unreadRoomCount = computed(() => (isProductPage.value ? undefined : chatStore.unreadRoomCount));

    const goToChat = () => {
      router.goToProfileChat();
    };

    const onCreateChat = () => {
      if (!props.productId) {
        return;
      }

      chatHelper.createChat('byProduct', { productId: props.productId });
    };

    const onClick = () => {
      if (!isAuthorized.value) {
        managementStore.setIsOpenLoginModal(true, () => onClick());
        return;
      }

      if (isProductPage.value) {
        onCreateChat();
      } else {
        goToChat();
      }
    };

    return {
      isActive,
      unreadRoomCount,

      onClick,
    };
  },
});
