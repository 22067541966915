import { FetchError } from 'ofetch';
import { useReCaptcha } from 'vue-recaptcha-v3';
import { useCartPageModel } from '~/pages/CartPage/model';
import { EnumCustomErrorCode } from '~/shared/api/types';
import { StorageService } from '~/shared/browserAPI/storageService';
import { checkNetworkRecaptchaError } from '~/shared/helpers/errorHandler';
import { formatPhone } from '~/shared/helpers/formatPhone';
import { sentryLog } from '~/shared/helpers/sentryLog';
import useAppRouter from '~/shared/hooks/useAppRouter';

import WiModalWrapper from '~/shared/ui/WiModalWrapper/index.vue';

const seconds = 30;

export default defineNuxtComponent({
  name: 'WiVerifyPhoneModal',

  components: {
    WiModalWrapper,
  },

  props: {
    isOpen: {
      type: Boolean,
    },

    phoneNumber: {
      type: String,
    },
  },

  emits: ['onClose', 'onSuccess'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const { $api } = useNuxtApp();
    const router = useAppRouter();
    const recaptchaInstance = useReCaptcha();
    const notificationsStore = globalNotificationsStore();
    const managementStore = globalComponentManagementStore();
    const { handleGoToCheckout } = useCartPageModel();
    const storageService = new StorageService('local');

    const codeInputsWrapper = ref();

    const functionConfirmVerified = ref(null);

    const timeLeft = ref<null | number | string>(null);

    const codeArray = ref(['', '', '', '']);

    const sentCode = ref<null | string>(null);

    const isErrorCode = ref<null | string>(null);

    const isLoadingNewCode = ref(false);

    onBeforeMount(() => {
      startTimer();
    });

    const IsErrorCode = computed(() => {
      return getCode.value === isErrorCode.value;
    });

    const getCode = computed(() => {
      return codeArray.value.join('');
    });

    const getPhoneNumber = computed(() => {
      if (!props.phoneNumber) {
        return '';
      }

      return props.phoneNumber;
    });

    const closeModal = (isGoBack = true) => {
      if (isGoBack && router.currentRoute.value.meta.isCart) {
        managementStore.setIsOpenConfirmPhoneNumberModal(true);
      }
      emit('onClose');
    };

    const sendCode = async () => {
      const token = getCode.value;

      let phone = '';
      if (props.phoneNumber) {
        phone = formatPhone(props.phoneNumber);
      }

      if (sentCode.value === token) {
        return;
      }

      sentCode.value = token;

      await recaptchaInstance?.recaptchaLoaded();

      const recaptchaToken = await recaptchaInstance?.executeRecaptcha('submit');

      if (!recaptchaToken) {
        notificationsStore.addNotificationsPending([
          {
            message: t('error.recaptchaError'),
            type: EnumNotificationItemStatus.Error,
          },
        ]);

        return;
      }

      try {
        await $api.auth.phoneVerificationConfirm({
          data: { phone: '+' + phone, code: token },
          fetchOptions: { headers: { 'g-recaptcha-response': recaptchaToken } },
        });

        notificationsStore.addNotificationsPending([
          { message: t('success.acceptPhone'), type: EnumNotificationItemStatus.Success },
        ]);

        emit('onSuccess', { isVerified: true });

        storageService.removeItem('TimerPhoneSms');

        closeModal(false);

        managementStore.setIsOpenConfirmPhoneNumberModal(false);

        if (router.currentRoute.value.meta.isCart) {
          handleGoToCheckout();
        }
      } catch (error) {
        isErrorCode.value = getCode.value;

        if (error instanceof FetchError && error.data?.message) {
          checkNetworkRecaptchaError(error.data?.message);
        }
        sentryLog(error as Error);
      }
    };

    const changeCodeInputFocus = () => {
      const wrapper = codeInputsWrapper.value;

      if (!wrapper) {
        return;
      }

      const index = codeArray.value.findIndex((elem) => elem === '');

      if (index !== -1) {
        const child = wrapper.children[index];

        if (!child) {
          return;
        }

        child.focus();
      } else {
        wrapper.children[codeArray.value.length - 1].focus();

        sendCode();
      }
    };

    const codeInputHandler = (event: any, index: number) => {
      const parseValue = formatPhone(event.target.value);

      if (parseValue.length === codeArray.value.length) {
        codeArray.value = parseValue.split('');
        changeCodeInputFocus();
        return;
      }

      if (parseValue !== '') {
        codeArray.value[index] = parseValue[0];
        event.target.value = parseValue[0];
        changeCodeInputFocus();
      } else {
        codeArray.value[index] = '';
        event.target.value = '';
        changeCodeInputFocus();
      }
    };

    const codeInputBackspace = (event: any, index: number) => {
      if (event.target.value === '' && index > 0) {
        codeArray.value[index - 1] = '';
        changeCodeInputFocus();
      }
    };

    const startTimer = () => {
      let timer = 0;

      const start = new Date();

      const dateFromStorage = storageService.getItem<string>('TimerPhoneSms');

      const previous = dateFromStorage ? new Date(dateFromStorage) : null;

      if (previous) {
        const diff = Math.abs(start.getTime() - previous.getTime());

        if (diff > seconds * 1000) {
          timer = seconds;

          storageService.setItem('TimerPhoneSms', JSON.stringify(start));
          getNewCode();
        } else {
          timer = seconds - parseInt((diff / 1000).toString(), 10);
        }
      } else {
        getNewCode();
        timer = seconds;
        storageService.setItem('TimerPhoneSms', new Date());
      }

      const interval = setInterval(() => {
        timer--;

        timeLeft.value = `00:${timer > 9 ? timer : '0' + timer}`;

        if (timer === 0) {
          timeLeft.value = 0;
          clearInterval(interval);
        }
      }, 1000);
    };

    const getNewCode = async () => {
      if ((timeLeft?.value && Number(timeLeft.value) > 0) || isLoadingNewCode.value) {
        return;
      }

      try {
        isLoadingNewCode.value = true;
        codeArray.value = ['', '', '', ''];

        let phone = '';
        if (props.phoneNumber) {
          phone = '+' + formatPhone(props.phoneNumber);
        }

        await recaptchaInstance?.recaptchaLoaded();

        const recaptchaToken = await recaptchaInstance?.executeRecaptcha('submit');

        if (!recaptchaToken) {
          notificationsStore.addNotificationsPending([
            {
              message: t('error.recaptchaError'),
              type: EnumNotificationItemStatus.Error,
            },
          ]);

          return;
        }

        const res = await $api.auth.phoneVerificationRequest({
          data: { phone },
          fetchOptions: { headers: { 'g-recaptcha-response': recaptchaToken } },
        });

        if (res) {
          notificationsStore.addNotificationsPending([
            { message: t('success.sendSmsWithCode'), type: EnumNotificationItemStatus.Success },
          ]);

          startTimer();
        }
      } catch (error) {
        if (error instanceof FetchError && error.data?.code) {
          if (error.data.code === EnumCustomErrorCode.PHONE_ALREADY_IN_USE) {
            closeModal();
            storageService.removeItem('TimerPhoneSms');
          } else {
            if (error instanceof FetchError && error.data?.message) {
              checkNetworkRecaptchaError(error.data?.message);
            }
          }

          sentryLog(error, 'error');
        }
      } finally {
        isLoadingNewCode.value = false;
      }
    };

    return {
      codeInputsWrapper,
      functionConfirmVerified,
      timeLeft,
      codeArray,
      sentCode,
      isErrorCode,
      isLoadingNewCode,
      IsErrorCode,
      getPhoneNumber,
      getNewCode,
      codeInputBackspace,
      codeInputHandler,
      sendCode,
      closeModal,
      changeCodeInputFocus,
    };
  },
});
