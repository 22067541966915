import { Icons } from '@wikkeo/ui-kit';
import useAppRouter from '~/shared/hooks/useAppRouter';
import WiMobileControlWrapper from '~/shared/ui/mobileControl/modules/WiMobileControlWrapper/ui/index.vue';

const { WiHome } = Icons;

export default defineNuxtComponent({
  name: 'WiMobileControlHome',

  components: {
    WiHome,
    WiMobileControlWrapper,
  },

  setup() {
    const router = useAppRouter();

    const isActive = toRef(() => router.route.value.meta.isMainPage);

    const handleGoToHome = () => {
      router.goToHome();
    };

    return {
      isActive,

      handleGoToHome,
    };
  },
});
