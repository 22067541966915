import WiStaticWrapperControlBlock from '~/shared/ui/mobileControl/WiStaticWrapperControlBlock/ui/index.vue';
import WiMobileControlHome from '~/shared/ui/mobileControl/modules/WiMobileControlHome/ui/index.vue';
import WiCategoryBlock from '~/widgets/WiCategoryBlock/ui/index.vue';
import WiMobileControlChat from '~/shared/ui/mobileControl/modules/WiMobileControlChat/ui/index.vue';
import WiMobileControlCart from '~/shared/ui/mobileControl/modules/WiMobileControlCart/ui/index.vue';
import WiMobileControlUserMenu from '~/shared/ui/mobileControl/modules/WiMobileControlUserMenu/ui/index.vue';
import WiNuxtErrorBoundary from '~/shared/ui/WiNuxtErrorBoundary/index.vue';
import { useMobile } from '~/shared/hooks/useMobile';

export default defineNuxtComponent({
  name: 'WiCommonMobileControl',

  components: {
    WiMobileControlHome,
    WiStaticWrapperControlBlock,
    WiCategoryBlock,
    WiMobileControlChat,
    WiMobileControlCart,
    WiMobileControlUserMenu,
    WiNuxtErrorBoundary,
  },

  setup() {
    const { isMobile } = useMobile();

    return {
      isMobile,
    };
  },
});
