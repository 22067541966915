<template>
  <article class="wi-login-component">
    <WiTabs :variant="'primaryLegacy'" :tabs="tabs" :activeTab="activeTab" @setActiveTab="setActiveTab" />

    <div v-show="activeTab === 'login'">
      <WiAuthorization />
    </div>

    <div v-show="activeTab === 'register'">
      <WiRegistration />
    </div>

    <WiSighInTelegram :formType="activeTab" />

    <div class="wi-login-security-policy-section">
      <span v-if="activeTab === 'register'">
        {{ $t('login.confirmPolicyPart1') }}
        <a class="wi-login-security-policy-section__link" :href="privacyPolicyLink" download>
          {{ $t('login.confirmPolicyPart2') }}
        </a>

        &nbsp;{{ $t('login.confirmPolicyPart3') }}&nbsp;

        <a class="wi-login-security-policy-section__link" :href="offerForSuppliersLink" download>
          {{ $t('login.confirmPolicyPart4') }}
        </a>
      </span>
    </div>
  </article>
</template>

<style lang="scss" scoped src="./index.scss"></style>
<script lang="ts" src="./index.ts"></script>
