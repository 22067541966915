export const useProfileLayoutApi = () => {
  const { $api } = useNuxtApp();

  const getShopOrdersStatistic = async () => {
    const data = await $api.personalAccount.getShopOrdersStatistic();
    return data;
  };

  return {
    getShopOrdersStatistic,
  };
};
