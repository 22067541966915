<template>
  <WiNuxtErrorBoundary>
    <section class="wi-static-wrapper-control-block" :style="{ minHeight }">
      <div
        class="wi-static-wrapper-control-block__content"
        :class="{
          'wi-static-wrapper-control-block_blur': isWithBlur,
        }"
        :style="{ minHeight }"
      >
        <slot name="default"></slot>
      </div>
    </section>
  </WiNuxtErrorBoundary>
</template>

<script lang="ts" src="./index.ts"></script>
<style lang="scss" scoped src="./index.scss"></style>
