<template>
  <WiModalWrapper :isOpen="isOpen" @onClose="closeModal">
    <template #body>
      <div class="wi-login-modal">
        <WiLogin />
      </div>
    </template>
  </WiModalWrapper>
</template>

<style lang="scss" scoped src="./index.scss"></style>
<script lang="ts" src="./index.ts"></script>
