<template>
  <ClientOnly>
    <WiConfirmPhoneNumberModal
      :isOpen="managementStore?.isOpenConfirmPhoneNumberModal"
      @onSetConfimedNumber="setCurrentPhone"
      @onClose="onCloseConfirmPhoneNumberModal"
    />

    <WiVerifyPhoneModal
      v-if="managementStore?.isOpenVerifyPhoneModal"
      :isOpen="managementStore?.isOpenVerifyPhoneModal"
      :phoneNumber="phoneNumber"
      @onClose="onCloseVerifyPhoneModal"
    />
  </ClientOnly>
</template>

<style lang="scss" scoped src="./index.scss"></style>
<script lang="ts" src="./index.ts" />
