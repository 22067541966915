import type { PropType } from 'vue';
import type { IProfileMenuItem } from '~/shared/types/profile/profileMenu';
import useAppRouter from '~/shared/hooks/useAppRouter';
import type { IShopOrdersStatusesStatisticObject } from '~/shared/api/v1/personalAccount/types';

import { Icons } from '@wikkeo/ui-kit';

const { WiProfile, WiBox, WiChat, WiStarOutlined, WiLocation, WiSettings, WiChevron } = Icons;

export default defineNuxtComponent({
  name: 'WiProfileMenuItem',

  components: {
    WiProfile,
    WiBox,
    WiChat,
    WiStarOutlined,
    WiLocation,
    WiSettings,
    WiChevron,
  },

  props: {
    menuItem: {
      type: Object as PropType<IProfileMenuItem>,
      required: true,
    },
    shopOrdersStatusesStatistic: {
      type: Object as PropType<IShopOrdersStatusesStatisticObject>,
      default: () => ({}),
    },
  },

  setup(props) {
    const router = useAppRouter();
    const isOpenSubMenu = ref(props.menuItem.subMenu?.some((item) => item.checkIsActive?.()) || false);

    const isShowSubMenu = computed(() => props.menuItem.subMenu && isOpenSubMenu.value);

    const onClickMenuItem = () => {
      if (props.menuItem.subMenu) {
        isOpenSubMenu.value = !isOpenSubMenu.value;

        return;
      }

      router.push({ path: props.menuItem.link });
    };

    const onClickMenuSubItem = (link: string) => {
      router.push(link);
    };

    const getShopOrdersCounterByStatus = (status?: string) => {
      if (!status) {
        return '';
      }

      // NOTE: the "completedWithoutReviews" field is not included in the sum calculation because the UI does not match 1 to 1 with the data
      if (status === 'allShopOrders') {
        const { completedWithoutReviews, ...statistic } = props.shopOrdersStatusesStatistic;
        return Object.values(statistic).reduce((prev, current) => (prev += current), 0);
      } else {
        return props.shopOrdersStatusesStatistic[status as keyof IShopOrdersStatusesStatisticObject];
      }
    };

    return {
      isShowSubMenu,
      onClickMenuItem,
      onClickMenuSubItem,
      getShopOrdersCounterByStatus,
    };
  },
});
