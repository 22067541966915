import WiSubstrateArea from '~/widgets/WiSubstrateArea/index.vue';
import WiNuxtErrorBoundary from '~/shared/ui/WiNuxtErrorBoundary/index.vue';
import { Components } from '@wikkeo/ui-kit';

import { useSubstrateManagementHook } from '~/shared/hooks/useSubstrateManagement';

const { WiModal } = Components;

export default defineNuxtComponent({
  name: 'WiModalWrapper',

  components: {
    WiModal,
    WiSubstrateArea,
    WiNuxtErrorBoundary,
  },

  props: {
    isOpen: {
      type: Boolean,
    },

    width: {
      type: String,
      default: '456px',
    },

    maxHeight: {
      type: String,
      default: '',
    },

    isVisibleContent: {
      type: Boolean,
      default: false,
    },

    isNoPadding: {
      type: Boolean,
      default: false,
    },

    isFullScreen: {
      type: Boolean,
      default: false,
    },

    isHideSlideControl: {
      type: Boolean,
      default: false,
    },

    isTransparentContent: {
      type: Boolean,
      default: false,
    },

    modalId: {
      type: String,
    },
  },

  emits: ['onClose'],

  setup(props, { emit }) {
    const { isOpen } = toRefs(props);

    const { isShowSubstrate, handleOpenSubstrate } = useSubstrateManagementHook();

    onMounted(() => {
      handleOpenSubstrate(isOpen.value);
    });

    onUnmounted(() => {
      handleOpenSubstrate(false);
    });

    watch(isShowSubstrate, (newValue) => {
      if (!isShowSubstrate.value) {
        handleClose();
      }
    });

    watch(isOpen, (newValue) => {
      handleOpenSubstrate(isOpen.value);
    });

    const handleClose = () => {
      handleOpenSubstrate(false);

      emit('onClose');
    };

    return {
      isShowSubstrate,

      handleClose,
    };
  },
});
