<template>
  <WiMobileControlWrapper :isActive="isActive" @onClick="handleToggleCategoryMenu">
    <WiCategoryMenu />

    <WiMobileMenu
      v-if="isOpenMenuCategory"
      :isOpen="isOpenMenuCategory"
      :menuName="$t('wiMobileControlCategory.productCatalog')"
      :menuLevel="levelShowCategory"
      @onLevelDown="onLevelDownCategory"
      @onClose="handleCloseCategoryMenu"
    >
      <template #content>
        <WiMobileControlCategoryContent
          :categories="categories"
          :showCategory="showCategory"
          @onShowCategory="showSubCategories"
        />
      </template>
    </WiMobileMenu>
  </WiMobileControlWrapper>
</template>

<script lang="ts" src="./index.ts"></script>
<style lang="scss" scoped src="./index.scss"></style>
