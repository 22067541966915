<template>
  <div class="wi-nuxt-error-boundary">
    <NuxtErrorBoundary @error="errorLogger">
      <slot />

      <template #error="{ error }">
        <p>{{ error }}</p>
      </template>
    </NuxtErrorBoundary>
  </div>
</template>

<script lang="ts" src="./index.ts"></script>
<style lang="scss" scoped src="./index.scss"></style>
