import unhead_KgADcZ0jPj from "/workspace/www/wikkeo-main-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/workspace/www/wikkeo-main-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/workspace/www/wikkeo-main-frontend/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/workspace/www/wikkeo-main-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/workspace/www/wikkeo-main-frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_UciE0i6zes from "/workspace/www/wikkeo-main-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/workspace/www/wikkeo-main-frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/workspace/www/wikkeo-main-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/workspace/www/wikkeo-main-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_OVoKJro5pc from "/workspace/www/wikkeo-main-frontend/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_ghbUAjaD3n from "/workspace/www/wikkeo-main-frontend/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import titles_dw2T9lEw4h from "/workspace/www/wikkeo-main-frontend/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_fcc0iSNKKy from "/workspace/www/wikkeo-main-frontend/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_izaWKZ8rEu from "/workspace/www/wikkeo-main-frontend/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/workspace/www/wikkeo-main-frontend/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import defaults_JNk3m5wGSX from "/workspace/www/wikkeo-main-frontend/node_modules/nuxt-schema-org/dist/runtime/nuxt/plugin/defaults.js";
import switch_locale_path_ssr_5csfIgkrBP from "/workspace/www/wikkeo-main-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/workspace/www/wikkeo-main-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import api_slUmq5TON0 from "/workspace/www/wikkeo-main-frontend/app/plugins/api.ts";
import sentry_h2FqLeojBn from "/workspace/www/wikkeo-main-frontend/app/plugins/sentry.ts";
import chat_wVZXSPdIL9 from "/workspace/www/wikkeo-main-frontend/app/plugins/chat.ts";
import google_recaptcha_ueqXOtzeSB from "/workspace/www/wikkeo-main-frontend/app/plugins/google-recaptcha.ts";
import init_3TppeXojsQ from "/workspace/www/wikkeo-main-frontend/node_modules/nuxt-schema-org/dist/runtime/nuxt/plugin/init.js";
export default [
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_OVoKJro5pc,
  plugin_ghbUAjaD3n,
  titles_dw2T9lEw4h,
  defaultsWaitI18n_fcc0iSNKKy,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  defaults_JNk3m5wGSX,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  api_slUmq5TON0,
  sentry_h2FqLeojBn,
  chat_wVZXSPdIL9,
  google_recaptcha_ueqXOtzeSB,
  init_3TppeXojsQ
]