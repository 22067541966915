<template>
  <WiNuxtErrorBoundary>
    <ClientOnly>
      <div class="wi-header-search">
        <div
          :class="{
            'wi-header-search-block': true,
            'wi-header-search-block_active': isOpenSearchResult,
            'wi-header-search-block_active-border-radius': isSearchLimit && !searchInfo,
          }"
        >
          <WiHeaderSearchOptionsButton
            :shopAvatar="shopAvatar"
            @openDropdown="handlerCloseModal"
            @changeSelectOption="handlerSearchInputValue"
          />

          <input
            ref="inputSearchRef"
            class="wi-header-search-block__input"
            type="search"
            v-model.trim="searchInputValue"
            :placeholder="searchPlaceholder"
            @focus="onFocus"
            @keyup.enter="handlerGoSearchPage"
            autocomplete="one-time-code"
          />

          <div class="wi-header-search-block__search-action-buttons">
            <button
              v-show="searchInputValue.length > 0"
              class="wi-header-search-block__clear-search-button wi-flex-content-center"
              @click="searchInputValue = ''"
            >
              <svg
                class="wi-mobile-version"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.46458 15.5354L15.5356 8.46436" stroke="#9AA6B1" stroke-width="1.5" stroke-linecap="round" />
                <path d="M8.46458 8.46458L15.5356 15.5356" stroke="#9AA6B1" stroke-width="1.5" stroke-linecap="round" />
              </svg>

              <WiClose class="wi-desktop-version" />
            </button>

            <button class="wi-header-search-block__search-button wi-flex-content-center" @click="handlerGoSearchPage">
              <WiSearch />
            </button>
          </div>

          <div v-show="isOpenSearchResult" class="wi-header-search-block__search-result">
            <div
              v-show="shopData || categoryData"
              class="wi-header-search-block__search-result-options wi-mobile-version"
            >
              <div class="wi-header-search-block__search-result-options-list">
                <button
                  v-if="shopData"
                  class="wi-header-search-block__search-result-options-item"
                  :class="{
                    'wi-header-search-block__search-result-options-item_active':
                      userDataForSearch?.selectOption === 'shop',
                  }"
                  @click="handlerChangeSearchOption('shop')"
                >
                  {{ shopData.name }}
                </button>

                <button
                  v-if="categoryData"
                  class="wi-header-search-block__search-result-options-item"
                  :class="{
                    'wi-header-search-block__search-result-options-item_active':
                      userDataForSearch?.selectOption === 'category',
                  }"
                  @click="handlerChangeSearchOption('category')"
                >
                  {{ categoryData.name }}
                </button>

                <button
                  class="wi-header-search-block__search-result-options-item"
                  :class="{
                    'wi-header-search-block__search-result-options-item_active':
                      userDataForSearch?.selectOption === null,
                  }"
                  @click="handlerChangeSearchOption(null)"
                >
                  Везде
                </button>
              </div>
            </div>

            <div v-show="!isEmptySearchResult" class="wi-header-search-block__search-result-name-block">
              {{ $t('wiHeaderSearchBlock.searchResults') }}
            </div>

            <ul
              class="wi-header-search-block__search-result-list"
              :class="{
                'wi-header-search-block__search-result-list-full-height': searchInfo,
              }"
            >
              <li
                v-show="!isSearchBySKU"
                v-for="tag in searchInfo?.tags.slice(0, 5)"
                :key="tag"
                class="wi-header-search-block__search-result-item"
                @click="onClickLink"
              >
                <div
                  class="wi-header-search-block__search-result-link"
                  @click.prevent.stop="
                    handlerClickTag({
                      search: tag,
                      category_id: -1,
                      page: 1,
                    })
                  "
                >
                  <div class="wi-header-search-block__search-result-link-image-wrapper wi-flex-content-center">
                    <svg width="19" height="19" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M8 2.00019C7.21207 2.00019 6.43185 2.15538 5.7039 2.45691C4.97595 2.75844 4.31451 3.2004 3.75736 3.75755C3.20021 4.3147 2.75825 4.97613 2.45672 5.70409C2.15519 6.43204 2 7.21226 2 8.00019C2 8.78812 2.15519 9.56833 2.45672 10.2963C2.75825 11.0242 3.20021 11.6857 3.75736 12.2428C4.31451 12.8 4.97595 13.2419 5.7039 13.5435C6.43185 13.845 7.21207 14.0002 8 14.0002C9.5913 14.0002 11.1174 13.368 12.2426 12.2428C13.3679 11.1176 14 9.59149 14 8.00019C14 6.40889 13.3679 4.88277 12.2426 3.75755C11.1174 2.63233 9.5913 2.00019 8 2.00019ZM9.28481e-09 8.00019C4.80655e-05 6.72434 0.305246 5.467 0.890125 4.33311C1.475 3.19922 2.3226 2.22166 3.36217 1.48201C4.40175 0.742364 5.60315 0.262085 6.86612 0.0812522C8.12909 -0.0995802 9.417 0.0242803 10.6224 0.442497C11.8277 0.860714 12.9156 1.56116 13.7951 2.48537C14.6747 3.40958 15.3204 4.53076 15.6785 5.75534C16.0366 6.97991 16.0965 8.27237 15.8534 9.52485C15.6103 10.7773 15.0712 11.9535 14.281 12.9552L18.7 17.2852C18.7939 17.3771 18.8688 17.4866 18.9203 17.6075C18.9719 17.7284 18.9992 17.8582 19.0006 17.9896C19.0019 18.121 18.9774 18.2514 18.9285 18.3733C18.8795 18.4952 18.8069 18.6063 18.715 18.7002C18.6231 18.7941 18.5136 18.869 18.3927 18.9205C18.2718 18.9721 18.142 18.9994 18.0106 19.0007C17.8792 19.0021 17.7488 18.9776 17.6269 18.9286C17.505 18.8796 17.3939 18.8071 17.3 18.7152L12.856 14.3582C11.6705 15.2636 10.2555 15.8196 8.77079 15.9634C7.28604 16.1071 5.79067 15.8328 4.45356 15.1715C3.11645 14.5103 1.99086 13.4883 1.20389 12.2211C0.416921 10.9539 -7.18457e-05 9.49188 9.28481e-09 8.00019Z"
                        fill="#9AA6B1"
                      />
                    </svg>
                  </div>
                  <div class="wi-header-search-block__search-result-link-block">
                    <span class="wi-header-search-block__search-result-link-block-text">{{ tag }}</span>
                    <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.96967 0.46967C1.26256 0.176777 1.73744 0.176777 2.03033 0.46967L6.03033 4.46967C6.32322 4.76256 6.32322 5.23744 6.03033 5.53033L2.03033 9.53033C1.73744 9.82322 1.26256 9.82322 0.96967 9.53033C0.676777 9.23744 0.676777 8.76256 0.96967 8.46967L4.43934 5L0.96967 1.53033C0.676777 1.23744 0.676777 0.762563 0.96967 0.46967Z"
                        fill="#9AA6B1"
                      />
                    </svg>
                  </div>
                </div>
              </li>

              <li
                v-show="!isSearchBySKU"
                v-for="category in searchInfo?.categories.slice(0, 3)"
                :key="category.id"
                class="wi-header-search-block__search-result-item"
                @click="
                  handlerClickCategory({
                    search: searchInputValue,
                    category_id: category.id,
                    page: 1,
                  })
                "
              >
                <a class="wi-header-search-block__search-result-link">
                  <div class="wi-header-search-block__search-result-link-image-wrapper wi-flex-content-center 123123">
                    <!-- <img
                  v-if="category?.logo"
                  class="wi-header-search-block__search-result-link-image"
                  :src="imgSrc(category?.logo?.sizes) || defaultImageLink"
                  :alt="category.name"
                /> -->
                  </div>
                  <div class="wi-header-search-block__search-result-link-block">
                    <div class="wi-header-search-block__search-result-link-block-name">
                      <span class="wi-header-search-block__search-result-link-block-name-text">{{
                        category.name
                      }}</span>
                      <span class="wi-header-search-block__search-result-link-block-name-additional">
                        {{ category.path }}
                      </span>
                    </div>

                    <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.96967 0.46967C1.26256 0.176777 1.73744 0.176777 2.03033 0.46967L6.03033 4.46967C6.32322 4.76256 6.32322 5.23744 6.03033 5.53033L2.03033 9.53033C1.73744 9.82322 1.26256 9.82322 0.96967 9.53033C0.676777 9.23744 0.676777 8.76256 0.96967 8.46967L4.43934 5L0.96967 1.53033C0.676777 1.23744 0.676777 0.762563 0.96967 0.46967Z"
                        fill="#9AA6B1"
                      />
                    </svg>
                  </div>
                </a>
              </li>

              <li
                v-show="!isSearchBySKU"
                v-for="shop in searchInfo?.shops.slice(0, 3)"
                :key="shop.id"
                class="wi-header-search-block__search-result-item"
                @click="onClickLink"
              >
                <NuxtLink class="wi-header-search-block__search-result-link" :to="getShopLink(shop.link)">
                  <div class="wi-header-search-block__search-result-link-image-wrapper wi-flex-content-center">
                    <WiCustomImage :image="shop?.logo" :selectedSize="{ mobileSize: 300, desktopSize: 300 }" />
                  </div>
                  <div class="wi-header-search-block__search-result-link-block">
                    <div class="wi-header-search-block__search-result-link-block-name">
                      <span class="wi-header-search-block__search-result-link-block-name-text">
                        {{ shop.name }}
                      </span>
                      <span class="wi-header-search-block__search-result-link-block-name-additional">{{
                        $t('wiHeaderSearchBlock.goToTheStore')
                      }}</span>
                    </div>

                    <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.96967 0.46967C1.26256 0.176777 1.73744 0.176777 2.03033 0.46967L6.03033 4.46967C6.32322 4.76256 6.32322 5.23744 6.03033 5.53033L2.03033 9.53033C1.73744 9.82322 1.26256 9.82322 0.96967 9.53033C0.676777 9.23744 0.676777 8.76256 0.96967 8.46967L4.43934 5L0.96967 1.53033C0.676777 1.23744 0.676777 0.762563 0.96967 0.46967Z"
                        fill="#9AA6B1"
                      />
                    </svg>
                  </div>
                </NuxtLink>
              </li>

              <li
                v-show="isSearchBySKU"
                v-for="product in searchInfo?.products.slice(0, 1)"
                :key="product.id"
                class="wi-header-search-block__search-result-item"
                @click="onClickLink"
              >
                <NuxtLink class="wi-header-search-block__search-result-link" :to="getProductLink(product.slug)">
                  <div class="wi-header-search-block__search-result-link-image-wrapper wi-flex-content-center">
                    <WiCustomImage :image="product?.image" :selectedSize="{ mobileSize: 300, desktopSize: 300 }" />
                  </div>
                  <div class="wi-header-search-block__search-result-link-block">
                    <div class="wi-header-search-block__search-result-link-block-name">
                      <span class="wi-header-search-block__search-result-link-block-name-text">{{ product.name }}</span>
                      <span class="wi-header-search-block__search-result-link-block-name-additional">
                        {{ product.sku }}
                      </span>
                    </div>

                    <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.96967 0.46967C1.26256 0.176777 1.73744 0.176777 2.03033 0.46967L6.03033 4.46967C6.32322 4.76256 6.32322 5.23744 6.03033 5.53033L2.03033 9.53033C1.73744 9.82322 1.26256 9.82322 0.96967 9.53033C0.676777 9.23744 0.676777 8.76256 0.96967 8.46967L4.43934 5L0.96967 1.53033C0.676777 1.23744 0.676777 0.762563 0.96967 0.46967Z"
                        fill="#9AA6B1"
                      />
                    </svg>
                  </div>
                </NuxtLink>
              </li>

              <div
                v-show="isEmptySearchResult && searchInfo"
                class="wi-header-search-block__search-result-empty"
                @click="handlerCloseModal"
              >
                <span>{{ $t('wiHeaderSearchBlock.noProductsFound') }}</span>
              </div>
            </ul>
          </div>
        </div>

        <WiSubstrateArea v-if="isShowSubstrate" />
      </div>
    </ClientOnly>
  </WiNuxtErrorBoundary>
</template>

<script lang="ts" src="./index.ts" />
<style lang="scss" src="./index.scss" scoped />
