import BaseApi from '../..';
import type { IRequestParamsHandler, IResponseWithPaging } from '../../types';
import type {} from '#app';
import type { IApiFeatureFlag } from './types';

class FeaturesFlagsApiV1 extends BaseApi {
  private VERSION = 'v1';
  private RESOURCE = 'featureFlags';

  async getFeaturesFlags(params: IRequestParamsHandler<IResponseWithPaging<IApiFeatureFlag>>) {
    const url = `${this.VERSION}/${this.RESOURCE}`;

    const requestAsyncData = useAsyncData(
      url,
      () =>
        this.get<IResponseWithPaging<IApiFeatureFlag>>({
          url,
          query: params?.params,
          body: params?.data,
          fetchOptions: params?.fetchOptions,
          errorOptions: params?.errorOptions,
          isRaw: params?.isRaw,
        }),
      params.asyncDataOptions,
    );

    return requestAsyncData;
  }
}

export default FeaturesFlagsApiV1;
