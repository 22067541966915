import type { _ActionsTree } from 'pinia';
import type { IGlobalCartStore } from './types';
import { CartEntity } from '~/entities/cart';
import { CartOrderEntity } from '~/entities/cartOrder';

export const globalCartActions: _ActionsTree = {
  initCartEntity: function (this: IGlobalCartStore) {
    this.dataCartStore.cartEntity = new CartEntity([]);
  },

  initCartOrderEntity: function (this: IGlobalCartStore) {
    this.dataCartStore.cartOrderEntity = new CartOrderEntity([]);
  },

  clearCandidates: function (this: IGlobalCartStore) {
    if (!this.dataCartStore.cartEntity) {
      return;
    }

    this.dataCartStore.cartEntity.productsCandidates = [];
  },
};
