import type { IUserUpdatePayload } from '~/shared/api/v1/auth/types';

export default function useConfirmPhoneNumberApi() {
  const { $api } = useNuxtApp();

  const isLoading = ref(false);

  const updateUser = async (user: IUserUpdatePayload | FormData) => {
    isLoading.value = true;
    try {
      const res = await $api.personalAccount.updateUser({
        data: user,
      });
      return res;
    } catch (error: any) {
      console.error(error);
    } finally {
      isLoading.value = false;
    }
  };

  return { isLoading, updateUser };
}
