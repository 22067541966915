import BaseApi from '../..';
import type { IRequestParamsHandler } from '../../types';
import type { ICreateShopApiV1, IShopApiV1 } from './types';

class ShopsApiV1 extends BaseApi {
  private VERSION = 'v1';
  private RESOURCE = 'shops';

  async getShop(shopId: number | string, params?: IRequestParamsHandler<IShopApiV1>) {
    const url = `${this.VERSION}/${this.RESOURCE}/${shopId}`;

    const requestAsyncData = await useAsyncData<IShopApiV1>(
      url,
      () =>
        this.get({
          url,
          query: params?.params,
          fetchOptions: params?.fetchOptions,
          errorOptions: params?.errorOptions,
        }),
      params?.asyncDataOptions,
    );

    return requestAsyncData;
  }

  async createShop(params: IRequestParamsHandler<ICreateShopApiV1, ICreateShopApiV1>): Promise<IShopApiV1> {
    const url = `${this.VERSION}/${this.RESOURCE}`;

    return this.post({
      url,
      body: params?.data,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }
}

export default ShopsApiV1;
