import BaseApi from '../..';
import type { IRequestParamsHandler, IResponseWithPaging } from '../../types';
import type {
  IPriceDictionaryLegacy,
  ISearchCategoryDictionaryLegacy,
  ISearchResponseLegacy,
  IShopLegacy,
  IStorageDictionaryLegacy,
} from './types';

class SearchApiLegacy extends BaseApi {
  private VERSION = 'legacy';
  private RESOURCE = 'search';

  async searchAutocompleteRequest(params?: IRequestParamsHandler<any>) {
    const url = `${this.VERSION}/${this.RESOURCE}/autocomplete`;

    return this.get<Array<any>>({
      url,
      query: params?.params,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
      isRaw: params?.isRaw,
    });
  }

  async searchRequest(params?: IRequestParamsHandler<ISearchResponseLegacy>) {
    const url = `${this.VERSION}/${this.RESOURCE}`;

    return this.get<ISearchResponseLegacy>({
      url,
      query: params?.params,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
      isRaw: params?.isRaw,
    });
  }

  async getStorageDictionary(params?: IRequestParamsHandler<IResponseWithPaging<IStorageDictionaryLegacy>>) {
    const url = `${this.VERSION}/${this.RESOURCE}/dictionaries/storages`;

    return this.get<IResponseWithPaging<IStorageDictionaryLegacy>>({
      url,
      query: params?.params,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
      isRaw: params?.isRaw,
    });
  }

  async getShopDictionary(params?: IRequestParamsHandler<IResponseWithPaging<IShopLegacy>>) {
    const url = `${this.VERSION}/${this.RESOURCE}/dictionaries/shops`;

    return this.get<IResponseWithPaging<IShopLegacy>>({
      url,
      query: params?.params,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
      isRaw: params?.isRaw,
    });
  }

  async getPriceDictionary(params?: IRequestParamsHandler<IPriceDictionaryLegacy>) {
    const url = `${this.VERSION}/${this.RESOURCE}/dictionaries/prices`;

    return this.get<IPriceDictionaryLegacy>({
      url,
      query: params?.params,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
      isRaw: params?.isRaw,
    });
  }

  async getCategoryDictionary(params?: IRequestParamsHandler<IResponseWithPaging<ISearchCategoryDictionaryLegacy>>) {
    const url = `${this.VERSION}/${this.RESOURCE}/dictionaries/categories`;

    return this.get<IResponseWithPaging<ISearchCategoryDictionaryLegacy>>({
      url,
      query: params?.params,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
      isRaw: params?.isRaw,
    });
  }
}

export default SearchApiLegacy;
