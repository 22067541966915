<template>
  <div class="error-page">
    <div class="content">
      <img class="error-page__image" src="/public/images/errors/500.png" alt="500.png" loading="lazy" />

      <div class="wi-error-content">
        <h1 class="wi-error-content-title">{{ $t('errorPage.serverError') }}</h1>

        <p class="wi-error-content-text">
          {{ $t('serverErrorPage.text') }}
          <a :href="supportTgUrl" target="_blank" class="wi-link">{{ $t('serverErrorPage.support') }} </a>
        </p>

        <div class="wi-error-actions">
          <button class="wi-error-action-button wi-error-content-refresh" @click="onRefresh">
            {{ $t('serverErrorPage.reload') }}
          </button>
          <button class="wi-error-action-button wi-error-content-go-to-main" @click="onClearError">
            {{ $t('serverErrorPage.toMainPage') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="./index.ts" />
<style lang="scss" src="./index.scss" scoped />
