export default defineNuxtComponent({
  name: 'NotFound',

  setup() {
    const onClearError = () => {
      clearError({ redirect: '/' });
    };

    return {
      onClearError,
    };
  },
});
