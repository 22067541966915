<template>
  <WiNuxtErrorBoundary>
    <Teleport to="#teleports">
      <div class="wi-notification-section">
        <ClientOnly>
          <div class="wi-notification-section__top">
            <WiNotification v-for="item in topNotifications" :key="item.id" :notification="item" @onClose="onClose" />
          </div>

          <div class="wi-notification-section__bottom">
            <WiNotification
              v-for="item in bottomNotifications"
              :key="item.id"
              :notification="item"
              @onClose="onClose"
            />
          </div>
        </ClientOnly>
      </div>
    </Teleport>
  </WiNuxtErrorBoundary>
</template>

<script lang="ts" src="./index.ts"></script>
<style lang="scss" scoped src="./index.scss"></style>
