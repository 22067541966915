<template>
  <WiModalWrapper :isOpen="isOpen" @onClose="closeModal" width="600px" class="wi-modal-forget">
    <template #title>{{ $t('wiForgetPasswordModal.forgetPassword') }}</template>

    <template #subtitle>
      <span class="wi-modal-forget-subtitle">{{ $t('wiForgetPasswordModal.writeToUs') }}</span>
    </template>

    <template #body>
      <div class="wi-modal-forget-form">
        <WiInput
          :styleVariant="'second'"
          :placeholder="$t('login.placeholderEmail')"
          :value="emailData.email"
          @onInput="setValue($event)"
          :type="'text'"
          :autocomplete="'email'"
          :infoMessage="{
            text: v$.email.$errors[0]?.$message,
            type: 'error',
          }"
        />

        <div class="wi-modal-forget_button">
          <WiButton :size="'largeMedium'" :isLoading="isLoadingReset" @click="handlerForgetPassword">{{
            $t('wiForgetPasswordModal.send')
          }}</WiButton>
        </div>
      </div>
    </template>
  </WiModalWrapper>
</template>

<style lang="scss" scoped src="./index.scss"></style>
<script lang="ts" src="./index.ts"></script>
