import type { IGlobalUserStore } from './types';

export const getGlobalUserState = (): IGlobalUserStore => {
  const tokens: { accessToken?: string; refreshToken?: string } = {};
  let wikUser = null;
  let wishlistIds = null;

  const isLoadingUserInfo = true;

  const userDataForSearch = {
    query: '',
    selectOption: null,
    shop: null,
  };

  const shopOrdersStatusesStatistic = {
    awaiting_shipment: 0,
    completed: 0,
    completedWithoutReviews: 0,
    declined: 0,
    on_delivery: 0,
    pending: 0,
    processing: 0,
    waiting_contact: 0,
  };

  return {
    tokens: tokens,
    user: wikUser,
    wishlistIds: wishlistIds,
    userDataForSearch,
    isLoadingUserInfo,
    shopOrdersStatusesStatistic,
  };
};
