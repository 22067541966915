import { useFeatureAddProductToCartModel } from './model';

export const useFeatureAddProductToCart = () => {
  const {
    addCandidatesProductsToCart,
    setCartEntityFromLocalStorageToStore,
    changeProductsToCart,
    removeProducts,
    clearProductsForCart,
    synchronizationCartWhenExitPage,
  } = useFeatureAddProductToCartModel();

  return {
    addCandidatesProductsToCart,
    changeProductsToCart,
    setCartEntityFromLocalStorageToStore,
    removeProducts,
    clearProductsForCart,
    synchronizationCartWhenExitPage,
  };
};
