import type { AsyncDataOptions } from '#app';
import type { FetchOptions } from 'ofetch';

export interface IErrorOptionsRequest {
  isShow?: boolean;
  isShowDetailsErrors?: boolean;
  isShowServerError?: boolean;
  entity?: string;
}

export interface IRequestParamsHandler<
  ResponseType extends unknown = void,
  BodyType extends TAnyLiteral = any,
  QueryType extends TAnyLiteral = TAnyLiteral,
> {
  asyncDataOptions?: AsyncDataOptions<ResponseType>;
  data?: BodyType;
  params?: IRequestParams<QueryType>;
  errorOptions?: IErrorOptionsRequest;
  fetchOptions?: FetchOptions<'json'>;
  isRaw?: boolean;
}
export interface IResponseWithPaging<T> {
  items: Array<T>;
  page: number;
  limit: number;
  total: number;
}
export interface IRequestParams<T> extends IPagination {
  query?: TQuery;
  sortBy?: TSort<T>;
  filter?: TFilter<T>;
  search?: TSearch;
}

export interface IPagination {
  page?: number;
  limit?: number;
}

export type TSort<T> = Partial<Record<keyof T, 'ASC' | 'DESC'>>;

export type TFilter<EntityType> = Partial<
  Record<
    keyof EntityType,
    Partial<Record<Partial<EnumFilterKey>, string | number | boolean | TAnyLiteral | Array<string | number>>>
  >
>;

export type TSearch = string;

export type TQuery = string;

export enum EnumFilterKey {
  Eq = '$eq',
  Not = '$not',
  ILike = '$ilike',
  And = '$and',
  Or = '$or',
  Gt = '$gt',
  Gte = '$gte',
  In = '$in',
  Null = '$null',
  Lt = '$lt',
  Lte = '$lte',
  Btw = '$btw',
  Sw = '$sw',
  Contains = '$contains',
}

export interface IImageSize {
  imageUUID: string;
  width: number;
  height: number;
  jpegURL: string | undefined;
  webpURL: string | undefined;
  avifURL: string | undefined;
  svgURL: string | undefined;
}

export interface IImage {
  originalImageUUID: string;
  sizes: Record<string, IImageSize>;
}

export enum EnumCustomErrorCode {
  PHONE_NOT_VERIFIED = 'PHONE_NOT_VERIFIED',
  PHONE_ALREADY_IN_USE = 'PHONE_ALREADY_IN_USE',

  WRONG_ADDRESS = 'WRONG_ADDRESS',
  UNKNOWN_DELIVERY_COST = 'UNKNOWN_DELIVERY_COST',
}
