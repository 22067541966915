<template>
  <WiNuxtErrorBoundary>
    <ClientOnly>
      <WiInputPhone
        :modelValue="modelValue"
        :label="label"
        :isVerify="isVerify"
        :isDisabled="isDisabled"
        :isReadonly="isReadonly"
        :errorMessage="errorMessage"
        :isOpenDropdown="isOpenDropdown"
        :isMobile="isMobile"
        @onOpenDropdown="onOpenDropdown"
        @validate="onValidate"
        @update:modelValue="onUpdateModelValue"
      />

      <WiSubstrateArea v-if="isShowSubstrate && isSubstrate" />
    </ClientOnly>
  </WiNuxtErrorBoundary>
</template>

<style lang="scss" scoped src="./index.scss"></style>
<script lang="ts" src="./index.ts" />
