import type { ICartsRequestProduct, ICartsResponse } from '~/shared/api/v1/carts/types';

export const useFeatureAddProductToCartApi = () => {
  const { $api, $i18n } = useNuxtApp();

  const notificationStore = globalNotificationsStore();

  const getProductsFromCartApi = async () => {
    try {
      const data = await $api.carts.getCartsProducts();

      return data;
    } catch (error) {
      notificationStore.addNotificationsPending([
        {
          message: $i18n.t('featureAddProductsToCart.errorGetCart'),
          type: EnumNotificationItemStatus.Error,
        },
      ]);

      return null;
    }
  };

  const validateProductsFromStorageApi = async (
    payload: Array<ICartsRequestProduct>,
  ): Promise<ICartsResponse | null> => {
    if (payload.length === 0) {
      return null;
    }

    try {
      const data = await $api.carts.validateCartsProducts({ data: { products: payload } });

      return data;
    } catch (error) {
      notificationStore.addNotificationsPending([
        {
          message: $i18n.t('featureAddProductsToCart.errorValidateCart'),
          type: EnumNotificationItemStatus.Error,
        },
      ]);

      return null;
    }
  };

  const addProductsToCartApi = async (payload: ICartsRequestProduct[]) => {
    try {
      const data = await $api.carts.addCartsProducts({ data: { products: payload } });

      data.errors.forEach(error => {
        if(error.isDeleted){
          notificationStore.addNotificationsPending([
            {
              title: $i18n.t('featureAddProductsToCart.errorAddProductToCartTitle'),
              message: $i18n.t('featureAddProductsToCart.errorAddProductToCartText'),
              type: EnumNotificationItemStatus.Error,
            },
          ]);
        }
      })

      return data;
    } catch (error) {
      notificationStore.addNotificationsPending([
        {
          message: $i18n.t('featureAddProductsToCart.errorAddProductToCartText'),
          type: EnumNotificationItemStatus.Error,
        },
      ]);

      return null;
    }
  };

  const removeProductsToCartApi = async (payload: ICartsRequestProduct[]) => {
    try {
      const data = await $api.carts.removeCartsProducts({ data: { products: payload } });

      return data;
    } catch (error) {
      notificationStore.addNotificationsPending([
        {
          message: $i18n.t('featureAddProductsToCart.errorRemoveToCart'),
          type: EnumNotificationItemStatus.Error,
        },
      ]);

      return null;
    }
  };

  return {
    getProductsFromCartApi,
    validateProductsFromStorageApi,
    addProductsToCartApi,
    removeProductsToCartApi,
  };
};
