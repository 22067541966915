<template>
  <div ref="notificationContainer" class="wi-notification" @mouseenter="stopTimeout" @mouseleave="setTimeoutForClose">
    <div
      class="wi-notification__icon-wrapper"
      :class="['wi-notification__icon-wrapper_color-' + notification.icon.color]"
    >
      <component :is="notification.icon.component" />
    </div>

    <div class="wi-notification__info-block">
      <span v-if="notification.title && notification.title !== ''" class="wi-notification__title">{{ notification.title }}</span>

      <span v-if="notification.message && notification.message !== ''" class="wi-notification__subtitle">{{ notification.message }}</span>
    </div>

    <button class="wi-notification__icon-wrapper wi-notification__icon-wrapper_close" @click="closeNotification">
      <WiClose />
    </button>
  </div>
</template>

<script lang="ts" src="./index.ts"></script>
<style lang="scss" scoped src="./index.scss"></style>
