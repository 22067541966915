import WiNotification from '~/shared/ui/WiNotification/index.vue';
import WiNuxtErrorBoundary from '~/shared/ui/WiNuxtErrorBoundary/index.vue';

import { globalNotificationsStore } from '~/app/store/notifications';
import { type INotificationItem } from '~/app/store/notifications/types';
import { MAX_SHOW_NOTIFICATION } from '~/shared/config/notifications';

export default defineNuxtComponent({
  name: 'WiNotificationSection',

  components: {
    WiNotification,
    WiNuxtErrorBoundary,
  },

  setup() {
    const { topNotificationsQueue, bottomNotificationsQueue } = storeToRefs(globalNotificationsStore());

    const notificationsStore = globalNotificationsStore();

    const topNotifications = computed(() => topNotificationsQueue.value.slice(0, MAX_SHOW_NOTIFICATION));

    const bottomNotifications = computed(() => bottomNotificationsQueue.value.slice(0, MAX_SHOW_NOTIFICATION));

    const onClose = (notification: INotificationItem) => {
      notificationsStore.remove(notification);
    };

    return {
      topNotifications,
      bottomNotifications,
      onClose,
    };
  },
});
