import { default as indexoU7KBGasGCMeta } from "/workspace/www/wikkeo-main-frontend/app/routes/cart/index.vue?macro=true";
import { default as _91slug_93uwc1jBEHZoMeta } from "/workspace/www/wikkeo-main-frontend/app/routes/category/[slug].vue?macro=true";
import { default as indexZHWNQfuJGsMeta } from "/workspace/www/wikkeo-main-frontend/app/routes/checkout/index.vue?macro=true";
import { default as for_45vendors2bWN0rhRCxMeta } from "/workspace/www/wikkeo-main-frontend/app/routes/for-vendors.vue?macro=true";
import { default as forget_passwordeDYRe2HluFMeta } from "/workspace/www/wikkeo-main-frontend/app/routes/forget_password.vue?macro=true";
import { default as helpPq8XTYrciTMeta } from "/workspace/www/wikkeo-main-frontend/app/routes/help.vue?macro=true";
import { default as indexVcuE8MqRCUMeta } from "/workspace/www/wikkeo-main-frontend/app/routes/index.vue?macro=true";
import { default as infoNMS7O699sXMeta } from "/workspace/www/wikkeo-main-frontend/app/routes/info.vue?macro=true";
import { default as _91slug_93H2rSyCsq0hMeta } from "/workspace/www/wikkeo-main-frontend/app/routes/item/[slug].vue?macro=true";
import { default as new_productsr3Jgz6uoaQMeta } from "/workspace/www/wikkeo-main-frontend/app/routes/new_products.vue?macro=true";
import { default as searchkfCya7oc6SMeta } from "/workspace/www/wikkeo-main-frontend/app/routes/search.vue?macro=true";
import { default as _91slug_93Q7ehau5PUcMeta } from "/workspace/www/wikkeo-main-frontend/app/routes/store/[slug].vue?macro=true";
import { default as _91id_93c3sUNtb09VMeta } from "/workspace/www/wikkeo-main-frontend/app/routes/success_check/[id].vue?macro=true";
import { default as chatMs998JMYE0Meta } from "/workspace/www/wikkeo-main-frontend/app/routes/user/chat.vue?macro=true";
import { default as create_shoplC8TOA5QsbMeta } from "/workspace/www/wikkeo-main-frontend/app/routes/user/create_shop.vue?macro=true";
import { default as deliveryProfilesqMWyVUSikAMeta } from "/workspace/www/wikkeo-main-frontend/app/routes/user/deliveryProfiles.vue?macro=true";
import { default as logingfpYbrBtcGMeta } from "/workspace/www/wikkeo-main-frontend/app/routes/user/login.vue?macro=true";
import { default as _91orderId_93zApkE4JXA1Meta } from "/workspace/www/wikkeo-main-frontend/app/routes/user/orders/[orderId].vue?macro=true";
import { default as indexNevRPYkGDLMeta } from "/workspace/www/wikkeo-main-frontend/app/routes/user/orders/by-wko/index.vue?macro=true";
import { default as indexTZCUXM2BpkMeta } from "/workspace/www/wikkeo-main-frontend/app/routes/user/orders/index.vue?macro=true";
import { default as profileR3OhyNczGsMeta } from "/workspace/www/wikkeo-main-frontend/app/routes/user/profile.vue?macro=true";
import { default as reviewsGY7viQkvSKMeta } from "/workspace/www/wikkeo-main-frontend/app/routes/user/reviews.vue?macro=true";
import { default as notificationsAY81YwiwO5Meta } from "/workspace/www/wikkeo-main-frontend/app/routes/user/settings/notifications.vue?macro=true";
import { default as telegramuGDWV90C89Meta } from "/workspace/www/wikkeo-main-frontend/app/routes/user/settings/telegram.vue?macro=true";
import { default as unauthorizedw8SrJPgb6ZMeta } from "/workspace/www/wikkeo-main-frontend/app/routes/user/unauthorized.vue?macro=true";
import { default as wishlistsN4vo0COnBvMeta } from "/workspace/www/wikkeo-main-frontend/app/routes/user/wishlists.vue?macro=true";
export default [
  {
    name: "cart___ru",
    path: "/cart",
    meta: indexoU7KBGasGCMeta || {},
    component: () => import("/workspace/www/wikkeo-main-frontend/app/routes/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "category-slug___ru",
    path: "/category/:slug()",
    meta: _91slug_93uwc1jBEHZoMeta || {},
    component: () => import("/workspace/www/wikkeo-main-frontend/app/routes/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "checkout___ru",
    path: "/checkout",
    meta: indexZHWNQfuJGsMeta || {},
    component: () => import("/workspace/www/wikkeo-main-frontend/app/routes/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "for-vendors___ru",
    path: "/for-vendors",
    component: () => import("/workspace/www/wikkeo-main-frontend/app/routes/for-vendors.vue").then(m => m.default || m)
  },
  {
    name: "forget_password___ru",
    path: "/forget_password",
    component: () => import("/workspace/www/wikkeo-main-frontend/app/routes/forget_password.vue").then(m => m.default || m)
  },
  {
    name: "help___ru",
    path: "/help",
    component: () => import("/workspace/www/wikkeo-main-frontend/app/routes/help.vue").then(m => m.default || m)
  },
  {
    name: "index___ru",
    path: "/",
    meta: indexVcuE8MqRCUMeta || {},
    component: () => import("/workspace/www/wikkeo-main-frontend/app/routes/index.vue").then(m => m.default || m)
  },
  {
    name: "info___ru",
    path: "/info",
    meta: infoNMS7O699sXMeta || {},
    component: () => import("/workspace/www/wikkeo-main-frontend/app/routes/info.vue").then(m => m.default || m)
  },
  {
    name: "item-slug___ru",
    path: "/item/:slug()",
    meta: _91slug_93H2rSyCsq0hMeta || {},
    component: () => import("/workspace/www/wikkeo-main-frontend/app/routes/item/[slug].vue").then(m => m.default || m)
  },
  {
    name: "new_products___ru",
    path: "/new_products",
    component: () => import("/workspace/www/wikkeo-main-frontend/app/routes/new_products.vue").then(m => m.default || m)
  },
  {
    name: "search___ru",
    path: "/search",
    component: () => import("/workspace/www/wikkeo-main-frontend/app/routes/search.vue").then(m => m.default || m)
  },
  {
    name: "store-slug___ru",
    path: "/store/:slug()",
    meta: _91slug_93Q7ehau5PUcMeta || {},
    component: () => import("/workspace/www/wikkeo-main-frontend/app/routes/store/[slug].vue").then(m => m.default || m)
  },
  {
    name: "success_check-id___ru",
    path: "/success_check/:id()",
    meta: _91id_93c3sUNtb09VMeta || {},
    component: () => import("/workspace/www/wikkeo-main-frontend/app/routes/success_check/[id].vue").then(m => m.default || m)
  },
  {
    name: "user-chat___ru",
    path: "/user/chat",
    meta: chatMs998JMYE0Meta || {},
    component: () => import("/workspace/www/wikkeo-main-frontend/app/routes/user/chat.vue").then(m => m.default || m)
  },
  {
    name: "user-create_shop___ru",
    path: "/user/create_shop",
    meta: create_shoplC8TOA5QsbMeta || {},
    component: () => import("/workspace/www/wikkeo-main-frontend/app/routes/user/create_shop.vue").then(m => m.default || m)
  },
  {
    name: "user-deliveryProfiles___ru",
    path: "/user/deliveryProfiles",
    meta: deliveryProfilesqMWyVUSikAMeta || {},
    component: () => import("/workspace/www/wikkeo-main-frontend/app/routes/user/deliveryProfiles.vue").then(m => m.default || m)
  },
  {
    name: "user-login___ru",
    path: "/user/login",
    meta: logingfpYbrBtcGMeta || {},
    component: () => import("/workspace/www/wikkeo-main-frontend/app/routes/user/login.vue").then(m => m.default || m)
  },
  {
    name: "user-orders-orderId___ru",
    path: "/user/orders/:orderId()",
    meta: _91orderId_93zApkE4JXA1Meta || {},
    component: () => import("/workspace/www/wikkeo-main-frontend/app/routes/user/orders/[orderId].vue").then(m => m.default || m)
  },
  {
    name: "user-orders-by-wko___ru",
    path: "/user/orders/by-wko",
    meta: indexNevRPYkGDLMeta || {},
    component: () => import("/workspace/www/wikkeo-main-frontend/app/routes/user/orders/by-wko/index.vue").then(m => m.default || m)
  },
  {
    name: "user-orders___ru",
    path: "/user/orders",
    meta: indexTZCUXM2BpkMeta || {},
    component: () => import("/workspace/www/wikkeo-main-frontend/app/routes/user/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "user-profile___ru",
    path: "/user/profile",
    meta: profileR3OhyNczGsMeta || {},
    component: () => import("/workspace/www/wikkeo-main-frontend/app/routes/user/profile.vue").then(m => m.default || m)
  },
  {
    name: "user-reviews___ru",
    path: "/user/reviews",
    meta: reviewsGY7viQkvSKMeta || {},
    component: () => import("/workspace/www/wikkeo-main-frontend/app/routes/user/reviews.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-notifications___ru",
    path: "/user/settings/notifications",
    meta: notificationsAY81YwiwO5Meta || {},
    component: () => import("/workspace/www/wikkeo-main-frontend/app/routes/user/settings/notifications.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-telegram___ru",
    path: "/user/settings/telegram",
    meta: telegramuGDWV90C89Meta || {},
    component: () => import("/workspace/www/wikkeo-main-frontend/app/routes/user/settings/telegram.vue").then(m => m.default || m)
  },
  {
    name: "user-unauthorized___ru",
    path: "/user/unauthorized",
    meta: unauthorizedw8SrJPgb6ZMeta || {},
    component: () => import("/workspace/www/wikkeo-main-frontend/app/routes/user/unauthorized.vue").then(m => m.default || m)
  },
  {
    name: "user-wishlists___ru",
    path: "/user/wishlists",
    meta: wishlistsN4vo0COnBvMeta || {},
    component: () => import("/workspace/www/wikkeo-main-frontend/app/routes/user/wishlists.vue").then(m => m.default || m)
  }
]