<template>
  <WiNuxtErrorBoundary>
    <div class="wi-mobile-control-category-content">
      <span v-show="isShowCategoryParentName" class="wi-mobile-control-category-content-title">
        <div class="wi-mobile-control-category-content-item-link-active-field wi-flex-content-center">
          <div class="wi-mobile-control-category-content-item-link-active-field-elem"></div>
        </div>

        <span class="wi-mobile-control-category-content-title-text">
          {{ getCategoryParentName }}
        </span>
      </span>

      <div class="wi-mobile-control-category-content-list-wrapper">
        <!--First level -->
        <ul
          :class="{
            'wi-mobile-control-category-content-list': true,
            'wi-mobile-control-category-content-list_disable-scroll': showCategory[1]?.id,
          }"
        >
          <li
            v-for="generalCategory in categories"
            :key="generalCategory.value.id + '_general'"
            class="wi-mobile-control-category-content-item"
          >
            <div
              class="wi-mobile-control-category-content-item-link"
              @click="
                showSubCategories({
                  id: generalCategory.value.id,
                  level: '1',
                  slug: generalCategory.value.slug,
                  name: generalCategory.value.name,
                })
              "
            >
              <div class="wi-mobile-control-category-content-item-link-active-field wi-flex-content-center">
                <div
                  v-show="showCategory[1]?.id === generalCategory.value.id"
                  class="wi-mobile-control-category-content-item-link-active-field-elem"
                ></div>
              </div>
              <span class="wi-mobile-control-category-content-item-link-text">{{ generalCategory.value.name }}</span>
            </div>

            <div
              class="wi-mobile-control-category-content-item-icon-wrapper"
              @click="
                showSubCategories({
                  id: generalCategory.value.id,
                  level: '1',
                  slug: generalCategory.value.slug,
                  name: generalCategory.value.name,
                })
              "
            >
              <WiChevron class="wi-mobile-control-category-content-item-icon wi-image-container" />
            </div>
            <!--Second level -->
            <ul
              v-show="showCategory[1]?.id === generalCategory.value.id"
              :class="{
                'wi-mobile-control-category-content-list': true,
                'wi-mobile-control-category-content-list_second-level': true,
                'wi-mobile-control-category-content-list_disable-scroll': showCategory[2]?.id,
              }"
            >
              <li
                v-for="subcategory in generalCategory.children"
                :key="subcategory.value.id + '_sub'"
                class="wi-mobile-control-category-content-item"
              >
                <div
                  class="wi-mobile-control-category-content-item-link right-offset"
                  @click="
                    showSubCategories({
                      id: subcategory.value.id,
                      level: '2',
                      slug: subcategory.value.slug,
                      name: subcategory.value.name,
                    })
                  "
                >
                  <div class="wi-mobile-control-category-content-item-link-active-field wi-flex-content-center">
                    <div
                      v-show="showCategory[2]?.id === subcategory.value.id"
                      class="wi-mobile-control-category-content-item-link-active-field-elem"
                    ></div>
                  </div>
                  <span class="wi-mobile-control-category-content-item-link-text">{{ subcategory.value.name }}</span>
                </div>

                <div
                  class="wi-mobile-control-category-content-item-icon-wrapper"
                  @click="
                    showSubCategories({
                      id: subcategory.value.id,
                      level: '2',
                      slug: subcategory.value.slug,
                      name: subcategory.value.name,
                    })
                  "
                >
                  <WiChevron class="wi-mobile-control-category-content-item-icon wi-image-container" />
                </div>

                <!-- Third level -->
                <ul
                  v-show="showCategory[2]?.id === subcategory.value.id"
                  class="wi-mobile-control-category-content-list wi-mobile-control-category-content-list_third-level"
                >
                  <li
                    v-for="childSubcategory in subcategory.children"
                    :key="childSubcategory.value.id + '_childSub'"
                    class="wi-mobile-control-category-content-item"
                    @click="
                      showSubCategories({
                        id: childSubcategory.value.id,
                        level: '3',
                        slug: childSubcategory.value.slug,
                        name: childSubcategory.value.name,
                      })
                    "
                  >
                    <div class="wi-mobile-control-category-content-item-link right-offset">
                      <div class="wi-mobile-control-category-content-item-link-active-field wi-flex-content-center">
                        <div
                          v-show="showCategory[3]?.id === childSubcategory.value.id"
                          class="wi-mobile-control-category-content-item-link-active-field-elem"
                        ></div>
                      </div>
                      <span class="wi-mobile-control-category-content-item-link-text">{{
                        childSubcategory.value.name
                      }}</span>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div
        v-show="showCategory[1]?.id || showCategory[2]?.id"
        class="wi-mobile-control-category-content-button-section"
      >
        <div
          class="wi-mobile-control-category-content-button wi-mobile-control-category-content-button_disable wi-flex-content-center"
          @click="goCategoryPage"
        >
          {{ $t('wiMobileControlCategoryContent.goTo') }}
        </div>
      </div>
    </div>
  </WiNuxtErrorBoundary>
</template>

<script lang="ts" src="./index.ts"></script>
<style lang="scss" scoped src="./index.scss"></style>
