import { FetchError } from 'ofetch';
import type { TSearch } from '~/shared/api/types';
import type { TShippingServiceProvider } from '~/shared/api/v1/shippings/types';
import { ORDERS_LIMIT } from '~/shared/config/orders';
import { sentryLog } from '~/shared/helpers/sentryLog';

export default function useOrdersApi() {
  const { $api } = useNuxtApp();
  const notificationsStore = globalNotificationsStore();

  const getShopOrders = async (page: number, limit = ORDERS_LIMIT, search?: TSearch, filter?: TAnyLiteral) => {
    try {
      const res = await $api.personalAccount.getShopOrders({
        params: {
          search: search,
          filter: filter,
          limit: limit,
          page: page,
          sortBy: { createdAt: 'DESC' },
        },
        errorOptions: { isShowServerError: true },
      });

      return res;
    } catch (error) {
      console.error(error);
      if (error instanceof FetchError && error.data?.message) {
        notificationsStore.addNotificationsPending([
          {
            message: error.data.message,
            type: EnumNotificationItemStatus.Error,
          },
        ]);
      }

      sentryLog(error as Error, 'error');
    }
  };

  const getDeliveryHistory = async (id: number, provider: TShippingServiceProvider, code: string) => {
    try {
      const res = await $api.shipping.getStatusHistory(id, provider, {
        data: { code: code },
        errorOptions: { isShow: false },
      });

      return res;
    } catch (error: any) {
      console.error(error);
      sentryLog(error);
    }
  };

  return { getShopOrders, getDeliveryHistory };
}
