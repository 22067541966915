<template>
  <DefaultLayout>
    <NotFound v-if="error?.statusCode === EnumResponseCode.NotFound" />
    <Forbidden v-if="error?.statusCode === EnumResponseCode.Forbidden" />
    <ServerError v-if="error?.statusCode === EnumResponseCode.ServerError" />
    <p v-else>{{ error }}</p>
  </DefaultLayout>
</template>

<script lang="ts">
import type { NuxtError } from '#app';
import type { PropType } from 'vue';
import DefaultLayout from './app/layouts/default/ui/index.vue';
import NotFound from './pages/Errors/404/index.vue';
import Forbidden from './pages/Errors/403/index.vue';
import ServerError from './pages/Errors/500/index.vue';
import { EnumResponseCode } from './shared/api/constants';

export default defineNuxtComponent({
  name: 'ErrorPage',

  components: {
    NotFound,
    DefaultLayout,
    Forbidden,
    ServerError,
  },

  props: {
    error: {
      type: Object as PropType<NuxtError>,
    },
  },

  setup(props) {
    const { t } = useI18n();

    const title = () => {
      if (props.error?.statusCode === EnumResponseCode.NotFound) {
        return t('errorPage.notFoundPage');
      }
      if (props.error?.statusCode === EnumResponseCode.ServerError) {
        return t('errorPage.serverError');
      }
      return t('pageTitles.default');
    };

    useSeoMeta({
      title: title(),
    });

    return {
      EnumResponseCode,
    };
  },
});
</script>
