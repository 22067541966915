import BaseApi from '../..';
import type { IRequestParamsHandler, IResponseWithPaging } from '../../types';
import type { ILoginResponse, IUserUpdatePayload } from '../auth/types';
import type {
  IReviewExtended,
  IShopOrderDetailsApiV1,
  IShopOrdersStatusesStatisticResponse,
  IShopOrderApiV1,
} from './types';

class PersonalAccountApiV1 extends BaseApi {
  private VERSION = 'v1';
  private RESOURCE = 'personalAccount';

  async getReviews(params?: IRequestParamsHandler<IReviewExtended>): Promise<IResponseWithPaging<IReviewExtended>> {
    const url = `${this.VERSION}/${this.RESOURCE}/reviews`;

    return this.get({
      url,
      query: params?.params,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }

  async getReviewsCount(params?: IRequestParamsHandler<IReviewExtended>): Promise<{ count: number }> {
    const url = `${this.VERSION}/${this.RESOURCE}/reviews/count`;

    return this.get({
      url,
      query: params?.params,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }

  async getShopOrders(params?: IRequestParamsHandler<IShopOrderApiV1>): Promise<IResponseWithPaging<IShopOrderApiV1>> {
    const url = `${this.VERSION}/${this.RESOURCE}/shopOrders`;

    return this.get({
      url,
      query: params?.params,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }

  async getShopOrderById(
    shopOrderId: number,
    params?: IRequestParamsHandler<IShopOrderDetailsApiV1>,
  ): Promise<IShopOrderDetailsApiV1> {
    const url = `${this.VERSION}/${this.RESOURCE}/shopOrders/${shopOrderId}`;

    return this.get({
      url,
      query: params?.params,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }

  async cancelShopOrder(
    shopOrderId: number,
    params?: IRequestParamsHandler<IShopOrderDetailsApiV1>,
  ): Promise<IShopOrderDetailsApiV1> {
    const url = `${this.VERSION}/${this.RESOURCE}/shopOrders/${shopOrderId}/cancel`;

    return this.patch({
      url,
      query: params?.params,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }

  async getShopOrdersStatistic(params?: IRequestParamsHandler<IShopOrdersStatusesStatisticResponse>) {
    const url = `${this.VERSION}/${this.RESOURCE}/shopOrders/statuses/statistic`;

    return this.get<IShopOrdersStatusesStatisticResponse>({
      url,
      query: params?.params,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }

  async updateUser(params: IRequestParamsHandler<void, IUserUpdatePayload | FormData>) {
    const url = `${this.VERSION}/${this.RESOURCE}`;

    return this.patch<ILoginResponse>({
      url,
      body: params?.data,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }

  async subscribeTelegram(params?: any) {
    const url = `${this.VERSION}/${this.RESOURCE}/telegram/subscribe`;

    return this.post<ILoginResponse>({
      url,
      body: params?.data,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }

  async unsubscribeTelegram(params?: any) {
    const url = `${this.VERSION}/${this.RESOURCE}/telegram/unsubscribe`;

    return this.post<ILoginResponse>({
      url,
      body: params?.data,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }
}

export default PersonalAccountApiV1;
