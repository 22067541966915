import type { FetchOptions } from 'ofetch';
import BaseApi from '../..';
import type { IRequestParamsHandler, IResponseWithPaging } from '../../types';
import type { IDeliveryInfoApiV1, IProductApiV1 } from './types';

class ProductsApiV1 extends BaseApi {
  private VERSION = 'v1';
  private RESOURCE = 'products';

  async getProducts(params: IRequestParamsHandler<Array<IProductApiV1>>) {
    const url = `${this.VERSION}/${this.RESOURCE}`;

    const requestAsyncData = useAsyncData<Array<IProductApiV1>>(
      url,
      () =>
        this.get({
          url,
          query: params?.params,
          fetchOptions: params?.fetchOptions,
          errorOptions: params?.errorOptions,
        }),
      params.asyncDataOptions,
    );

    return requestAsyncData;
  }

  async getProduct(slug: string, params?: IRequestParamsHandler<IProductApiV1>) {
    const url = `${this.VERSION}/${this.RESOURCE}/${slug}`;

    const requestAsyncData = await useAsyncData<IProductApiV1>(
      url,
      () =>
        this.get({
          url,
          query: params?.params,
          fetchOptions: params?.fetchOptions,
          errorOptions: params?.errorOptions,
        }),
      params?.asyncDataOptions,
    );

    return requestAsyncData;
  }

  async getSearchProducts(
    params?: IRequestParamsHandler<IResponseWithPaging<IProductApiV1>>,
  ): Promise<IResponseWithPaging<IProductApiV1>> {
    const url = `${this.VERSION}/search/${this.RESOURCE}`;

    return this.get({
      url,
      query: params?.params,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }

  async getDeliveryInfoProducts(
    productId: number,
    params?: IRequestParamsHandler<IDeliveryInfoApiV1>,
  ): Promise<IDeliveryInfoApiV1> {
    const url = `${this.VERSION}/${this.RESOURCE}/${productId}/delivery-info`;

    return this.get({
      url,
      query: params?.params,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }

  // <<< request for test!
  async getCheckAuth(params?: IRequestParamsHandler<Array<IProductApiV1>>) {
    const url = `${this.VERSION}/auth/check`;
    const fetchOptions: FetchOptions<'json'> = {};

    const requestAsyncData = useAsyncData<Array<IProductApiV1>>(
      url,
      () =>
        this.get({
          url,
          query: params?.params,
          fetchOptions: fetchOptions,
          errorOptions: params?.errorOptions,
        }),
      params?.asyncDataOptions,
    );

    return requestAsyncData;
  }
  // >>>
}

export default ProductsApiV1;
