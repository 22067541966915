export const getUserInitials = (data: { firstName?: string; lastName?: string }, defaultInitials: string = 'N/A') => {
  const { firstName, lastName } = data;
  const initials = [firstName ? firstName[0] : '', lastName ? lastName[0] : ''].filter(Boolean);

  if (initials.length === 0) {
    return defaultInitials;
  }

  return initials.join('');
};
