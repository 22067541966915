import WiNuxtErrorBoundary from '~/shared/ui/WiNuxtErrorBoundary/index.vue';
import WiCustomImage from '~/shared/ui/WiCustomImage/index.vue';
import WiInputCount from '~/widgets/WiInputCount/ui/index.vue';
import { Icons } from '@wikkeo/ui-kit';

import { CartProductEntity } from '~/entities/cartProduct';
import { CartProductWithErrorEntity } from '~/entities/cartProductWithError';
import { getPriceString } from '~/shared/formatters/getPriceString';
import useAppRouter from '~/shared/hooks/useAppRouter';
import { getCartStore } from '~/features/cart/getCartStore';
import { useFeatureAddProductToCart } from '~/features/cart/addProductsToCart';
import { getInputCountProps } from '~/shared/helpers/product/getInputCountProps';
import { openLinkInNewTab } from '~/shared/browserAPI/openLinkInNewTab';

const { WiTrash, WiNoPhoto } = Icons;

export default defineNuxtComponent({
  name: 'WiCartPreviewDesktopItem',

  components: {
    WiNuxtErrorBoundary,
    WiCustomImage,
    WiTrash,
    WiInputCount,
    WiNoPhoto,
  },

  props: {
    product: {
      type: Object as PropType<CartProductEntity | CartProductWithErrorEntity>,
      required: true,
    },
  },

  emits: ['onRemove'],

  setup(props, { emit }) {
    const { product } = toRefs(props);

    const router = useAppRouter();

    const cartStore = globalCartStore();

    const { dataCartStore } = storeToRefs(getCartStore(cartStore));

    const { cartEntity } = reactive(dataCartStore.value);

    const { changeProductsToCart } = useFeatureAddProductToCart();

    const productWithoutError = computed(() => {
      return product.value instanceof CartProductEntity ? product.value : null;
    });

    const productWithError = computed(() => {
      return product.value instanceof CartProductWithErrorEntity ? product.value : null;
    });

    const inputCountProps = computed(() => {
      return getInputCountProps(productWithoutError.value);
    });

    const handleChangeCount = (quantity: number, product: CartProductEntity) => {
      if (!cartEntity) {
        return;
      }

      cartEntity.changeQuantityProducts(
        [
          {
            ids: {
              productId: product.id,
              productOptionId: product.productOptionId,
              productOptionValueId: product.productOptionValueId,
            },
            quantity,
          },
        ],

        'productsCart',
      );

      changeProductsToCart();
    };

    const handelGoToProduct = (slug: string | null) => {
      if (!slug) {
        return;
      }

      openLinkInNewTab(router.getProductLink(slug));
    };

    const removeProduct = () => {
      emit('onRemove', product.value);
    };

    return {
      productWithoutError,
      productWithError,
      inputCountProps,

      getPriceString,
      handelGoToProduct,
      handleChangeCount,
      removeProduct,
    };
  },
});
