import { Shared } from '@wikkeo/ui-kit';
import useAppRouter from '~/shared/hooks/useAppRouter';
import { globalComponentManagementStore } from '~/app/store/componentManagement';

// @ts-ignore
const { Helpers } = Shared;

export default defineNuxtComponent({
  name: 'WiSubstrateArea',

  props: {
    isDisableScroll: {
      type: Boolean,
      default: true,
    },
  },

  setup() {
    const useGlobalComponentManagementStore = globalComponentManagementStore();

    const { getSubstrateAreaActiveIds } = storeToRefs(useGlobalComponentManagementStore);

    const router = useAppRouter();

    const isDisabledScroll = computed(() => {
      return getSubstrateAreaActiveIds.value.length > 0;
    });

    watch(getSubstrateAreaActiveIds, () => {
      handleDisableScroll();
    });

    onMounted(() => {
      handleDisableScroll();
    });

    onUnmounted(() => {
      handleDisableScroll();
    });

    watch(router.route, () => {
      if (getSubstrateAreaActiveIds.value.length > 0) {
        useGlobalComponentManagementStore.removeAllSubstrateAreaIds();
      }
    });

    const handleDisableScroll = async () => {
      // NOTE: this nextTick is dirty hack, need rethink SubstrateAreaActiveIds logic
      await nextTick();

      Helpers.DOM.disableMainScroll({
        isDisabled: isDisabledScroll.value,
        elementClass: 'main-layout__content_disable-scroll',
        elementId: 'mainLayoutScroll',
      });
    };

    const handleClick = () => {
      useGlobalComponentManagementStore.removeLastSubstrateAreaId();
    };

    return {
      handleClick,
    };
  },
});
