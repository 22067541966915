import { getProfileMenu } from '~/shared/config/profileLayoutMenu';
import WiProfileMenuItem from '../WiProfileMenuItem/index.vue';
import type { PropType } from 'vue';
import type { IShopOrdersStatusesStatisticObject } from '~/shared/api/v1/personalAccount/types';

export default defineNuxtComponent({
  name: 'WiProfileMenu',

  components: {
    WiProfileMenuItem,
  },

  props: {
    shopOrdersStatusesStatistic: {
      type: Object as PropType<IShopOrdersStatusesStatisticObject>,
    },
  },

  setup() {
    return {
      profileMenu: getProfileMenu(),
    };
  },
});
