import BaseApi from '../..';
import type { IRequestParamsHandler } from '../../types';
import type { IOrderCreateRequest, IOrderResponse } from './types';

class OrdersApiV1 extends BaseApi {
  private VERSION = 'v1';
  private RESOURCE = 'orders';

  async createOrder(params: IRequestParamsHandler<IOrderResponse, IOrderCreateRequest>): Promise<IOrderResponse> {
    const url = `${this.VERSION}/${this.RESOURCE}`;

    return this.post({
      url,
      body: params?.data,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
      isRaw: params?.isRaw,
    });
  }

  async getOrderById(id: string, params?: IRequestParamsHandler<IOrderResponse>): Promise<IOrderResponse> {
    const url = `${this.VERSION}/${this.RESOURCE}/${id}`;

    return this.get({
      url,
      query: params?.params,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }
}

export default OrdersApiV1;
