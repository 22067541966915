import { LinkUtils } from '~/shared/utils/link/index';

import WiModalWrapper from '~/shared/ui/WiModalWrapper/index.vue';

import { Icons } from '@wikkeo/ui-kit';

const { WiTgPlane } = Icons;

export default defineNuxtComponent({
  name: 'WiSupportModal',

  components: {
    WiModalWrapper,
    WiTgPlane,
  },

  setup() {
    const managementStore = globalComponentManagementStore();
    const config = useRuntimeConfig();

    const onClose = () => {
      managementStore.setIsOpenSupportModal(false);
    };

    return {
      managementStore,
      supportTgUrl: LinkUtils.getTelegramLink(config.public.supportTgName),
      supportOrdersTgUrl: LinkUtils.getTelegramLink(config.public.supportOrdersTgName),
      supportVendorsTgUrl: LinkUtils.getTelegramLink(config.public.supportVendorsTgName),

      onClose,
    };
  },
});
