import { useProfileLayoutApi } from '../api';

export const useProfileLayoutModel = () => {
  const profileLayoutApi = useProfileLayoutApi();
  const userStore = useGlobalUserStore();

  const getShopOrdersStatistic = async () => {
    const data = await profileLayoutApi.getShopOrdersStatistic();

    userStore.setShopOrdersStatusesStatistic(data.statistic);
  };

  return {
    getShopOrdersStatistic,
  };
};
