import type { IImage } from '~/shared/api/types';
import { ProductPriceType } from '~/shared/api/v1/products/types';
import type { IShopWholesale } from '~/shared/api/v1/shops/types';
import type { TCartEntityKey } from '../cart/types';
import type { ICartProductWithErrorConstructorPayload, ICartProductWithErrorEntityOption } from './types';
import type { ICartsResponseErrors } from '~/shared/api/v1/carts/types';
import type { CartShopEntity } from '../cartShop';

export class CartProductWithErrorEntity {
  id!: number;
  sku!: string;
  shopId!: number;
  productOptionId!: number;
  productOptionValueId!: number;
  name!: string;
  productGroupId!: number | null;
  price!: number | null;
  option!: ICartProductWithErrorEntityOption | null;
  image!: IImage | null;
  slug!: string | null;
  priceType!: ProductPriceType;
  quantity!: number | null;
  shop!: CartShopEntity | null;
  shopWholesales!: IShopWholesale | null;
  isChecked!: boolean;
  cartEntityKey!: TCartEntityKey;
  errors!: Array<ICartsResponseErrors>;

  public constructor(product: ICartProductWithErrorConstructorPayload) {
    Object.assign(this, product);
  }

  public changeProduct(data: Partial<CartProductWithErrorEntity>) {
    Object.assign(this, data);
  }

  public changeProductShopErrors(errors: Array<string>) {
    if (!this.shop) {
      return;
    }

    this.shop.errors = errors;
  }
}
