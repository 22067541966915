<template>
  <WiModalWrapper :isOpen="isOpen" @onClose="closeModal">
    <template #body>
      <span class="wi-confirm-phone-number-modal__title">{{ $t('wiConfirmPhoneNumberModal.phoneNotConfirm') }}</span>
      <span class="wi-confirm-phone-number-modal__subtitle">
        {{ $t('wiConfirmPhoneNumberModal.inputPhoneForVerified') }}
      </span>
      <WiInputPhoneWrapper
        v-model="phoneNumber"
        :isSubstrate="isMobile"
        :errorMessage="formatPhone(phoneNumber).length < 3 ? errorMessagesData.REQUIRED : ''"
        @validate="onValidatePhone"
      />

      <div class="wi-confirm-phone-number-modal__buttons">
        <WiButton isFullWidth size="large" theme="alternative" @click="closeModal">
          {{ $t('wiConfirmPhoneNumberModal.back') }}
        </WiButton>
        <WiButton isFullWidth size="large" :isDisabled="!isValidPhone" :isLoading="isLoading" @click="confirmPhone">
          {{ $t('wiConfirmPhoneNumberModal.continue') }}
        </WiButton>
      </div>
    </template>
  </WiModalWrapper>
</template>

<style lang="scss" scoped src="./index.scss"></style>
<script lang="ts" src="./index.ts"></script>
