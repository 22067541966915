import BaseApi from '../..';
import type { IImage, IRequestParamsHandler, IResponseWithPaging } from '../../types';
import type { ICreateReviewPayload, IReview, IReviewsImageResponse, IReviewStat } from './types';

class ReviewsApiV1 extends BaseApi {
  private VERSION = 'v1';
  private RESOURCE = 'reviews';

  async getOne(reviewId: number, params?: IRequestParamsHandler<IReview>): Promise<IReview> {
    const url = `${this.VERSION}/${this.RESOURCE}/${reviewId}`;

    return this.get({
      url,
      query: params?.params,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }

  async getMany(params?: IRequestParamsHandler<IResponseWithPaging<IReview>>): Promise<IResponseWithPaging<IReview>> {
    const url = `${this.VERSION}/${this.RESOURCE}`;

    return this.get({
      url,
      query: params?.params,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }

  async getReviewsImages(
    params?: IRequestParamsHandler<IResponseWithPaging<IReviewsImageResponse>>,
  ): Promise<IResponseWithPaging<IReviewsImageResponse>> {
    const url = `${this.VERSION}/${this.RESOURCE}/images`;

    return this.get({
      url,
      query: params?.params,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }

  async getReviewsImagesWithInfo(
    params?: IRequestParamsHandler<IResponseWithPaging<IImage>>,
  ): Promise<IResponseWithPaging<IImage>> {
    const url = `${this.VERSION}/${this.RESOURCE}/images/withInfo`;

    return this.get({
      url,
      query: params?.params,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }

  async getReviewsStat(params?: IRequestParamsHandler<IReviewStat>): Promise<IReviewStat> {
    const url = `${this.VERSION}/${this.RESOURCE}/stats`;

    return this.get({
      url,
      query: params?.params,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }

  async getReviewsImagesCount(params?: IRequestParamsHandler<{ count: number }>): Promise<{ count: number }> {
    const url = `${this.VERSION}/${this.RESOURCE}/count`;

    return this.get({
      url,
      query: params?.params,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }

  async reviewLikeById(reviewId: number, params?: IRequestParamsHandler<void>): Promise<void> {
    const url = `${this.VERSION}/${this.RESOURCE}/${reviewId}/like`;

    return this.post({
      url,
      body: params?.data,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }

  async createReview(params?: IRequestParamsHandler<void, ICreateReviewPayload>): Promise<IReview> {
    const url = `${this.VERSION}/${this.RESOURCE}`;

    return this.post({
      url,
      body: params?.data,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }

  async patchReview(
    reviewId: number,
    params?: IRequestParamsHandler<void, Partial<ICreateReviewPayload>>,
  ): Promise<void> {
    const url = `${this.VERSION}/${this.RESOURCE}/${reviewId}`;

    return this.patch({
      url,
      body: params?.data,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }

  async createReviewImage(reviewId: number, image: File, params?: IRequestParamsHandler<void>): Promise<void> {
    const url = `${this.VERSION}/${this.RESOURCE}/${reviewId}/images`;

    const formData = new FormData();
    formData.append('file', image, image.name);

    return this.post({
      url,
      body: formData,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }

  async deleteReviewImage(
    reviewId: number,
    params?: IRequestParamsHandler<void, { ids: Array<number> }>,
  ): Promise<void> {
    const url = `${this.VERSION}/${this.RESOURCE}/${reviewId}/images`;

    return this.delete({
      url,
      body: params?.data,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }
}

export default ReviewsApiV1;
