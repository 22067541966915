import WiNuxtErrorBoundary from '~/shared/ui/WiNuxtErrorBoundary/index.vue';
import WiHeaderCategoriesContent from '~/shared/ui/header/legacy/WiHeaderCategoriesContent/index.vue';
import WiSubstrateArea from '~/widgets/WiSubstrateArea/index.vue';

import { Icons } from '@wikkeo/ui-kit';
import type { IApiCategoriesTree } from '~/shared/api/v1/categories/types';

import { useSubstrateManagementHook } from '~/shared/hooks/useSubstrateManagement';
import useAppRouter from '~/shared/hooks/useAppRouter';

// @ts-ignore
const { WiCategoryMenu, WiClose, WiCategoryMenuCorner, WiLogoName } = Icons;

type TShowCategory = {
  id: number;
  slug: string;
  name: string;
};

export default defineNuxtComponent({
  name: 'WiHeaderCategoryBlockDesktop',

  components: {
    WiNuxtErrorBoundary,
    WiCategoryMenu,
    WiClose,
    WiHeaderCategoriesContent,
    WiCategoryMenuCorner,
    WiLogoName,
    WiSubstrateArea,
  },

  props: {
    categories: {
      type: Array as PropType<Array<IApiCategoriesTree>>,
    },

    isLoadingCategories: {
      type: Boolean,
    },
  },

  setup(props) {
    const router = useAppRouter();

    const { categories } = toRefs(props);

    const isOpenCategory = ref(false);

    const { isShowSubstrate, handleOpenSubstrate } = useSubstrateManagementHook();

    let isLoadingCategories = ref(false);

    const showCategory = ref<Record<string, TShowCategory | null>>({
      1: null,
      2: null,
      3: null,
    });

    watch(isShowSubstrate, () => {
      isOpenCategory.value = isShowSubstrate.value;
    });

    const handleShowCategoryMenu = () => {
      isOpenCategory.value = !isOpenCategory.value;

      handleOpenSubstrate(isOpenCategory.value);
    };

    const showSubCategories = (category: any) => {
      const { id, level, slug, name } = category;

      if (showCategory.value[level] === undefined) {
        return;
      }

      showCategory.value[level] = { id, name, slug };

      if (level === 2) {
        showCategory.value[3] = null;
      }
    };

    const goToCategory = (slug: string) => {
      handleOpenSubstrate(false);
      router.goToCategory(slug);
    };

    return {
      isOpenCategory,
      categories,
      isLoadingCategories,
      showCategory,
      isShowSubstrate,

      handleShowCategoryMenu,
      showSubCategories,
      goToCategory,
    };
  },
});
