//@ts-ignore
import { useChatStore } from '@wikkeo/wikkeo-chat-library';
import { FetchError } from 'ofetch';
import { useReCaptcha } from 'vue-recaptcha-v3';
import type { ILoginPayload } from '~/shared/api/v1/auth/types';
import { setUser } from '~/shared/helpers/auth.js';
import { checkNetworkRecaptchaError } from '~/shared/helpers/errorHandler';
import { sentryLog } from '~/shared/helpers/sentryLog';
import useAppRouter from '~/shared/hooks/useAppRouter';
import { useStoreWithContext } from '~/shared/hooks/useStoreWithContext';
import { useWish } from '~/shared/hooks/useWish';

export default function useWiAuthorizationWidgetApi() {
  const { t } = useI18n();
  const router = useAppRouter();
  const { $api } = useNuxtApp();
  const { loadWishlistIds } = useWish();
  const recaptchaInstance = useReCaptcha();
  const notificationsStore = globalNotificationsStore();
  const managementStore = globalComponentManagementStore();
  const chatStore = useStoreWithContext(useChatStore);

  const isLoadingLogin = ref<boolean>(false);

  const onLogin = async (loginCredentials: ILoginPayload) => {
    isLoadingLogin.value = true;

    await recaptchaInstance?.recaptchaLoaded();

    const recaptchaToken = await recaptchaInstance?.executeRecaptcha('submit');

    if (!recaptchaToken) {
      notificationsStore.addNotificationsPending([
        {
          message: t('error.recaptchaError'),
          type: EnumNotificationItemStatus.Error,
        },
      ]);

      return;
    }

    try {
      const res = await $api.auth.login({
        data: {
          email: loginCredentials.email,
          password: loginCredentials.password,
        },
        fetchOptions: { headers: { 'g-recaptcha-response': recaptchaToken } },
      });

      if (res) {
        setUser(res);

        managementStore.onSuccessLoginCallback?.();

        managementStore.setIsOpenLoginModal(false);

        await loadWishlistIds();

        router.goToProfileAfterLogin();

        await chatStore.initChat();
      }
    } catch (error) {
      if (error instanceof FetchError && error.data?.message) {
        checkNetworkRecaptchaError(error.data?.message);
      }
      sentryLog(error as Error);
    } finally {
      isLoadingLogin.value = false;
    }
  };

  return { isLoadingLogin, onLogin };
}
