<template>
  <WiNuxtErrorBoundary>
    <div class="wi-header-categories-content">
      <ul class="wi-header-categories-content__general">
        <li
          v-for="generalCategory in categories"
          :key="generalCategory.value.id + '_general'"
          class="wi-header-categories-content__general-item"
          @click="goToCategory(generalCategory.value.slug)"
        >
          <a
            class="wi-header-categories-content__general-item-link"
            :class="[
              generalCategory.value.id === showCategory[1]?.id
                ? 'wi-header-categories-content__general-item-link_active'
                : '',
            ]"
            @mouseover="
              showSubCategories({
                id: generalCategory.value.id,
                level: generalCategory.level,
                slug: generalCategory.value.slug,
                name: generalCategory.value.name,
              })
            "
          >
            <div class="wi-header-categories-content__general-item-link-image-wrapper">
              <img
                v-if="generalCategory.value.slug"
                loading="lazy"
                :srcset="getImageCategoryHref(generalCategory.value.slug)"
                class="wi-header-categories-content__general-item-link-image"
                :title="generalCategory.value.name"
                @error="handlerErrorIconCategory"
              />
            </div>

            <span class="wi-header-categories-content__general-item-link-text">{{ generalCategory.value.name }}</span>

            <div class="wi-header-categories-content__general-item-link-icon">
              <WiChevron class="wi-image-container" />
            </div>
          </a>
        </li>
      </ul>

      <div class="wi-header-categories-content__subcategories">
        <template v-for="category in categories">
          <div
            class="wi-header-categories-content__subcategories-item"
            v-if="showCategory[1]?.id === category.value.id"
            :key="category.value.id + '_subcategory'"
          >
            <h3 class="wi-header-categories-content__subcategories-title">
              {{ category.value.name }}
            </h3>

            <ul class="wi-header-categories-content__subcategories-list">
              <li
                v-for="subcategory in category.children"
                :key="subcategory.value.id"
                class="wi-header-categories-content__subcategories-item"
                @click="goToCategory(subcategory.value.slug)"
              >
                <a class="wi-header-categories-content__subcategories-item-link">
                  {{ subcategory.value.name }}
                </a>

                <ul
                  :ref="
                    (el) => {
                      if (el) subcategoryRefs['subcategory' + subcategory.value.id] = el;
                    }
                  "
                  :class="{
                    'wi-header-categories-content__child-categories-list': true,
                    'wi-header-categories-content__child-categories-list_hidden-child':
                      subcategory.children?.length > 5,
                  }"
                >
                  <li
                    v-for="childCategory in subcategory.children"
                    :key="childCategory.value.id"
                    class="wi-header-categories-content__child-categories-item"
                    @click="goToCategory(childCategory.value.slug)"
                  >
                    <a class="wi-header-categories-content__child-categories-item-link">
                      {{ childCategory.value.name }}
                    </a>
                  </li>

                  <button
                    v-if="subcategory.children?.length > 5"
                    class="wi-header-categories-content__child-categories-button-get-more wi-flex-content-center"
                    @click.stop.capture="
                      getMoreChildCategories(
                        $event,
                        'subcategory' + subcategory.value.id,
                        'wi-header-categories-content__child-categories-list_hidden-child',
                      )
                    "
                  >
                    {{ $t('wiHeaderCategoriesContent.more') }}

                    <div class="wi-header-categories-content__child-categories-button-get-more-icon-wrapper">
                      <WiChevron />
                    </div>
                  </button>
                </ul>
              </li>
            </ul>
          </div>
        </template>
      </div>
    </div>
  </WiNuxtErrorBoundary>
</template>

<script lang="ts" src="./index.ts"></script>
<style lang="scss" scoped src="./index.scss"></style>
