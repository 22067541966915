<template>
  <Transition name="wi-slide-cookies-modal">
    <article v-if="isNeedShowCookieModal" class="wi-cookies-modal-component">
      <div class="wi-cookies-modal-component__modal">
        <h2 class="wi-cookies-modal-component__modal-title">{{ $t('cookiesModal.title') }}</h2>
        <div class="wi-cookies-modal-component__modal-body">
          <p class="wi-cookies-modal-component__modal-text">
            {{ $t('cookiesModal.text1') }}
          </p>
          <p class="wi-cookies-modal-component__modal-text">
            {{ $t('cookiesModal.text2') }}
            <a class="wi-cookies-modal-component__modal-text_link" target="_blank" :href="cookiePoliticsLink">
              {{ $t('cookiesModal.linkText') }}
            </a>
          </p>
        </div>
        <div class="wi-cookies-modal-component__modal-button-section">
          <button class="wi-cookies-modal-component__modal-button" @click="setCookieInLocalStorage">
            {{ $t('cookiesModal.confirm') }}
          </button>
        </div>
      </div>
    </article>
  </Transition>
</template>

<script lang="ts" src="./index.ts" />
<style lang="scss" src="./index.scss" scoped />
