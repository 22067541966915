import { checkIsServer } from './isServer';
import { scrollToTop } from './scrollToTop';

export const scrollElementTo = (id: string, margin = 0) => {
  if (checkIsServer()) {
    return;
  }

  const element = document.getElementById(id);

  const header = document.getElementById('wiStaticWrapperHeader');

  if (!element || !header) {
    return;
  }

  const offsetPosition = element.offsetTop - header.getBoundingClientRect().height - margin;

  setTimeout(() => {
    scrollToTop(undefined, offsetPosition);
  }, 300);
};
