export const uniqByField = <T extends TAnyLiteral>(arr: Array<T>, field: keyof T): Array<T> => {
  const result: Array<T> = [];
  const uniqValues = new Set<any>();

  arr.forEach((item) => {
    if (!uniqValues.has(item[field])) {
      uniqValues.add(item[field]);
      result.push(item);
    }
  });

  return result;
};
