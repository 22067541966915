import type { PublicRuntimeConfig } from 'nuxt/schema';
import useAppRouter from '../hooks/useAppRouter';

export const getMenuLoginUser = (localization: any) => {
  const config = useRuntimeConfig();
  const router = useAppRouter();

  const publicConfig = config.public as unknown as PublicRuntimeConfig;

  return [
    {
      label: localization('wiHeaderNavigation.createShop'),
      link: router.getCreateShopPath(),
    },
    {
      label: localization('wiHeaderNavigation.shopManagement'),
      link: publicConfig.vendorLink,
      target: '_blank',
    },
    {
      label: localization('wiHeaderNavigation.newItems'),
      link: router.getNewProductsPath(),
    },
    {
      label: localization('wiHeaderNavigation.forSuppliers'),
      link: router.getForVendorsPath(),
    },
    {
      label: localization('wiHeaderNavigation.help'),
      link: router.getHelpPath(),
    },
    {
      label: localization('wiHeaderNavigation.support'),
      link: '',
      action: 'openSupportModal',
    },
  ];
};

export const getMenuIsLoginVendor = (localization: any) => {
  const config = useRuntimeConfig();
  const router = useAppRouter();

  const publicConfig = config.public as unknown as PublicRuntimeConfig;

  return [
    {
      label: localization('wiHeaderNavigation.shopManagement'),
      link: publicConfig.vendorLink,
      target: '_blank',
    },
    {
      label: localization('wiHeaderNavigation.newItems'),
      link: router.getNewProductsPath(),
    },
    {
      label: localization('wiHeaderNavigation.forSuppliers'),
      link: router.getForVendorsPath(),
    },
    {
      label: localization('wiHeaderNavigation.help'),
      link: router.getHelpPath(),
    },
    {
      label: localization('wiHeaderNavigation.support'),
      link: '',
      action: 'openSupportModal',
    },
  ];
};

export const getMenuIsNotLoginUser = (localization: any) => {
  const config = useRuntimeConfig();
  const router = useAppRouter();

  const publicConfig = config.public as unknown as PublicRuntimeConfig;

  return [
    {
      label: localization('wiHeaderNavigation.sighIn'),
      link: router.getLoginPath(),
    },
    {
      label: localization('wiHeaderNavigation.shopManagement'),
      link: publicConfig.vendorLink,
      target: '_blank',
    },
    {
      label: localization('wiHeaderNavigation.newItems'),
      link: router.getNewProductsPath(),
    },
    {
      label: localization('wiHeaderNavigation.forSuppliers'),
      link: router.getForVendorsPath(),
    },
    {
      label: localization('wiHeaderNavigation.help'),
      link: router.getHelpPath(),
    },
    {
      label: localization('wiHeaderNavigation.support'),
      link: '',
      action: 'openSupportModal',
    },
  ];
};

export const getUserMenuFastButtonsSeller = () => {
  const { t } = useI18n();
  const config = useRuntimeConfig();
  const router = useAppRouter();

  const publicConfig = config.public as unknown as PublicRuntimeConfig;

  return [
    {
      label: t('profileLayout.menu.links.address'),
      link: router.getProfileDeliveryProfilesPath(),
      icon: 'WiLocationV2',
    },
    {
      label: t('profileLayout.menu.links.sell'),
      link: publicConfig.vendorLink,
      target: '_blank',
      icon: 'WiShoppingBag',
    },
    {
      label: t('profileLayout.menu.links.support'),
      link: '',
      icon: 'WiSupport',
      action: 'openSupportModal',
    },
    {
      label: t('profileLayout.menu.links.aboutApp'),
      link: '/info',
      icon: 'WIInfo',
    },
  ];
};

export const getUserMenuFastButtonsUser = () => {
  const { t } = useI18n();
  const router = useAppRouter();

  return [
    {
      label: t('profileLayout.menu.links.address'),
      link: router.getProfileDeliveryProfilesPath(),
      icon: 'WiLocationV2',
    },
    {
      label: t('profileLayout.menu.links.sell'),
      link: router.getCreateShopPath(),
      icon: 'WiShoppingBag',
    },
    {
      label: t('profileLayout.menu.links.support'),
      link: '',
      icon: 'WiSupport',
      action: 'openSupportModal',
    },
    {
      label: t('profileLayout.menu.links.aboutApp'),
      link: '/info',
      icon: 'WIInfo',
    },
  ];
};

export const getUserMenuFastButtonsUnauthorized = () => {
  const { t } = useI18n();

  return [
    {
      label: t('profileLayout.menu.links.address'),
      link: '',
      icon: 'WiLocationV2',
      action: 'openLoginModal',
    },
    {
      label: t('profileLayout.menu.links.sell'),
      link: '',
      icon: 'WiShoppingBag',
      action: 'openLoginModal',
    },
    {
      label: t('profileLayout.menu.links.support'),
      link: '',
      icon: 'WiSupport',
      action: 'openSupportModal',
    },
    {
      label: t('profileLayout.menu.links.aboutApp'),
      link: '/info',
      icon: 'WIInfo',
    },
  ];
};
