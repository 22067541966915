import { checkIsMobile } from '../browserAPI/isMobile';
import { checkIsServer } from '../browserAPI/isServer';

export const useMobile = () => {
  const uiConfigStore = globalUiConfigStore();

  const { isMobileFromStore } = storeToRefs(uiConfigStore);

  const isMobileRef = computed(() => {
    if (checkIsServer()) {
      // TODO
      const { isMobile } = useDevice();

      return isMobile;
    } else {
      if (isMobileFromStore === undefined || isMobileFromStore.value === undefined) {
        return false;
      }

      return isMobileFromStore.value;
    }
  });

  const checkWindowView = () => {
    const isMobileViewport = checkIsMobile();

    uiConfigStore.setIsMobile(isMobileViewport);
  };

  return {
    isMobile: isMobileRef,

    checkWindowView,
  };
};
