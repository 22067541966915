import BaseApi from '../..';
import type { IRequestParamsHandler } from '../../types';
import type {
  IRemoveProductWishlistsByIdsApiV1,
  ISetProductWishlistsApiV1,
  IWishlistItemApiV1,
  IWishlistsCountApiV1,
} from './types';

class ProductsWishlistApiV1 extends BaseApi {
  private VERSION = 'v1';
  private RESOURCE = 'productWishlists';

  async getProductWishlistCount(params?: IRequestParamsHandler<IWishlistsCountApiV1>) {
    const url = `${this.VERSION}/${this.RESOURCE}/count`;

    const requestAsyncData = await useAsyncData<IWishlistsCountApiV1>(
      url,
      () =>
        this.get({
          url,
          query: params?.params,
          fetchOptions: params?.fetchOptions,
          errorOptions: params?.errorOptions,
        }),
      params?.asyncDataOptions,
    );

    return requestAsyncData;
  }

  async getProductIdsWishlist(
    params?: IRequestParamsHandler<Array<IWishlistItemApiV1>>,
  ): Promise<Array<IWishlistItemApiV1>> {
    const url = `${this.VERSION}/${this.RESOURCE}`;

    return this.get({
      url,
      query: params?.params,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions || { isShow: false },
    });
  }

  async setProductWishlist(params?: IRequestParamsHandler<ISetProductWishlistsApiV1>) {
    const url = `${this.VERSION}/${this.RESOURCE}`;

    return this.post({
      url,
      body: params?.data,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }

  async removeProductWishlist(productId: number, params?: IRequestParamsHandler<any>) {
    const url = `${this.VERSION}/${this.RESOURCE}/${productId}`;

    return this.delete({
      url,
      body: params?.data,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }

  async removeProductWishlistByIds(params: IRequestParamsHandler<IRemoveProductWishlistsByIdsApiV1>) {
    const url = `${this.VERSION}/${this.RESOURCE}/products`;

    return this.delete({
      url,
      body: params?.data,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }
}

export default ProductsWishlistApiV1;
