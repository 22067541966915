<template>
  <div class="wi-mobile-control-cart">
    <WiMobileControlWrapper :isActive="isActive" @click="goToCart" :count="cartProductsCount">
      <WiCart v-show="cartProductsCount === 0" />

      <WiCartForCounter v-show="cartProductsCount > 0" />
    </WiMobileControlWrapper>
  </div>
</template>

<script lang="ts" src="./index.ts"></script>
<style lang="scss" scoped src="./index.scss"></style>
