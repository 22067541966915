<template>
  <WiNuxtErrorBoundary>
    <div
      class="wi-cart-preview-desktop-item"
      :class="{ 'wi-cart-preview-desktop__body-item_error': productWithError }"
    >
      <div class="wi-cart-preview-desktop-item__content">
        <div class="wi-cart-preview-desktop-item__left" @click="handelGoToProduct(product.slug)">
          <div class="wi-cart-preview-desktop-item__left-image-wrapper">
            <WiCustomImage :image="product.option?.value?.image" :selectedSize="{ mobileSize: 300 }">
              <template #errorImage>
                <div class="wi-cart-preview-desktop-item__left-image-wrapper-no-photo">
                  <WiNoPhoto class="wi-image-container" />
                </div>
              </template>
            </WiCustomImage>
          </div>

          <div class="wi-cart-preview-desktop-item__left-info">
            <span class="wi-cart-preview-desktop-item__left-info-name">{{ product.name }}</span>

            <span class="wi-cart-preview-desktop-item__left-info-option"
              >{{ product.option?.title }} - {{ product.option?.value?.title }}
            </span>

            <span
              v-if="productWithError && productWithError.errors.length"
              class="wi-cart-preview-desktop-item__left-info-error"
            >
              {{ $t('product.notAvailable') }}
            </span>
          </div>
        </div>

        <div class="wi-cart-preview-desktop-item__right">
          <WiInputCount
            v-if="productWithoutError !== null"
            :value="productWithoutError.quantity"
            :step="inputCountProps.step"
            :min="inputCountProps.min"
            :max="inputCountProps.max"
            @onChange="handleChangeCount($event, productWithoutError)"
          />

          <div v-else></div>

          <span class="wi-cart-preview-desktop-item__right-price">
            {{ productWithoutError ? getPriceString(productWithoutError.currentPrice) : '' }}
          </span>

          <button class="wi-cart-preview-desktop-item__right-button-delete" @click="removeProduct">
            <WiTrash class="wi-image-container" />
          </button>
        </div>
      </div>

      <div class="wi-cart-preview-desktop-item__separate-line"></div>
    </div>
  </WiNuxtErrorBoundary>
</template>

<script lang="ts" src="./index.ts"></script>
<style lang="scss" scoped src="./index.scss"></style>
