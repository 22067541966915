<template>
  <WiNuxtErrorBoundary>
    <div class="wi-cart-preview-desktop">
      <div class="wi-cart-preview-desktop__header">
        <div class="wi-cart-preview-desktop__header-main">
          <span class="wi-cart-preview-desktop__header-main-title">
            {{ $t('wiCartPreviewDesktop.products') }}
          </span>

          <span class="wi-cart-preview-desktop__header-main-quantity">
            {{ cartProducts.length }} {{ $t('postfix.thing') }}.
          </span>
        </div>

        <button class="wi-cart-preview-desktop__header-button-clear" @click="removeAllProduct">
          {{ $t('wiCartPreviewDesktop.clear') }}
        </button>
      </div>

      <div class="wi-cart-preview-desktop__body">
        <div class="wi-cart-preview-desktop__body-list wi-custom-scroll">
          <WiCartPreviewDesktopItem
            v-for="product in cartProducts"
            :key="product.productOptionValueId"
            :product="product"
            @onRemove="removeProduct"
          />
        </div>
      </div>

      <div class="wi-cart-preview-desktop__result">
        <span class="wi-cart-preview-desktop__result-text">{{ $t('wiCartPreviewDesktop.total') }}</span>

        <span class="wi-cart-preview-desktop__result-text">{{ resultSumText }}</span>
      </div>

      <div class="wi-cart-preview-desktop__footer">
        <WiButton size="large" isFullWidth @onClick="goToCart"> {{ $t('wiCartPreviewDesktop.goToCart') }}</WiButton>
      </div>
    </div>

    <WiSubstrateArea v-if="isShowSubstrate" />
  </WiNuxtErrorBoundary>
</template>

<script lang="ts" src="./index.ts"></script>
<style lang="scss" scoped src="./index.scss"></style>
