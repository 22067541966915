<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="167" height="114" viewBox="0 0 167 114" fill="none">
    <rect
      x="82.8991"
      y="8.77422"
      width="84.8843"
      height="91.4144"
      rx="4.76852"
      transform="rotate(10 82.8991 8.77422)"
      fill="white"
      stroke="#E1E2EA"
      stroke-width="1.5"
    />
    <rect
      x="86.9737"
      y="14.5933"
      width="74.3357"
      height="64.7917"
      rx="4.76852"
      transform="rotate(10 86.9737 14.5933)"
      fill="#F5F6FA"
      stroke="#F5F6FA"
      stroke-width="1.5"
    />
    <rect
      x="0.550836"
      y="23.5142"
      width="84.8843"
      height="91.4144"
      rx="4.76852"
      transform="rotate(-10 0.550836 23.5142)"
      fill="white"
      stroke="#E1E2EA"
      stroke-width="1.5"
    />
    <rect
      x="6.36993"
      y="27.5891"
      width="74.3357"
      height="64.7917"
      rx="4.76852"
      transform="rotate(-10 6.36993 27.5891)"
      fill="#F5F6FA"
      stroke="#F5F6FA"
      stroke-width="1.5"
    />
    <rect
      x="35.5042"
      y="1.22314"
      width="101.317"
      height="109.134"
      rx="5.56046"
      fill="white"
      stroke="#E1E2EA"
      stroke-width="1.5"
    />
    <rect
      opacity="0.5"
      x="41.5173"
      y="7.23633"
      width="88.6895"
      height="77.2647"
      rx="5.56046"
      :fill="`url(#paint0_linear_27575_23263-${id})`"
      stroke="#E1E2EA"
      stroke-width="1.5"
    />
    <path
      d="M64.9515 40.8624H64.6143C64.3615 40.8624 64.1929 40.7859 64.1929 40.6711C64.1929 40.5563 64.3615 40.4797 64.6143 40.4797H64.9515C65.2043 40.4797 65.3729 40.5563 65.3729 40.6711C65.3729 40.7859 65.2043 40.8624 64.9515 40.8624Z"
      fill="white"
    />
    <path
      d="M64.9515 42.3932H64.6143C64.3615 42.3932 64.1929 42.3166 64.1929 42.2018C64.1929 42.087 64.3615 42.0105 64.6143 42.0105H64.9515C65.2043 42.0105 65.3729 42.087 65.3729 42.2018C65.3729 42.3166 65.2043 42.3932 64.9515 42.3932Z"
      fill="white"
    />
    <path
      d="M64.9515 43.9237H64.6143C64.3615 43.9237 64.1929 43.8471 64.1929 43.7323C64.1929 43.6175 64.3615 43.541 64.6143 43.541H64.9515C65.2043 43.541 65.3729 43.6175 65.3729 43.7323C65.3729 43.8471 65.2043 43.9237 64.9515 43.9237Z"
      fill="white"
    />
    <path
      d="M64.9515 45.4544H64.6143C64.3615 45.4544 64.1929 45.3779 64.1929 45.2631C64.1929 45.1483 64.3615 45.0718 64.6143 45.0718H64.9515C65.2043 45.0718 65.3729 45.1483 65.3729 45.2631C65.3729 45.3779 65.2043 45.4544 64.9515 45.4544Z"
      fill="white"
    />
    <path
      d="M80.3377 40.8624H74.7938C74.569 40.8624 74.4192 40.7859 74.4192 40.6711C74.4192 40.5563 74.569 40.4797 74.7938 40.4797H80.3377C80.5624 40.4797 80.7123 40.5563 80.7123 40.6711C80.7123 40.7859 80.5624 40.8624 80.3377 40.8624Z"
      fill="white"
    />
    <path
      d="M92.15 45.4544H74.781C74.5639 45.4544 74.4192 45.3779 74.4192 45.2631C74.4192 45.1483 74.5639 45.0718 74.781 45.0718H92.15C92.3671 45.0718 92.5118 45.1483 92.5118 45.2631C92.5118 45.3779 92.3671 45.4544 92.15 45.4544Z"
      :fill="`url(#paint1_linear_27575_23263-${id})`"
    />
    <path
      d="M80.3593 42.3932H72.8056C72.5938 42.3932 72.4526 42.3166 72.4526 42.2018C72.4526 42.087 72.5938 42.0105 72.8056 42.0105H80.3593C80.5711 42.0105 80.7123 42.087 80.7123 42.2018C80.7123 42.3166 80.5711 42.3932 80.3593 42.3932Z"
      fill="white"
    />
    <path
      d="M86.2599 43.9237H72.8049C72.5935 43.9237 72.4526 43.8471 72.4526 43.7323C72.4526 43.6175 72.5935 43.541 72.8049 43.541H86.2599C86.4712 43.541 86.6121 43.6175 86.6121 43.7323C86.5416 43.8471 86.4007 43.9237 86.2599 43.9237Z"
      fill="white"
    />
    <path
      d="M92.1287 43.9237H86.9952C86.7653 43.9237 86.6121 43.8471 86.6121 43.7323C86.6121 43.6175 86.7653 43.541 86.9952 43.541H92.1287C92.3586 43.541 92.5118 43.6175 92.5118 43.7323C92.5118 43.8471 92.3586 43.9237 92.1287 43.9237Z"
      fill="white"
    />
    <path
      d="M95.2863 43.9237H93.2771C93.0539 43.9237 92.9051 43.8471 92.9051 43.7323C92.9051 43.6175 93.0539 43.541 93.2771 43.541H95.2863C95.5095 43.541 95.6583 43.6175 95.6583 43.7323C95.6583 43.8471 95.5095 43.9237 95.2863 43.9237Z"
      :fill="`url(#paint2_linear_27575_23263)-${id}`"
    />
    <path
      d="M98.4115 40.4797V64.9699H62.7234C61.3156 64.9699 60.2597 63.8692 60.2597 62.5622V40.4797H98.4115Z"
      fill="white"
      stroke="#E1E2EA"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M112.571 40.4797V62.5622C112.571 63.938 111.397 64.9699 110.003 64.9699H98.4116V40.4797H112.571Z"
      fill="#E1E2EA"
    />
    <path
      d="M112.571 40.4797V62.5622C112.571 63.938 111.397 64.9699 110.003 64.9699H98.4116V40.4797H112.571Z"
      fill="#F5F6FA"
      stroke="#E1E2EA"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path d="M74.0259 40.4798L81.5304 29H120.044L112.327 40.4798H74.0259Z" fill="white" />
    <path
      d="M74.0259 40.4798L81.5304 29H120.044L112.327 40.4798H74.0259Z"
      fill="#F5F6FA"
      stroke="#E1E2EA"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      opacity="0.3"
      d="M112.571 40.5468V53.4902H102.246C101.288 53.4902 100.624 52.8866 100.477 52.0147L98.4116 40.4797L112.571 40.5468Z"
      :fill="`url(#paint3_linear_27575_23263)-${id}`"
    />
    <path
      d="M112.274 40.4797H98.4116L105.451 50.8597C105.954 51.5471 106.744 51.9595 107.534 51.9595H118.308C119.026 51.9595 119.529 51.1346 119.098 50.5847L112.274 40.4797Z"
      fill="white"
      stroke="#E1E2EA"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M98.4116 40.4798L90.8546 29H52.0001L59.8423 40.4798H98.4116Z"
      fill="white"
      stroke="#E1E2EA"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M79.3001 54.6382H64.4252C64.0777 54.6382 63.7996 54.3831 63.7996 54.0642C63.7996 53.7453 64.0777 53.4902 64.4252 53.4902H79.3001C79.6476 53.4902 79.9257 53.7453 79.9257 54.0642C79.8561 54.3831 79.6476 54.6382 79.3001 54.6382Z"
      fill="#E1E2EA"
    />
    <path
      d="M79.3001 57.3168H64.4252C64.0777 57.3168 63.7996 57.1468 63.7996 56.9342C63.7996 56.7216 64.0777 56.5515 64.4252 56.5515H79.3001C79.6476 56.5515 79.9257 56.7216 79.9257 56.9342C79.8561 57.1468 79.6476 57.3168 79.3001 57.3168Z"
      fill="#E1E2EA"
    />
    <path
      d="M71.4239 60.3781H64.435C64.082 60.3781 63.7996 60.208 63.7996 59.9955C63.7996 59.7829 64.082 59.6128 64.435 59.6128H71.4239C71.7769 59.6128 72.0593 59.7829 72.0593 59.9955C71.9887 60.208 71.7063 60.3781 71.4239 60.3781Z"
      fill="#E1E2EA"
    />
    <path d="M55.7497 70.365H79.0796" stroke="#E1E2EA" stroke-width="1.5" stroke-linecap="round" />
    <path d="M43.0626 65H129.661" stroke="#E1E2EA" stroke-width="1.5" stroke-linecap="square" />
    <path d="M72.6862 74.0403H87.7102" stroke="#E1E2EA" stroke-width="1.5" stroke-linecap="round" />
    <path d="M61.5023 74.2H70.4507" stroke="#E1E2EA" stroke-width="1.5" stroke-linecap="round" />
    <path d="M102.41 70.6848H109.76" stroke="#E1E2EA" stroke-width="1.5" stroke-linecap="round" />
    <defs>
      <linearGradient
        :id="`paint0_linear_27575_23263-${id}`"
        x1="48.2679"
        y1="8.12497"
        x2="117.83"
        y2="83.8392"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#E1E2EA" />
        <stop offset="1" stop-color="#E1E2EA" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        :id="`paint1_linear_27575_23263-${id}`"
        x1="74.5005"
        y1="45.2709"
        x2="92.5328"
        y2="45.2709"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF66A9" />
        <stop offset="1" stop-color="#F53689" />
      </linearGradient>
      <linearGradient
        :id="`paint2_linear_27575_23263-${id}`"
        x1="92.9235"
        y1="43.7206"
        x2="95.6578"
        y2="43.7206"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#83A6FF" />
        <stop offset="1" stop-color="#5A78FF" />
      </linearGradient>
      <linearGradient
        :id="`paint3_linear_27575_23263-${id}`"
        x1="105.495"
        y1="53.7572"
        x2="105.495"
        y2="41.9063"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00289017" stop-color="#777984" stop-opacity="0" />
        <stop offset="1" stop-color="#D2D2D8" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script lang="ts">
import { generateUid } from '~/shared/utils/string/generateUid';

export default defineNuxtComponent({
  name: 'NoPhotoProductIconLight',

  setup() {
    const id = generateUid();

    return {
      id,
    };
  },
});
</script>
