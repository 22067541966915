import { CartProductAdapter } from '~/shared/adapters/v1/cartProduct';
import { StorageService } from '~/shared/browserAPI/storageService';

export const migrateOldCartDataToNew = () => {
  const storageService = new StorageService('local');

  const oldCartData = storageService.getItem('cartProducts', true, false);

  if (!Array.isArray(oldCartData)) {
    return;
  }

  if (oldCartData.length === 0) {
    return;
  }

  const cartProductAdapter = new CartProductAdapter();

  const newData = oldCartData.map((old) => {
    return cartProductAdapter.fromOldCartToApiCarts({
      id: old.id,
      productOptionId: old.optionId,
      productOptionValueId: old.optionValueId,
      quantity: old.quantity,
      isChecked: old.isChecked,
    });
  });

  const cartData = storageService.getItem('Cart');

  let allProduct = [...newData];

  if (cartData && Array.isArray(cartData)) {
    allProduct = [...allProduct, ...cartData];
  }

  storageService.setItem('Cart', allProduct);

  storageService.removeItem('cartProducts', false);
  storageService.removeItem('cartOrder', false);
  storageService.removeItem('successCheck', false);
};
