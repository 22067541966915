import type { IProfileMenuItem } from '~/shared/types/profile/profileMenu';
import { ShopOrdersStatusesKeyEnum } from '~/shared/api/v1/personalAccount/types';
import useAppRouter from '~/shared/hooks/useAppRouter';
import { EnumFilterKey } from '~/shared/api/types';

export const ORDERS_LIMIT = 5;

export const cdekTrackingUrl = 'https://www.cdek.ru/ru/tracking/?order_id=';
export const pecTrackingUrl = 'https://pecom.ru/services-are/order-status/?code=';

export const getDefaultOrdersFilters = (t: any): Array<IProfileMenuItem> => {
  const router = useAppRouter();

  return [
    {
      label: t('profileLayout.menu.ordersTabs.all'),
      link: router.getProfileOrdersPath(),
      key: ShopOrdersStatusesKeyEnum.all,
      filterKey: null,
    },
    {
      label: t('profileLayout.menu.ordersTabs.pending'),
      link: router.getProfileOrdersPath(`status=${EnumFilterKey.Eq}:pending`),
      key: ShopOrdersStatusesKeyEnum.pending,
      filterKey: 'status',
    },
    {
      label: t('profileLayout.menu.ordersTabs.sent'),
      link: router.getProfileOrdersPath(`status=${EnumFilterKey.Eq}:on_delivery`),
      key: ShopOrdersStatusesKeyEnum.on_delivery,
      filterKey: 'status',
    },
    {
      label: t('profileLayout.menu.ordersTabs.delivered'),
      link: router.getProfileOrdersPath(`status=${EnumFilterKey.Eq}:completed`),
      key: ShopOrdersStatusesKeyEnum.completed,
      filterKey: 'status',
    },
    {
      label: t('profileLayout.menu.ordersTabs.waitingForEvaluation'),
      link: router.getProfileOrdersPath(`hasReviews=false&status=${EnumFilterKey.Eq}:completed`),
      key: ShopOrdersStatusesKeyEnum.hasReviewsFalse,
      filterKey: 'hasReviewsFalse',
    },
    {
      label: t('profileLayout.menu.ordersTabs.declined'),
      link: router.getProfileOrdersPath(`status=${EnumFilterKey.Eq}:declined`),
      key: ShopOrdersStatusesKeyEnum.declined,
      filterKey: 'status',
    },
  ];
};
