export default function useWiSighInTelegramApi() {
  const { $api } = useNuxtApp();

  const onLoginByTelegram = async (userId: string, recaptchaToken: any) => {
    return await $api.auth.loginByTelegram({
      data: {
        telegramId: userId,
      },
      fetchOptions: { headers: { 'g-recaptcha-response': recaptchaToken } },
    });
  };

  const onRegisterByTelegram = async (userId: string, recaptchaToken: any) => {
    return await $api.auth.registerByTelegram({
      data: {
        telegramId: userId,
      },
      fetchOptions: { headers: { 'g-recaptcha-response': recaptchaToken } },
    });
  };

  return { onLoginByTelegram, onRegisterByTelegram };
}
