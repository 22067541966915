import type { IProductOptionValueDiscount } from '~/shared/api/v1/products/types';

export const getMinDiscount = (discounts: Array<IProductOptionValueDiscount>) => {
  let minDiscount: IProductOptionValueDiscount = discounts[0];

  discounts.forEach((elem) => {
    if (minDiscount.from > elem.from) {
      minDiscount = elem;
    }
  });

  return minDiscount;
};
