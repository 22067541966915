<template>
  <WiNuxtErrorBoundary>
    <div v-if="isLoadingUserInfo" key="header-control-block" class="wi-header-control-block">
      <div class="wi-header-control-block__item wi-flex-content-center">
        <WiWish />
      </div>
      <div class="wi-header-control-block__item wi-flex-content-center">
        <WiCart />
      </div>
      <div class="wi-header-control-block__item wi-flex-content-center">
        <WiMenuAvatar />
      </div>
    </div>

    <div v-else class="wi-header-control-block">
      <NuxtLink class="wi-header-control-block__item wi-flex-content-center" to="/user/wishlists">
        <span
          v-show="wishlistIds?.length"
          id="controlBlockCounterWishlists"
          class="wi-header-control-block__item-counter wi-flex-content-center"
          >{{ wishlistIds?.length }}</span
        >
        <WiWish />
      </NuxtLink>

      <NuxtLink v-show="user" class="wi-header-control-block__item wi-flex-content-center" to="/user/orders">
        <WiOrdersBox />
      </NuxtLink>

      <NuxtLink v-show="user" class="wi-header-control-block__item wi-flex-content-center chat-call" to="/user/chat">
        <span class="wi-header-control-block__item-counter wi-flex-content-center" v-show="unreadRoomCount">
          {{ unreadRoomCount }}
        </span>
        <WiChatMessage />
      </NuxtLink>

      <div
        class="wi-header-control-block__item wi-flex-content-center"
        :class="{
          'wi-header-control-block__item_active': isShowCartPreview,
        }"
      >
        <span v-show="cartProductsCount" class="wi-header-control-block__item-counter wi-flex-content-center">
          {{ cartProductsCount }}
        </span>

        <div class="wi-header-control-block__item-icon-wrapper" @click="handlerShowCartPreview">
          <WiCart />
        </div>

        <article v-show="isShowCartPreview" class="cart-preview-wrapper">
          <WiCartPreviewDesktop @onClose="closeCartPreview" :isOpen="isShowCartPreview" />
        </article>
      </div>

      <div class="wi-header-control-block__item wi-flex-content-center" @click.stop="handlerUserMenu">
        <div class="wi-header-control-block__item-wrapper-avatar wi-flex-content-center">
          <WiMenuAvatar v-if="!user" />

          <WiCustomImage
            v-if="user && user?.image"
            class="wi-header-control-block__item-avatar wi-flex-content-center"
            :image="user.image"
            alt="avatar"
          />

          <span v-if="user && !user?.image" class="wi-header-control-block__item-avatar wi-flex-content-center">
            {{ initials }}
          </span>
        </div>

        <Transition name="user-menu">
          <ul
            v-if="isOpenUserMenu"
            class="wi-header-control-block__user-menu-list wi-desktop-version"
            v-wi-click-outside="clickOutsideUserMenu"
          >
            <div class="wi-header-control-block__user-menu-list-wrapper">
              <li class="wi-header-control-block__user-menu-list-item">
                <NuxtLink class="wi-header-control-block__user-menu-list-item-link" to="/user/profile">
                  <div class="wi-header-control-block__user-menu-list-item-icon-wrapper">
                    <WiProfile width="20" height="20" />
                  </div>
                  <span class="wi-header-control-block__user-menu-list-item-link__label">
                    {{ $t('wiHeaderControlBlock.profile') }}
                  </span>
                </NuxtLink>
              </li>

              <li class="wi-header-control-block__user-menu-list-item">
                <NuxtLink class="wi-header-control-block__user-menu-list-item-link" to="/user/orders">
                  <div class="wi-header-control-block__user-menu-list-item-icon-wrapper">
                    <WiBox width="20" height="20" />
                  </div>
                  <span class="wi-header-control-block__user-menu-list-item-link__label">
                    {{ $t('wiHeaderControlBlock.orders') }}
                  </span>
                </NuxtLink>
              </li>

              <li class="wi-header-control-block__user-menu-list-item">
                <NuxtLink class="wi-header-control-block__user-menu-list-item-link" to="/user/reviews">
                  <div class="wi-header-control-block__user-menu-list-item-icon-wrapper">
                    <WiStarOutlined width="20" height="20" />
                  </div>
                  <span class="wi-header-control-block__user-menu-list-item-link__label">
                    {{ $t('wiHeaderControlBlock.myReviews') }}
                  </span>
                </NuxtLink>
              </li>

              <li class="wi-header-control-block__user-menu-list-item">
                <span class="wi-header-control-block__user-menu-list-item-link" @click.stop="onLogout">
                  <div class="wi-header-control-block__user-menu-list-item-icon-wrapper">
                    <WiExit width="20" height="20" />
                  </div>
                  <span class="wi-header-control-block__user-menu-list-item-link__label">
                    {{ $t('wiHeaderControlBlock.logout') }}
                  </span>
                </span>
              </li>
            </div>
          </ul>
        </Transition>
      </div>
    </div>
  </WiNuxtErrorBoundary>
</template>

<script lang="ts" src="./index.ts" />
<style lang="scss" src="./index.scss" scoped />
