<template>
  <WiNuxtErrorBoundary>
    <div
      class="wi-header-category-block-desktop"
      :class="{
        'wi-header-category-block-desktop_open': isOpenCategory,
      }"
    >
      <slot name="before"></slot>

      <button
        class="wi-header-category-block-desktop__button wi-flex-content-center"
        :class="{
          'wi-header-category-block-desktop__button_open': isOpenCategory,
        }"
        @click="handleShowCategoryMenu()"
      >
        <div
          v-show="!isOpenCategory"
          class="wi-header-category-block-desktop__button-wrapper-icon wi-flex-content-center"
        >
          <WiCategoryMenu class="wi-image-container" />
        </div>

        <div
          v-show="isOpenCategory"
          class="wi-header-category-block-desktop__button-wrapper-icon wi-header-category-block-desktop__button-wrapper-icon_open wi-flex-content-center"
        >
          <WiClose class="wi-image-container" />
        </div>
      </button>

      <NuxtLink to="/" class="wi-header-category-block-desktop__logo-name">
        <WiLogoName />
      </NuxtLink>

      <div
        class="wi-header-category-block-desktop__block-corner"
        :class="{
          'wi-header-category-block-desktop__block-corner_show': isOpenCategory,
        }"
      >
        <WiCategoryMenuCorner />
      </div>

      <WiHeaderCategoriesContent
        v-if="isOpenCategory && !isLoadingCategories"
        :categories="categories"
        :showCategory="showCategory"
        @showSubCategories="showSubCategories"
        @onGoToCategory="goToCategory"
      />
    </div>

    <WiSubstrateArea v-if="isShowSubstrate" />
  </WiNuxtErrorBoundary>
</template>

<script lang="ts" src="./index.ts"></script>
<style lang="scss" scoped src="./index.scss"></style>
