import { StringUtils } from '~/shared/utils/string';
import { globalComponentManagementStore } from '~/app/store/componentManagement';

export const useSubstrateManagementHook = () => {
  const substrateId = ref<string>(StringUtils.generateUid());

  const useGlobalComponentManagementStore = globalComponentManagementStore();

  const { getSubstrateAreaActiveIds } = storeToRefs(useGlobalComponentManagementStore);

  const isShowSubstrate = computed(() => {
    return getSubstrateAreaActiveIds.value.includes(substrateId.value);
  });

  const handleOpenSubstrate = (isOpen: boolean) => {
    if (isOpen) {
      useGlobalComponentManagementStore.addSubstrateAreaId(substrateId.value);
    } else if (isShowSubstrate.value) {
      useGlobalComponentManagementStore.removeSubstrateAreaId(substrateId.value);
    }
  };

  return { handleOpenSubstrate, isShowSubstrate, substrateId };
};
