import { StringUtils } from '../string';
import { FILTER_CONDITION_DELIMITER, FILTER_KEY_PREFIX } from './constants';
import { DEFAULT_LIMIT, DEFAULT_PAGE } from '~/shared/api/constants';

export const queryParser = (query: TAnyLiteral) => {
  const { page = DEFAULT_PAGE, limit = DEFAULT_LIMIT, sortBy, ...filter } = query;

  const filterObject: TAnyLiteral = {};
  let sortByObject: TAnyLiteral | undefined = undefined;

  for (const key in filter) {
    const adaptedKey = key.replace(FILTER_KEY_PREFIX, '');
    filterObject[adaptedKey] = parseFilterValue(filter[key]);
  }

  if (sortBy) {
    sortByObject = parseSortValue(sortBy);
  }

  return {
    page,
    limit,
    sortBy: sortByObject,
    filter: filterObject,
  };
};

export const parseFilterValue = (payload: string | Array<string>) => {
  const isArray = Array.isArray(payload);

  if (!isArray) {
    const splittedFilter = payload.split(FILTER_CONDITION_DELIMITER);

    const conditions = splittedFilter.slice(0, splittedFilter.length - 1);

    let filterValue: any = splittedFilter[splittedFilter.length - 1];

    filterValue = StringUtils.parseBoolean(filterValue, true);

    if (conditions.length === 0) {
      return filterValue;
    }

    let result: TAnyLiteral = {};

    for (let index = conditions.length - 1; index > -1; index--) {
      const element = conditions[index];

      if (index === conditions.length - 1) {
        result = {
          [element]: filterValue,
        };
      } else {
        result = {
          [element]: result,
        };
      }
    }

    return result;
  } else {
    let arrayValues: TAnyLiteral = payload.map(parseFilterValue);

    if (arrayValues.length === 1 && typeof arrayValues[0] === 'boolean') {
      return arrayValues[0];
    } else {
      let result: TAnyLiteral = {};

      arrayValues.forEach((item: { [s: string]: unknown } | ArrayLike<unknown>) => {
        Object.entries(item).forEach(([key, value]) => {
          result[key] = value;
        });
      });

      return result;
    }
  }
};

const parseSortValue = (payload?: string) => {
  if (!payload) {
    return undefined;
  }

  const [field, condition] = payload.split(FILTER_CONDITION_DELIMITER);

  return {
    [field]: condition,
  };
};
