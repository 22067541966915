type ParseBoolean = {
  (string: string): boolean;
  (string: string, orReturnSource?: boolean): boolean | string;
};

export const parseBoolean: ParseBoolean = (string: string, orReturnSource?: boolean): any => {
  if (typeof string === 'string' && orReturnSource === undefined) {
    return string === 'true';
  }

  const values = ['true', 'false'];

  if (values.includes(string)) {
    return string === 'true';
  }

  return orReturnSource ? string : false;
};
