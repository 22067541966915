import * as Sentry from '@sentry/vue';
import { version } from '~/package.json';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const router = useRouter();

  if (config.public.sentry.dsn) {
    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: config.public.sentry.dsn,
      environment: config.public.sentry.environment,
      integrations: [
        Sentry.captureConsoleIntegration({ levels: ['warn', 'error'] }),
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      tracePropagationTargets: ['https://localhost', 'https://wikkeo.com'],
      release: 'wikkeo-nuxt@' + version,

      sampleRate: Number(config.public.sentry.SampleRate),
      tracesSampleRate: Number(config.public.sentry.TracesSampleRate),
      replaysSessionSampleRate: Number(config.public.sentry.SessionSampleRate),
      replaysOnErrorSampleRate: Number(config.public.sentry.SessionOnErrorSampleRate),
    });
  } else {
    // console.warn('Sentry OFF');
  }
});
