<template>
  <div class="wi-registration">
    <form @submit.prevent="onRegister">
      <WiInput
        :styleVariant="'second'"
        :placeholder="$t('login.placeholderLastName')"
        :value="registrationData.lastName"
        name="lastName"
        type="text"
        autocomplete="family-name"
        @onInput="setValue('lastName', $event)"
        :infoMessage="{
          text: v$.lastName.$errors[0]?.$message,
          type: 'error',
        }"
      />

      <WiInput
        :styleVariant="'second'"
        :placeholder="$t('login.placeholderFirstName')"
        :value="registrationData.firstName"
        name="firstName"
        type="text"
        autocomplete="given-name"
        @onInput="setValue('firstName', $event)"
        :infoMessage="{
          text: v$.firstName.$errors[0]?.$message,
          type: 'error',
        }"
      />

      <div
        :class="{
          'login-form__input-wrapper-error': v$.phone.$error || isValidCurrentPhone === false,
        }"
      >
        <WiInputPhoneWrapper
          class="wi-registration-phone"
          v-model="registrationData.phone"
          :isSubstrate="isMobile"
          @validate="handlerValidationPhone($event)"
        />

        <span
          class="login-form__input-error-message"
          :class="{
            'login-form__input-error-message_show': v$.phone.$error || isValidCurrentPhone === false,
          }"
          >{{ v$.phone.$errors[0]?.$message || errorMessagesData.INVALID_PHONE }}</span
        >
      </div>

      <WiInput
        :styleVariant="'second'"
        :placeholder="$t('login.placeholderEmail')"
        :value="registrationData.email"
        name="email"
        type="email"
        autocomplete="new-password"
        @onInput="setValue('email', $event)"
        :infoMessage="{
          text: v$.email.$errors[0]?.$message,
          type: 'error',
        }"
      />

      <WiInput
        :styleVariant="'second'"
        :placeholder="$t('login.placeholderPassword')"
        :value="registrationData.password"
        name="password"
        type="password"
        autocomplete="new-password"
        @onInput="setValue('password', $event)"
        isToggleVisible
        :infoMessage="{
          text: v$.password.$errors[0]?.$message,
          type: 'error',
        }"
      />

      <WiInput
        :styleVariant="'second'"
        :placeholder="$t('login.placeholderPassword')"
        :value="registrationData.confirmPassword"
        name="confirm-password"
        type="password"
        autocomplete="new-password"
        @onInput="setValue('confirmPassword', $event)"
        isToggleVisible
        :infoMessage="{
          text: v$.confirmPassword.$errors[0]?.$message,
          type: 'error',
        }"
      />

      <div class="wi-registration__button-section">
        <WiButton type="submit" :size="'largeMedium'" isFullWidth :isLoading="isLoadingSignup">{{
          $t('login.registration')
        }}</WiButton>
      </div>
    </form>
  </div>

  <WiVerifyPhoneModal
    v-if="isOpenVerifyModal"
    :isOpen="isOpenVerifyModal"
    :phoneNumber="formatPhone(registrationData.phone)"
    @onClose="onCloseVerifyModal"
    @onSuccess="handleSignup"
  />
</template>

<style lang="scss" scoped src="./index.scss"></style>
<script lang="ts" src="./index.ts" />
