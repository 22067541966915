import WiUserGeoLocation from '~/shared/ui/WiUserGeoLocation/ui/index.vue';
import WiSubstrateArea from '~/widgets/WiSubstrateArea/index.vue';
import WiNuxtErrorBoundary from '~/shared/ui/WiNuxtErrorBoundary/index.vue';

import { Icons } from '@wikkeo/ui-kit';
import { useSubstrateManagementHook } from '~/shared/hooks/useSubstrateManagement';

const { WiClose, WiChevron } = Icons;

export default defineNuxtComponent({
  name: 'WiMobileMenu',

  components: {
    WiUserGeoLocation,
    WiSubstrateArea,
    WiNuxtErrorBoundary,
    WiClose,
    WiChevron,
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },

    menuLevel: {
      type: Number,
      default: 1,
    },

    menuName: {
      type: String,
      default: '',
    },
  },

  emits: ['onOpen', 'onClose', 'onLevelDown'],

  setup(props, { emit }) {
    const { isOpen } = toRefs(props);

    const { isShowSubstrate, handleOpenSubstrate } = useSubstrateManagementHook();

    watch(isOpen, () => {
      handleOpenSubstrate(isOpen.value);
    });

    watch(isShowSubstrate, () => {
      if (isShowSubstrate.value) {
        handleOpenMenu();
      } else {
        handleCloseMenu();
      }
    });

    const handleOpenMenu = () => {
      emit('onOpen');
    };

    const handleCloseMenu = () => {
      emit('onClose');
    };

    const comeBackPreviousLevel = () => {
      emit('onLevelDown');
    };

    return {
      isShowSubstrate,
      handleCloseMenu,
      comeBackPreviousLevel,
    };
  },
});
