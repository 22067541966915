import useAppRouter from '../hooks/useAppRouter';
import { checkIsServer } from './isServer';

export const openLinkInNewTab = (link: string) => {
  if (checkIsServer() || !window) {
    return;
  }

  const router = useAppRouter();

  const { isSafari } = useDevice();

  if (isSafari) {
    router.push(link);
  } else {
    return window.open(link, '_blank');
  }
};
