import { checkIsServer } from '~/shared/browserAPI/isServer';
import useOrdersModel from '~/widgets/settings/WiOrdersBlock/model';

export default defineNuxtRouteMiddleware((to, from) => {
  if (checkIsServer()) {
    return;
  }

  if ((from.meta.isPrivate && !to.meta.isPrivate) || to.meta.pageName === 'UserProfile') {
    useOrdersModel().clearOrderState();
  }

  const userStore = useGlobalUserStore();

  // NOTE: for support legacy logic
  if (from.meta.isCategoryPage && !to.meta.isCategoryPage) {
    userStore.setDataForSearch({ key: 'category', value: null });
    userStore.setDataForSearch({ key: 'selectOption', value: null });
  }

  if (from.meta.isShopPage && !to.meta.isShopPage) {
    userStore.setDataForSearch({ key: 'shop', value: null });
    userStore.setDataForSearch({ key: 'selectOption', value: null });
  }
});
