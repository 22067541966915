<template>
  <WiNuxtErrorBoundary>
    <section id="wiNavigationHeader" class="wi-navigation-header">
      <div class="wi-navigation-header__body wi-custom-container">
        <WiUserGeoLocation />

        <nav class="wi-navigation-header__nav">
          <ClientOnly>
            <template #fallback>
              <li
                v-for="(elementLink, key) in defaultProfileMenu"
                :key="elementLink.label + key"
                :class="{
                  'wi-navigation-header__nav-item_active': getLocationPathName === elementLink.link,
                }"
                class="wi-navigation-header__nav-item"
              >
                <span class="wi-navigation-header__nav-link">
                  {{ elementLink.label }}
                </span>
              </li>
            </template>

            <li
              v-for="(elementLink, key) in getProfileMenu()"
              :key="elementLink.label + key"
              :class="{
                'wi-navigation-header__nav-item_active': getLocationPathName === elementLink.link,
              }"
              class="wi-navigation-header__nav-item"
            >
              <NuxtLink
                v-if="elementLink.link"
                class="wi-navigation-header__nav-link"
                :to="elementLink.link"
                :target="elementLink.target"
              >
                {{ elementLink.label }}
              </NuxtLink>
              <span v-else class="wi-navigation-header__nav-link" @click="onApplyLinkAction(elementLink.action)">
                {{ elementLink.label }}
              </span>
            </li>
          </ClientOnly>
        </nav>

        <div class="wi-navigation-header__technical-support">
          <WiThemeButton />
        </div>
      </div>
    </section>
  </WiNuxtErrorBoundary>
</template>

<script lang="ts" src="./index.ts" />
<style lang="scss" src="./index.scss" scoped />
