<template>
  <WiNuxtErrorBoundary>
    <div class="wi-input-count" :class="['wi-input-count_theme-' + theme]">
      <button
        class="wi-input-count__button wi-input-count__button_decrement"
        :class="{ 'wi-input-count__button_disabled': isDisableDecrement }"
        :disabled="isDisableDecrement"
        @click="onDecrement"
      >
        <WiMinusAlternative v-if="theme === 'alternative'" />

        <WiMinus v-else-if="theme === 'default'" />
      </button>

      <input
        class="wi-input-count__input"
        :class="{ 'wi-input-count__input_is-value': valueInput > 0 }"
        :value="valueInput"
        @change="onInput"
        type="number"
        :disabled="isDisableInput"
      />

      <button
        class="wi-input-count__button wi-input-count__button_increment"
        :class="{ 'wi-input-count__button_disabled': isDisableIncrement }"
        :disabled="isDisableIncrement"
        @click="onIncrement"
      >
        <WiPlusAlternative v-if="theme === 'alternative'" />

        <WiPlus v-else-if="theme === 'default'" />
      </button>
    </div>
  </WiNuxtErrorBoundary>
</template>

<script lang="ts" src="./index.ts" />
<style lang="scss" src="./index.scss" scoped />
