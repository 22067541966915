import BaseApi from '../..';
import type { IRequestParamsHandler } from '../../types';
import type { IApiCategoriesTree, ICategoryFromTree } from './types';

class CategoriesApiV1 extends BaseApi {
  private VERSION = 'v1';
  private RESOURCE = 'categories';

  async getCategoriesTree(params?: IRequestParamsHandler<IApiCategoriesTree>) {
    const url = `${this.VERSION}/${this.RESOURCE}/tree`;

    return this.get<Array<IApiCategoriesTree>>({
      url,
      query: params?.params,
      body: params?.data,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
      isRaw: params?.isRaw,
    });
  }

  async getCategory(slug: string, params?: IRequestParamsHandler<ICategoryFromTree>) {
    const url = `${this.VERSION}/${this.RESOURCE}/${slug}`;

    const responseAsyncData = await useAsyncData(
      url,
      () =>
        this.get<ICategoryFromTree>({
          url,
          query: params?.params,
          body: params?.data,
          fetchOptions: params?.fetchOptions,
          errorOptions: params?.errorOptions,
          isRaw: params?.isRaw,
        }),
      params?.asyncDataOptions,
    );

    return responseAsyncData;
  }
}

export default CategoriesApiV1;
