export const globalComponentManagementState = () => {
  return {
    wiSubstrateArea: {
      activeIds: [],
    },
    isOpenLoginModal: false,
    isOpenVerifyPhoneModal: false,
    isOpenConfirmPhoneNumberModal: false,
    isOpenSupportModal: false,
    isCategoriesTree: false,
    isLoadingPreviousDelivery: false,
    previousDeliveryInfo: {
      incorrectAddress: true,
    },
  };
};
