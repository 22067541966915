<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="167" height="114" viewBox="0 0 167 114" fill="none">
    <rect
      x="82.8991"
      y="8.77422"
      width="84.8843"
      height="91.4144"
      rx="4.76852"
      transform="rotate(10 82.8991 8.77422)"
      fill="#2A2C33"
      stroke="#17181C"
      stroke-width="1.5"
    />
    <rect
      x="86.9736"
      y="14.5933"
      width="74.3357"
      height="64.7917"
      rx="4.76852"
      transform="rotate(10 86.9736 14.5933)"
      fill="#24252B"
      stroke="#24252B"
      stroke-width="1.5"
    />
    <rect
      x="0.550775"
      y="23.5142"
      width="84.8843"
      height="91.4144"
      rx="4.76852"
      transform="rotate(-10 0.550775 23.5142)"
      fill="#2A2C33"
      stroke="#17181C"
      stroke-width="1.5"
    />
    <rect
      x="6.36987"
      y="27.5891"
      width="74.3357"
      height="64.7917"
      rx="4.76852"
      transform="rotate(-10 6.36987 27.5891)"
      fill="#24252B"
      stroke="#24252B"
      stroke-width="1.5"
    />
    <rect
      x="35.5042"
      y="1.22314"
      width="101.317"
      height="109.134"
      rx="5.56046"
      fill="#2A2C33"
      stroke="#17181C"
      stroke-width="1.5"
    />
    <rect
      opacity="0.5"
      x="41.5172"
      y="7.23633"
      width="88.6895"
      height="77.2647"
      rx="5.56046"
      :fill="`url(#paint0_linear_30604_24351-${id})`"
      stroke="#1A1A1F"
      stroke-width="1.5"
    />
    <mask id="path-7-inside-1_30604_24351" fill="white">
      <path
        d="M64.9514 40.8624H64.6143C64.3614 40.8624 64.1929 40.7859 64.1929 40.6711C64.1929 40.5563 64.3614 40.4797 64.6143 40.4797H64.9514C65.2043 40.4797 65.3728 40.5563 65.3728 40.6711C65.3728 40.7859 65.2043 40.8624 64.9514 40.8624Z"
      />
    </mask>
    <path
      d="M64.9514 40.8624H64.6143C64.3614 40.8624 64.1929 40.7859 64.1929 40.6711C64.1929 40.5563 64.3614 40.4797 64.6143 40.4797H64.9514C65.2043 40.4797 65.3728 40.5563 65.3728 40.6711C65.3728 40.7859 65.2043 40.8624 64.9514 40.8624Z"
      fill="#121317"
    />
    <path
      d="M64.9514 39.9168H64.6143V41.808H64.9514V39.9168ZM64.6143 39.9168C64.566 39.9168 64.6078 39.907 64.6997 39.9487C64.748 39.9707 64.8483 40.0244 64.9447 40.1382C65.0513 40.264 65.1385 40.4491 65.1385 40.6711H63.2472C63.2472 41.2748 63.7021 41.5729 63.9178 41.6708C64.1572 41.7795 64.4097 41.808 64.6143 41.808V39.9168ZM65.1385 40.6711C65.1385 40.8931 65.0513 41.0782 64.9447 41.204C64.8483 41.3178 64.748 41.3715 64.6997 41.3934C64.6078 41.4351 64.566 41.4254 64.6143 41.4254V39.5341C64.4097 39.5341 64.1572 39.5626 63.9178 39.6713C63.7021 39.7693 63.2472 40.0673 63.2472 40.6711H65.1385ZM64.6143 41.4254H64.9514V39.5341H64.6143V41.4254ZM64.9514 41.4254C64.9997 41.4254 64.9579 41.4351 64.866 41.3934C64.8177 41.3715 64.7174 41.3178 64.621 41.204C64.5144 41.0782 64.4272 40.8931 64.4272 40.6711H66.3185C66.3185 40.0673 65.8636 39.7693 65.6479 39.6713C65.4085 39.5626 65.156 39.5341 64.9514 39.5341V41.4254ZM64.4272 40.6711C64.4272 40.4491 64.5144 40.264 64.621 40.1382C64.7174 40.0244 64.8177 39.9707 64.866 39.9487C64.9579 39.907 64.9997 39.9168 64.9514 39.9168V41.808C65.156 41.808 65.4085 41.7795 65.6479 41.6708C65.8636 41.5729 66.3185 41.2748 66.3185 40.6711H64.4272Z"
      fill="#24252B"
      mask="url(#path-7-inside-1_30604_24351)"
    />
    <mask id="path-9-inside-2_30604_24351" fill="white">
      <path
        d="M64.9514 42.3932H64.6143C64.3614 42.3932 64.1929 42.3166 64.1929 42.2018C64.1929 42.087 64.3614 42.0105 64.6143 42.0105H64.9514C65.2043 42.0105 65.3728 42.087 65.3728 42.2018C65.3728 42.3166 65.2043 42.3932 64.9514 42.3932Z"
      />
    </mask>
    <path
      d="M64.9514 42.3932H64.6143C64.3614 42.3932 64.1929 42.3166 64.1929 42.2018C64.1929 42.087 64.3614 42.0105 64.6143 42.0105H64.9514C65.2043 42.0105 65.3728 42.087 65.3728 42.2018C65.3728 42.3166 65.2043 42.3932 64.9514 42.3932Z"
      fill="#121317"
    />
    <path
      d="M64.9514 41.4475H64.6143V43.3388H64.9514V41.4475ZM64.6143 41.4475C64.566 41.4475 64.6078 41.4378 64.6997 41.4795C64.748 41.5014 64.8483 41.5551 64.9447 41.6689C65.0513 41.7947 65.1385 41.9798 65.1385 42.2018H63.2472C63.2472 42.8056 63.7021 43.1036 63.9178 43.2016C64.1572 43.3103 64.4097 43.3388 64.6143 43.3388V41.4475ZM65.1385 42.2018C65.1385 42.4238 65.0513 42.6089 64.9447 42.7347C64.8483 42.8485 64.748 42.9022 64.6997 42.9242C64.6078 42.9659 64.566 42.9561 64.6143 42.9561V41.0649C64.4097 41.0649 64.1572 41.0934 63.9178 41.2021C63.7021 41.3 63.2472 41.5981 63.2472 42.2018H65.1385ZM64.6143 42.9561H64.9514V41.0649H64.6143V42.9561ZM64.9514 42.9561C64.9997 42.9561 64.9579 42.9659 64.866 42.9242C64.8177 42.9022 64.7174 42.8485 64.621 42.7347C64.5144 42.6089 64.4272 42.4238 64.4272 42.2018H66.3185C66.3185 41.5981 65.8636 41.3 65.6479 41.2021C65.4085 41.0934 65.156 41.0649 64.9514 41.0649V42.9561ZM64.4272 42.2018C64.4272 41.9798 64.5144 41.7947 64.621 41.6689C64.7174 41.5551 64.8177 41.5014 64.866 41.4795C64.9579 41.4378 64.9997 41.4475 64.9514 41.4475V43.3388C65.156 43.3388 65.4085 43.3103 65.6479 43.2016C65.8636 43.1036 66.3185 42.8056 66.3185 42.2018H64.4272Z"
      fill="#24252B"
      mask="url(#path-9-inside-2_30604_24351)"
    />
    <mask id="path-11-inside-3_30604_24351" fill="white">
      <path
        d="M64.9514 43.9237H64.6143C64.3614 43.9237 64.1929 43.8471 64.1929 43.7323C64.1929 43.6175 64.3614 43.541 64.6143 43.541H64.9514C65.2043 43.541 65.3728 43.6175 65.3728 43.7323C65.3728 43.8471 65.2043 43.9237 64.9514 43.9237Z"
      />
    </mask>
    <path
      d="M64.9514 43.9237H64.6143C64.3614 43.9237 64.1929 43.8471 64.1929 43.7323C64.1929 43.6175 64.3614 43.541 64.6143 43.541H64.9514C65.2043 43.541 65.3728 43.6175 65.3728 43.7323C65.3728 43.8471 65.2043 43.9237 64.9514 43.9237Z"
      fill="#121317"
    />
    <path
      d="M64.9514 42.978H64.6143V44.8693H64.9514V42.978ZM64.6143 42.978C64.566 42.978 64.6078 42.9683 64.6997 43.01C64.748 43.032 64.8483 43.0856 64.9447 43.1994C65.0513 43.3252 65.1385 43.5103 65.1385 43.7323H63.2472C63.2472 44.3361 63.7021 44.6341 63.9178 44.7321C64.1572 44.8408 64.4097 44.8693 64.6143 44.8693V42.978ZM65.1385 43.7323C65.1385 43.9544 65.0513 44.1395 64.9447 44.2653C64.8483 44.379 64.748 44.4327 64.6997 44.4547C64.6078 44.4964 64.566 44.4866 64.6143 44.4866V42.5954C64.4097 42.5954 64.1572 42.6239 63.9178 42.7326C63.7021 42.8305 63.2472 43.1286 63.2472 43.7323H65.1385ZM64.6143 44.4866H64.9514V42.5954H64.6143V44.4866ZM64.9514 44.4866C64.9997 44.4866 64.9579 44.4964 64.866 44.4547C64.8177 44.4327 64.7174 44.379 64.621 44.2653C64.5144 44.1395 64.4272 43.9544 64.4272 43.7323H66.3185C66.3185 43.1286 65.8636 42.8305 65.6479 42.7326C65.4085 42.6239 65.156 42.5954 64.9514 42.5954V44.4866ZM64.4272 43.7323C64.4272 43.5103 64.5144 43.3252 64.621 43.1994C64.7174 43.0856 64.8177 43.032 64.866 43.01C64.9579 42.9683 64.9997 42.978 64.9514 42.978V44.8693C65.156 44.8693 65.4085 44.8408 65.6479 44.7321C65.8636 44.6341 66.3185 44.3361 66.3185 43.7323H64.4272Z"
      fill="#24252B"
      mask="url(#path-11-inside-3_30604_24351)"
    />
    <mask id="path-13-inside-4_30604_24351" fill="white">
      <path
        d="M64.9514 45.4544H64.6143C64.3614 45.4544 64.1929 45.3779 64.1929 45.2631C64.1929 45.1483 64.3614 45.0718 64.6143 45.0718H64.9514C65.2043 45.0718 65.3728 45.1483 65.3728 45.2631C65.3728 45.3779 65.2043 45.4544 64.9514 45.4544Z"
      />
    </mask>
    <path
      d="M64.9514 45.4544H64.6143C64.3614 45.4544 64.1929 45.3779 64.1929 45.2631C64.1929 45.1483 64.3614 45.0718 64.6143 45.0718H64.9514C65.2043 45.0718 65.3728 45.1483 65.3728 45.2631C65.3728 45.3779 65.2043 45.4544 64.9514 45.4544Z"
      fill="#121317"
    />
    <path
      d="M64.9514 44.5088H64.6143V46.4001H64.9514V44.5088ZM64.6143 44.5088C64.566 44.5088 64.6078 44.4991 64.6997 44.5408C64.748 44.5627 64.8483 44.6164 64.9447 44.7302C65.0513 44.856 65.1385 45.0411 65.1385 45.2631H63.2472C63.2472 45.8669 63.7021 46.1649 63.9178 46.2629C64.1572 46.3715 64.4097 46.4001 64.6143 46.4001V44.5088ZM65.1385 45.2631C65.1385 45.4851 65.0513 45.6702 64.9447 45.796C64.8483 45.9098 64.748 45.9635 64.6997 45.9854C64.6078 46.0272 64.566 46.0174 64.6143 46.0174V44.1261C64.4097 44.1261 64.1572 44.1547 63.9178 44.2633C63.7021 44.3613 63.2472 44.6593 63.2472 45.2631H65.1385ZM64.6143 46.0174H64.9514V44.1261H64.6143V46.0174ZM64.9514 46.0174C64.9997 46.0174 64.9579 46.0272 64.866 45.9854C64.8177 45.9635 64.7174 45.9098 64.621 45.796C64.5144 45.6702 64.4272 45.4851 64.4272 45.2631H66.3185C66.3185 44.6593 65.8636 44.3613 65.6479 44.2633C65.4085 44.1547 65.156 44.1261 64.9514 44.1261V46.0174ZM64.4272 45.2631C64.4272 45.0411 64.5144 44.856 64.621 44.7302C64.7174 44.6164 64.8177 44.5627 64.866 44.5408C64.9579 44.4991 64.9997 44.5088 64.9514 44.5088V46.4001C65.156 46.4001 65.4085 46.3715 65.6479 46.2629C65.8636 46.1649 66.3185 45.8669 66.3185 45.2631H64.4272Z"
      fill="#24252B"
      mask="url(#path-13-inside-4_30604_24351)"
    />
    <mask id="path-15-inside-5_30604_24351" fill="white">
      <path
        d="M80.3376 40.8624H74.7937C74.569 40.8624 74.4191 40.7859 74.4191 40.6711C74.4191 40.5563 74.569 40.4797 74.7937 40.4797H80.3376C80.5624 40.4797 80.7122 40.5563 80.7122 40.6711C80.7122 40.7859 80.5624 40.8624 80.3376 40.8624Z"
      />
    </mask>
    <path
      d="M80.3376 40.8624H74.7937C74.569 40.8624 74.4191 40.7859 74.4191 40.6711C74.4191 40.5563 74.569 40.4797 74.7937 40.4797H80.3376C80.5624 40.4797 80.7122 40.5563 80.7122 40.6711C80.7122 40.7859 80.5624 40.8624 80.3376 40.8624Z"
      fill="#121317"
    />
    <path
      d="M80.3376 39.9168H74.7937V41.808H80.3376V39.9168ZM74.7937 39.9168C74.7715 39.9168 74.8403 39.9104 74.9523 39.9676C75.0668 40.0261 75.3648 40.2425 75.3648 40.6711H73.4735C73.4735 41.2145 73.8464 41.5265 74.092 41.6519C74.3351 41.7761 74.5912 41.808 74.7937 41.808V39.9168ZM75.3648 40.6711C75.3648 41.0997 75.0668 41.316 74.9523 41.3745C74.8403 41.4317 74.7715 41.4254 74.7937 41.4254V39.5341C74.5912 39.5341 74.3351 39.566 74.092 39.6902C73.8464 39.8157 73.4735 40.1277 73.4735 40.6711H75.3648ZM74.7937 41.4254H80.3376V39.5341H74.7937V41.4254ZM80.3376 41.4254C80.3599 41.4254 80.2911 41.4317 80.1791 41.3745C80.0645 41.316 79.7666 41.0997 79.7666 40.6711H81.6578C81.6578 40.1277 81.285 39.8157 81.0393 39.6902C80.7962 39.566 80.5401 39.5341 80.3376 39.5341V41.4254ZM79.7666 40.6711C79.7666 40.2425 80.0645 40.0261 80.1791 39.9676C80.2911 39.9104 80.3599 39.9168 80.3376 39.9168V41.808C80.5401 41.808 80.7962 41.7761 81.0393 41.6519C81.285 41.5265 81.6578 41.2145 81.6578 40.6711H79.7666Z"
      fill="#24252B"
      mask="url(#path-15-inside-5_30604_24351)"
    />
    <mask id="path-17-inside-6_30604_24351" fill="white">
      <path
        d="M92.1499 45.4544H74.781C74.5639 45.4544 74.4191 45.3779 74.4191 45.2631C74.4191 45.1483 74.5639 45.0718 74.781 45.0718H92.1499C92.367 45.0718 92.5117 45.1483 92.5117 45.2631C92.5117 45.3779 92.367 45.4544 92.1499 45.4544Z"
      />
    </mask>
    <path
      d="M92.1499 45.4544H74.781C74.5639 45.4544 74.4191 45.3779 74.4191 45.2631C74.4191 45.1483 74.5639 45.0718 74.781 45.0718H92.1499C92.367 45.0718 92.5117 45.1483 92.5117 45.2631C92.5117 45.3779 92.367 45.4544 92.1499 45.4544Z"
      :fill="`url(#paint1_linear_30604_24351-${id})`"
    />
    <path
      d="M92.1499 44.5088H74.781V46.4001H92.1499V44.5088ZM74.781 44.5088C74.7815 44.5088 74.785 44.5088 74.7913 44.5094C74.7975 44.51 74.8077 44.5113 74.8213 44.514C74.8458 44.519 74.8964 44.5319 74.9607 44.5659C75.0864 44.6323 75.3648 44.8518 75.3648 45.2631H73.4735C73.4735 45.7892 73.8242 46.1043 74.0766 46.2378C74.3208 46.3669 74.5784 46.4001 74.781 46.4001V44.5088ZM75.3648 45.2631C75.3648 45.6744 75.0864 45.8939 74.9607 45.9604C74.8964 45.9943 74.8458 46.0072 74.8213 46.0122C74.8077 46.015 74.7975 46.0162 74.7913 46.0168C74.785 46.0174 74.7815 46.0174 74.781 46.0174V44.1261C74.5784 44.1261 74.3208 44.1593 74.0766 44.2884C73.8242 44.4219 73.4735 44.737 73.4735 45.2631H75.3648ZM74.781 46.0174H92.1499V44.1261H74.781V46.0174ZM92.1499 46.0174C92.1494 46.0174 92.1459 46.0174 92.1396 46.0168C92.1333 46.0162 92.1232 46.015 92.1096 46.0122C92.0851 46.0072 92.0344 45.9943 91.9702 45.9604C91.8445 45.8939 91.5661 45.6744 91.5661 45.2631H93.4574C93.4574 44.737 93.1066 44.4219 92.8543 44.2884C92.6101 44.1593 92.3525 44.1261 92.1499 44.1261V46.0174ZM91.5661 45.2631C91.5661 44.8518 91.8445 44.6323 91.9702 44.5659C92.0344 44.5319 92.0851 44.519 92.1096 44.514C92.1232 44.5113 92.1333 44.51 92.1396 44.5094C92.1459 44.5088 92.1494 44.5088 92.1499 44.5088V46.4001C92.3525 46.4001 92.6101 46.3669 92.8543 46.2378C93.1066 46.1043 93.4574 45.7892 93.4574 45.2631H91.5661Z"
      fill="#24252B"
      mask="url(#path-17-inside-6_30604_24351)"
    />
    <mask id="path-19-inside-7_30604_24351" fill="white">
      <path
        d="M80.3593 42.3932H72.8056C72.5938 42.3932 72.4526 42.3166 72.4526 42.2018C72.4526 42.087 72.5938 42.0105 72.8056 42.0105H80.3593C80.5711 42.0105 80.7123 42.087 80.7123 42.2018C80.7123 42.3166 80.5711 42.3932 80.3593 42.3932Z"
      />
    </mask>
    <path
      d="M80.3593 42.3932H72.8056C72.5938 42.3932 72.4526 42.3166 72.4526 42.2018C72.4526 42.087 72.5938 42.0105 72.8056 42.0105H80.3593C80.5711 42.0105 80.7123 42.087 80.7123 42.2018C80.7123 42.3166 80.5711 42.3932 80.3593 42.3932Z"
      fill="#121317"
    />
    <path
      d="M80.3593 41.4475H72.8056V43.3388H80.3593V41.4475ZM72.8056 41.4475C72.8082 41.4475 72.8251 41.4477 72.854 41.4537C72.8813 41.4594 72.9343 41.4734 73.0003 41.5092C73.1334 41.5813 73.3982 41.8028 73.3982 42.2018H71.5069C71.5069 42.7156 71.8424 43.0328 72.099 43.1719C72.344 43.3047 72.6027 43.3388 72.8056 43.3388V41.4475ZM73.3982 42.2018C73.3982 42.6009 73.1334 42.8223 73.0003 42.8945C72.9343 42.9302 72.8813 42.9443 72.854 42.95C72.8251 42.956 72.8082 42.9561 72.8056 42.9561V41.0649C72.6027 41.0649 72.344 41.099 72.099 41.2318C71.8424 41.3709 71.5069 41.688 71.5069 42.2018H73.3982ZM72.8056 42.9561H80.3593V41.0649H72.8056V42.9561ZM80.3593 42.9561C80.3566 42.9561 80.3397 42.956 80.3108 42.95C80.2835 42.9443 80.2305 42.9302 80.1645 42.8945C80.0314 42.8223 79.7666 42.6009 79.7666 42.2018H81.6579C81.6579 41.688 81.3225 41.3709 81.0658 41.2318C80.8209 41.099 80.5622 41.0649 80.3593 41.0649V42.9561ZM79.7666 42.2018C79.7666 41.8028 80.0314 41.5813 80.1645 41.5092C80.2305 41.4734 80.2835 41.4594 80.3108 41.4537C80.3397 41.4477 80.3566 41.4475 80.3593 41.4475V43.3388C80.5622 43.3388 80.8209 43.3047 81.0658 43.1719C81.3225 43.0328 81.6579 42.7156 81.6579 42.2018H79.7666Z"
      fill="#24252B"
      mask="url(#path-19-inside-7_30604_24351)"
    />
    <mask id="path-21-inside-8_30604_24351" fill="white">
      <path
        d="M86.2598 43.9237H72.8048C72.5935 43.9237 72.4526 43.8471 72.4526 43.7323C72.4526 43.6175 72.5935 43.541 72.8048 43.541H86.2598C86.4711 43.541 86.612 43.6175 86.612 43.7323C86.5416 43.8471 86.4007 43.9237 86.2598 43.9237Z"
      />
    </mask>
    <path
      d="M86.2598 43.9237H72.8048C72.5935 43.9237 72.4526 43.8471 72.4526 43.7323C72.4526 43.6175 72.5935 43.541 72.8048 43.541H86.2598C86.4711 43.541 86.612 43.6175 86.612 43.7323C86.5416 43.8471 86.4007 43.9237 86.2598 43.9237Z"
      fill="#121317"
    />
    <path
      d="M86.612 43.7323L87.418 44.2269L87.5577 43.9994V43.7323H86.612ZM86.2598 42.978H72.8048V44.8693H86.2598V42.978ZM72.8048 42.978C72.8077 42.978 72.8249 42.9782 72.854 42.9843C72.8815 42.99 72.9347 43.0042 73.0008 43.0401C73.1345 43.1127 73.3982 43.3344 73.3982 43.7323H71.5069C71.5069 44.2451 71.8411 44.5624 72.0981 44.702C72.3431 44.8351 72.6019 44.8693 72.8048 44.8693V42.978ZM73.3982 43.7323C73.3982 44.1303 73.1345 44.3519 73.0008 44.4246C72.9347 44.4605 72.8815 44.4747 72.854 44.4804C72.8249 44.4865 72.8077 44.4866 72.8048 44.4866V42.5954C72.6019 42.5954 72.3431 42.6296 72.0981 42.7627C71.8411 42.9023 71.5069 43.2196 71.5069 43.7323H73.3982ZM72.8048 44.4866H86.2598V42.5954H72.8048V44.4866ZM86.2598 44.4866C86.2569 44.4866 86.2397 44.4865 86.2106 44.4804C86.1831 44.4747 86.1299 44.4605 86.0638 44.4246C85.9301 44.3519 85.6664 44.1303 85.6664 43.7323H87.5577C87.5577 43.2196 87.2235 42.9023 86.9665 42.7627C86.7215 42.6296 86.4627 42.5954 86.2598 42.5954V44.4866ZM85.806 43.2378C85.8672 43.138 85.9442 43.0763 86.0109 43.0401C86.0783 43.0035 86.1621 42.978 86.2598 42.978V44.8693C86.7111 44.8693 87.1662 44.6373 87.418 44.2269L85.806 43.2378Z"
      fill="#24252B"
      mask="url(#path-21-inside-8_30604_24351)"
    />
    <mask id="path-23-inside-9_30604_24351" fill="white">
      <path
        d="M92.1287 43.9237H86.9951C86.7652 43.9237 86.612 43.8471 86.612 43.7323C86.612 43.6175 86.7652 43.541 86.9951 43.541H92.1287C92.3585 43.541 92.5118 43.6175 92.5118 43.7323C92.5118 43.8471 92.3585 43.9237 92.1287 43.9237Z"
      />
    </mask>
    <path
      d="M92.1287 43.9237H86.9951C86.7652 43.9237 86.612 43.8471 86.612 43.7323C86.612 43.6175 86.7652 43.541 86.9951 43.541H92.1287C92.3585 43.541 92.5118 43.6175 92.5118 43.7323C92.5118 43.8471 92.3585 43.9237 92.1287 43.9237Z"
      fill="#121317"
    />
    <path
      d="M92.1287 42.978H86.9951V44.8693H92.1287V42.978ZM86.9951 42.978C86.9679 42.978 87.0315 42.971 87.1399 43.0251C87.2465 43.0783 87.5576 43.2924 87.5576 43.7323H85.6664C85.6664 44.2871 86.0541 44.5968 86.2948 44.7171C86.5372 44.8381 86.7925 44.8693 86.9951 44.8693V42.978ZM87.5576 43.7323C87.5576 44.1723 87.2465 44.3864 87.1399 44.4396C87.0315 44.4937 86.9679 44.4866 86.9951 44.4866V42.5954C86.7925 42.5954 86.5372 42.6266 86.2948 42.7476C86.0541 42.8679 85.6664 43.1776 85.6664 43.7323H87.5576ZM86.9951 44.4866H92.1287V42.5954H86.9951V44.4866ZM92.1287 44.4866C92.1559 44.4866 92.0922 44.4937 91.9839 44.4396C91.8772 44.3864 91.5661 44.1723 91.5661 43.7323H93.4574C93.4574 43.1776 93.0697 42.8679 92.8289 42.7476C92.5865 42.6266 92.3313 42.5954 92.1287 42.5954V44.4866ZM91.5661 43.7323C91.5661 43.2924 91.8772 43.0783 91.9839 43.0251C92.0922 42.971 92.1559 42.978 92.1287 42.978V44.8693C92.3313 44.8693 92.5865 44.8381 92.8289 44.7171C93.0697 44.5968 93.4574 44.2871 93.4574 43.7323H91.5661Z"
      fill="#24252B"
      mask="url(#path-23-inside-9_30604_24351)"
    />
    <mask id="path-25-inside-10_30604_24351" fill="white">
      <path
        d="M95.2862 43.9237H93.2771C93.0539 43.9237 92.905 43.8471 92.905 43.7323C92.905 43.6175 93.0539 43.541 93.2771 43.541H95.2862C95.5094 43.541 95.6583 43.6175 95.6583 43.7323C95.6583 43.8471 95.5094 43.9237 95.2862 43.9237Z"
      />
    </mask>
    <path
      d="M95.2862 43.9237H93.2771C93.0539 43.9237 92.905 43.8471 92.905 43.7323C92.905 43.6175 93.0539 43.541 93.2771 43.541H95.2862C95.5094 43.541 95.6583 43.6175 95.6583 43.7323C95.6583 43.8471 95.5094 43.9237 95.2862 43.9237Z"
      :fill="`url(#paint2_linear_30604_24351-${id})`"
    />
    <path
      d="M95.2862 42.978H93.2771V44.8693H95.2862V42.978ZM93.2771 42.978C93.2564 42.978 93.3267 42.9719 93.4398 43.0301C93.5566 43.0902 93.8507 43.3071 93.8507 43.7323H91.9594C91.9594 44.2723 92.3279 44.585 92.5749 44.712C92.8182 44.8371 93.0746 44.8693 93.2771 44.8693V42.978ZM93.8507 43.7323C93.8507 44.1575 93.5566 44.3745 93.4398 44.4346C93.3267 44.4928 93.2564 44.4866 93.2771 44.4866V42.5954C93.0746 42.5954 92.8182 42.6275 92.5749 42.7527C92.3279 42.8797 91.9594 43.1923 91.9594 43.7323H93.8507ZM93.2771 44.4866H95.2862V42.5954H93.2771V44.4866ZM95.2862 44.4866C95.3069 44.4866 95.2366 44.4928 95.1235 44.4346C95.0067 44.3745 94.7126 44.1575 94.7126 43.7323H96.6039C96.6039 43.1923 96.2354 42.8797 95.9884 42.7527C95.7451 42.6275 95.4887 42.5954 95.2862 42.5954V44.4866ZM94.7126 43.7323C94.7126 43.3071 95.0067 43.0902 95.1235 43.0301C95.2366 42.9719 95.3069 42.978 95.2862 42.978V44.8693C95.4887 44.8693 95.7451 44.8371 95.9884 44.712C96.2354 44.585 96.6039 44.2723 96.6039 43.7323H94.7126Z"
      fill="#24252B"
      mask="url(#path-25-inside-10_30604_24351)"
    />
    <path
      d="M98.4115 40.4797V64.9699H62.7233C61.3155 64.9699 60.2596 63.8692 60.2596 62.5622V40.4797H98.4115Z"
      fill="#2B2C33"
      stroke="#1B1C21"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M112.098 40.9526V62.5622C112.098 63.6402 111.174 64.4971 110.003 64.4971H98.8844V40.9526H112.098Z"
      fill="#282930"
      stroke="#24252B"
      stroke-width="0.945633"
    />
    <path
      d="M112.571 40.4797V62.5622C112.571 63.938 111.397 64.9699 110.003 64.9699H98.4116V40.4797H112.571Z"
      fill="#26272E"
      stroke="#1B1C21"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M112.075 40.007H74.8998L81.7861 29.4728H119.156L112.075 40.007Z"
      fill="#121317"
      stroke="#24252B"
      stroke-width="0.945633"
    />
    <path
      d="M74.0258 40.4798L81.5303 29H120.044L112.327 40.4798H74.0258Z"
      fill="#26272E"
      stroke="#1B1C21"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M112.274 40.4797H98.4116L105.451 50.8597C105.953 51.5471 106.744 51.9595 107.534 51.9595H118.308C119.026 51.9595 119.529 51.1346 119.098 50.5847L112.274 40.4797Z"
      fill="#2B2C33"
      stroke="#1B1C21"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M98.4115 40.4798L90.8545 29H52L59.8422 40.4798H98.4115Z"
      fill="#2B2C33"
      stroke="#1B1C21"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M79.3 54.1654H64.4251C64.3676 54.1654 64.3258 54.1446 64.3017 54.1224C64.2784 54.1011 64.2724 54.0808 64.2724 54.0642C64.2724 54.0476 64.2784 54.0274 64.3017 54.006C64.3258 53.9839 64.3676 53.9631 64.4251 53.9631H79.3C79.3576 53.9631 79.3993 53.9839 79.4235 54.006C79.433 54.0148 79.4397 54.0234 79.4442 54.0316C79.425 54.0841 79.4018 54.1175 79.3837 54.1355C79.3723 54.1468 79.3623 54.1529 79.3531 54.1568C79.3441 54.1605 79.3279 54.1654 79.3 54.1654Z"
      stroke="#1B1C21"
      stroke-width="0.945633"
    />
    <mask id="path-35-inside-11_30604_24351" fill="white">
      <path
        d="M79.3 57.3168H64.4251C64.0776 57.3168 63.7996 57.1468 63.7996 56.9342C63.7996 56.7216 64.0776 56.5515 64.4251 56.5515H79.3C79.6476 56.5515 79.9256 56.7216 79.9256 56.9342C79.8561 57.1468 79.6476 57.3168 79.3 57.3168Z"
      />
    </mask>
    <path
      d="M79.9256 56.9342L80.8244 57.228L80.8712 57.0848V56.9342H79.9256ZM79.3 56.3712H64.4251V58.2625H79.3V56.3712ZM64.4251 56.3712C64.3944 56.3712 64.4154 56.3618 64.4755 56.3985C64.5221 56.4271 64.7452 56.5904 64.7452 56.9342H62.8539C62.8539 57.4906 63.216 57.8452 63.4886 58.0119C63.7746 58.1868 64.1084 58.2625 64.4251 58.2625V56.3712ZM64.7452 56.9342C64.7452 57.278 64.5221 57.4413 64.4755 57.4698C64.4154 57.5066 64.3944 57.4971 64.4251 57.4971V55.6059C64.1084 55.6059 63.7746 55.6815 63.4886 55.8564C63.216 56.0232 62.8539 56.3778 62.8539 56.9342H64.7452ZM64.4251 57.4971H79.3V55.6059H64.4251V57.4971ZM79.3 57.4971C79.3308 57.4971 79.3098 57.5066 79.2497 57.4698C79.203 57.4413 78.98 57.278 78.98 56.9342H80.8712C80.8712 56.3778 80.5091 56.0232 80.2366 55.8564C79.9506 55.6815 79.6168 55.6059 79.3 55.6059V57.4971ZM79.0268 56.6403C79.0574 56.5467 79.1191 56.4671 79.1947 56.417C79.2688 56.3678 79.3183 56.3712 79.3 56.3712V58.2625C79.989 58.2625 80.608 57.89 80.8244 57.228L79.0268 56.6403Z"
      fill="#1B1C21"
      mask="url(#path-35-inside-11_30604_24351)"
    />
    <mask id="path-37-inside-12_30604_24351" fill="white">
      <path
        d="M71.4239 60.3781H64.4349C64.0819 60.3781 63.7996 60.208 63.7996 59.9955C63.7996 59.7829 64.0819 59.6128 64.4349 59.6128H71.4239C71.7769 59.6128 72.0592 59.7829 72.0592 59.9955C71.9886 60.208 71.7063 60.3781 71.4239 60.3781Z"
      />
    </mask>
    <path
      d="M72.0592 59.9955L72.9567 60.2935L73.0049 60.1484V59.9955H72.0592ZM71.4239 59.4325H64.4349V61.3237H71.4239V59.4325ZM64.4349 59.4325C64.3988 59.4325 64.4154 59.4219 64.4728 59.4564C64.5146 59.4817 64.7452 59.6444 64.7452 59.9955H62.8539C62.8539 60.5591 63.2257 60.9132 63.497 61.0766C63.7838 61.2493 64.118 61.3237 64.4349 61.3237V59.4325ZM64.7452 59.9955C64.7452 60.3465 64.5146 60.5092 64.4728 60.5345C64.4154 60.569 64.3988 60.5584 64.4349 60.5584V58.6672C64.118 58.6672 63.7838 58.7416 63.497 58.9143C63.2257 59.0777 62.8539 59.4318 62.8539 59.9955H64.7452ZM64.4349 60.5584H71.4239V58.6672H64.4349V60.5584ZM71.4239 60.5584C71.46 60.5584 71.4434 60.569 71.386 60.5345C71.3442 60.5092 71.1136 60.3465 71.1136 59.9955H73.0049C73.0049 59.4318 72.6331 59.0777 72.3618 58.9143C72.075 58.7416 71.7408 58.6672 71.4239 58.6672V60.5584ZM71.1618 59.6974C71.1914 59.6082 71.2343 59.549 71.2636 59.5161C71.2933 59.4828 71.3188 59.4651 71.3331 59.4564C71.3568 59.4422 71.383 59.4325 71.4239 59.4325V61.3237C71.7471 61.3237 72.0557 61.229 72.3089 61.0766C72.5517 60.9303 72.8295 60.6763 72.9567 60.2935L71.1618 59.6974Z"
      fill="#1B1C21"
      mask="url(#path-37-inside-12_30604_24351)"
    />
    <path d="M55.7496 70.365H79.0796" stroke="#1B1C21" stroke-width="1.5" stroke-linecap="round" />
    <path d="M43.0625 65H129.661" stroke="#1B1C21" stroke-width="1.5" stroke-linecap="square" />
    <path d="M72.6861 74.0403H87.7101" stroke="#1B1C21" stroke-width="1.5" stroke-linecap="round" />
    <path d="M61.5022 74.2H70.4507" stroke="#1B1C21" stroke-width="1.5" stroke-linecap="round" />
    <path d="M102.409 70.6848H109.76" stroke="#1B1C21" stroke-width="1.5" stroke-linecap="round" />
    <defs>
      <linearGradient
        :id="`paint0_linear_30604_24351-${id}`"
        x1="48.2679"
        y1="8.12497"
        x2="117.83"
        y2="83.8392"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#1A1A1F" />
        <stop offset="1" stop-color="#2A2C33" />
      </linearGradient>
      <linearGradient
        :id="`paint1_linear_30604_24351-${id}`"
        x1="74.5004"
        y1="45.2709"
        x2="92.5327"
        y2="45.2709"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF66A9" />
        <stop offset="1" stop-color="#F53689" />
      </linearGradient>
      <linearGradient
        :id="`paint2_linear_30604_24351-${id}`"
        x1="92.9234"
        y1="43.7206"
        x2="95.6577"
        y2="43.7206"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#83A6FF" />
        <stop offset="1" stop-color="#5A78FF" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script lang="ts">
import { generateUid } from '~/shared/utils/string/generateUid';

export default defineNuxtComponent({
  name: 'NoPhotoProductIconDark',

  setup() {
    const id = generateUid();

    return {
      id,
    };
  },
});
</script>
