import { roundDown } from './roundDown';

export const roundDownWithPostfix = (currentSum: number) => {
  const result = {
    postfix: '',
    sum: currentSum,
  };

  if (currentSum < 1000) {
    return result;
  }

  if (Number.isInteger(currentSum / 1000)) {
    result.sum = roundDown(currentSum);
    result.postfix = 'к';
  }

  if (result.sum < 1000) {
    return result;
  }

  if (Number.isInteger(result.sum / 1000)) {
    result.sum = roundDown(result.sum);
    result.postfix = 'млн';
  }

  return result;
};
