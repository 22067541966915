import WiNuxtErrorBoundary from '~/shared/ui/WiNuxtErrorBoundary/index.vue';

import { Icons } from '@wikkeo/ui-kit';

// @ts-ignore
const { WiChevron } = Icons;

import type { IApiCategoriesTree } from '~/shared/api/v1/categories/types';

export default defineNuxtComponent({
  name: 'WiHeaderCategoryContent',

  components: {
    WiChevron,
    WiNuxtErrorBoundary,
  },

  props: {
    categories: {
      type: Array as PropType<Array<IApiCategoriesTree>>,
      default: () => [],
    },

    showCategory: {
      type: Object as PropType<any>,
      required: true,
    },
  },

  emits: ['showSubCategories', 'onGoToCategory'],

  setup(_, { emit }) {
    const subcategoryRefs = ref({}) as any;

    const showSubCategories = (data: any) => {
      emit('showSubCategories', data);
    };

    const getMoreChildCategories = (event: Event, refElement: string, className: string) => {
      const elem = subcategoryRefs.value[refElement];

      if (!elem) {
        return;
      }

      elem.classList.remove(className);

      if (event.target) {
        (event.target as Element).classList.add('hidden');
      }
    };

    const handlerErrorIconCategory = (event: Event) => {
      (event.target as any)?.remove();
    };

    const getImageCategoryHref = (slug: string) => {
      return `/images/categories/${slug}.svg`;
    };

    const goToCategory = (slug: string) => {
      emit('onGoToCategory', slug);
    };

    return {
      subcategoryRefs,

      showSubCategories,
      getMoreChildCategories,
      handlerErrorIconCategory,
      getImageCategoryHref,

      goToCategory,
    };
  },
});
