import { checkIsServer } from './isServer';

export const checkIsMobile = () => {
  if (checkIsServer()) {
    return false;
  }

  return window
    ? window.innerWidth < 991
    : /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};
