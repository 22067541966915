import ErrorBrowserApi from '../errors/ErrorBrowserApi';
import { checkIsServer } from './isServer';

export enum ProviderEnum {
  local,
  memory,
  session,
  notAllowed,
}

export enum StorageKeyEnum {
  AccessToken,
  RefreshToken,
  User,
  Theme,
  TimerPhoneSms,
  IsAcceptPrivate,
  CategoriesTreeWithDate,
  ViewedProducts,
  Cart,
  appliedFilters,
  WishlistIds,
  CookieAccepted,
  // legacy
  cartProducts,
  cartOrder,
  successCheck,
}

interface IStorageService {
  getItem<T>(key: string, parse?: boolean): T | null;
  setItem<T>(key: string, value: T): void;
  removeItem(key: string): void;
}

type TProviderName = keyof typeof ProviderEnum;

type TStorageKey = keyof typeof StorageKeyEnum;

interface IStorageServiceProvider extends IStorageService {}

const prefix = 'wik:';

export class StorageService implements IStorageService {
  #provider!: IStorageServiceProvider;
  #storagePrefix = prefix;

  public constructor(providerName?: TProviderName) {
    if (providerName) {
      this.setProvider(providerName);
    }
  }

  private checkProvider() {
    if (this.#provider) {
      return;
    }

    this.setProvider('notAllowed');
  }

  private getKey(key: TStorageKey) {
    return this.#storagePrefix + key;
  }

  public setProvider(providerName: TProviderName) {
    switch (providerName.toLowerCase()) {
      case 'local':
        try {
          this.#provider = new StorageServiceProviderLocal();
        } catch (error) {
          this.#provider = new StorageServiceProviderNotAllowed();
        }
        break;

      default:
        this.#provider = new StorageServiceProviderNotAllowed();
        break;
    }
  }

  public getItem<T>(key: TStorageKey, parse: boolean = true, withPrefix: boolean = true) {
    this.checkProvider();

    const requestKey = withPrefix ? this.getKey(key) : key;

    return this.#provider.getItem<T>(requestKey, parse);
  }

  public setItem<T>(key: TStorageKey, value: T) {
    this.checkProvider();
    return this.#provider.setItem(this.getKey(key), value);
  }

  public removeItem(key: TStorageKey, withPrefix: boolean = true) {
    this.checkProvider();

    const requestKey = withPrefix ? this.getKey(key) : key;

    return this.#provider.removeItem(requestKey);
  }

  public getProvider() {
    return this.#provider;
  }
}

class StorageServiceProviderNotAllowed implements IStorageServiceProvider {
  public getItem(_key: TStorageKey) {
    return null;
  }

  public setItem(_key: TStorageKey) {}

  public removeItem(_key: TStorageKey) {}
}

class StorageServiceProviderLocal implements IStorageServiceProvider {
  constructor() {
    if (checkIsServer()) {
      throw new ErrorBrowserApi();
    }
  }

  public getItem<T>(key: TStorageKey, parse: boolean = true): T | null {
    const item = localStorage.getItem(key);

    if (item === null) {
      return null;
    }

    try {
      return parse ? JSON.parse(item) : item;
    } catch (err) {
      console.error(err, item, key);
      return null;
    }
  }

  public setItem<T>(key: TStorageKey, value: T) {
    localStorage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value));
  }

  public removeItem(key: TStorageKey) {
    localStorage.removeItem(key);
  }
}
