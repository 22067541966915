<template>
  <div class="error-page">
    <div class="content">
      <img src="/public/images/errors/404.png" alt="404.png" loading="lazy" />

      <div class="error-page-text__wrapper">
        <span class="error-page-text"> {{ $t('errorPage.notFoundPage') }}, </span>

        <button @click="onClearError" class="error-page__clear-button">
          {{ $t('errorPage.backHomeQuestion') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="./index.ts" />
<style lang="scss" src="./index.scss" scoped />
