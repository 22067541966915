import type { Store } from 'pinia';
import { globalCartActions } from './actions';
import { globalCartState } from './state';
import type { IGlobalCartStore } from './types';

export type TCartStore = Store<'globalCartStore', IGlobalCartStore, typeof globalCartActions>;

export const globalCartStore = defineStore('globalCartStore', {
  state: () => globalCartState() as IGlobalCartStore,
  actions: globalCartActions,
});
