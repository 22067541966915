import { globalComponentManagementActions } from './actions';
import { globalComponentManagementGetters } from './getters';
import { globalComponentManagementState } from './state';

import type { IGlobalComponentManagementStore } from './types';
import type { IApiCategoriesTree } from '~/shared/api/v1/categories/types';

import { checkIsServer } from '~/shared/browserAPI/isServer';
import { StorageService } from '~/shared/browserAPI/storageService';

export const globalComponentManagementStore = defineStore('globalComponentManagementStore', {
  state: () => globalComponentManagementState() as IGlobalComponentManagementStore,
  actions: globalComponentManagementActions,
  getters: globalComponentManagementGetters,

  hydrate(state, _initialState) {
    let isCategoriesTreeInLocalStorage = false;
    const isServer = checkIsServer();

    const storageService = new StorageService();

    if (!isServer) {
      storageService.setProvider('local');
    }

    const categoriesLength = storageService.getItem<{ categories: Array<IApiCategoriesTree> }>('CategoriesTreeWithDate')
      ?.categories.length;

    if (categoriesLength) {
      isCategoriesTreeInLocalStorage = true;
    }

    state.isCategoriesTree = isCategoriesTreeInLocalStorage;
  },
});
