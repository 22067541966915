import type { FetchResponse } from 'ofetch';

import { EnumResponseCode } from './constants';
import ErrorUnauthorized from '../errors/ErrorUnauthorized';
import ErrorNetwork from '../errors/ErrorNetwork';
import ErrorNotFound from '../errors/ErrorNotFound';
import ErrorServer from '../errors/ErrorServer';
import ErrorForbidden from '../errors/ErrorForbidden';

export const convertError = (responseError: (FetchResponse<any> & FetchResponse<'json'>) | null | undefined) => {
  const { $i18n } = useNuxtApp();

  const arrayErrors = [];

  if (!responseError || responseError === undefined) {
    arrayErrors.push(
      new ErrorNetwork({
        message: $i18n.t('error.unknown'),
      }),
    );

    return arrayErrors;
  }

  const { errors } = responseError._data;

  if (responseError.status === EnumResponseCode.Unauthorized) {
    arrayErrors.push(
      new ErrorUnauthorized({
        message: $i18n.t('error.userNotLogin'),
        errors,
      }),
    );

    return arrayErrors;
  }

  if (responseError.status === EnumResponseCode.NotFound) {
    arrayErrors.push(
      new ErrorNotFound({
        message: $i18n.t('error.notFound'),
        errors,
      }),
    );

    return arrayErrors;
  }

  if (responseError.status === EnumResponseCode.ServerError) {
    arrayErrors.push(new ErrorServer({ errors }));

    return arrayErrors;
  }

  if (responseError.status === EnumResponseCode.Forbidden) {
    arrayErrors.push(
      new ErrorForbidden({
        message: responseError._data.message || $i18n.t('error.notConfirmPhone'),
        errors,
      }),
    );

    return arrayErrors;
  }

  arrayErrors.push(new ErrorNetwork({ message: responseError._data.message || $i18n.t('error.unknown'), errors }));

  return arrayErrors;
};
