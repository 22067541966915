import type { IImage } from '~/shared/api/types';
import { ProductPriceType } from '~/shared/api/v1/products/types';
import type { IShopWholesale } from '~/shared/api/v1/shops/types';
import { ProductHelpers } from '~/shared/helpers/product';
import type { TCartEntityKey } from '../cart/types';
import type {
  ICartProductConstructorPayload,
  ICartProductEntityOption,
  ICartProductEntityShopSumWholesale,
  ICartProductEntitySumWholesale,
} from './types';
import type { CartShopEntity } from '../cartShop';

export class CartProductEntity {
  id!: number;
  sku!: string;
  shopId!: number;
  productOptionId!: number;
  productOptionValueId!: number;
  name!: string;
  productGroupId!: number | null;
  isFreeShipping!: boolean;
  option!: ICartProductEntityOption;
  image!: IImage | null;
  slug!: string;
  priceType!: ProductPriceType;
  shop!: CartShopEntity;

  discountId!: string | null;
  currentDiscountQuantity!: number;
  shopWholesales!: IShopWholesale | null;
  shopSumWholesale!: ICartProductEntityShopSumWholesale | null;
  sumWholesale!: ICartProductEntitySumWholesale | null;
  currentPrice!: number;
  currentPriceWithoutSale!: number;

  isChecked!: boolean;
  checkedSum!: {
    currentDiscountQuantity: number;
    shopSumWholesale: ICartProductEntityShopSumWholesale | null;
    sumWholesale: ICartProductEntitySumWholesale | null;
    currentPrice: number;
    currentPriceWithoutSale: number;
  };

  quantity!: number;
  quantityPrevious!: number;
  pricePrevious!: number;
  price!: number;
  discount!: number;
  discountPrevious!: number;

  cartEntityKey!: TCartEntityKey;

  public constructor(product: ICartProductConstructorPayload) {
    Object.assign(this, product);

    this.calculateCurrentPrice();
  }

  public changeProduct(data: Partial<CartProductEntity>) {
    Object.assign(this, data);

    this.calculateCurrentPrice();
  }

  public changeProductShopErrors(errors: Array<string>) {
    this.shop.errors = errors;
  }

  public calculateCurrentPrice() {
    if (this.priceType === ProductPriceType.ByQuantity) {
      let discount = ProductHelpers.getCurrentDiscount(this.option.value.discounts, this.currentDiscountQuantity);
      const minDiscount = ProductHelpers.getMinDiscount(this.option.value.discounts);

      if (discount === undefined) {
        return;
      }

      this.currentPriceWithoutSale = minDiscount.price * this.quantity;
      this.currentPrice = discount.price * this.quantity;
    }

    if (this.priceType === ProductPriceType.BySum) {
      if (!this.shopSumWholesale) {
        return;
      }

      const { wholesale } = this.option!.value!;

      this.sumWholesale = {
        sumPrice: this.option!.value!.price * this.quantity,
        sumSmall: wholesale.priceSmall * this.quantity,
        sumNormal: wholesale.priceNormal * this.quantity,
        sumLarge: wholesale.priceLarge * this.quantity,
      };

      this.currentPriceWithoutSale = this.option!.value!.price * this.quantity;
      this.currentPrice = this.sumWholesale[this.shopSumWholesale.currentWholesale];
    }

    this.calculateCurrentPriceChecked();
  }

  public calculateCurrentPriceChecked() {
    if (this.priceType === ProductPriceType.ByQuantity) {
      let discount = ProductHelpers.getCurrentDiscount(
        this.option.value.discounts,
        this.checkedSum.currentDiscountQuantity,
      );
      const minDiscount = ProductHelpers.getMinDiscount(this.option.value.discounts);

      if (discount === undefined) {
        return;
      }

      this.checkedSum.currentPriceWithoutSale = minDiscount.price * this.quantity;
      this.checkedSum.currentPrice = discount.price * this.quantity;
    }

    if (this.priceType === ProductPriceType.BySum) {
      if (!this.checkedSum.shopSumWholesale) {
        return;
      }

      const { wholesale } = this.option!.value!;

      this.checkedSum.sumWholesale = {
        sumPrice: this.option!.value!.price * this.quantity,
        sumSmall: wholesale.priceSmall * this.quantity,
        sumNormal: wholesale.priceNormal * this.quantity,
        sumLarge: wholesale.priceLarge * this.quantity,
      };

      this.checkedSum.currentPriceWithoutSale = this.option!.value!.price * this.quantity;
      this.checkedSum.currentPrice = this.checkedSum.sumWholesale[this.checkedSum.shopSumWholesale.currentWholesale];
    }
  }
}
