import type { INotificationItem } from '~/app/store/notifications/types';
import { Icons } from '@wikkeo/ui-kit';
import { AUTO_REMOVE_TIME } from '~/shared/config/notifications';

const { WiFavoriteNotification, WiSuccessNotification, WiWarningNotification, WiClose } = Icons;

export default defineNuxtComponent({
  name: 'WiNotification',

  components: {
    WiFavoriteNotification,
    WiSuccessNotification,
    WiWarningNotification,
    WiClose,
  },

  props: {
    notification: {
      type: Object as PropType<INotificationItem>,
      required: true,
    },
  },

  emits: ['onClose'],

  setup(props, { emit }) {
    const { notification } = toRefs(props);

    const notificationContainer = ref();

    let timeoutValue = null as any;

    const removeTime = toRef(() => notification.value?.delay || AUTO_REMOVE_TIME);

    onMounted(() => {
      setTimeoutForClose();
    });

    const addClassClose = () => {
      if (!notificationContainer.value) {
        return;
      }

      notificationContainer.value.style.setProperty('--duration', removeTime.value / 1000 + 's');
      notificationContainer.value.classList.add('wi-notification_transition-close');
    };

    const setTimeoutForClose = () => {
      addClassClose();

      timeoutValue = setTimeout(() => {
        closeNotification();
      }, removeTime.value);
    };

    const stopTimeout = () => {
      if (!notificationContainer.value) {
        return;
      }

      notificationContainer.value.classList.remove('wi-notification_transition-close');

      clearTimeout(timeoutValue);
    };

    const closeNotification = () => {
      if (!notificationContainer.value) {
        return;
      }

      notificationContainer.value.style.setProperty('--duration', '0.5s');

      emit('onClose', notification.value);
    };

    return {
      notificationContainer,

      stopTimeout,
      setTimeoutForClose,
      closeNotification,
    };
  },
});
