<template>
  <div class="wi-custom-image">
    <div class="wi-custom-image__container blur">
      <WiSkeleton v-if="isLoadingImage" :isPill="isPill" />

      <picture v-show="!isLoadingImage && !errorImg" class="container-picture">
        <template v-if="sizeKeysSorted.length">
          <template v-if="currentMobileSize === currentDesktopsSize">
            <!-- common sources -->
            <source :srcset="image.sizes?.[currentMobileSize].avifURL" type="image/avif" />
            <source :srcset="image.sizes?.[currentMobileSize].webpURL" type="image/webp" />
            <source :srcset="image.sizes?.[currentMobileSize].jpegURL" type="image/jpeg" />
            <source
              v-if="image.sizes?.[currentMobileSize].svgURL"
              :srcset="image.sizes?.[currentMobileSize].svgURL"
              type="image/svg"
            />
          </template>
          <template v-else>
            <!-- mobile sources -->
            <source :srcset="image.sizes?.[currentMobileSize].avifURL" type="image/avif" media="(max-width: 991px)" />
            <source :srcset="image.sizes?.[currentMobileSize].webpURL" type="image/webp" media="(max-width: 991px)" />
            <source :srcset="image.sizes?.[currentMobileSize].jpegURL" type="image/jpeg" media="(max-width: 991px)" />
            <source
              v-if="image.sizes?.[currentMobileSize].svgURL"
              :srcset="image.sizes?.[currentMobileSize].svgURL"
              type="image/svg"
              media="(max-width: 991px)"
            />

            <!-- desktop sources -->
            <source :srcset="image.sizes?.[currentDesktopsSize].avifURL" type="image/avif" media="(min-width: 992px)" />
            <source :srcset="image.sizes?.[currentDesktopsSize].webpURL" type="image/webp" media="(min-width: 992px)" />
            <source :srcset="image.sizes?.[currentDesktopsSize].jpegURL" type="image/jpeg" media="(min-width: 992px)" />
            <source
              v-if="image.sizes?.[currentDesktopsSize].svgURL"
              :srcset="image.sizes?.[currentDesktopsSize].svgURL"
              type="image/svg"
              media="(min-width: 992px)"
            />
          </template>
        </template>

        <slot name="defaultImage">
          <img
            v-if="!errorImg"
            :loading="isLazy ? 'lazy' : 'eager'"
            ref="imgElemRef"
            class="container-picture__img"
            :srcset="defaultImages[defaultImage]"
            :alt="alt"
            @load="loadHandler"
            @error="errorHandler"
          />
        </slot>
      </picture>

      <slot v-if="errorImg" name="errorImage">
        <div v-if="defaultImage === 'default'" class="default-image-container">
          <WiNoPhoto class="wi-no-photo-icon" />
        </div>
        <div v-else-if="defaultImage === 'productCard'" class="default-image-container">
          <NoPhotoProductIconLight class="wi-no-photo-icon wi-product-no-photo-light" />
          <NoPhotoProductIconDark class="wi-no-photo-icon wi-product-no-photo-dark" />
        </div>
        <img
          v-else
          class="container-picture__img"
          :loading="isLazy ? 'lazy' : 'eager'"
          :alt="alt"
          :src="defaultImages[defaultImage]"
        />
      </slot>
    </div>
  </div>
</template>

<style lang="scss" scoped src="./index.scss"></style>
<script lang="ts" src="./index.ts" />
