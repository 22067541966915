import { EnumFilterKey } from '../api/types';
import { ShopOrdersStatusesEnum } from '../api/v1/personalAccount/types';
import useAppRouter from '../hooks/useAppRouter';
import type { IProfileMenuItem } from '../types/profile/profileMenu';

export const getProfileMenu = (): Array<IProfileMenuItem> => {
  const router = useAppRouter();
  const { t } = useI18n();

  return [
    {
      label: t('profileLayout.menu.profile'),
      link: router.getProfilePath(),
      icon: 'WiProfile',
      checkIsActive: () => Boolean(router.currentRoute.value.meta.isProfilePage),
    },
    {
      label: t('profileLayout.menu.orders'),
      link: router.getProfileOrdersPath(),
      icon: 'WiBox',
      subMenu: [
        {
          label: t('profileLayout.menu.ordersTabs.all'),
          link: router.getProfileOrdersPath(),
          counterKey: 'allShopOrders',
          checkIsActive: () =>
            Boolean(router.currentRoute.value.meta.isOrdersPage && !router.currentRoute.value.query.status),
        },
        {
          label: t('profileLayout.menu.ordersTabs.pending'),
          link: router.getProfileOrdersPath(`status=${EnumFilterKey.Eq}:pending`),
          counterKey: ShopOrdersStatusesEnum.pending,
          checkIsActive: () =>
            Boolean(
              router.currentRoute.value.meta.isOrdersPage &&
                router.currentRoute.value.query.status?.includes('pending'),
            ),
        },
        {
          label: t('profileLayout.menu.ordersTabs.sent'),
          link: router.getProfileOrdersPath(`status=${EnumFilterKey.Eq}:on_delivery`),
          counterKey: ShopOrdersStatusesEnum.onDelivery,
          checkIsActive: () =>
            Boolean(
              router.currentRoute.value.meta.isOrdersPage &&
                router.currentRoute.value.query.status?.includes('on_delivery'),
            ),
        },
        {
          label: t('profileLayout.menu.ordersTabs.delivered'),
          link: router.getProfileOrdersPath(`status=${EnumFilterKey.Eq}:completed`),
          counterKey: ShopOrdersStatusesEnum.completed,
          checkIsActive: () =>
            Boolean(
              router.currentRoute.value.meta.isOrdersPage &&
                router.currentRoute.value.query.status?.includes('completed') &&
                !router.currentRoute.value.query?.hasReviews,
            ),
        },
        {
          label: t('profileLayout.menu.ordersTabs.waitingForEvaluation'),
          link: router.getProfileOrdersPath(`hasReviews=false&status=${EnumFilterKey.Eq}:completed`),
          counterKey: ShopOrdersStatusesEnum.completedWithoutReviews,
          checkIsActive: () =>
            Boolean(
              router.currentRoute.value.meta.isOrdersPage &&
                router.currentRoute.value.query.status?.includes('completed') &&
                router.currentRoute.value.query?.hasReviews,
            ),
        },
        {
          label: t('profileLayout.menu.ordersTabs.declined'),
          link: router.getProfileOrdersPath(`status=${EnumFilterKey.Eq}:declined`),
          counterKey: ShopOrdersStatusesEnum.declined,
          checkIsActive: () =>
            Boolean(
              router.currentRoute.value.meta.isOrdersPage &&
                router.currentRoute.value.query.status?.includes('declined'),
            ),
        },
      ],
    },
    {
      label: t('profileLayout.menu.messages'),
      link: router.getProfileChatPath(),
      icon: 'WiChat',
      checkIsActive: () => Boolean(router.currentRoute.value.meta.isChatPage),
    },
    {
      label: t('profileLayout.menu.reviews'),
      link: router.getProfileReviewsPath(),
      icon: 'WiStarOutlined',
      checkIsActive: () => Boolean(router.currentRoute.value.meta.isReviewsPage),
    },
    {
      label: t('profileLayout.menu.deliveryProfiles'),
      link: router.getProfileDeliveryProfilesPath(),
      icon: 'WiLocation',
      checkIsActive: () => Boolean(router.currentRoute.value.meta.isDeliveryProfilesPage),
    },
    {
      label: t('profileLayout.menu.settings'),
      link: router.getProfileSettingsNotificationsPath(),
      icon: 'WiSettings',
      subMenu: [
        {
          label: t('profileLayout.menu.settingsTabs.notifications'),
          link: router.getProfileSettingsNotificationsPath(),
          checkIsActive: () => Boolean(router.currentRoute.value.meta.isProfileNotificationsPage),
        },
        {
          label: t('profileLayout.menu.settingsTabs.telegram'),
          link: router.getProfileSettingsTelegramPath(),
          checkIsActive: () => Boolean(router.currentRoute.value.meta.isTelegramSettingsPage),
        },
      ],
    },
  ];
};
