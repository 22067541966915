import { checkIsServer } from '~/shared/browserAPI/isServer';

export const useHeaderFixedTopHook = (config: {
  scrollElementId: string;
  overElementId: string;
  targetElementId: string;
}) => {
  const setScrollListener = (value: boolean) => {
    let startPosition = 0;

    const scrollElement = document.getElementById(config.scrollElementId);

    if (!scrollElement) {
      return;
    }

    if (value) {
      const topElement = document.getElementById(config.overElementId);

      if (topElement) {
        startPosition = topElement.getBoundingClientRect().height;
      }

      scrollElement.addEventListener('scroll', () => handlerScrollListener(startPosition));
    } else {
      scrollElement.removeEventListener('scroll', () => handlerScrollListener(startPosition));
    }

    handlerScrollListener(startPosition);
  };

  const handlerScrollListener = (start: number) => {
    const scrollElement = document.getElementById(config.scrollElementId);

    const targetElement = document.getElementById(config.targetElementId);

    if (!targetElement || !scrollElement) {
      return;
    }

    if (scrollElement.scrollTop > start) {
      targetElement.classList.add('top_fixed');
    } else {
      targetElement.classList.remove('top_fixed');
    }
  };

  onMounted(() => {
    if (!checkIsServer()) {
      setScrollListener(true);
    }
  });

  onBeforeUnmount(() => {
    if (checkIsServer()) {
      return;
    }

    setScrollListener(false);
  });
};
