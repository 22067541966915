import type { IProductOptionValueDiscount } from '~/shared/api/v1/products/types';

export const getCustomIdDiscount = (discounts: Array<IProductOptionValueDiscount>) => {
  let customId = '';

  discounts.forEach((discount) => {
    customId = customId + `andFrom${discount.from}To${discount.to}Price${discount.price}`;
  });

  return customId;
};
