import type { PublicRuntimeConfig } from 'nuxt/schema';
import type { FetchOptions } from 'ofetch';
import { $fetch } from 'ofetch';

import SearchApiLegacy from '~/shared/api/legacy/search';
import AuthApiV1 from '~/shared/api/v1/auth';
import CategoriesApiV1 from '~/shared/api/v1/categories';
import FeaturesFlagsApiV1 from '~/shared/api/v1/featureFlags';
import ProductsApiV1 from '~/shared/api/v1/products';
import CartsApiV1 from '~/shared/api/v1/carts';
import ShopsApiV1 from '~/shared/api/v1/shops';
import ReviewsApiV1 from '~/shared/api/v1/reviews';
import ProductGroupsApiV1 from '~/shared/api/v1/productGroups';
import ProductsWishlistApiV1 from '~/shared/api/v1/productWishlists';
import AddressesApiV1 from '~/shared/api/v1/addresses';
import { checkIsServer } from '~/shared/browserAPI/isServer';
import PersonalAccountApiV1 from '~/shared/api/v1/personalAccount';
import UserDeliveryProfilesApiV1 from '~/shared/api/v1/userDeliveryProfiles';
import ShippingCompaniesApiV1 from '~/shared/api/v1/shippingCompanies';
import GeocodeYandexApi from '~/shared/api/ymap/geocode';
import ShippingsApiV1 from '~/shared/api/v1/shippings';
import HomeApiV1 from '~/shared/api/v1/home';
import OrdersApiV1 from '~/shared/api/v1/orders';

interface IApiInstance {
  auth: AuthApiV1;
  products: ProductsApiV1;
  featuresFlags: FeaturesFlagsApiV1;
  categories: CategoriesApiV1;
  shops: ShopsApiV1;
  reviews: ReviewsApiV1;
  productGroups: ProductGroupsApiV1;
  productsWishlist: ProductsWishlistApiV1;
  carts: CartsApiV1;
  addresses: AddressesApiV1;
  personalAccount: PersonalAccountApiV1;
  userDeliveryProfiles: UserDeliveryProfilesApiV1;
  shippingCompanies: ShippingCompaniesApiV1;
  geocodeYandex: GeocodeYandexApi;
  shipping: ShippingsApiV1;
  homeApiV1: HomeApiV1;
  ordersApiV1: OrdersApiV1;

  legacySearch: SearchApiLegacy;
}

export default defineNuxtPlugin((_nuxtApp) => {
  const config = useRuntimeConfig();
  const isServer = checkIsServer();
  
  const publicConfig = config.public as unknown as PublicRuntimeConfig;

  const fetchOptions: FetchOptions = {
    baseURL: isServer && config.apiUrlSsr ? config.apiUrlSsr : publicConfig.apiUrlClient,
  };

  // Create a new instance of $fecther with custom option
  const apiFetcher = $fetch.create(fetchOptions);
  const apiFetcherEmpty = $fetch.create({ baseURL: '' });

  // An object containing all repositories we need to expose
  const modules: IApiInstance = {
    auth: new AuthApiV1(apiFetcher),
    products: new ProductsApiV1(apiFetcher),
    featuresFlags: new FeaturesFlagsApiV1(apiFetcher),
    categories: new CategoriesApiV1(apiFetcher),
    shops: new ShopsApiV1(apiFetcher),
    reviews: new ReviewsApiV1(apiFetcher),
    productGroups: new ProductGroupsApiV1(apiFetcher),
    productsWishlist: new ProductsWishlistApiV1(apiFetcher),
    carts: new CartsApiV1(apiFetcher),
    addresses: new AddressesApiV1(apiFetcher),
    personalAccount: new PersonalAccountApiV1(apiFetcher),
    userDeliveryProfiles: new UserDeliveryProfilesApiV1(apiFetcher),
    shippingCompanies: new ShippingCompaniesApiV1(apiFetcher),
    shipping: new ShippingsApiV1(apiFetcher),
    homeApiV1: new HomeApiV1(apiFetcher),
    ordersApiV1: new OrdersApiV1(apiFetcher),

    legacySearch: new SearchApiLegacy(apiFetcher),

    geocodeYandex: new GeocodeYandexApi(apiFetcherEmpty),
  };

  return {
    provide: {
      api: modules,
    },
  };
});
