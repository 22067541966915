import WiNuxtErrorBoundary from '~/shared/ui/WiNuxtErrorBoundary/index.vue';
import WiHeaderCategoryBlockDesktop from '~/shared/ui/header/desktop/WiHeaderCategoryBlockDesktop/ui/index.vue';
import WiMobileControlCategory from '~/shared/ui/mobileControl/modules/WiMobileControlCategory/ui/index.vue';

import { useHeaderCategoryBlockApi } from '../api';

export default defineNuxtComponent({
  name: 'WiCategoryBlock',

  components: {
    WiNuxtErrorBoundary,
    WiHeaderCategoryBlockDesktop,
    WiMobileControlCategory,
  },

  setup() {
    const { categoriesTree, isLoadingCategories, checkCategoriesFromLocalStorage } = useHeaderCategoryBlockApi();

    onMounted(() => {
      checkCategoriesFromLocalStorage();
    });

    return {
      categoriesTree,
      isLoadingCategories,
    };
  },
});
