import { CartProductEntity } from '~/entities/cartProduct';
import { ProductPriceType } from '~/shared/api/v1/products/types';
import { ProductHelpers } from '~/shared/helpers/product';
import { ArrayUtils } from '~/shared/utils/array';
import type { ILatestResponseCart, TCartEntityKey, TProductFilterParams } from './types';
import type { ICartProductEntitySumWholesale } from '../cartProduct/types';
import { CartProductWithErrorEntity } from '../cartProductWithError';

export class CartEntity {
  productsCart: Array<CartProductEntity | CartProductWithErrorEntity> = [];
  productsCandidates: Array<CartProductEntity | CartProductWithErrorEntity> = [];
  latestResponse: ILatestResponseCart | null = null;

  public constructor(productsCart: Array<CartProductEntity | CartProductWithErrorEntity>) {
    this.productsCart = productsCart;
  }

  public getAllProducts(key: TCartEntityKey) {
    return this[key];
  }

  public setProducts(items: Array<CartProductEntity | CartProductWithErrorEntity>, key: TCartEntityKey) {
    this[key] = [...items];

    if (items.length === 0) {
      this.updatePricesProducts(this.productsCart, 'productsCart');
      this.updatePricesProducts(this.productsCandidates, 'productsCandidates');
    } else {
      this.updatePricesProducts(items, key);
    }
  }

  public setLatestResponse(latestResponseData: ILatestResponseCart | null) {
    this.latestResponse = latestResponseData;
  }

  public addProducts(items: Array<CartProductEntity | CartProductWithErrorEntity>, key: TCartEntityKey) {
    const addedProducts: Array<CartProductEntity | CartProductWithErrorEntity> = [];

    items.forEach((item) => {
      let product = this.getProduct(
        {
          productId: item.id,
          productOptionId: item.productOptionId,
          productOptionValueId: item.productOptionValueId,
        },
        key,
      );

      if (product === undefined) {
        const newCartProduct =
          item instanceof CartProductEntity ? new CartProductEntity(item) : new CartProductWithErrorEntity(item);

        newCartProduct.cartEntityKey = key;

        this[key].push(newCartProduct);

        product = this.getProduct(
          {
            productId: item.id,
            productOptionId: item.productOptionId,
            productOptionValueId: item.productOptionValueId,
          },
          key,
        );
      } else {
        if (product instanceof CartProductEntity) {
          product.changeProduct({ quantity: item.quantity! + product.quantity! });
        }
      }

      if (product !== undefined) {
        addedProducts.push(product);
      }
    });

    this.updatePricesProducts(addedProducts, key);
  }

  public addProductsFromCandidatesToCart(items: Array<CartProductEntity | CartProductWithErrorEntity>) {
    const addedProducts: Array<CartProductEntity> = [];

    items.forEach((item) => {
      let product = this.getProduct(
        {
          productId: item.id,
          productOptionId: item.productOptionId,
          productOptionValueId: item.productOptionValueId,
        },
        'productsCart',
      );

      if (product === undefined) {
        const newCartProduct =
          item instanceof CartProductEntity ? new CartProductEntity(item) : new CartProductWithErrorEntity(item);

        newCartProduct.cartEntityKey = 'productsCart';

        this.productsCart.push(newCartProduct);

        product = this.getProduct(
          {
            productId: item.id,
            productOptionId: item.productOptionId,
            productOptionValueId: item.productOptionValueId,
          },
          'productsCart',
        );
      } else {
        if (product instanceof CartProductEntity) {
          product.changeProduct({ quantity: item.quantity! + product.quantity! });
        }
      }

      if (product !== undefined && product instanceof CartProductEntity) {
        addedProducts.push(product);
      }

      if (item instanceof CartProductEntity) {
        item.changeProduct({ quantity: 0 });
      }
    });

    this.updatePricesProducts(addedProducts, 'productsCart');
  }

  public removeAllProducts(key?: TCartEntityKey) {
    if (!key) {
      this.productsCandidates = [];
      this.productsCart = [];

      return;
    }

    if (key === 'productsCandidates') {
      const changedProducts = [...this.productsCandidates];

      this.productsCandidates = [];

      this.updatePricesProducts(changedProducts, key);
    }

    if (key === 'productsCart') {
      const changedProducts = [...this.productsCart];

      this.productsCart = [];

      this.updatePricesProducts(changedProducts, key);
    }
  }

  public removeProducts(products: Array<CartProductEntity | CartProductWithErrorEntity>, key: TCartEntityKey) {
    this[key] = this[key].filter((product) => {
      const index = products.findIndex(
        (elem) =>
          elem.id === product.id &&
          elem.productOptionId === product.productOptionId &&
          elem.productOptionValueId === product.productOptionValueId,
      );

      return index === -1;
    });

    this.updatePricesProducts(products, key);
  }

  public getProductByFilter(filterParams: TProductFilterParams, key?: TCartEntityKey) {
    if (key !== undefined) {
      return this.customFilterProducts(filterParams, this[key]);
    } else {
      const productsCartByIds = this.customFilterProducts(filterParams, this.productsCart);

      const productsCandidatesByIds = this.customFilterProducts(filterParams, this.productsCandidates);

      return [...productsCartByIds, ...productsCandidatesByIds];
    }
  }

  public getProductWithQuantity(key: TCartEntityKey) {
    return this[key]
      .filter((product) => product instanceof CartProductEntity)
      .filter((product) => product.quantity && product.quantity > 0);
  }

  private customFilterProducts(
    filterParams: TProductFilterParams,
    array: Array<CartProductEntity | CartProductWithErrorEntity>,
  ) {
    return array.filter((elem) => {
      let isTrue = true;

      if (filterParams.withoutError !== undefined) {
        isTrue = elem instanceof CartProductEntity;
      }

      if (isTrue && filterParams.productId !== undefined) {
        isTrue = elem.id === filterParams.productId;
      }

      if (isTrue && filterParams.shopId !== undefined) {
        isTrue = elem.shopId === filterParams.shopId;
      }

      if (isTrue && filterParams.productOptionId !== undefined) {
        isTrue = elem.productOptionId === filterParams.productOptionId;
      }

      if (isTrue && filterParams.productOptionValueId !== undefined) {
        isTrue = elem.productOptionValueId === filterParams.productOptionValueId;
      }

      return isTrue;
    });
  }

  public getProduct(
    ids: {
      productId: number;
      productOptionId: number | undefined;
      productOptionValueId: number | undefined;
    },
    key: TCartEntityKey,
  ) {
    const product = this[key].find(
      (elem) =>
        elem.id === ids.productId &&
        elem.productOptionId === ids.productOptionId &&
        elem.productOptionValueId === ids.productOptionValueId,
    );

    return product;
  }

  public changeQuantityProducts(
    products: Array<{
      ids: {
        productId: number;
        productOptionId: number;
        productOptionValueId: number;
      };
      quantity: number;
    }>,

    key: TCartEntityKey,
  ) {
    const updatedProducts: Array<CartProductEntity> = [];

    products.forEach((elem) => {
      const product = this.getProduct(elem.ids, key);

      if (product === undefined || !(product instanceof CartProductEntity)) {
        return;
      }

      product.changeProduct({ quantity: elem.quantity });

      updatedProducts.push(product);
    });

    this.updatePricesProducts(updatedProducts, key);
  }

  public changeCheckedProducts(
    products: Array<{
      ids: {
        productId: number;
        productOptionId: number;
        productOptionValueId: number;
      };
      isChecked: boolean;
    }>,

    key: TCartEntityKey,
  ) {
    const updatedProducts: Array<CartProductEntity> = [];

    products.forEach((elem) => {
      const product = this.getProduct(elem.ids, key);

      if (product === undefined) {
        return;
      }

      product.changeProduct({ isChecked: elem.isChecked });

      if (product instanceof CartProductEntity) {
        updatedProducts.push(product);
      }
    });

    if (!updatedProducts.length) {
      return;
    }

    this.updatePricesProducts(updatedProducts, key);
  }

  private updatePricesProducts(
    changedProducts: Array<CartProductEntity | CartProductWithErrorEntity>,
    key: TCartEntityKey,
  ) {
    const shopsIds = ArrayUtils.uniqSimple(
      changedProducts.filter((product) => product instanceof CartProductEntity).map((product) => product.shopId),
    );

    const productsCartForChange = this.productsCart
      .filter((product) => product instanceof CartProductEntity)
      .filter((product) => shopsIds.includes(product.shopId));

    const productsCandidateForChange = this.productsCandidates
      .filter((product) => product instanceof CartProductEntity)
      .filter((product) => shopsIds.includes(product.shopId));

    const commonListProducts = [...productsCartForChange, ...productsCandidateForChange];

    const commonListProductsFilteredByPriceType: {
      [ProductPriceType.ByQuantity]: Array<CartProductEntity>;
      [ProductPriceType.BySum]: Array<CartProductEntity>;
    } = {
      [ProductPriceType.ByQuantity]: [],
      [ProductPriceType.BySum]: [],
    };

    commonListProducts.forEach((product) => {
      if (product.priceType === ProductPriceType.ByQuantity) {
        commonListProductsFilteredByPriceType[ProductPriceType.ByQuantity].push(product);
      } else if (product.priceType === ProductPriceType.BySum) {
        commonListProductsFilteredByPriceType[ProductPriceType.BySum].push(product);
      }
    });

    if (commonListProductsFilteredByPriceType[ProductPriceType.ByQuantity].length) {
      this.updatePricesProductsByQuantity(commonListProductsFilteredByPriceType[ProductPriceType.ByQuantity], key);
    }

    if (commonListProductsFilteredByPriceType[ProductPriceType.BySum].length) {
      this.updatePricesProductsBySum(commonListProductsFilteredByPriceType[ProductPriceType.BySum], key);
    }
  }

  private updatePricesProductsByQuantity(products: Array<CartProductEntity>, key: TCartEntityKey) {
    const shopsIdsWithProducts = this.getProductsByShops({ products });

    if (!shopsIdsWithProducts) {
      return;
    }

    for (const shopKey in shopsIdsWithProducts) {
      const productsInShop = shopsIdsWithProducts[shopKey].filter((product) => product instanceof CartProductEntity);

      const groupIds: Array<number> = [];

      productsInShop.forEach((product) => {
        if (!product.productGroupId) {
          return;
        }

        if (!groupIds.includes(product.productGroupId)) {
          groupIds.push(product.productGroupId);
        }
      });

      const groupsWithProducts = this.getProductByGroups({ groupIds, products: productsInShop });
      const productsWithoutGroup = productsInShop.filter((product) => !product.productGroupId);

      if (groupsWithProducts) {
        for (const groupKey in groupsWithProducts) {
          const productsInGroups = groupsWithProducts[groupKey].filter(
            (product) => product instanceof CartProductEntity,
          );

          this.updatePricesProductsByDiscount(productsInGroups, key);
        }
      }

      this.updatePricesProductsByDiscount(productsWithoutGroup, key);
    }
  }

  private updatePricesProductsBySum(products: Array<CartProductEntity>, key: TCartEntityKey) {
    const shopsIds = ArrayUtils.uniqSimple(products.map((product) => product.shopId));

    shopsIds.forEach((id) => {
      let sumWholesale: ICartProductEntitySumWholesale = {
        sumPrice: 0,
        sumSmall: 0,
        sumNormal: 0,
        sumLarge: 0,
      };

      let sumWholesaleChecked: ICartProductEntitySumWholesale = {
        sumPrice: 0,
        sumSmall: 0,
        sumNormal: 0,
        sumLarge: 0,
      };

      const products = this.getProductByFilter({ shopId: id }).filter(
        (product) => product instanceof CartProductEntity,
      );

      products.forEach((product) => {
        if (product.priceType === ProductPriceType.ByQuantity) {
          sumWholesale.sumPrice = sumWholesale.sumPrice + product.currentPrice;
          sumWholesale.sumSmall = sumWholesale.sumSmall + product.currentPrice;
          sumWholesale.sumNormal = sumWholesale.sumNormal + product.currentPrice;
          sumWholesale.sumLarge = sumWholesale.sumLarge + product.currentPrice;
        } else if (product.priceType === ProductPriceType.BySum && product.sumWholesale) {
          sumWholesale.sumPrice = sumWholesale.sumPrice + product.sumWholesale.sumPrice;
          sumWholesale.sumSmall = sumWholesale.sumSmall + product.sumWholesale.sumSmall;
          sumWholesale.sumNormal = sumWholesale.sumNormal + product.sumWholesale.sumNormal;
          sumWholesale.sumLarge = sumWholesale.sumLarge + product.sumWholesale.sumLarge;
        }

        if (product.priceType === ProductPriceType.ByQuantity && product.isChecked) {
          sumWholesaleChecked.sumPrice = sumWholesaleChecked.sumPrice + product.currentPrice;
          sumWholesaleChecked.sumSmall = sumWholesaleChecked.sumSmall + product.currentPrice;
          sumWholesaleChecked.sumNormal = sumWholesaleChecked.sumNormal + product.currentPrice;
          sumWholesaleChecked.sumLarge = sumWholesaleChecked.sumLarge + product.currentPrice;
        } else if (
          product.priceType === ProductPriceType.BySum &&
          product.checkedSum.sumWholesale &&
          product.isChecked
        ) {
          sumWholesaleChecked.sumPrice = sumWholesaleChecked.sumPrice + product.checkedSum.sumWholesale.sumPrice;
          sumWholesaleChecked.sumSmall = sumWholesaleChecked.sumSmall + product.checkedSum.sumWholesale.sumSmall;
          sumWholesaleChecked.sumNormal = sumWholesaleChecked.sumNormal + product.checkedSum.sumWholesale.sumNormal;
          sumWholesaleChecked.sumLarge = sumWholesaleChecked.sumLarge + product.checkedSum.sumWholesale.sumLarge;
        }
      });

      products.forEach((product) => {
        if (product.priceType !== ProductPriceType.BySum) {
          return;
        }

        if (key === 'productsCart' || key === product.cartEntityKey) {
          const currentWholesale = ProductHelpers.getCurrentWholesaleByObjectKey(sumWholesale, product.shopWholesales!);
          const currentWholesaleChecked = ProductHelpers.getCurrentWholesaleByObjectKey(
            sumWholesaleChecked,
            product.shopWholesales!,
          );

          product.changeProduct({ shopSumWholesale: { ...sumWholesale, currentWholesale } });
          product.changeProduct({
            checkedSum: {
              ...product.checkedSum,
              shopSumWholesale: { ...sumWholesaleChecked, currentWholesale: currentWholesaleChecked },
            },
          });
        }
      });
    });
  }

  private updatePricesProductsByDiscount(products: Array<CartProductEntity>, key: TCartEntityKey) {
    const discountsWithProducts = this.getDiscountIdsWithProduct({ products });

    for (const discountsKey in discountsWithProducts) {
      const sumQuantity = this.getSumQuantityProducts(discountsWithProducts[discountsKey]);
      const sumQuantityChecked = this.getSumQuantityProducts(
        discountsWithProducts[discountsKey].filter((elem) => elem.isChecked),
      );

      discountsWithProducts[discountsKey].forEach((product) => {
        if (key === 'productsCart' || key === product.cartEntityKey) {
          product.changeProduct({ currentDiscountQuantity: sumQuantity });

          product.changeProduct({
            checkedSum: { ...product.checkedSum, currentDiscountQuantity: sumQuantityChecked },
          });
        }
      });
    }
  }

  public getCartProductsByProducts(data: {
    products: Array<CartProductEntity | CartProductWithErrorEntity>;
  }): Record<string, Array<CartProductEntity | CartProductWithErrorEntity>> | null {
    const { products } = data;

    let cartProductByProduct: Record<string, Array<CartProductEntity | CartProductWithErrorEntity>> | null = null;

    products.forEach((product) => {
      if (!cartProductByProduct) {
        cartProductByProduct = {};
      }

      if (cartProductByProduct[product.id] === undefined) {
        cartProductByProduct[product.id] = [product];
      } else {
        cartProductByProduct[product.id].push(product);
      }
    });

    return cartProductByProduct;
  }

  public getProductsByShops(data: {
    shopIds?: Array<number>;
    products: Array<CartProductEntity | CartProductWithErrorEntity>;
  }): Record<string, Array<CartProductEntity | CartProductWithErrorEntity>> | null {
    const { shopIds, products } = data;

    const filteredProductsByShopId =
      shopIds !== undefined ? products.filter((product) => shopIds.includes(product.shopId)) : products;

    let productByShops: Record<string, Array<CartProductEntity | CartProductWithErrorEntity>> | null = null;

    filteredProductsByShopId.forEach((product) => {
      if (!productByShops) {
        productByShops = {};
      }

      if (productByShops[product.shopId] === undefined) {
        productByShops[product.shopId] = [product];
      } else {
        productByShops[product.shopId].push(product);
      }
    });

    return productByShops;
  }

  private getProductByGroups(data: {
    groupIds?: Array<number>;
    products: Array<CartProductEntity | CartProductWithErrorEntity>;
  }): Record<string, Array<CartProductEntity | CartProductWithErrorEntity>> | null {
    const { groupIds, products } = data;

    const filteredProductsByGroupId =
      groupIds !== undefined
        ? products.filter((product) => product.productGroupId && groupIds.includes(product.productGroupId))
        : products;

    let productByGroups: Record<string, Array<CartProductEntity | CartProductWithErrorEntity>> | null = null;

    filteredProductsByGroupId.forEach((product) => {
      if (!product.productGroupId) {
        return;
      }

      if (!productByGroups) {
        productByGroups = {};
      }

      if (productByGroups[product.productGroupId] === undefined) {
        productByGroups[product.productGroupId] = [product];
      } else {
        productByGroups[product.productGroupId].push(product);
      }
    });

    return productByGroups;
  }

  private getDiscountIdsWithProduct(data: {
    discountIds?: Array<string>;
    products: Array<CartProductEntity>;
  }): Record<string, Array<CartProductEntity>> | null {
    const { discountIds, products } = data;

    const filteredProductsByDiscountIds =
      discountIds !== undefined
        ? products.filter((product) => product.discountId && discountIds.includes(product.discountId))
        : products;

    let productByDiscount: Record<string, Array<CartProductEntity>> | null = null;

    filteredProductsByDiscountIds.forEach((product) => {
      if (!product.discountId) {
        return;
      }

      if (!productByDiscount) {
        productByDiscount = {};
      }

      if (productByDiscount[product.discountId] === undefined) {
        productByDiscount[product.discountId] = [product];
      } else {
        productByDiscount[product.discountId].push(product);
      }
    });

    return productByDiscount;
  }

  private getSumQuantityProducts(products: Array<CartProductEntity | CartProductWithErrorEntity>) {
    let quantity = 0;

    products.forEach((product) => {
      if (product instanceof CartProductEntity) {
        quantity = quantity + product.quantity!;
      }
    });

    return quantity;
  }

  public getPriceProducts(
    products: Array<CartProductEntity | CartProductWithErrorEntity>,
    filter?: { isChecked?: boolean },
  ) {
    let result = {
      price: 0,
      priceWithoutDiscount: 0,
    };

    products.forEach((product) => {
      if (product instanceof CartProductEntity) {
        if (filter !== undefined && filter.isChecked !== undefined) {
          if (product.isChecked === filter.isChecked) {
            result.price = result.price + product.checkedSum.currentPrice;
            result.priceWithoutDiscount = result.priceWithoutDiscount + product.checkedSum.currentPriceWithoutSale;
          }
        } else {
          result.price = result.price + product.currentPrice;
          result.priceWithoutDiscount = result.priceWithoutDiscount + product.currentPriceWithoutSale;
        }
      }
    });

    return result;
  }

  public getPriceProductsByServer(products: Array<CartProductEntity>) {
    const result = {
      price: 0,
      priceWithoutDiscount: 0,
    };

    products.forEach((product) => {
      if (product instanceof CartProductEntity) {
        result.price = result.price + product.price * product.quantity;
        result.priceWithoutDiscount =
          result.priceWithoutDiscount + (product.price + product.discount) * product.quantity;
      }
    });

    return result;
  }
}
