import { Icons } from '@wikkeo/ui-kit';
import WiThemeButton from '~/shared/ui/WiThemeButton/ui/index.vue';
import {
  getMobileControlMenuProfilesIsLoginUser,
  getMobileControlMenuProfilesIsLoginVendor,
  getMobileControlMenuProfilesNotLogin,
  getMobileControlUserLinks,
} from '~/shared/config/mobileControlMenuList';
import WiNuxtErrorBoundary from '~/shared/ui/WiNuxtErrorBoundary/index.vue';
import { StringUtils } from '~/shared/utils/string';

// @ts-ignore
const { WiChevron } = Icons;

export default defineNuxtComponent({
  name: 'WiMobileControlUserMenuContent',

  components: {
    WiChevron,
    WiThemeButton,
    WiNuxtErrorBoundary,
  },

  setup() {
    const { t } = useI18n();

    const userInfo = ref<any>(null);

    const links = ref(getMobileControlUserLinks(t));

    const getMobileProfileMenu = computed((): any => {
      if (!userInfo.value) {
        return getMobileControlMenuProfilesNotLogin(t);
      }
      if (userInfo.value?.isVendor) {
        return getMobileControlMenuProfilesIsLoginVendor(t);
      } else {
        return getMobileControlMenuProfilesIsLoginUser(t);
      }
    });

    const onApplyLinkAction = (action: string | undefined) => {
      return;
    };

    const userLogout = () => {
      return;
    };

    return {
      userInfo,
      StringUtils,
      links,
      getMobileProfileMenu,

      onApplyLinkAction,
      userLogout,
    };
  },
});
