import { Icons } from '@wikkeo/ui-kit';
import WiHeaderSearchOptionsButton from '~/shared/ui/header/legacy/WiHeaderSearchOptionsButton/index.vue';
import WiNuxtErrorBoundary from '~/shared/ui/WiNuxtErrorBoundary/index.vue';
import WiSubstrateArea from '~/widgets/WiSubstrateArea/index.vue';

const { WiSearch, WiClose } = Icons;

import { useRoute } from 'vue-router';
import { useSubstrateManagementHook } from '~/shared/hooks/useSubstrateManagement';
import useAppRouter from '~/shared/hooks/useAppRouter';
import { eventService } from '~/shared/services/eventService';
import { EventServiceEventEnum } from '~/shared/services/eventService/types';
import type { IImage } from '~/shared/api/types';
import { MIN_SEARCH_AUTOCOMPLETE_LENGTH } from '~/shared/config/search';
import WiCustomImage from '~/shared/ui/WiCustomImage/index.vue';
import { scrollElementTo } from '~/shared/browserAPI/scrollElementTo';
import { scrollToElementPaddingOffset } from '~/shared/config/common';

export default defineNuxtComponent({
  name: 'WiHeaderSearchBlock',

  components: {
    WiSearch,
    WiHeaderSearchOptionsButton,
    WiClose,
    WiSubstrateArea,
    WiNuxtErrorBoundary,
    WiCustomImage,
  },

  props: {
    isMobile: {
      type: Boolean,
    },

    shopAvatar: {
      type: Object as PropType<IImage | null>,
      default: null,
    },
  },

  emits: ['handlerCloseModal', 'onApplySearch'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const { isShowSubstrate, handleOpenSubstrate } = useSubstrateManagementHook();

    const router = useAppRouter();

    const route = toRefs(useRoute());

    const { $api } = useNuxtApp();

    const userStore = useGlobalUserStore();

    const { userDataForSearch } = storeToRefs(userStore);

    const isOpenSearchResult = ref(false);

    const { isMobile } = toRefs(props);

    // const dispatch = useStore().dispatch

    const searchInputValue = ref('');

    const searchInfo = ref(null) as any;

    const timeout = ref(null) as any;

    const inputSearchRef = ref<null | HTMLElement>(null);

    // const defaultImageLink = DEFAULT_IMG_PATH;

    const shopData = computed(() => {
      if (!userDataForSearch.value?.shop) {
        return null;
      }

      return userDataForSearch.value.shop;
    });

    const categoryData = computed(() => {
      if (!userDataForSearch.value?.category) {
        return null;
      }

      return userDataForSearch.value.category;
    });

    const isSearchLimit = computed(() => {
      return searchInputValue.value.length < 2;
    });

    const searchPlaceholder = computed(() => {
      if (userDataForSearch.value?.selectOption === 'shop' && shopData?.value.name) {
        let maxLengthName = 25;

        if (isMobile.value) {
          maxLengthName = 15;
        }

        let shopName = shopData.value.name;

        if (shopName.length > maxLengthName) {
          shopName = shopName.slice(0, maxLengthName - 1) + '...';
        }

        return `${t('wiHeaderSearchBlock.searchIn')} ${shopName}`;
      }

      if (userDataForSearch.value?.selectOption === 'category' && categoryData?.value.name) {
        let maxLengthName = 25;

        if (isMobile.value) {
          maxLengthName = 15;
        }

        let categoryName = categoryData.value.name;

        if (categoryName.length > maxLengthName) {
          categoryName = categoryName.slice(0, maxLengthName - 1) + '...';
        }

        return `${t('wiHeaderSearchBlock.searchInCategory')} ${categoryName}`;
      }

      return t('wiHeaderSearchBlock.searchInWikkeo');
    });

    const isSearchBySKU = computed(() => {
      if (searchInputValue.value === '') {
        return false;
      }

      return searchInputValue.value.toLowerCase().includes('wvp');
    });

    const isEmptySearchResult = computed(() => {
      if (!searchInfo.value) {
        return true;
      }

      let isEmpty = true;

      for (const key in searchInfo.value) {
        if (searchInfo.value[key].length > 0) {
          isEmpty = false;
          break;
        }
      }

      return isEmpty;
    });

    watch(isShowSubstrate, () => {
      isOpenSearchResult.value = isShowSubstrate.value;
    });

    watch(searchInputValue, () => {
      handlerSearchInputValue();
    });

    onMounted(() => {
      getSearchString();
    });

    const imgSrc = (sizes: any) => {
      return;
      // if (!sizes) {
      //   return '';
      // }
      // return getDefaultImgSrc(sizes);
    };

    const getSearchString = async () => {
      const searchParams = new URLSearchParams(location.search).get('search');

      if (searchParams) {
        searchInputValue.value = searchParams;
        return;
      }
    };

    const handlerGoSearchPage = async () => {
      if (userDataForSearch.value?.selectOption === 'shop' || userDataForSearch.value?.selectOption === 'category') {
        if (inputSearchRef.value) {
          inputSearchRef.value.blur();
        }

        const filterParams = getRouteFilterParams();

        handlerSearchInShop(filterParams);

        handlerShowSearchResult(false);

        emit('onApplySearch');

        scrollToShopProductList();

        return;
      }

      if (!searchInputValue.value.length) {
        return;
      }

      const currentPath = router.route.value.path;

      await router.goToSearch(searchInputValue.value);

      if (currentPath === '/search') {
        triggerSearchReload();
      }

      handlerShowSearchResult(false);
    };

    const getRouteFilterParams = () => {
      const routeParams = route?.query?.value as any;

      if (!routeParams) {
        return null;
      }

      return routeParams;
    };

    const handlerSearchInShop = async (params: any) => {
      handlerShowSearchResult(false);

      scrollToShopProductList();

      if (!params) {
        await router.push({
          path: location.pathname,
          query: {
            tab: 'products',
            search: searchInputValue.value,
          },
        });

        triggerSearchReload();

        handlerShowSearchResult(false);

        return;
      }

      const queryParams = params;

      queryParams.page = 1;

      if (!searchInputValue.value.length) {
        userStore.setDataForSearch({
          key: 'query',
          value: undefined,
        });

        delete queryParams['search'];

        await router.push({
          query: {
            ...queryParams,
            tab: 'products',
          },
          force: true,
        });

        triggerSearchReload();

        handlerShowSearchResult(false);
        return;
      }

      await router.push({
        query: {
          ...queryParams,
          tab: 'products',
          search: searchInputValue.value,
        },
        force: true,
      });

      triggerSearchReload();

      handlerShowSearchResult(false);
    };

    const handlerClickTag = (payload: any) => {
      if (userDataForSearch.value?.selectOption === 'shop') {
        handlerClickTagWithShop(payload.search);

        return;
      }

      handlerGoSearchPageWithParams(payload);
    };

    const handlerClickCategory = (payload: any) => {
      if (userDataForSearch.value?.selectOption === 'shop') {
        handlerClickCategoryWithShop(payload.category_id);

        return;
      }

      handlerGoSearchPageWithParams(payload);
    };

    const handlerClickTagWithShop = (query: string) => {
      searchInputValue.value = query;

      const filterParams = getRouteFilterParams();

      handlerSearchInShop(filterParams);
    };

    const handlerClickCategoryWithShop = (categoryId: string) => {
      const filterParams = getRouteFilterParams();

      if (!filterParams) {
        return;
      }

      if (!categoryId) {
        return;
      }

      searchInputValue.value = '';

      filterParams.category_id = categoryId;

      handlerSearchInShop(filterParams);
    };

    const handlerGoSearchPageWithParams = async (payload: any) => {
      if (!payload) {
        return;
      }

      const currentPath = router.route.value.path;

      await router.goToSearch(payload.search, { query: payload });

      if (currentPath === '/search') {
        triggerSearchReload();
      }

      handlerShowSearchResult(false);
    };

    const searchInfoAdapter = (searchInfo: any) => {
      return {
        ...searchInfo,
        categories: searchInfo.categories.map((item: any) => ({
          ...item,
          path: item.path ? item.path.replaceAll('/', ' / ') : item.path,
        })),
      };
    };

    const searchAutocomplete = async () => {
      if (searchInputValue.value.length < MIN_SEARCH_AUTOCOMPLETE_LENGTH) {
        return;
      }

      const payload = {
        query: searchInputValue.value,
      } as any;

      if (userDataForSearch.value?.shop && userDataForSearch.value?.selectOption === 'shop') {
        payload.shop_id = userDataForSearch.value.shop.id;
      }

      if (userDataForSearch.value?.category && userDataForSearch.value?.selectOption === 'category') {
        payload.category_id_with_nested = userDataForSearch.value.category.id;
      }

      userStore.setDataForSearch({
        key: 'query',
        value: searchInputValue.value,
      });

      const data = await $api.legacySearch.searchAutocompleteRequest({ params: payload, isRaw: true });

      if (isSearchLimit.value) {
        searchInfo.value = null;
        return;
      }

      searchInfo.value = searchInfoAdapter(data);
    };

    const handlerSearchInputValue = () => {
      if (isSearchLimit.value) {
        searchInfo.value = null;
        return;
      }
      createDebounce(searchAutocomplete, 300);
    };

    const createDebounce = (func: Function, delay = 500) => {
      clearTimeout(timeout.value);
      timeout.value = setTimeout(() => {
        func();
      }, delay);
    };

    const handlerShowSearchResult = (value: boolean) => {
      isOpenSearchResult.value = value;

      handleOpenSubstrate(isOpenSearchResult.value);
    };

    const handlerCloseModal = () => {
      if (!isOpenSearchResult.value) {
        return;
      }

      emit('handlerCloseModal');
    };

    const handlerChangeSearchOption = (value: string | null) => {
      if (userDataForSearch.value?.selectOption === value) {
        return;
      }

      userStore.setDataForSearch({ key: 'selectOption', value: value });

      handlerSearchInputValue();
    };

    const triggerSearchReload = () => {
      eventService.triggerEvent(EventServiceEventEnum.wikkeoSearchReload, router.route.value.path);
    };

    const getShopLink = (link: string) => router.getShopLink(link);
    const getProductLink = (slug: string) => router.getProductLink(slug);

    const onClickLink = () => {
      handlerShowSearchResult(false);
    };

    const onFocus = () => {
      if (!isShowSubstrate.value) {
        handlerShowSearchResult(true);
      }
    };

    const scrollToShopProductList = () => {
      if (router.route.value.meta.isShopPage) {
        if (isMobile.value) {
          scrollElementTo('wiShopMobileBody', 16);
        } else {
          scrollElementTo('wiShopDesktopBody', scrollToElementPaddingOffset);
        }
      }
    };

    return {
      isSearchLimit,
      isSearchBySKU,
      isEmptySearchResult,
      searchInputValue,
      searchInfo,
      // defaultImageLink,
      searchPlaceholder,
      shopData,
      categoryData,
      userDataForSearch,
      inputSearchRef,
      isOpenSearchResult,
      isShowSubstrate,

      handlerGoSearchPage,
      handlerShowSearchResult,
      imgSrc,
      handlerCloseModal,
      handlerClickTag,
      handlerClickCategory,
      handlerChangeSearchOption,
      handlerSearchInputValue,

      getShopLink,
      getProductLink,
      onClickLink,
      onFocus,
    };
  },
});
