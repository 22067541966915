import { Icons } from '@wikkeo/ui-kit';
import { scrollToTop } from '~/shared/browserAPI/scrollToTop';
import { version as chatVersion } from '@wikkeo/wikkeo-chat-library';
import { infoFilesLinks } from '~/shared/config/infoFilesLinks';

// @ts-ignore
const { WiArrow, WiSliderChevron, WiTelegram, WiVkontakte, WiYoutube, WiLogoFull } = Icons;

export default defineNuxtComponent({
  name: 'WiCommonFooter',

  components: {
    WiArrow,
    WiSliderChevron,
    WiTelegram,
    WiVkontakte,
    WiYoutube,
    WiLogoFull,
  },

  setup() {
    const { t } = useI18n();

    const config = useRuntimeConfig();

    const socialNetworks = {
      WiYoutube: config.public.social.youtube,
      WiTelegram: config.public.social.telegram,
      WiVkontakte: config.public.social.vkontakte,
    };

    const site = [
      {
        label: t('footer.forSellers'),
        link: '/for-vendors',
      },
      {
        label: t('common.help'),
        link: '/help',
      },
    ];

    return {
      config,
      socialNetworks,
      site,
      info: infoFilesLinks(t),
      scrollToTop,
      chatVersion,
    };
  },
});
