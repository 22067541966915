import { CartProductAdapter } from '~/shared/adapters/v1/cartProduct';
import type { ICartsRequestProduct, ICartsResponse, ICartsResponseErrors } from '~/shared/api/v1/carts/types';
import { StorageService } from '~/shared/browserAPI/storageService';
import { useFeatureAddProductToCartApi } from '../api';
import type { CartProductEntity } from '~/entities/cartProduct';
import { FunctionUtils } from '~/shared/utils/function';
import { getCartStore } from '~/features/cart/getCartStore';
import type { CartProductWithErrorEntity } from '~/entities/cartProductWithError';

export const useFeatureAddProductToCartModel = () => {
  const { $i18n } = useNuxtApp();

  const notificationStore = globalNotificationsStore();

  const userStore = useGlobalUserStore();

  const cartStore: TCartStore = globalCartStore();

  const { user } = storeToRefs(userStore);

  const { dataCartStore } = storeToRefs(getCartStore(cartStore));

  const productsForDelete = ref<Array<CartProductEntity | CartProductWithErrorEntity>>([]);

  const storageService = new StorageService('local');

  const cartProductAdapter = new CartProductAdapter();

  const isChangedProduct = ref(false);

  const { validateProductsFromStorageApi, getProductsFromCartApi, addProductsToCartApi, removeProductsToCartApi } =
    useFeatureAddProductToCartApi();

  const synchronizationCartWhenExitPage = () => {
    if (!dataCartStore.value) {
      return;
    }

    if (isChangedProduct.value) {
      changeProductsToCartApiAuthorizedUser();
    }

    if (productsForDelete.value.length) {
      removeProductsInCartAuthorizedUser();
    }
  };

  const setCartEntityFromLocalStorageToStore = () => {
    if (user.value) {
      setProductsFromCartsApiAndStorageAuthorizeUser();
    } else {
      setProductsFromStorageToStore();
    }
  };

  const setProductsFromCartsApiAndStorageAuthorizeUser = async () => {
    if (!dataCartStore.value.cartEntity) {
      return;
    }

    const cartProductsFromStorage = getProductsFromLocalStorage();

    if (cartProductsFromStorage.length) {
      setProductsToCartApiAuthorizedUserFromStorage();
    } else {
      const cart = await getProductsFromCartApi();

      if (!cart) {
        return;
      }

      const cartProducts = cart.lines.map((line) =>
        cartProductAdapter.fromApiCarts({
          product: line.product,
          productOption: line.productOption,
          productOptionValue: line.productOptionValue,
          productOptionId: line.productOptionId,
          productOptionValueId: line.productOptionValueId,
          shop: line.shop,
          quantity: line.quantity,
          cartEntityKey: 'productsCart',
          pricePrevious: line.pricePrevious,
          price: line.price,
          quantityPrevious: line.quantityPrevious,
          isChecked: line.isChecked,
          discount: line.discount,
          discountPrevious: line.discountPrevious,
          errors: cart.errors,
        }),
      );

      const latestResponse = {
        sum: cart.sum,
        discount: cart.discount,
        errors: cart.errors,
      };

      dataCartStore.value.cartEntity.setLatestResponse(latestResponse);

      if (dataCartStore.value.cartOrderEntity) {
        dataCartStore.value.cartOrderEntity.setLatestResponse(latestResponse);
      }

      dataCartStore.value.cartEntity.setProducts(cartProducts, 'productsCart');
    }
  };

  const setProductsFromStorageToStore = async () => {
    if (!dataCartStore.value.cartEntity) {
      return;
    }

    const cartProductsFromStorage = getProductsFromLocalStorage();

    const validatedProduct = await getValidatedProducts(cartProductsFromStorage);

    dataCartStore.value.cartEntity.setProducts(validatedProduct, 'productsCart');
  };

  const getProductsFromLocalStorage = () => {
    const cartProducts = storageService.getItem('Cart') as Array<ICartsRequestProduct> | null;

    if (!Array.isArray(cartProducts)) {
      return [];
    }

    return cartProducts;
  };

  const getValidatedProducts = async (cartProducts: Array<ICartsRequestProduct>) => {
    const cart = await validateProductsFromStorageApi(cartProducts);

    if (cart && cart.lines.length && dataCartStore.value.cartEntity) {
      const cartProducts = cart.lines.map((line) =>
        cartProductAdapter.fromApiCarts({
          product: line.product,
          productOption: line.productOption,
          productOptionValue: line.productOptionValue,
          productOptionId: line.productOptionId,
          productOptionValueId: line.productOptionValueId,
          shop: line.shop,
          quantity: line.quantity,
          pricePrevious: line.pricePrevious,
          price: line.price,
          quantityPrevious: line.quantityPrevious,
          discount: line.discount,
          discountPrevious: line.discountPrevious,
          cartEntityKey: 'productsCart',
          isChecked: line.isChecked,
          errors: cart.errors,
        }),
      );

      const latestResponse = {
        sum: cart.sum,
        discount: cart.discount,
        errors: cart.errors,
      };

      dataCartStore.value.cartEntity.setLatestResponse(latestResponse);

      if (dataCartStore.value.cartOrderEntity) {
        dataCartStore.value.cartOrderEntity.setLatestResponse(latestResponse);
      }

      return cartProducts;
    }

    return [];
  };

  const changeProductsToCart = () => {
    if (user.value) {
      isChangedProduct.value = true;

      debounceChangeProductsToCartApiAuthorizedUser();
    } else {
      setCartProductsToStorage();
    }
  };

  const removeProducts = (products: Array<CartProductEntity | CartProductWithErrorEntity>) => {
    if (user.value) {
      removeProductsAuthorizedUser(products);
    } else {
      removeProductsUnauthorizedUser(products);
    }
  };

  const removeProductsUnauthorizedUser = (products: Array<CartProductEntity | CartProductWithErrorEntity>) => {
    if (!dataCartStore.value.cartEntity) {
      return;
    }

    dataCartStore.value.cartEntity.removeProducts(products, 'productsCart');

    setCartProductsToStorage();
  };

  const removeProductsAuthorizedUser = (products: Array<CartProductEntity | CartProductWithErrorEntity>) => {
    if (!dataCartStore.value.cartEntity) {
      return;
    }

    productsForDelete.value = [...productsForDelete.value, ...products];

    dataCartStore.value.cartEntity.removeProducts(productsForDelete.value, 'productsCart');

    debounceRemoveProductsInCartAuthorizedUser();
  };

  const removeProductsInCartAuthorizedUser = async () => {
    if (!productsForDelete.value.length) {
      return;
    }

    const productsForCartApi = productsForDelete.value.map((product) =>
      cartProductAdapter.fromEntityToApiCarts(product),
    );

    productsForDelete.value = [];

    const cart = await removeProductsToCartApi(productsForCartApi);

    if (!cart) {
      return;
    }

    if (dataCartStore.value.cartEntity) {
      const latestResponse = {
        sum: cart.sum,
        discount: cart.discount,
        errors: cart.errors,
      };

      dataCartStore.value.cartEntity.setLatestResponse(latestResponse);
    }

    setProductsToStoreFromApi(cart);

    setCartProductsToStorage();
  };

  const addCandidatesProductsToCart = () => {
    if (user.value) {
      addCandidatesProductsToCartApiAuthorizedUser();
    } else {
      addCandidatesProductsToStorageAndStoreCartNotAuthorizedUser();
    }
  };

  const changeProductsToCartApiAuthorizedUser = async () => {
    if (!dataCartStore.value.cartEntity) {
      return;
    }

    const payload = dataCartStore.value.cartEntity
      .getProductWithQuantity('productsCart')
      .map((product) => cartProductAdapter.fromEntityToApiCarts(product));

    if (payload.length === 0) {
      return;
    }

    const cart = await addProductsToCartApi(payload);

    isChangedProduct.value = false;

    if (!cart) {
      return;
    }

    const latestResponse = {
      sum: cart.sum,
      discount: cart.discount,
      errors: cart.errors,
    };

    dataCartStore.value.cartEntity.setLatestResponse(latestResponse);

    updateErrorsShops(cart.errors);
  };

  const setProductsToCartApiAuthorizedUserFromStorage = async () => {
    if (!dataCartStore.value.cartEntity) {
      return;
    }

    const payloadWithQuantity = getProductsFromLocalStorage().filter((product) => product.quantity > 0);

    const cart = await addProductsToCartApi(payloadWithQuantity);

    if (!cart) {
      return;
    }

    setProductsToStoreFromApi(cart);

    const latestResponse = {
      sum: cart.sum,
      discount: cart.discount,
      errors: cart.errors,
    };

    dataCartStore.value.cartEntity.setLatestResponse(latestResponse);

    updateErrorsShops(cart.errors);

    storageService.removeItem('Cart');
  };

  const updateErrorsShops = (errorsData: Array<ICartsResponseErrors>) => {
    if (!dataCartStore.value.cartEntity) {
      return;
    }
    const shopsErrors = errorsData.filter((elem) => elem.entity === 'shop');

    if (shopsErrors.length === 0) {
      dataCartStore.value.cartEntity.getAllProducts('productsCart').forEach((productCart) => {
        productCart.changeProductShopErrors([]);
      });

      return;
    }

    shopsErrors.forEach((shopError) => {
      if (!dataCartStore.value.cartEntity) {
        return;
      }

      dataCartStore.value.cartEntity.getAllProducts('productsCart').forEach((productCart) => {
        if (productCart.shopId === shopError.id) {
          productCart.changeProductShopErrors(shopError.messages);
        }
      });
    });
  };

  const addCandidatesProductsToCartApiAuthorizedUser = async () => {
    if (!dataCartStore.value.cartEntity) {
      return;
    }

    combineCandidatesAndCartProducts();

    const payload = dataCartStore.value.cartEntity
      .getProductWithQuantity('productsCart')
      .map((product) => cartProductAdapter.fromEntityToApiCarts(product));

    if (payload.length !== 0) {
      const cart = await addProductsToCartApi(payload);

      if (cart) {
        setProductsToStoreFromApi(cart);

        notificationStore.addNotificationsPending([
          {
            title: '',
            message: $i18n.t('success.addToCart'),
            type: EnumNotificationItemStatus.Success,
          },
        ]);
      }
    }

    storageService.removeItem('Cart');
  };

  const setProductsToStoreFromApi = (cartResponse: ICartsResponse) => {
    if (!dataCartStore.value.cartEntity) {
      return;
    }

    const cartProducts = cartResponse.lines.map((line) =>
      cartProductAdapter.fromApiCarts({
        product: line.product,
        productOption: line.productOption,
        productOptionValue: line.productOptionValue,
        productOptionId: line.productOptionId,
        productOptionValueId: line.productOptionValueId,
        shop: line.shop,
        quantity: line.quantity,
        pricePrevious: line.pricePrevious,
        price: line.price,
        quantityPrevious: line.quantityPrevious,
        discount: line.discount,
        discountPrevious: line.discountPrevious,
        cartEntityKey: 'productsCart',
        isChecked: line.isChecked,
        errors: cartResponse.errors,
      }),
    );

    dataCartStore.value.cartEntity.setProducts(cartProducts, 'productsCart');
  };

  const addCandidatesProductsToStorageAndStoreCartNotAuthorizedUser = () => {
    combineCandidatesAndCartProducts();

    setCartProductsToStorage();

    notificationStore.addNotificationsPending([
      {
        title: '',
        message: $i18n.t('success.addToCart'),
        type: EnumNotificationItemStatus.Success,
      },
    ]);
  };

  const setCartProductsToStorage = () => {
    if (!dataCartStore.value.cartEntity) {
      return;
    }

    const payload = dataCartStore.value.cartEntity
      .getProductWithQuantity('productsCart')
      .map((product) => cartProductAdapter.fromEntityToApiCarts(product));

    storageService.setItem('Cart', payload);
  };

  const combineCandidatesAndCartProducts = () => {
    if (!dataCartStore.value.cartEntity) {
      return;
    }

    const productsCandidatesArray = getProductCandidatesForCart();

    if (!productsCandidatesArray.length) {
      return;
    }

    dataCartStore.value.cartEntity.addProductsFromCandidatesToCart(productsCandidatesArray);
  };

  const getProductCandidatesForCart = () => {
    if (!dataCartStore.value.cartEntity) {
      return [];
    }

    return dataCartStore.value.cartEntity.getProductWithQuantity('productsCandidates');
  };

  const clearProductsForCart = () => {
    if (!dataCartStore.value.cartEntity) {
      return;
    }

    dataCartStore.value.cartEntity.removeAllProducts();

    storageService.removeItem('Cart');
  };

  const debounceChangeProductsToCartApiAuthorizedUser = FunctionUtils.debounce(changeProductsToCartApiAuthorizedUser);

  const debounceRemoveProductsInCartAuthorizedUser = FunctionUtils.debounce(removeProductsInCartAuthorizedUser);

  return {
    synchronizationCartWhenExitPage,
    addCandidatesProductsToCart,
    changeProductsToCart,
    setCartEntityFromLocalStorageToStore,
    removeProducts,
    clearProductsForCart,
  };
};
