import { Icons } from '@wikkeo/ui-kit';
import WiNuxtErrorBoundary from '~/shared/ui/WiNuxtErrorBoundary/index.vue';

import type { IApiCategoriesTree } from '~/shared/api/v1/categories/types';
import useAppRouter from '~/shared/hooks/useAppRouter';

// @ts-ignore
const { WiChevron } = Icons;

export default defineNuxtComponent({
  name: 'WiMobileControlCategoryContent',

  components: {
    WiChevron,
    WiNuxtErrorBoundary,
  },

  props: {
    categories: {
      type: Array as PropType<Array<IApiCategoriesTree> | null>,
      default: () => [],
    },

    showCategory: {
      type: Object as PropType<any>,
      required: true,
    },
  },

  emits: ['onShowCategory'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const { showCategory } = toRefs(props);

    const router = useAppRouter();

    const isShowCategoryParentName = computed(() => {
      return !(showCategory.value[1] === null);
    });

    const getCategoryParentName = computed(() => {
      if (showCategory.value[2]?.name) {
        return showCategory.value[2].name;
      }

      if (showCategory.value[1]?.name) {
        return showCategory.value[1].name;
      }

      return t('wiMobileControlCategoryContent.selectCategory');
    });

    const showSubCategories = (data: any) => {
      emit('onShowCategory', data);
    };

    const goCategoryPage = () => {
      let slug = '';

      if (showCategory.value[3]) {
        slug = showCategory.value[3].slug;
      } else if (showCategory.value[2]) {
        slug = showCategory.value[2].slug;
      } else if (showCategory.value[1]) {
        slug = showCategory.value[1].slug;
      } else {
        return;
      }

      router.goToCategory(slug);
    };

    return {
      showCategory,
      isShowCategoryParentName,
      getCategoryParentName,

      showSubCategories,
      goCategoryPage,
    };
  },
});
