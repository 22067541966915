import { useGlobalUserStore } from '~/app/store/user';
import type { ILoginResponse } from '../api/v1/auth/types';
import useAppRouter from '../hooks/useAppRouter';
import { useStoreWithContext } from '../hooks/useStoreWithContext';

//@ts-ignore
import { useChatStore } from '@wikkeo/wikkeo-chat-library';
import { useFeatureAddProductToCart } from '~/features/cart/addProductsToCart';

export const logout = async (isMobile = false) => {
  const appRouter = useAppRouter();
  const userStore = useGlobalUserStore();
  const chatStore = useStoreWithContext(useChatStore);

  const { clearProductsForCart } = useFeatureAddProductToCart();

  if (appRouter.route.value.meta.isPrivate) {
    appRouter.goToHome();
  }

  userStore.removeTokens();
  userStore.removeUser();
  userStore.clearWishlist();

  clearProductsForCart();

  //@ts-ignore
  chatStore.resetChat();
};

export const setUser = (data: ILoginResponse) => {
  const userStore = useGlobalUserStore();

  const { setCartEntityFromLocalStorageToStore } = useFeatureAddProductToCart();

  userStore.setTokens({ accessToken: data.accessToken, refreshToken: data.refreshToken });

  userStore.setUser(data.user);

  setCartEntityFromLocalStorageToStore();
};
