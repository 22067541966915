import type { IUserUpdatePayload } from '~/shared/api/v1/auth/types';
import useConfirmPhoneNumberApi from '../api';

export default function useConfirmPhoneNumberModel() {
  const userStore = useGlobalUserStore();
  const { updateUser } = useConfirmPhoneNumberApi();

  const isLoading = ref(false);

  const updateUserData = async (user: IUserUpdatePayload, succsessFunction: Function) => {
    try {
      isLoading.value = true;

      const res = await updateUser(user);

      if (res) {
        userStore.setUser(res.user);
        succsessFunction();
      }
    } finally {
      isLoading.value = false;
    }
  };

  return { isLoading, updateUserData };
}
