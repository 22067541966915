import { CartProductEntity } from '~/entities/cartProduct';
import { ProductPriceType } from '~/shared/api/v1/products/types';

export const getInputCountProps = (cartProductEntity: CartProductEntity | null) => {
  let defaultProps = {
    step: 1,
    min: 0,
    max: Infinity,
  };

  if (cartProductEntity) {
    let minValue = 0;

    if (cartProductEntity.priceType === ProductPriceType.ByQuantity) {
      minValue = Infinity;

      cartProductEntity.option.value.discounts.forEach((elem) => {
        minValue = elem.from < minValue ? elem.from : minValue;
      });
    } else {
      minValue = cartProductEntity.option.value.step;
    }

    defaultProps.step = cartProductEntity.option.value.step;
    defaultProps.max = cartProductEntity.option.value.quantity;
    defaultProps.min = minValue;
  }

  return defaultProps;
};
