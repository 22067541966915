import type {
  IUserDeliveryProfileResponse,
  IUserFullDeliveryProfileResponse,
} from '~/shared/api/v1/userDeliveryProfiles/types';
import type { CartOrderByShopEntity } from '../cartOrderByShop';
import type { ILatestResponseCartOrder } from './types';

export class CartOrderEntity {
  cartOrderByShops: Array<CartOrderByShopEntity> = [];
  latestResponse: ILatestResponseCartOrder | null = null;
  deliveryProfile: IUserDeliveryProfileResponse | null = null;
  deliveryProfileFull: IUserFullDeliveryProfileResponse | null = null;
  discountPromotionsOrders: number = 0;

  public constructor(cartOrderByShops: Array<CartOrderByShopEntity>) {
    this.cartOrderByShops = cartOrderByShops;
  }

  public setLatestResponse(data: ILatestResponseCartOrder | null) {
    this.latestResponse = data ? { ...data } : null;
  }

  public setOrdersByShops(data: Array<CartOrderByShopEntity>) {
    this.cartOrderByShops = [...data];

    this.calculateDiscountPromotionsOrders()
  }

  public setDeliveryProfile(data: IUserDeliveryProfileResponse | null) {
    this.deliveryProfile = data ? { ...data } : null;

    if (data && this.deliveryProfileFull && data.id !== this.deliveryProfileFull.id) {
      this.setDeliveryProfileFull(null);
    }
  }

  public setDeliveryProfileFull(data: IUserFullDeliveryProfileResponse | null) {
    this.deliveryProfileFull = data ? { ...data } : null;
  }

  public calculateDiscountPromotionsOrders() {
    let sum = 0;

    this.cartOrderByShops.forEach((order) => {
      sum += order.discountPromotionOrder;
    });

    this.discountPromotionsOrders = sum;
  }
}
