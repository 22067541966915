import type { IGlobalUiConfigStore } from './types';

export const globalUiConfigActions = {
  setDisableMainScroll: function (this: IGlobalUiConfigStore, value: boolean) {
    this.isDisableMainScroll = value;
  },

  setIsAcceptPrivate: function (this: IGlobalUiConfigStore, value: boolean) {
    this.isAcceptPrivate = value;
  },

  setIsDarkMode: function (this: IGlobalUiConfigStore, value: boolean) {
    this.isDarkMode = value;
  },

  setIsMobile: function (this: IGlobalUiConfigStore, value: boolean) {
    this.isMobileFromStore = value;
  },
};
