import { useMobile } from '~/shared/hooks/useMobile';

export default defineNuxtRouteMiddleware((to, from) => {
  const { isMobile, checkWindowView } = useMobile();

  if (to.meta.isOnlyMobile) {
    checkWindowView();

    if (!isMobile.value) {
      return false;
    }
  }
});
