import { CartProductEntity } from '~/entities/cartProduct';
import { CartProductWithErrorEntity } from '~/entities/cartProductWithError';
import { useFeatureAddProductToCart } from '~/features/cart/addProductsToCart';
import { getCartStore } from '~/features/cart/getCartStore';
import { useCartPageApi } from '../api';
import useAppRouter from '~/shared/hooks/useAppRouter';
import type { IShippingCompaniesByShopsIdsResponse } from '~/shared/api/v1/carts/types';

export const useCartPageModel = () => {
  const { t } = useI18n();

  const cartStore = globalCartStore();

  const { dataCartStore } = storeToRefs(getCartStore(cartStore));

  const { removeProducts, changeProductsToCart } = useFeatureAddProductToCart();

  const { getShippingCompaniesByShopsIdsApi } = useCartPageApi();

  const router = useAppRouter();

  const isLoadingGoToCheckout = ref(false);

  const isDeleteMode = ref(false);

  const notificationStore = globalNotificationsStore();

  const shippingCompaniesByShopId = ref<Array<IShippingCompaniesByShopsIdsResponse>>([]);

  const products = computed(() => {
    if (!dataCartStore.value.cartEntity) {
      return [];
    }

    return dataCartStore.value.cartEntity.productsCart;
  });

  const arrayProductsWithoutError = computed(() => {
    return products.value.filter((product) => product instanceof CartProductEntity);
  });

  const isEmptyCart = computed(() => {
    return products.value.length === 0;
  });

  const shopsIdsWithProducts = computed(() => {
    if (!dataCartStore.value.cartEntity) {
      return {};
    }

    return (
      dataCartStore.value.cartEntity.getProductsByShops({ products: dataCartStore.value.cartEntity.productsCart }) || {}
    );
  });

  const isCheckedProducts = computed(() => {
    return arrayCheckedProducts.value.length > 0;
  });

  const arrayCheckedProducts = computed(() => {
    return products.value.filter((product) => product.isChecked);
  });

  const arrayCheckedProductsWithoutError = computed(() => {
    return arrayProductsWithoutError.value.filter((product) => product.isChecked);
  });

  const isErrorMinSum = computed(() => {
    let isError = false;

    if (!dataCartStore.value.cartEntity) {
      return isError;
    }

    for (const shopId in shopsIdsWithProducts.value) {
      const productsArray = shopsIdsWithProducts.value[shopId]
        .filter((product) => product instanceof CartProductEntity)
        .filter((product) => product.isChecked);
      if (!productsArray.length) {
        continue;
      }
      const sum = dataCartStore.value.cartEntity.getPriceProducts(productsArray, { isChecked: true });

      if (productsArray[0] && sum.price < productsArray[0].shop.sumMin) {
        return true;
      }
    }

    return isError;
  });

  const shopsIdsWithNotAvailableShippingCompanies = computed(() => {
    return shippingCompaniesByShopId.value
      .filter((elem) => {
        const indexCheckedProduct = arrayCheckedProducts.value.findIndex(
          (checkedProduct) => checkedProduct.shopId === elem.id,
        );
        return elem.shippingCompanies.length === 0 && indexCheckedProduct !== -1;
      })
      .map((elem) => elem.id);
  });

  const disabledBuyButtonText = computed(() => {
    if (shopsIdsWithNotAvailableShippingCompanies.value.length !== 0) {
      return t('cartPage.messageShopNotAllowedShippingCompanies');
    }

    if (arrayCheckedProducts.value.length === 0) {
      return t('cartPage.messageEmptyProducts');
    }

    if (isErrorMinSum.value) {
      return t('cartPage.messageShopMinSum');
    }

    if (shopsIdsWithNotAvailableShippingCompanies.value.length !== 0) {
      return t('cartPage.messageShopNotAllowedShippingCompanies');
    }

    const isCheckedError =
      arrayCheckedProducts.value.findIndex((elem) => elem instanceof CartProductWithErrorEntity) !== -1;

    if (isCheckedError) {
      return t('cartPage.messageNotAllowed');
    }

    return '';
  });

  const isPartialSelected = computed(() => {
    return arrayCheckedProducts.value.length !== products.value.length && arrayCheckedProducts.value.length > 0;
  });

  const changeDeleteMode = () => {
    isDeleteMode.value = !isDeleteMode.value;
  };

  const onDeleteProducts = () => {
    if (!arrayCheckedProducts.value.length) {
      return;
    }

    removeProducts(arrayCheckedProducts.value);

    isDeleteMode.value = false;
  };

  const handleCheckAllProducts = (isCheckedValue: boolean) => {
    if (!dataCartStore.value.cartEntity) {
      return '';
    }

    const payload = products.value.map((elem) => {
      return {
        ids: {
          productId: elem.id,
          productOptionId: elem.productOptionId,
          productOptionValueId: elem.productOptionValueId,
        },
        isChecked: isCheckedValue,
      };
    });

    dataCartStore.value.cartEntity.changeCheckedProducts(payload, 'productsCart');

    if (arrayProductsWithoutError.value.length) {
      changeProductsToCart();
    }
  };

  const checkShippingCompaniesShops = async () => {
    const shopsIds: Array<number> = [];

    products.value.forEach((elem) => {
      if (!shopsIds.includes(elem.shopId)) {
        shopsIds.push(elem.shopId);
      }
    });

    const response = await getShippingCompaniesByShopsIdsApi(shopsIds);

    if (!response) {
      return false;
    }

    shippingCompaniesByShopId.value = [...response];

    return shopsIdsWithNotAvailableShippingCompanies.value.length === 0;
  };

  const handleGoToCheckout = async () => {
    isLoadingGoToCheckout.value = true;

    const isAvailable = await checkShippingCompaniesShops();

    isLoadingGoToCheckout.value = false;

    if (!isAvailable) {
      notificationStore.addNotificationsPending([
        {
          title: t('cartPage.unableToCheckout'),
          message: t('cartPage.unableToCheckoutErrorMessage'),
          type: EnumNotificationItemStatus.Error,
        },
      ]);

      return;
    }

    router.goToCheckout();
  };

  return {
    shopsIdsWithProducts,
    isCheckedProducts,
    isDeleteMode,
    isEmptyCart,
    isErrorMinSum,
    isPartialSelected,
    arrayCheckedProducts,
    arrayCheckedProductsWithoutError,
    disabledBuyButtonText,
    isLoadingGoToCheckout,
    shopsIdsWithNotAvailableShippingCompanies,

    changeDeleteMode,
    onDeleteProducts,
    handleCheckAllProducts,
    handleGoToCheckout,
  };
};
