import useWiSighInTelegramModel from '../model';

import WiNuxtErrorBoundary from '~/shared/ui/WiNuxtErrorBoundary/index.vue';

export type TTelegramAuthType = 'login' | 'register';

declare global {
  interface Window {
    tgWidgetLogin: (user: any) => void;
  }
}

export default defineNuxtComponent({
  name: 'WiSighInTelegram',

  components: {
    WiNuxtErrorBoundary,
  },

  props: {
    formType: {
      type: String as PropType<TTelegramAuthType>,
      default: 'login',
    },
  },

  setup(props) {
    const config = useRuntimeConfig();

    const { onTgWidgetLogin } = useWiSighInTelegramModel();

    const botName = ref(config.public.telegramNotificationBotName as string);

    const elRef = ref();

    const addTelegramWidgetScript = (el: any) => {
      const externalScript = document.createElement('script');
      externalScript.setAttribute('src', 'https://telegram.org/js/telegram-widget.js?22');
      externalScript.setAttribute('data-telegram-login', botName.value);
      externalScript.setAttribute('data-size', 'large');
      externalScript.setAttribute('data-request-access', 'write');
      externalScript.setAttribute('data-onauth', 'tgWidgetLogin(user)');

      el.appendChild(externalScript);

      window.tgWidgetLogin = async function (user: any) {
        await onTgWidgetLogin(props.formType, user);
      };
    };

    onNuxtReady(() => {
      addTelegramWidgetScript(elRef.value);
    });

    return {
      elRef,
    };
  },
});
