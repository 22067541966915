import { fractionalPart } from './fractionalPart';
import { roundDown } from './roundDown';
import { roundDownWithPostfix } from './roundDownForUi';
import { roundFloatWithPostfix } from './roundFloatWithPostfix';
import { toFloat } from './toFloat';
import { toLocaleString } from './toLocaleString';

export const NumberUtils = {
  roundDown,
  toFloat,
  toLocaleString,
  fractionalPart,
  roundDownWithPostfix,
  roundFloatWithPostfix,
};
