<template>
  <li class="wi-profile-menu">
    <WiProfileMenuItem
      v-for="profileMenuItem in profileMenu"
      :key="profileMenuItem.label"
      :menuItem="profileMenuItem"
      :shopOrdersStatusesStatistic="shopOrdersStatusesStatistic"
    />
  </li>
</template>

<script lang="ts" src="./index.ts" />
<style lang="scss" src="./index.scss" scoped />
