import { geocodeMapsUrl } from '~/shared/config/map';
import BaseApi from '../..';

class GeocodeYandexApi extends BaseApi {
  async getGeocodeYandex(geocode: string | Array<number>) {
    let query = `apikey=${useRuntimeConfig().public.apiYandexMapsKey}&format=json`;

    if (geocode) {
      if (typeof geocode === 'string') {
        query += `&geocode=${geocode}`;
      } else {
        query += `&geocode=${geocode[0]},${geocode[1]}`;
      }
    }

    return this.get<any>({ url: `${geocodeMapsUrl}?${query}` });
  }
}

export default GeocodeYandexApi;
