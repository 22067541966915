export default class ErrorNetwork extends Error {
  errors: Array<any>;

  constructor(data: { message?: string; errors?: Array<any> }) {
    // TODO localization default messages
    super(data.message || 'Упс, что-то пошло не так');

    this.name = 'Network';
    this.errors = data.errors || [];
  }
}
