import { Icons } from '@wikkeo/ui-kit';

// @ts-ignore
const { WiGeoLocation } = Icons;

export default defineNuxtComponent({
  name: 'WiUserGeoLocation',

  components: {
    WiGeoLocation,
  },
});
