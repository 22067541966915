import { checkIsServer } from '~/shared/browserAPI/isServer';

const getCSSVariable = (name: string) => {
  if (checkIsServer()) {
    return '';
  }

  return getComputedStyle(document.documentElement).getPropertyValue(name);
};

export const DOMUtils = {
  getCSSVariable,
};
