import BaseApi from '../..';
import type { IRequestParamsHandler } from '../../types';
import type { IAddressApi } from './types';

class AddressesApiV1 extends BaseApi {
  private VERSION = 'v1';
  private RESOURCE = 'addresses';

  async getAddressesAutocomplete(params?: IRequestParamsHandler): Promise<Array<IAddressApi>> {
    const url = `${this.VERSION}/${this.RESOURCE}/autocomplete`;

    return this.get({
      url,
      query: params?.params,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
      isRaw: params?.isRaw,
    });
  }
}

export default AddressesApiV1;
