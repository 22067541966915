import { formattedPrice } from './formattedPrice';
import { generateUid } from './generateUid';
import { getFullName } from './getFullName';
import { getOnlyNumbers } from './getOnlyNumbers';
import { getReviewAuthorName } from './getReviewAuthorName';
import { getUserInitials } from './getUserInitials';
import { getUserName } from './getUserName';
import { lcFirst } from './lcFirst';
import { parseBoolean } from './parseBoolean';

export const StringUtils = {
  lcFirst,
  generateUid,
  getUserInitials,
  getFullName,
  getOnlyNumbers,
  parseBoolean,
  getUserName,
  formattedPrice,
  getReviewAuthorName,
};
