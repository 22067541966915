import { useChatStore } from '@wikkeo/wikkeo-chat-library';
import { StorageService } from '~/shared/browserAPI/storageService';
import { logout } from '~/shared/helpers/auth';
import { useStoreWithContext } from '~/shared/hooks/useStoreWithContext';
import { StringUtils } from '~/shared/utils/string';
import { useDefaultLayoutApi } from '../api';
import { sentryLog } from '~/shared/helpers/sentryLog';
import { useWish } from '~/shared/hooks/useWish';

const storageService = new StorageService('local');

export const useDefaultLayoutModel = () => {
  const userStore = useGlobalUserStore();
  const defaultLayoutApi = useDefaultLayoutApi();

  const setTokens = () => {
    const accessToken = storageService.getItem<string | null>('AccessToken', false);
    const refreshToken = storageService.getItem<string | null>('RefreshToken', false);

    if (accessToken !== null && refreshToken !== null) {
      userStore.setTokens({ accessToken: accessToken, refreshToken: refreshToken });
    }
  };

  const getUserInfo = async () => {
    const accessToken = storageService.getItem<string | null>('AccessToken', false);
    const user = storageService.getItem<IUser | null>('User');

    if (!accessToken) {
      userStore.changeLoadingUser(false);

      return;
    }

    const config = useRuntimeConfig();
    const chatStore = useStoreWithContext(useChatStore);
    const isChatDisabled = StringUtils.parseBoolean(config.public.chat.isChatDisabled);
    const { loadWishlistIds } = useWish();

    try {
      let userInfo = await defaultLayoutApi.auth();

      if (!userInfo) {
        return;
      }

      userStore.setUser(userInfo);

      loadWishlistIds();

      if (isChatDisabled) {
        return;
      }

      if (user?.id !== userInfo.id) {
        await chatStore.reloadChatUser();
      } else {
        await chatStore.initChat();
      }

      // TODO: rewrite this "if" logic
      // if (
      //   (now < accessTokenDecoded.exp && user?.id !== accessTokenDecoded.id) ||
      //   (user?.id === accessTokenDecoded.id && !userStore.user)
      // ) {
      // }
    } catch (error) {
      sentryLog(error as Error);
      console.error(error);
    } finally {
      userStore.changeLoadingUser(false);
    }
  };

  return {
    logout,
    getUserInfo,
    setTokens,
  };
};
