import { Icons } from '@wikkeo/ui-kit';
import { changeThemeMode } from '~/shared/helpers/themeMode';
import { broadcastMessages } from '~/shared/services/BroadcastChannel';
import { BroadcastChannelEventEnum } from '~/shared/services/BroadcastChannel/types';

// @ts-ignore
const { WiDarkModeIcon, WiLiteModeIcon } = Icons;

export default defineNuxtComponent({
  name: 'WiThemeButton',

  components: {
    WiDarkModeIcon,
    WiLiteModeIcon,
  },

  setup() {
    const onChangeThemeMode = () => {
      changeThemeMode();
      broadcastMessages.triggerEvent(BroadcastChannelEventEnum.toggleTheme);
    };

    return {
      onChangeThemeMode,
    };
  },
});
