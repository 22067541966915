import type { ICartProductEntityShopSumWholesale, ICartProductEntitySumWholesale } from '~/entities/cartProduct/types';
import type { IShopWholesale } from '~/shared/api/v1/shops/types';

export const getCurrentWholesaleByObjectKey = (
  productWholesale: ICartProductEntitySumWholesale | ICartProductEntityShopSumWholesale,
  shopWholesale: IShopWholesale,
) => {
  if (shopWholesale.sumLarge <= productWholesale.sumLarge) {
    return 'sumLarge';
  }

  if (shopWholesale.sumNormal <= productWholesale.sumNormal) {
    return 'sumNormal';
  }

  if (shopWholesale.sumSmall <= productWholesale.sumSmall) {
    return 'sumSmall';
  }

  return 'sumPrice';
};
