<template>
  <div class="wi-header-control-block__skeleton">
    <WiSkeleton height="48px" width="48px" />
    <WiSkeleton height="48px" width="48px" />
    <WiSkeleton height="48px" width="48px" />
  </div>
</template>

<style lang="scss" scoped>
.wi-header-control-block__skeleton {
  display: flex;
  align-items: center;
  column-gap: 12px;
}
</style>

<script lang="ts">
import { Components } from '@wikkeo/ui-kit';
//@ts-ignore
const { WiSkeleton } = Components;

export default defineNuxtComponent({
  name: 'WiHeaderControlBlockSkeleton',

  components: { WiSkeleton },
});
</script>
