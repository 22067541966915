import { StorageService } from '~/shared/browserAPI/storageService';
import type { IWishlistItemApiV1 } from '~/shared/api/v1/productWishlists/types';
import type { IGlobalUserStore, ITokens } from './types';
import type { IShopOrdersStatusesStatisticObject } from '~/shared/api/v1/personalAccount/types';

const storageService = new StorageService('local');

export const globalUserActions = {
  setTokens: async function (this: IGlobalUserStore, newTokens: ITokens) {
    storageService.setItem('AccessToken', newTokens.accessToken);
    storageService.setItem('RefreshToken', newTokens.refreshToken);

    this.tokens = newTokens;
  },

  removeTokens: function (this: IGlobalUserStore) {
    storageService.removeItem('AccessToken');
    storageService.removeItem('RefreshToken');

    this.tokens = {};
  },

  setUser: function (this: IGlobalUserStore, newUser: any) {
    storageService.setItem('User', newUser);

    this.user = newUser;
  },

  removeUser: function (this: IGlobalUserStore) {
    storageService.removeItem('User');

    this.user = null;
  },

  clearWishlist: function (this: IGlobalUserStore) {
    this.wishlistIds = null;

    storageService.removeItem('WishlistIds');
  },

  setWishlistIds: function (this: IGlobalUserStore, wishlistItems: Array<IWishlistItemApiV1>) {
    const adaptedWishlistsIds = wishlistItems.map((item) => {
      return item.productId;
    });

    this.wishlistIds = adaptedWishlistsIds;

    storageService.setItem('WishlistIds', adaptedWishlistsIds);
  },

  toggleProductInWishlist: async function (this: IGlobalUserStore, productId: number, value: boolean) {
    if (!this.wishlistIds) {
      return;
    }

    if (value) {
      this.wishlistIds = this.wishlistIds.filter((id) => {
        return id !== productId;
      });
    } else {
      this.wishlistIds?.push(productId);
    }

    storageService.setItem('WishlistIds', this.wishlistIds);
  },

  setDataForSearch: function (this: IGlobalUserStore, payload: TAnyLiteral) {
    const { key, value } = payload;

    if (!key) {
      return;
    }

    this.userDataForSearch[key] = value;
  },

  changeLoadingUser: function (this: IGlobalUserStore, payload: boolean) {
    this.isLoadingUserInfo = payload;
  },

  setShopOrdersStatusesStatistic: function (this: IGlobalUserStore, payload: IShopOrdersStatusesStatisticObject) {
    this.shopOrdersStatusesStatistic = payload;
  },
};
