import BaseApi from '../..';
import type { IRequestParamsHandler } from '../../types';
import type {
  ILoginPayload,
  ILoginResponse,
  IPhoneVerificationConfirmPayload,
  IPhoneVerificationRequestPayload,
  IRefreshRequestPayload,
  IRegisterPayload,
  IResetPasswordRequestConfirm,
  IResetPasswordRequestPayload,
  IUserResponse,
} from './types';

class AuthApiV1 extends BaseApi {
  private VERSION = 'v1';
  private RESOURCE = 'auth';

  async login(params: IRequestParamsHandler<ILoginResponse, ILoginPayload>) {
    const url = `${this.VERSION}/${this.RESOURCE}/login`;

    return this.post<ILoginResponse>({
      url,
      body: params?.data,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
      isSkipRefresh: true,
    });
  }

  async register(params: IRequestParamsHandler<ILoginResponse, IRegisterPayload>) {
    const url = `${this.VERSION}/${this.RESOURCE}/register`;

    return this.post<ILoginResponse>({
      url,
      body: params?.data,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
      isSkipRefresh: true,
    });
  }

  async loginByTelegram(params: IRequestParamsHandler<any>): Promise<ILoginResponse> {
    const url = `${this.VERSION}/${this.RESOURCE}/telegram/login`;

    return this.post({
      url,
      body: params?.data,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
      isSkipRefresh: true,
    });
  }

  async registerByTelegram(params: IRequestParamsHandler<any>): Promise<ILoginResponse> {
    const url = `${this.VERSION}/${this.RESOURCE}/telegram/register`;

    return this.post({
      url,
      body: params?.data,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
      isSkipRefresh: true,
    });
  }

  async phoneVerificationRequest(params: IRequestParamsHandler<IPhoneVerificationRequestPayload>) {
    const url = `${this.VERSION}/${this.RESOURCE}/phoneVerification/request`;

    return this.post<IPhoneVerificationRequestPayload>({
      url,
      body: params?.data,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }

  async phoneVerificationConfirm(params: IRequestParamsHandler<IPhoneVerificationConfirmPayload>) {
    const url = `${this.VERSION}/${this.RESOURCE}/phoneVerification/confirm`;

    return this.post<IPhoneVerificationConfirmPayload>({
      url,
      body: params?.data,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }

  async passwordResetRequest(params: IRequestParamsHandler<IResetPasswordRequestPayload>) {
    const url = `${this.VERSION}/${this.RESOURCE}/passwordReset/request`;

    return this.post<IResetPasswordRequestPayload>({
      url,
      body: params?.data,
      fetchOptions: params?.fetchOptions,
      errorOptions: params?.errorOptions,
    });
  }

  async passwordResetConfirm(params: IRequestParamsHandler<IResetPasswordRequestConfirm>) {
    const url = `${this.VERSION}/${this.RESOURCE}/passwordReset/confirm`;

    const requestAsyncData = useAsyncData(
      url,
      () =>
        this.post<IResetPasswordRequestConfirm>({
          url,
          body: params?.data,
          fetchOptions: params?.fetchOptions,
          errorOptions: params?.errorOptions,
        }),
      params.asyncDataOptions,
    );

    return requestAsyncData;
  }

  async auth() {
    return this.get<IUserResponse>({ url: `${this.VERSION}/${this.RESOURCE}` });
  }

  async refresh(payload: IRefreshRequestPayload) {
    return this.post<IRefreshRequestPayload>({
      url: `${this.VERSION}/${this.RESOURCE}/refresh`,
      body: payload,
    });
  }
}

export default AuthApiV1;
