import { sentryLog } from '~/shared/helpers/sentryLog';

export const useCartPageApi = () => {
  const { $api } = useNuxtApp();

  const { t } = useI18n();

  const notificationStore = globalNotificationsStore();

  const getShippingCompaniesByShopsIdsApi = async (shopIds: Array<number>) => {
    try {
      const data = await $api.carts.getShippingCompaniesByShopsIds({
        data: {
          shopIds,
        },
      });

      if (!data || !data.length) {
        notificationStore.addNotificationsPending([
          {
            message: t('error.contactTechnicalSupport'),
            type: EnumNotificationItemStatus.Error,
          },
        ]);
      }

      return data;
    } catch (error) {
      notificationStore.addNotificationsPending([
        {
          message: t('error.contactTechnicalSupport'),
          type: EnumNotificationItemStatus.Error,
        },
      ]);

      sentryLog(error as Error, 'error');

      console.error(error);

      return [];
    }
  };

  return {
    getShippingCompaniesByShopsIdsApi,
  };
};
