import useOrdersApi from '../api';
import { type IResponseWithPaging } from '~/shared/api/types';
import type { ICdekInfo, IPecInfo, IShopOrderApiV1 } from '~/shared/api/v1/personalAccount/types';
import { ORDERS_LIMIT } from '~/shared/config/orders';
import { defaultOrdersState } from './defaultState';
import type { IStatusHistoryItemResponse, TShippingServiceProvider } from '~/shared/api/v1/shippings/types';

export default function useOrdersModel() {
  const { getShopOrders, getDeliveryHistory } = useOrdersApi();

  const isLoading = ref(false);
  const isFullList = ref(false);

  const orders = ref<IResponseWithPaging<IShopOrderApiV1>>(defaultOrdersState);

  const isOpenTrackModal = ref(false);
  const integrationDeliveryHistory = ref<IPecInfo | ICdekInfo | null>(null);
  const deliveryHistory = ref<Array<IStatusHistoryItemResponse> | null>(null);
  const isLoadingDeliveryHistory = ref(false);
  const currentTrackCode = ref('');
  const currentCode = ref('');
  const currentTransportCompany = ref<TShippingServiceProvider | null>(null);
  const isOpenBarcodeModal = ref(false);

  const loadShopOrders = async (
    addNew: boolean,
    page: number,
    search?: string,
    filter?: TAnyLiteral,
    limit = ORDERS_LIMIT,
  ) => {
    if (!addNew) {
      clearOrderState();
      isFullList.value = false;
    }

    if (isFullList.value) {
      return;
    }

    isLoading.value = true;

    try {
      const res = await getShopOrders(page, limit, search, filter);

      if (res) {
        orders.value.items = addNew ? [...orders.value.items, ...res.items] : res.items;
        orders.value.limit = res.limit;
        orders.value.page = res.page;
        orders.value.total = res.total;

        isFullList.value = res.total === orders.value.items.length;
      }
    } finally {
      isLoading.value = false;
    }
  };

  const clearOrderState = () => {
    defaultOrdersState.items = [];
    defaultOrdersState.page = 1;
    defaultOrdersState.limit = ORDERS_LIMIT;
    defaultOrdersState.total = 0;

    orders.value = { items: [], page: 1, limit: ORDERS_LIMIT, total: 0 };
  };

  const loadDeliveryHistory = async (id: number, provider: TShippingServiceProvider, code: string) => {
    try {
      isLoadingDeliveryHistory.value = true;
      const res = await getDeliveryHistory(id, provider, code);

      if (res) {
        deliveryHistory.value = res;
      }
    } finally {
      isLoadingDeliveryHistory.value = false;
    }
  };

  const onOpenTrackInfoModal = async (
    integrationData: IPecInfo | ICdekInfo,
    shippingCompanyName: TShippingServiceProvider,
    orderId: number,
  ) => {
    isOpenTrackModal.value = true;
    setCurrentTrackCodeForDeliveryHistory(integrationData, shippingCompanyName);
    await loadDeliveryHistory(orderId, shippingCompanyName, currentCode.value);
  };

  const onCloseTrackInfoModal = () => {
    isOpenTrackModal.value = false;
    integrationDeliveryHistory.value = null;
    deliveryHistory.value = null;
    currentTransportCompany.value = null;
    currentTrackCode.value = '';
    currentCode.value = '';
  };

  const setCurrentTrackCodeForDeliveryHistory = (
    integrationData: IPecInfo | ICdekInfo,
    shippingCompanyName: TShippingServiceProvider,
  ) => {
    currentTransportCompany.value = shippingCompanyName;

    if (shippingCompanyName === 'cdek' && integrationData.trackCode) {
      currentTrackCode.value = integrationData.trackCode;
      currentCode.value = integrationData.code;
    }

    if (shippingCompanyName === 'pec' && integrationData.trackCode) {
      currentTrackCode.value = integrationData.trackCode;
      currentCode.value = integrationData.code;
    }
  };

  const onOpenBarcodeModal = (integrationData: ICdekInfo) => {
    currentTrackCode.value = integrationData.trackCode;
    isOpenBarcodeModal.value = true;
  };

  const onCloseBarcodeModal = () => {
    isOpenBarcodeModal.value = false;
    currentTrackCode.value = '';
  };

  return {
    isLoading,
    orders,
    isFullList,
    integrationDeliveryHistory,
    isLoadingDeliveryHistory,
    deliveryHistory,
    currentTrackCode,
    currentTransportCompany,
    loadShopOrders,
    clearOrderState,

    isOpenTrackModal,
    onOpenTrackInfoModal,
    onCloseTrackInfoModal,

    isOpenBarcodeModal,
    onOpenBarcodeModal,
    onCloseBarcodeModal,
  };
}
