export interface IGlobalNotificationsStore {
  topNotificationsQueue: Array<Partial<INotificationItem>>;
  bottomNotificationsQueue: Array<Partial<INotificationItem>>;
}

export type NotificationPosition = 'top' | 'bottom';

export interface INotificationItem {
  id: string;
  icon: INotificationItemIcon;
  type: EnumNotificationItemStatus;
  title: string;
  message: string;
  button?: INotificationItemButton;
  delay?: number;
  position?: NotificationPosition;
}

export enum EnumNotificationItemStatus {
  Error = 'error',
  Warn = 'warn',
  Info = 'info',
  Success = 'success',
}

export interface INotificationItemButton {
  text: string;
  variant: string;
  handler: () => void;
}

export interface INotificationItemIcon {
  component: string;
  color: string;
}
