import { useMobile } from '~/shared/hooks/useMobile';
import { errorMessages } from '~/shared/config/errorMessages';
import { formatPhone } from '~/shared/helpers/formatPhone';
import useConfirmPhoneNumberModel from '../model';

import WiModalWrapper from '~/shared/ui/WiModalWrapper/index.vue';
import WiInputPhoneWrapper from '~/shared/ui/WiInputPhoneWrapper/index.vue';

import { Components } from '@wikkeo/ui-kit';

const { WiButton } = Components;

export default defineNuxtComponent({
  name: 'WiConfirmPhoneNumberModal',

  components: { WiModalWrapper, WiInputPhoneWrapper, WiButton },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  emit: ['onClose', 'onSetConfimedNumber'],

  setup(_props, { emit }) {
    const { isMobile } = useMobile();
    const managementStore = globalComponentManagementStore();
    const errorMessagesData = ref(errorMessages());
    const { isLoading, updateUserData } = useConfirmPhoneNumberModel();

    const phoneNumber = ref('');
    const isValidPhone = ref(false);

    const closeModal = () => {
      emit('onClose');
    };

    const confirmPhone = async () => {
      await updateUserData({ phone: '+' + formatPhone(phoneNumber.value) }, onSetConfimedNumber);
    };

    const onSetConfimedNumber = () => {
      emit('onSetConfimedNumber', formatPhone(phoneNumber.value));
      closeModal();
      managementStore.setIsOpenVerifyPhoneModal(true);
    };

    const onValidatePhone = (isValid: boolean) => {
      isValidPhone.value = isValid;
    };

    return {
      isLoading,
      isMobile,
      errorMessagesData,
      phoneNumber,
      isValidPhone,
      formatPhone,
      closeModal,
      onValidatePhone,
      confirmPhone,
    };
  },
});
