<template>
  <WiNuxtErrorBoundary>
    <ClientOnly>
      <WiModal
        :modalId="modalId"
        :isOpen="isOpen"
        :width="width"
        :maxHeight="maxHeight"
        :isVisibleContent="isVisibleContent"
        :isNoPadding="isNoPadding"
        :isFullScreen="isFullScreen"
        :isHideSlideControl="isHideSlideControl"
        :isTransparentContent="isTransparentContent"
        @onClose="handleClose"
      >
        <template v-if="$slots.title" #title>
          <slot name="title" />
        </template>

        <template v-if="$slots.subtitle" #subtitle>
          <slot name="subtitle" />
        </template>

        <template v-if="$slots.body" #body>
          <slot name="body" />
        </template>

        <template v-if="$slots.footer" #footer>
          <slot name="footer" />
        </template>
      </WiModal>

      <WiSubstrateArea v-if="isShowSubstrate" />
    </ClientOnly>
  </WiNuxtErrorBoundary>
</template>

<script lang="ts" src="./index.ts" />
<style lang="scss" scoped src="./index.scss"></style>
