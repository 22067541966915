import { useWiInputCountModel } from '../model';
import { Icons } from '@wikkeo/ui-kit';
import WiNuxtErrorBoundary from '@/shared/ui/WiNuxtErrorBoundary/index.vue';

const { WiPlus, WiMinus, WiPlusAlternative, WiMinusAlternative } = Icons;

export default defineNuxtComponent({
  name: 'WiInputCount',

  components: {
    WiPlus,
    WiMinus,
    WiPlusAlternative,
    WiMinusAlternative,
    WiNuxtErrorBoundary,
  },

  props: {
    theme: {
      type: String as PropType<'default' | 'alternative'>,
      default: 'default',
    },

    value: {
      type: Number,
      default: 0,
    },

    min: {
      type: Number,
      default: 0,
    },

    max: {
      type: Number,
      default: Infinity,
    },

    step: {
      type: Number,
      default: 1,
    },

    isNeedZero: {
      type: Boolean,
    },

    isDisabled: {
      type: Boolean,
    },
  },

  emits: ['onChange'],

  setup(props, { emit }) {
    const { value, min, max, step, isNeedZero, isDisabled } = toRefs(props);

    const valueInput = ref(0);

    const {
      isDisableDecrement,
      isDisableIncrement,
      isDisableInput,
      getChangedValueIncrement,
      getChangedValueDecrement,
      getChangedValueInput,
    } = useWiInputCountModel(value, min, max, step, isNeedZero, isDisabled);

    watch(value, () => {
      valueInput.value = value.value;
    });

    onMounted(() => {
      valueInput.value = value.value;
    });

    const onIncrement = () => {
      valueInput.value = getChangedValueIncrement();

      emit('onChange', valueInput.value);
    };

    const onDecrement = () => {
      valueInput.value = getChangedValueDecrement();

      emit('onChange', valueInput.value);
    };

    const onInput = (event: Event) => {
      const newValue = (event.target as any).value;

      valueInput.value = newValue;

      valueInput.value = getChangedValueInput(newValue);

      emit('onChange', valueInput.value);
    };

    return {
      valueInput,
      isDisableDecrement,
      isDisableIncrement,
      isDisableInput,

      onIncrement,
      onDecrement,
      onInput,
    };
  },
});
