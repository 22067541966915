<template>
  <WiNuxtErrorBoundary>
    <WiStaticWrapperControlBlock v-show="isMobile">
      <template #default>
        <section class="wi-common-mobile-control">
          <WiMobileControlHome />

          <WiCategoryBlock />

          <WiMobileControlChat />

          <WiMobileControlCart />

          <WiMobileControlUserMenu />
        </section>
      </template>
    </WiStaticWrapperControlBlock>
  </WiNuxtErrorBoundary>
</template>

<script lang="ts" src="./index.ts"></script>
<style lang="scss" scoped src="./index.scss"></style>
