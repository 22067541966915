import type { TTransportCompanyStatus } from '~/shared/ui/WiTrackInfo/types';
import type { IProductApiV1, IProductOptionApiV1, IProductOptionValueApiV1 } from '../products/types';
import type { IReview } from '../reviews/types';
import type { IShopApiV1 } from '../shops/types';
import type { TShippingServiceProvider } from '../shippings/types';
import type { EnumPromotionUnit } from '../carts/types';
import type { EnumShopPromotionType } from '../orders/types';

export interface IReviewExtended extends IReview {
  blockReason: BanReason | null;
  moderateStatus: ModerateStatus;
  rejectReason: BanReason;
  shopOrder: IShopOrderApiV1;
}

export interface IShopOrderDetailsApiV1 {
  id: number;
  order: IOrderApiV1;
  orderId: number;
  orderVendorLnk: IOrderVendorLnk | null;
  shippingCompany: IShippingCompany | null;
  shippingCompanyId: number | null;
  shop: IShopApiV1;
  shopId: number;
  shopOrderItems: Array<IShopOrderItem>;
  status: ShopOrdersStatusesEnum;
  totalDiscount: number;
  totalQuantity: number;
  totalSum: number;
  totalSumDiscount: number | null;
  user: IUser | null;
  userId: number;
  shopPromotions: Array<IShopPromotions>;
}

export interface IShopPromotions {
  allowedShippingCompanyIds: number | null;
  createdAt: string;
  dateFrom: string;
  dateTo: string;
  description: string;
  discountUnit: EnumPromotionUnit;
  discountValue: number;
  id: number;
  isActive: boolean;
  limitSum: number;
  name: string;
  promoCode: string;
  shopId: number;
  type: EnumShopPromotionType;
  updatedAt: string;
}

export interface IShopOrderApiV1 {
  id: number;
  order: IOrderApiV1;
  orderId: number;
  shippingCompanyId: number | null;
  shippingCompany: IShippingCompany;
  user: IUser | null;
  userId: number;
  shop: IShopApiV1 | null;
  shopId: number;
  shopOrderItems: Array<IShopOrderItem>;
  totalQuantity: number;
  totalSum: number;
  totalSumDiscount: number | null;
  orderVendorLnk: IOrderVendorLnk | null;
  status: ShopOrdersStatusesEnum;
}

export interface IOrderApiV1 {
  id: number;
  userId: number;
  number: string;
  customerEmail: string | null;
  customerName: string | null;
  customerPhone: string | null;
  customerAddress: string | null;
  customerCountry: string | null;
  customerCity: string | null;
  customerZip: string | null;
  shipping: string | null;
  shippingEmail: string | null;
  shippingName: string | null;
  shippingPhone: string | null;
  shippingAddress: string | null;
  shippingCountry: string | null;
  shippingCity: string | null;
  shippingZip: string | null;
  pickupLocation: string | null;
  note: string | null;
  telegramComment: string | null;
  quantity: number;
  sum: number;
  createdAt: string;
}

export interface IDeliveryDetails {
  deliveryPointAddress?: string;
  deliveryPointCode?: string;
  recipientName?: string;
  recipientPhone?: string;
  shippingCompanyCode?: string;
}

export interface IOrderVendorLnk {
  cdekInfo: null | ICdekInfo;
  cdekUuid: null | string;
  deliveryDetails: IDeliveryDetails | null;
  id: number;
  orderId: number;
  pecInfo: null | IPecInfo;
  shippingCompanyId: number;
  shippingDetails: string;
  shopId: number;
  shopOrderId: number;
  lastStatus: null | { code: TTransportCompanyStatus };
}

export interface IPecInfo {
  code: string;
  createdAt: string;
  orderVendorLnkId: number;
  shippingType: string;
  trackCode: string;
  updatedAt: string;
}

export interface ICdekInfo {
  additionalServiceCost: number;
  agentCommissionSum: number;
  cashOnDelivery: number;
  code: string;
  createdAt: string;
  deliveredAt: number;
  invoiceDate: string;
  mainServiceCost: number;
  number: string;
  orderVendor: number;
  recipient: string;
  totalSum: number;
  trackCode: string;
  updatedAt: string;
}

export interface IShippingCompany {
  code: TShippingServiceProvider;
  id: number;
  name: string;
  isActive?: boolean;
  sortOrder?: number;
}

export interface IShopOrderItem {
  discount: number;
  id: number;
  price: number;
  product: IProductApiV1;
  productId: number;
  productOption: IProductOptionApiV1;
  productOptionValue: IProductOptionValueApiV1;
  productOptionValueId: number;
  quantity: number;
  shippingCompanyId: number | null;
  shopOrderId: number;

  isReviewAvailable: boolean | null;
}

export interface IShopOrdersStatusesStatisticResponse {
  statistic: IShopOrdersStatusesStatisticObject;
}

export interface IShopOrdersStatusesStatisticObject {
  waiting_contact: number;
  on_delivery: number;
  awaiting_shipment: number;
  declined: number;
  pending: number;
  processing: number;
  completed: number;
  completedWithoutReviews: number;
}

export enum ShopOrdersStatusesEnum {
  waitingContact = 'waiting_contact',
  onDelivery = 'on_delivery',
  awaitingShipment = 'awaiting_shipment',
  declined = 'declined',
  pending = 'pending',
  processing = 'processing',
  completed = 'completed',
  completedWithoutReviews = 'completedWithoutReviews',
}

export enum ShopOrdersStatusesKeyEnum {
  all = 'all',
  pending = 'pending',
  on_delivery = 'on_delivery',
  completed = 'completed',
  hasReviewsFalse = 'hasReviewsFalse',
  declined = 'declined',
}

export enum BanReason {
  spam = 'spam',
  advertisement = 'advertisement',
  profanity = 'profanity',
  insultsThreats = 'insults_threats',
  notAboutProduct = 'not_about_product',
  incorrectPhoto = 'incorrect_photo',
  fake = 'fake',
}

export enum ModerateStatus {
  published = 'published',
  onModeration = 'on_moderation',
  rejected = 'rejected',
}
