/**
 * Format number as human-readable text.
 * @param value Number of bytes.
 * @param dp Number of decimal places to display.
 * @return Formatted string "32.3k", "5.2млн" etc.
 */
export const roundFloatWithPostfix = (number: number | string, dp = 1) => {
  const { $i18n } = useNuxtApp();

  const thresh = 1000;

  number = Number(number);

  if (Math.abs(number) < thresh) {
    return number;
  }

  const units = [
    $i18n.t('postfix.kilo'),
    $i18n.t('postfix.million'),
    $i18n.t('postfix.billion'),
    $i18n.t('postfix.trillion'),
  ];

  let unitIndex = -1;
  const r = 10 ** dp;

  do {
    number /= thresh;
    ++unitIndex;
  } while (Math.round(Math.abs(number) * r) / r >= thresh && unitIndex < units.length - 1);

  if (!Number.isInteger(number)) {
    number = number.toFixed(dp);
  }

  return `${number}${units[unitIndex].toLowerCase()}`;
};
