import BaseApi from '../..';
import type { IRequestParamsHandler, IResponseWithPaging } from '../../types';
import type { IProductGroup } from './types';

class ProductGroupsApiV1 extends BaseApi {
  private VERSION = 'v1';
  private RESOURCE = 'productGroups';

  async getProductGroups(params?: IRequestParamsHandler<IResponseWithPaging<IProductGroup>>) {
    const url = `${this.VERSION}/${this.RESOURCE}`;

    const requestAsyncData = await useAsyncData<IResponseWithPaging<IProductGroup>>(
      url,
      () =>
        this.get({
          url,
          query: params?.params,
          fetchOptions: params?.fetchOptions,
          errorOptions: params?.errorOptions,
        }),
      params?.asyncDataOptions,
    );

    return requestAsyncData;
  }

  async getProductGroupById(productGroupId: number, params?: IRequestParamsHandler<IProductGroup>) {
    const url = `${this.VERSION}/${this.RESOURCE}/${productGroupId}`;

    const requestAsyncData = await useAsyncData<IProductGroup>(
      url,
      () =>
        this.get({
          url,
          query: params?.params,
          fetchOptions: params?.fetchOptions,
          errorOptions: params?.errorOptions,
        }),
      params?.asyncDataOptions,
    );

    return requestAsyncData;
  }
}

export default ProductGroupsApiV1;
