import type { IImage } from '~/shared/api/types';
import type { IShopEntityMetrics, IShopApiV1, IShopWholesale, IChatChannelInfo } from '~/shared/api/v1/shops/types';
import type { IShopConstructorPayload } from './types';

interface IShopEntity extends IShopApiV1 {
  roundingOrderQuantities(): number;
}

export class ShopEntity implements IShopEntity {
  id!: number;
  userId!: number;
  slug!: string;
  name!: string;
  description!: string;
  sumMin!: number;
  isActive!: boolean;
  rating!: number;
  socialSettings!: any | null;
  createdAt!: string;
  ordersCount!: number;
  reviewsCount!: number;
  answerInterval!: number;
  wholesale!: IShopWholesale | null;
  isVerified!: boolean;
  image!: IImage | null;
  banner!: IImage | null;
  bannerV2!: IImage | null;
  metrics!: IShopEntityMetrics;
  chatChannelInfo!: IChatChannelInfo | null;

  public constructor(shop: IShopConstructorPayload) {
    Object.assign(this, shop);
  }

  public roundingOrderQuantities() {
    const orderQuantities = this.ordersCount;

    if (typeof orderQuantities === 'undefined' || orderQuantities === null) {
      return 0;
    }

    const quantity = typeof orderQuantities === 'string' ? parseInt(orderQuantities) : orderQuantities;

    if (quantity > 1000) {
      return parseInt((quantity / 1000).toString()) * 1000;
    }

    if (quantity <= 1000 && quantity > 500) {
      return 500;
    }

    if (quantity <= 500 && quantity > 300) {
      return 300;
    }

    if (quantity <= 300 && quantity > 100) {
      return 100;
    }

    if (quantity <= 100 && quantity > 50) {
      return 50;
    }

    if (quantity <= 50 && quantity > 10) {
      return 10;
    }

    if (quantity <= 10 && quantity > 0) {
      return quantity;
    }

    return 0;
  }
}
