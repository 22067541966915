import WiVerifyPhoneModal from '~/widgets/login/legacy/WiVerifyPhoneModal/index.vue';

import { useVuelidate } from '@vuelidate/core';
import { required, email, sameAs, minLength, helpers } from '@vuelidate/validators';
import { errorMessages } from '~/shared/config/errorMessages';
import { formatPhone } from '~/shared/helpers/formatPhone';
import { useMobile } from '~/shared/hooks/useMobile';
import useWiRegistrationWidgetApi from '../api/index';
import type { IRegistrationData } from '../types';

import { Components } from '@wikkeo/ui-kit';
import WiInputPhoneWrapper from '~/shared/ui/WiInputPhoneWrapper/index.vue';

const { WiInput, WiButton } = Components;

export default defineNuxtComponent({
  name: 'WiRegistration',

  components: { WiInput, WiInputPhoneWrapper, WiButton, WiVerifyPhoneModal },

  setup() {
    const { isLoadingSignup, onSignup } = useWiRegistrationWidgetApi();

    const { t } = useI18n();
    const { isMobile } = useMobile();

    const errorMessagesData = ref(errorMessages(t));

    const isOpenVerifyModal = ref(false);

    const isValidCurrentPhone = ref<null | boolean>(null);

    const registrationData = ref<IRegistrationData>({
      lastName: '',
      firstName: '',
      phone: '',
      email: '',
      password: '',
      confirmPassword: '',
    });

    const registrationRules = computed(() => {
      return {
        lastName: {
          required: {
            ...required,
            $message: errorMessagesData.value.REQUIRED,
          },

          $autoDirty: true,
        },

        firstName: {
          required: {
            ...required,
            $message: errorMessagesData.value.REQUIRED,
          },

          $autoDirty: true,
        },

        phone: {
          required: {
            ...required,
            $message: errorMessagesData.value.REQUIRED,
          },
        },

        email: {
          email: {
            ...email,
            $message: errorMessagesData.value.INVALID_EMAIL,
          },

          required: {
            ...required,
            $message: errorMessagesData.value.REQUIRED,
          },

          $autoDirty: true,
        },

        password: {
          required: {
            ...required,
            $message: errorMessagesData.value.REQUIRED,
          },

          minLength: helpers.withMessage(errorMessagesData.value.getMinLengthErrorMessage(6), minLength(6)),

          $autoDirty: true,
        },

        confirmPassword: {
          required: {
            ...required,
            $message: errorMessagesData.value.REQUIRED,
          },

          sameAs: helpers.withMessage(
            errorMessagesData.value.SAME_PASSWORD_ERROR,
            sameAs(registrationData.value.password),
          ),

          $autoDirty: true,
        },
      };
    });

    const v$ = useVuelidate(registrationRules, registrationData);

    const handleSignup = async (isVerified: boolean) => {
      isOpenVerifyModal.value = false;

      if (!isVerified) {
        return;
      }

      await onSignup(registrationData.value);
    };

    const setValue = (key: keyof typeof registrationData.value, value: string) => {
      registrationData.value[key] = value;
    };

    const onRegister = () => {
      v$.value.$touch();

      if (v$.value.$error) {
        return;
      }

      onSignup(registrationData.value, onOpenVerifyModal);
    };

    const onOpenVerifyModal = () => {
      isOpenVerifyModal.value = true;
    };

    const onCloseVerifyModal = () => {
      isOpenVerifyModal.value = false;
    };

    const handlerValidationPhone = (value: boolean) => {
      isValidCurrentPhone.value = value;
    };

    return {
      v$,
      isMobile,
      isOpenVerifyModal,
      onOpenVerifyModal,
      onCloseVerifyModal,
      registrationData,
      onRegister,
      handleSignup,
      setValue,
      formatPhone,
      isLoadingSignup,
      errorMessagesData,
      isValidCurrentPhone,
      handlerValidationPhone,
    };
  },
});
