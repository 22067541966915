import { Icons } from '@wikkeo/ui-kit';
import { getCartStore } from '~/features/cart/getCartStore';
import useAppRouter from '~/shared/hooks/useAppRouter';
import WiMobileControlWrapper from '~/shared/ui/mobileControl/modules/WiMobileControlWrapper/ui/index.vue';

const { WiCart, WiCartForCounter } = Icons;

export default defineNuxtComponent({
  name: 'WiMobileControlCart',

  components: {
    WiCart,
    WiMobileControlWrapper,
    WiCartForCounter
  },

  setup() {
    const cartStore = globalCartStore();

    const { dataCartStore } = storeToRefs(getCartStore(cartStore));

    const router = useAppRouter();

    const isActive = ref(router.route.value.path === router.getCartPath());

    const goToCart = () => {
      router.goToCart();
    };

    const cartProductsCount = computed(() => {
      if (!dataCartStore.value.cartEntity || !dataCartStore.value.cartEntity.productsCart) {
        return 0;
      }

      return dataCartStore.value.cartEntity.productsCart.length;
    });

    return {
      isActive,
      cartProductsCount,

      goToCart,
    };
  },
});
