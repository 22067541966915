import { Icons, Directives } from '@wikkeo/ui-kit';
import type { IUserResponse } from '~/shared/api/v1/auth/types';
import WiCustomImage from '~/shared/ui/WiCustomImage/index.vue';
import WiHeaderControlBlockSkeleton from './skeleton/index.vue';
import WiCartPreviewDesktop from '~/shared/ui/header/desktop/WiCartPreviewDesktop/index.vue';

import WiNuxtErrorBoundary from '~/shared/ui/WiNuxtErrorBoundary/index.vue';
import { StringUtils } from '~/shared/utils/string';
import useAppRouter from '~/shared/hooks/useAppRouter';
import { useMobile } from '~/shared/hooks/useMobile';
import { getCartStore } from '~/features/cart/getCartStore';
import { useStoreWithContext } from '~/shared/hooks/useStoreWithContext';
import { useChatStore } from '@wikkeo/wikkeo-chat-library';

const { WiWish, WiChatMessage, WiCart, WiMenuAvatar, WiProfile, WiOrdersBox, WiBox, WiStarOutlined, WiExit } = Icons;
const { WiClickOutside } = Directives;

export default defineNuxtComponent({
  name: 'WiHeaderControlBlock',

  components: {
    WiWish,
    WiChatMessage,
    WiCart,
    WiMenuAvatar,
    WiProfile,
    WiOrdersBox,
    WiBox,
    WiStarOutlined,
    WiExit,
    WiNuxtErrorBoundary,
    WiCustomImage,
    WiHeaderControlBlockSkeleton,
    WiCartPreviewDesktop,
  },

  directives: {
    WiClickOutside,
  },

  props: {
    user: {
      type: [Object, null] as PropType<IUserResponse>,
      required: true,
    },

    isLoadingUserInfo: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['onLogout'],

  setup(props, { emit }) {
    const cartStore = globalCartStore();

    const { dataCartStore } = storeToRefs(getCartStore(cartStore));

    const { user } = toRefs(props);

    const userStore = useGlobalUserStore();

    const { wishlistIds } = storeToRefs(userStore);

    const { isMobile } = useMobile();

    const router = useAppRouter();

    const isShowCartPreview = ref(false);

    const isOpenUserMenu = ref(false);

    const chatStore = useStoreWithContext(useChatStore);
    const unreadRoomCount = computed(() => chatStore.unreadRoomCount);

    const initials = computed(() => StringUtils.getUserInitials(user.value, 'w'));

    const cartProductsCount = computed(() => {
      if (!dataCartStore.value.cartEntity || !dataCartStore.value.cartEntity.productsCart) {
        return 0;
      }

      return dataCartStore.value.cartEntity.productsCart.length;
    });

    const handlerUserMenu = () => {
      if (!user.value) {
        router.goToLogin();
        return;
      }

      isOpenUserMenu.value = !isOpenUserMenu.value;
    };

    const clickOutsideUserMenu = () => {
      if (isOpenUserMenu.value) {
        handlerUserMenu();
      }
    };

    const handlerShowCartPreview = () => {
      if (!dataCartStore.value.cartEntity) {
        return;
      }

      if (isMobile.value) {
        router.goToCart();

        return;
      }

      const { isCart, isCheckout } = router.route.value.meta;

      if (isCart || isCheckout || !dataCartStore.value.cartEntity.productsCart.length) {
        isShowCartPreview.value = false;

        return;
      }

      isShowCartPreview.value = !isShowCartPreview.value;
    };

    const closeUserMenu = () => {
      isOpenUserMenu.value = false;
    };

    const closeCartPreview = () => {
      isShowCartPreview.value = false;
    };

    const onLogout = () => {
      emit('onLogout');
      closeUserMenu();
    };

    return {
      wishlistIds,
      isShowCartPreview,
      initials,
      isOpenUserMenu,
      unreadRoomCount,
      cartProductsCount,

      closeCartPreview,
      onLogout,
      handlerUserMenu,
      handlerShowCartPreview,
      closeUserMenu,
      clickOutsideUserMenu,
    };
  },
});
