<template>
  <WiNuxtErrorBoundary>
    <header class="wi-common-header">
      <div v-show="!isMobile" class="wi-content">
        <WiHeaderNavigation />
      </div>

      <WiStaticWrapperHeader>
        <template #default>
          <div class="wi-common-header__content">
            <div v-show="!isMobile" class="wi-common-header__block">
              <WiCategoryBlock />
            </div>

            <div v-show="isWithSearch" class="wi-common-header__block_full-width">
              <WiHeaderSearchBlock />
            </div>

            <div v-show="!isMobile" class="wi-common-header__block">
              <WiHeaderControlBlock :user="user" :isLoadingUserInfo="isLoadingUserInfo" @onLogout="onLogout" />
            </div>
          </div>
        </template>
      </WiStaticWrapperHeader>
    </header>
  </WiNuxtErrorBoundary>
</template>

<script lang="ts" src="./index.ts"></script>
<style lang="scss" scoped src="./index.scss"></style>
