<template>
  <ProfileLayout>
    <template #default>
      <div class="error-page">
        <div class="content">
          <img src="/public/images/errors/403.png" alt="403.png" loading="lazy" />
          <span class="error-number">403</span>
          <span>
            {{ $t('errorPage.accessDenied') }},
            <button @click="onClearError" class="error-page__clear-button">
              {{ $t('errorPage.backHomeQuestion') }}
            </button>
          </span>
        </div>
      </div>
    </template>
  </ProfileLayout>
</template>

<script lang="ts" src="./index.ts" />
<style lang="scss" src="./index.scss" scoped />
