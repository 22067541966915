import WiModalWrapper from '~/shared/ui/WiModalWrapper/index.vue';
import WiLogin from '~/widgets/WiLogin/ui/index.vue';

export default defineNuxtComponent({
  name: 'WiLoginModal',

  components: { WiLogin, WiModalWrapper },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  emit: ['onClose'],

  setup(_props, { emit }) {
    const closeModal = () => {
      emit('onClose');
    };

    return { closeModal };
  },
});
