import { useChatStore } from '@wikkeo/wikkeo-chat-library';
import { useReCaptcha } from 'vue-recaptcha-v3';

import type { TTelegramAuthType } from '../ui';

import useWiSighInTelegramApi from '../api';
import useAppRouter from '~/shared/hooks/useAppRouter';
import { useStoreWithContext } from '~/shared/hooks/useStoreWithContext';
import { setUser } from '~/shared/helpers/auth';
import { sentryLog } from '~/shared/helpers/sentryLog';
import { FetchError } from 'ofetch';
import { useWish } from '~/shared/hooks/useWish';
import { checkNetworkRecaptchaError } from '~/shared/helpers/errorHandler';

export default function useWiSighInTelegramModel() {
  const { t } = useI18n();
  const router = useAppRouter();
  const { loadWishlistIds } = useWish();
  const recaptchaInstance = useReCaptcha();
  const notificationsStore = globalNotificationsStore();
  const chatStore = useStoreWithContext(useChatStore);
  const managementStore = globalComponentManagementStore();

  const { onLoginByTelegram, onRegisterByTelegram } = useWiSighInTelegramApi();

  async function onTgWidgetLogin(signUpType: TTelegramAuthType, user: any) {
    try {
      await recaptchaInstance?.recaptchaLoaded();

      const recaptchaToken = await recaptchaInstance?.executeRecaptcha('submit');

      if (!recaptchaToken) {
        notificationsStore.addNotificationsPending([
          {
            message: t('error.recaptchaError'),
            type: EnumNotificationItemStatus.Error,
          },
        ]);

        return;
      }

      let res;

      if (signUpType === 'login') {
        res = await onLoginByTelegram(user.id.toString(), recaptchaToken);
      }

      if (signUpType === 'register') {
        res = await onRegisterByTelegram(user.id.toString(), recaptchaToken);
      }

      if (res) {
        setUser(res);

        managementStore.onSuccessLoginCallback?.();

        managementStore.setIsOpenLoginModal(false);

        await loadWishlistIds();

        await chatStore.initChat();

        router.goToProfileAfterLogin();
      }
    } catch (error) {
      if (error instanceof FetchError && error.data?.message) {
        checkNetworkRecaptchaError(error.data?.message);
      }

      sentryLog(error as Error);
    }
  }

  return { onTgWidgetLogin };
}
