export default class ErrorServer extends Error {
  errors: Array<any>;

  constructor(data?: { message?: string; errors?: Array<any> }) {
    const { $i18n } = useNuxtApp();

    super(data?.message || $i18n.t('error.contactTechnicalSupport'));

    this.name = 'Server';
    this.errors = data?.errors || [];
  }
}
