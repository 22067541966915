import type { TCartEntityKey } from '~/entities/cart/types';
import { CartProductEntity } from '~/entities/cartProduct';
import type { ProductEntity } from '~/entities/product';
import type { ProductOptionEntity } from '~/entities/product/productOption';
import type { ProductOptionValueEntity } from '~/entities/product/productOption/productOptionValue';
import { ShopEntity } from '~/entities/shop';
import type { ICartsRequestProduct, ICartsResponseErrors } from '~/shared/api/v1/carts/types';
import {
  ProductPriceType,
  type IProductApiV1,
  type IProductOptionApiV1,
  type IProductOptionValueApiV1,
} from '~/shared/api/v1/products/types';
import type { IShopApiV1 } from '~/shared/api/v1/shops/types';
import { ProductHelpers } from '~/shared/helpers/product';
import { CartProductWithErrorEntity } from '~/entities/cartProductWithError';
import { CartShopAdapter } from './cartShop';

interface ICartProductAdapterFromEntityPayload {
  product: ProductEntity;
  productOption: ProductOptionEntity | null;
  productOptionValue: ProductOptionValueEntity | null;
  productOptionId: number;
  productOptionValueId: number;
  shop: ShopEntity | null;
  quantity?: number;
  isChecked?: boolean;
  cartEntityKey: TCartEntityKey;
}

interface ICartProductAdapterFromApiCartsPayload {
  product: IProductApiV1;
  productOption: IProductOptionApiV1 | null;
  productOptionValue: IProductOptionValueApiV1 | null;
  productOptionId: number;
  productOptionValueId: number;
  shop: IShopApiV1;
  quantity: number;
  isChecked: boolean;
  cartEntityKey: TCartEntityKey;
  pricePrevious: number;
  price: number;
  quantityPrevious: number;
  discount: number;
  discountPrevious: number;
  errors: Array<ICartsResponseErrors>;
}

export class CartProductAdapter {
  public fromEntity(data: ICartProductAdapterFromEntityPayload) {
    const {
      product,
      productOption,
      productOptionValue,
      shop,
      quantity = 0,
      cartEntityKey,
      isChecked = true,
      productOptionId,
      productOptionValueId,
    } = data;

    const isError = this.checkIsError(data);

    if (isError) {
      const value = productOptionValue
        ? {
            id: productOptionValue.id,
            title: productOptionValue.title,
            price: productOptionValue.price,
            quantity: productOptionValue.quantity >= 0 ? productOptionValue.quantity : 0,
            step: productOptionValue.step,
            discounts: productOptionValue.discounts,
            wholesale: productOptionValue.wholesale,
            image: productOptionValue.image,
          }
        : null;

      const option = productOption
        ? {
            id: productOption.id,
            title: productOption.title,
            value: value,
          }
        : null;

      const shopValue = shop ? new CartShopAdapter().fromEntity({ shop: shop!, errors: [] }) : null;

      return new CartProductWithErrorEntity({
        id: product.id,
        productOptionId: productOptionId,
        productOptionValueId: productOptionValueId,
        shopId: product.shopId,
        sku: product.sku,
        name: product.name,
        productGroupId: product.productGroupId,
        price: product.price,
        slug: product.slug,
        shop: shopValue,
        option: option,
        image: product.image,
        priceType: product.priceType,
        quantity: quantity || 0,
        shopWholesales: null,
        isChecked: false,
        cartEntityKey,
        errors: [],
      });
    } else {
      const sumWholesale =
        product.priceType === ProductPriceType.BySum
          ? {
              sumPrice: quantity * productOptionValue!.price,
              sumSmall: quantity * productOptionValue!.wholesale.priceSmall,
              sumNormal: quantity * productOptionValue!.wholesale.priceNormal,
              sumLarge: quantity * productOptionValue!.wholesale.priceLarge,
            }
          : null;

      const shopValue = new CartShopAdapter().fromEntity({ shop: shop!, errors: [] });

      return new CartProductEntity({
        id: product.id,
        productOptionId: productOptionId,
        productOptionValueId: productOptionValueId,
        shopId: product.shopId,
        sku: product.sku,
        name: product.name,
        productGroupId: product.productGroupId,
        price: product.price,
        isFreeShipping: product.isFreeShipping,
        slug: product.slug,
        shop: shopValue,
        option: {
          id: productOption!.id,
          title: productOption!.title,
          value: {
            id: productOptionValue!.id,
            title: productOptionValue!.title,
            price: productOptionValue!.price,
            quantity: productOptionValue!.quantity,
            step: productOptionValue!.step,
            discounts: productOptionValue!.discounts,
            wholesale: productOptionValue!.wholesale,
            image: productOptionValue!.image,
          },
        },
        image: product.image,
        priceType: product.priceType,

        currentDiscountQuantity: quantity,
        shopWholesales: product.priceType === ProductPriceType.BySum ? shop!.wholesale : null,
        shopSumWholesale: null,
        sumWholesale,
        discountId:
          product.priceType === ProductPriceType.ByQuantity
            ? ProductHelpers.getCustomIdDiscount(productOptionValue!.discounts)
            : null,
        currentPrice: 0,
        currentPriceWithoutSale: 0,

        isChecked: isChecked,
        checkedSum: {
          currentDiscountQuantity: quantity,
          shopSumWholesale: null,
          sumWholesale,
          currentPrice: 0,
          currentPriceWithoutSale: 0,
        },

        pricePrevious: 0,
        quantityPrevious: 0,
        discount: 0,
        discountPrevious: 0,
        quantity,

        cartEntityKey,
      });
    }
  }

  public fromApiCarts(data: ICartProductAdapterFromApiCartsPayload) {
    const {
      product,
      productOption,
      productOptionValue,
      shop,
      quantity,
      cartEntityKey,
      isChecked,
      productOptionId,
      productOptionValueId,
      pricePrevious,
      quantityPrevious,
      discount,
      discountPrevious,
      errors,
      price,
    } = data;

    const isError = this.checkIsError(data);

    const value = productOptionValue
      ? {
          id: productOptionValue.id,
          title: productOptionValue.title,
          price: productOptionValue.price,
          quantity: quantity,
          step: productOptionValue.step,
          discounts: productOptionValue.discounts,
          wholesale: productOptionValue.wholesale,
          image: productOptionValue.image,
        }
      : null;

    const option = productOption
      ? {
          id: productOption.id,
          title: productOption.title,
          value: value,
        }
      : null;

    const currentErrors = errors.filter((elem) => {
      if (elem.entity === 'product' && product.id === elem.id) {
        return true;
      }

      if (elem.entity === 'productOption' && productOptionId === elem.id) {
        return true;
      }

      if (elem.entity === 'productOptionValue' && productOptionValueId === elem.id) {
        return true;
      }

      return false;
    });

    const shopErrors = this.getShopErrors(product.shopId, errors);

    const shopValue = shop ? new CartShopAdapter().fromApi({ shop: shop, errors: shopErrors }) : null;

    if (isError || currentErrors.length) {
      return new CartProductWithErrorEntity({
        id: product.id,
        productOptionId: productOptionId,
        productOptionValueId: productOptionValueId,
        sku: product.sku,
        shopId: product.shopId,
        name: product.name,
        productGroupId: product.productGroupId,
        price: 0,
        slug: product.slug,
        shop: shopValue,
        option: option,
        image: product.image,
        priceType: product.priceType,
        quantity: quantity || 0,
        shopWholesales: null,
        isChecked: false,
        cartEntityKey,
        errors: currentErrors,
      });
    } else {
      const sumWholesale =
        product.priceType === ProductPriceType.BySum && !isError
          ? {
              sumPrice: quantity * productOptionValue!.price,
              sumSmall: quantity * productOptionValue!.wholesale.priceSmall,
              sumNormal: quantity * productOptionValue!.wholesale.priceNormal,
              sumLarge: quantity * productOptionValue!.wholesale.priceLarge,
            }
          : null;

      const shopValue = new CartShopAdapter().fromApi({ shop: shop, errors: shopErrors });

      return new CartProductEntity({
        id: product.id,
        productOptionId: productOptionId,
        productOptionValueId: productOptionValueId,
        sku: product.sku,
        shopId: product.shopId,
        name: product.name,
        productGroupId: product.productGroupId,
        price: price,
        slug: product.slug,
        shop: shopValue,
        isFreeShipping: product.isFreeShipping,
        option: {
          id: productOption!.id,
          title: productOption!.title,
          value: {
            id: productOptionValue!.id,
            title: productOptionValue!.title,
            price: productOptionValue!.price,
            quantity: productOptionValue!.quantity >= 0 ? productOptionValue!.quantity : 0,
            step: productOptionValue!.step,
            discounts: productOptionValue!.discounts,
            wholesale: productOptionValue!.wholesale,
            image: productOptionValue!.image,
          },
        },
        image: product.image,
        priceType: product.priceType,
        quantity: quantity,

        currentDiscountQuantity: quantity,
        shopWholesales: product.priceType === ProductPriceType.BySum ? shop.wholesale : null,
        shopSumWholesale: null,
        sumWholesale,
        discountId:
          product.priceType === ProductPriceType.ByQuantity
            ? ProductHelpers.getCustomIdDiscount(productOptionValue!.discounts)
            : null,
        currentPrice: 0,
        currentPriceWithoutSale: 0,

        isChecked,
        checkedSum: {
          currentDiscountQuantity: quantity,
          shopSumWholesale: null,
          sumWholesale,
          currentPrice: 0,
          currentPriceWithoutSale: 0,
        },

        pricePrevious,
        quantityPrevious,
        discount,
        discountPrevious,

        cartEntityKey,
      });
    }
  }

  public fromEntityToApiCarts(product: CartProductEntity | CartProductWithErrorEntity): ICartsRequestProduct {
    return {
      productId: product.id,
      productOptionId: product.productOptionId,
      productOptionValueId: product.productOptionValueId,
      quantity: product.quantity || 0,
      isChecked: product.isChecked,
    };
  }

  public fromOldCartToApiCarts(payload: {
    id: number;
    productOptionId: number;
    productOptionValueId: number;
    quantity: number;
    isChecked?: boolean;
  }) {
    return {
      productId: payload.id,
      productOptionId: payload.productOptionId,
      productOptionValueId: payload.productOptionValueId,
      quantity: payload.quantity,
      isChecked: payload.isChecked !== undefined ? payload.isChecked : false,
    };
  }

  private checkIsError(data: ICartProductAdapterFromEntityPayload | ICartProductAdapterFromApiCartsPayload) {
    let isError = false;

    const { product, productOption, productOptionValue, quantity, shop } = data;

    if (!product || !productOption || !productOptionValue || quantity === null || shop === null) {
      isError = true;
    }

    return isError;
  }

  private getShopErrors(shopId: number, errors: Array<ICartsResponseErrors>) {
    const errorsByCurrentShop = errors.filter((error) => error.entity === 'shop' && error.id === shopId);

    let errorsArrayStrings: Array<string> = [];

    errorsByCurrentShop.forEach((errorItem) => {
      errorsArrayStrings.push(...errorItem.messages);
    });

    return errorsArrayStrings;
  }
}
