import type { TCartStore } from '~/app/store/cart';
import { CartEntity } from '~/entities/cart';
import { CartOrderEntity } from '~/entities/cartOrder';
import { checkIsServer } from '~/shared/browserAPI/isServer';

export const getCartStore = (cartStore: TCartStore): TCartStore => {
  if (checkIsServer()) {
    return cartStore;
  }

  initCartEntityStore(cartStore);
  initCartOrderEntityStore(cartStore);

  return cartStore;
};

const initCartEntityStore = (cartStore: TCartStore) => {
  const cartEntity = storeToRefs(cartStore).dataCartStore.value.cartEntity;

  if (cartEntity instanceof CartEntity) {
    return;
  }

  cartStore.initCartEntity();
};

const initCartOrderEntityStore = (cartStore: TCartStore) => {
  const cartOrderEntity = storeToRefs(cartStore).dataCartStore.value.cartOrderEntity;

  if (cartOrderEntity instanceof CartOrderEntity) {
    return;
  }

  cartStore.initCartOrderEntity();
};
