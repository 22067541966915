import type {
  Router,
  RouteLocationAsRelativeGeneric,
  RouteLocationAsPathGeneric,
  RouteLocationNormalized,
  NavigationFailure,
  LocationQueryRaw,
} from '#vue-router';

export type IRouterPushPayload = RouteLocationAsRelativeGeneric | RouteLocationAsPathGeneric;
export type IRouterPushResponse = Promise<void | NavigationFailure | undefined>;

export interface IAppRouter extends Router {
  isHistory: () => boolean;
  getShopLink: (slug: string, isReturnFullLink?: boolean) => string;
  getProductLink: (slug: string, isReturnFullLink?: boolean) => string;
  getCategoryLink: (slug: string, isReturnFullLink?: boolean) => string;

  goToProfile: (query?: LocationQueryRaw) => void;
  goToLogin: () => void;
  goToUnauthorized: () => void;
  goToShop: (slug: string, params?: IRouterPushPayload) => IRouterPushResponse;
  goToProduct: (slug: string, params?: IRouterPushPayload) => IRouterPushResponse;
  goToCategory: (slug: string, params?: IRouterPushPayload) => IRouterPushResponse;
  goToSearch: (search: string, params?: IRouterPushPayload) => IRouterPushResponse;
  goToProfileOrders: (params?: IRouterPushPayload) => IRouterPushResponse;
  goToProfileOrder: (shopOrderId: number, params?: IRouterPushPayload) => IRouterPushResponse;
  goToProfileReviews: (params?: IRouterPushPayload) => IRouterPushResponse;
  goToProfileChat: (params?: IRouterPushPayload) => IRouterPushResponse;
  goToProfileAfterLogin: () => void;
  goToWishlists: () => void;

  goToHome: () => void;
  goToCart: () => void;
  goToCheckout: () => void;
  goToSuccessCheck: (number: string, params?: IRouterPushPayload) => IRouterPushResponse;
  goToBack: () => void;
  goToProfileDelivery: () => void;
  goToSettingsNotifications: () => void;
  goToSettingTelegram: () => void;

  getProfilePath: () => string;
  getCartPath: () => string;
  getCheckoutPath: () => string;
  getProfileOrdersPath: (query?: string) => string;
  getProfileOrderPath: (shopOrderId: number) => string;
  getProfileChatPath: () => string;
  getProfileReviewsPath: () => string;
  getProfileDeliveryProfilesPath: () => string;
  getProfileSettingsNotificationsPath: () => string;
  getProfileSettingsTelegramPath: () => string;

  getCreateShopPath: () => string;
  getNewProductsPath: () => string;
  getForVendorsPath: () => string;
  getHelpPath: (query?: string) => string;
  getLoginPath: () => string;
  getUnauthorizedPath: () => string;
  getWishlistsPath: () => string;

  route: Ref<RouteLocationNormalized>;
}

const getPath = (string: string, prefix: string = '') =>
  string?.includes('http') ? new URL(string).pathname : prefix + string;

const getFullLink = (slug: string, prefix: string = '') => location.origin + getPath(slug, prefix);

export default (): IAppRouter => {
  const router = useRouter();

  const route = computed(() => router.currentRoute.value);

  const getShopLink = (slug: string, isReturnFullLink?: boolean) =>
    isReturnFullLink ? getFullLink(slug, '/store/') : getPath(slug, '/store/');

  const getProductLink = (slug: string, isReturnFullLink?: boolean) =>
    isReturnFullLink ? getFullLink(slug, '/item/') : getPath(slug, '/item/');

  const getCategoryLink = (slug: string, isReturnFullLink?: boolean) =>
    isReturnFullLink ? getFullLink(slug, '/category/') : getPath(slug, '/category/');

  const getSuccessCheckLink = (number: string) => getPath(number, '/success_check/');
  const getCartPath = () => '/cart';
  const getCheckoutPath = () => '/checkout';
  const getProfilePath = () => '/user/profile';
  const getProfileOrdersPath = (query?: string) => (query ? '/user/orders' + `?${query}` : '/user/orders');
  const getProfileOrderPath = (shopOrderId: number) => `/user/orders/${shopOrderId}`;
  const getProfileChatPath = () => '/user/chat';
  const getProfileReviewsPath = () => '/user/reviews';
  const getProfileDeliveryProfilesPath = () => '/user/deliveryProfiles';
  const getProfileSettingsNotificationsPath = () => '/user/settings/notifications';
  const getProfileSettingsTelegramPath = () => '/user/settings/telegram';

  const getLoginPath = () => '/user/login';
  const getUnauthorizedPath = () => '/user/unauthorized';

  const getWishlistsPath = () => '/user/wishlists';

  const getCreateShopPath = () => '/user/create_shop';
  const getNewProductsPath = () => '/new_products';
  const getForVendorsPath = () => '/for-vendors';
  const getHelpPath = (query?: string) => (query ? '/help' + `?${query}` : '/help');
  const isHistory = () => {
    return router.options.history.state.back !== null;
  };

  const goToProfile = (query?: LocationQueryRaw) => {
    router.push({
      query: query ? query : undefined,
      path: getProfilePath(),
    });
  };

  const goToProfileDelivery = () => {
    router.push({
      path: getProfileDeliveryProfilesPath(),
    });
  };

  const goToSettingsNotifications = () => {
    router.push({
      path: getProfileSettingsNotificationsPath(),
    });
  };

  const goToSettingTelegram = () => {
    router.push({
      path: getProfileSettingsTelegramPath(),
    });
  };

  const goToLogin = () => {
    router.push({
      path: getLoginPath(),
    });
  };

  const goToUnauthorized = () => {
    router.push({
      path: getUnauthorizedPath(),
    });
  };

  const goToWishlists = () => {
    router.push({
      path: getWishlistsPath(),
    });
  };

  const goToShop = (slug: string, params?: IRouterPushPayload) => {
    return router.push({
      ...params,
      path: getShopLink(slug),
    });
  };

  const goToProduct = (slug: string, params?: IRouterPushPayload) => {
    return router.push({
      ...params,
      path: getProductLink(slug),
    });
  };

  const goToCategory = (slug: string, params?: IRouterPushPayload) =>
    router.push({
      ...params,
      path: getCategoryLink(slug),
    });

  const goToSearch = (search: string, params?: IRouterPushPayload) =>
    router.push({
      ...params,
      query: {
        ...(params?.query || {}),
        search,
      },
      path: '/search',
    });

  const goToHome = () => {
    router.push({
      path: '/',
    });
  };

  const goToCart = () => {
    router.push({
      path: '/cart',
    });
  };

  const goToCheckout = () => {
    router.push({
      path: '/checkout',
    });
  };

  const goToSuccessCheck = (number: string, params?: IRouterPushPayload) => {
    return router.push({
      ...params,
      path: getSuccessCheckLink(number),
    });
  };

  const goToProfileOrders = (params?: IRouterPushPayload) =>
    router.push({
      ...params,
      path: getProfileOrdersPath(),
    });

  const goToProfileOrder = (shopOrderId: number, params?: IRouterPushPayload) =>
    router.push({ ...params, path: getProfileOrderPath(shopOrderId) });

  const goToProfileReviews = (params?: IRouterPushPayload) =>
    router.push({
      ...params,
      path: getProfileReviewsPath(),
    });

  const goToProfileChat = (params?: IRouterPushPayload) =>
    router.push({
      ...params,
      path: getProfileChatPath(),
    });

  const goToProfileAfterLogin = () => {
    if (router.currentRoute.value.path === getLoginPath() || router.currentRoute.value.path === getUnauthorizedPath()) {
      goToProfile();
    }
  };

  const goToBack = () => {
    if (isHistory()) {
      router.back();
    } else {
      goToHome();
    }
  };

  return {
    ...router,
    route,

    isHistory,
    getShopLink,
    getProductLink,
    getCategoryLink,

    goToProfile,
    goToLogin,
    goToUnauthorized,
    goToShop,
    goToProduct,
    goToCategory,
    goToHome,
    goToCart,
    goToCheckout,
    goToSuccessCheck,
    goToSearch,
    goToProfileOrders,
    goToProfileOrder,
    goToProfileReviews,
    goToProfileDelivery,
    goToSettingsNotifications,
    goToSettingTelegram,
    goToProfileChat,
    goToProfileAfterLogin,
    goToWishlists,

    // private paths
    getProfilePath,
    getCartPath,
    getCheckoutPath,
    getProfileOrdersPath,
    getProfileOrderPath,
    getProfileChatPath,
    getProfileReviewsPath,
    getProfileDeliveryProfilesPath,
    getProfileSettingsNotificationsPath,
    getProfileSettingsTelegramPath,
    getWishlistsPath,
    // end private paths

    getCreateShopPath,
    getNewProductsPath,
    getForVendorsPath,
    getHelpPath,
    getLoginPath,
    getUnauthorizedPath,
    goToBack,
  };
};
